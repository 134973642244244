import React from "react"
import { View } from "react-native"

import { useColor } from "../hooks/useColor"
import { Color } from "../types"

export function RowSeparator(props: { color?: Color }) {
  const backgroundColor = useColor(props.color ?? "base5")
  return <View style={[{ height: 1 }, { backgroundColor }]} />
}

export function ColumnSeparator(props: { color?: Color }) {
  const backgroundColor = useColor(props.color ?? "base5")

  return <View style={[{ width: 1 }, { backgroundColor }]} />
}
