import { useScreenOrientation } from "./useScreenOrientation"

interface Orientation<T = any> {
  landscape: T
  portrait: T
}

export function useOrientationDependentValue(values: Orientation): any {
  const orientation = useScreenOrientation()

  switch (orientation) {
    case "landscape":
      return values.landscape
    case "portrait":
      return values.portrait
    default:
      return undefined
  }
}
