import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"

// Like useState(), but prevents setState() once the component is unmounted, to avoid the
// "Can't perform a React state update on an unmounted component" error.

export function useStateIfMounted<T>(initialState: T) {
  const isMounted = useRef(false)

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  const [state, setStateInternal] = useState(initialState)
  const setState: Dispatch<SetStateAction<T>> = useCallback(state => {
    if (isMounted.current) setStateInternal(state)
  }, [])

  return [state, setState] as const
}
