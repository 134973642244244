// Removes all empty design / line entries,
// before the first and after
// the last line, which are equal to an empty string.
import { ReceiptOutput } from "~shared/api/graphql"
import { PrinterId } from "~shared/types"

// Evaluates the output type of the receipt through the used printerId.
export function determineOutputType(printerId: PrinterId): ReceiptOutput {
  let outputType: ReceiptOutput
  if (printerId == "email") {
    outputType = "Email"
  } else if (printerId.length != 0) {
    outputType = "Paper"
    // Should never happen
  } else {
    outputType = "NoOutput"
  }

  return outputType
}
