import { atom } from "recoil"
import * as z from "zod"

import { jsonDbAtom } from "@axtesys/react-tools"

import { defaultReceiptPrinterIdState } from "../CashRegister/state"

// Whether we should ask the user to configure their printers on the device
export const askForPrinterSetupState = jsonDbAtom({
  key: "askForPrinterSetup",
  default: true,
  schema: z.boolean(),
})

// When we do the auto-print, use this printerId (or else default)
export const autoPrintPrinterIdState = atom({
  key: "autoPrintPrinterId",
  default: defaultReceiptPrinterIdState,
})
