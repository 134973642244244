import { useEffect, useRef } from "react"

// Executes an effect whenever the state changes from one value to another.
export function useTransitionEffect<T>(
  currentState: T,
  effect: (currentState: T, lastState: T) => void,
) {
  const stateRef = useRef(currentState)

  useEffect(() => {
    const lastState = stateRef.current
    if (currentState != lastState) effect(currentState, lastState)
    stateRef.current = currentState
  }, [currentState, effect])
}
