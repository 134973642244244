import * as z from "zod"

import { createEntityFor } from "@axtesys/react-tools"

export type CompanyId = string

export type CompanyInfo = {
  companyId: CompanyId
  name: string
  taxId: string
  manager: {
    managerId: string
    email: string
    phoneNumber?: string
  }
  address: {
    city: string
    street: string
    streetNumber: string
    zipCode: string
  }
}

export const CompanyEntity = createEntityFor<CompanyInfo>()
  .withSchema(
    z.object({
      companyId: z.string(),
      name: z.string(),
      taxId: z.string(),
      manager: z.object({
        managerId: z.string(),
        email: z.string(),
        phoneNumber: z.ostring(),
      }),
      address: z.object({
        city: z.string(),
        street: z.string(),
        streetNumber: z.string(),
        zipCode: z.string(),
      }),
    }),
  )
  .serialize(data => data)
  .deserialize(json => json)
