import React from "react"

import { ResponsiveSpacing } from "../types"
import { Box } from "./Box"

export type GapProps = {
  size?: ResponsiveSpacing
  vertical?: ResponsiveSpacing
  horizontal?: ResponsiveSpacing
}

export function Gap(props: GapProps) {
  return (
    <Box
      padLeft={props.horizontal ?? props.size}
      padTop={props.vertical ?? props.size}
    />
  )
}
