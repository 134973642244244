import { take } from "lodash"
import { useMemo, useRef } from "react"

import {
  useIntervalEffect,
  useMountEffect,
  useNetworkStatus,
} from "@axtesys/hooks"

import versionInfo from "~src/versionInfo.json"

import { UNAUTHENTICATED } from "../../api/graphql/constants"
import { useHttp } from "../../api/http/useHttp"
import { wait } from "../../lib/Async"
import { isNetworkError, transformError } from "../../lib/Errors"
import { useMutableRecoilState } from "../../lib/Recoil"
import { useIsOperatorLoggedIn, useLogoutAndFail } from "../Authentication"
import { log } from "./log"
import { logMessageQueueState, logMessageSubject } from "./state"

const LOG_DELAY = 60 * 1000
const LOG_TIMEOUT = 15 * 1000
const LOG_TRANSMISSION_INTERVAL = 5 * 1000

type LogFunc = (message: string, ...data: any) => void

const logError: LogFunc = (message, ...data) => log.error(message, ...data)
const logWarn: LogFunc = (message, ...data) => log.warn(message, ...data)
const logInfo: LogFunc = (message, ...data) => log.info(message, ...data)
const logDebug: LogFunc = (message, ...data) => log.debug(message, ...data)
const logTrace: LogFunc = (message, ...data) => log.trace(message, ...data)

export { logError, logWarn, logInfo, logDebug, logTrace }

export function useLogLogic() {
  const http = useHttp()
  const logoutAndFail = useLogoutAndFail()
  const { connected } = useNetworkStatus()
  const isInitRef = useRef(false)
  const sendInProgressRef = useRef(false)
  const [storedLogMessages, updateStoredLogMessages] =
    useMutableRecoilState(logMessageQueueState)
  const isOperatorNotLoggedIn = !useIsOperatorLoggedIn()

  useMountEffect(() => {
    // Make sure that this logic
    // is only ever executed once per app start.
    if (isInitRef.current) return undefined

    // Set up the subscription to the logMessageSubject
    // in order to receive, store and process incoming log messages.
    const messageQueueSubscription = logMessageSubject.subscribe(messages =>
      updateStoredLogMessages(previousMessages => {
        previousMessages.push(...messages)
      }),
    )

    // Initialisation took place therefore
    // we do not need to create another subscription.
    isInitRef.current = true

    return () => messageQueueSubscription.unsubscribe()
  })

  useIntervalEffect(
    LOG_TRANSMISSION_INTERVAL,
    async () => {
      if (!connected) return
      if (!isInitRef.current) return
      if (isOperatorNotLoggedIn) return
      if (sendInProgressRef.current) return
      if (storedLogMessages.length == 0) return

      sendInProgressRef.current = true

      try {
        const messagesToSend = take(storedLogMessages, 1000).map(
          log => log.message,
        )

        const response = await http({
          // Avoids recursive behavior,
          // as call could lead to additional logs being produced.
          log: false,
          method: "POST",
          path: "/api/log",
          timeout: LOG_TIMEOUT,
          body: JSON.stringify(messagesToSend),
          headers: { "Content-Type": "application/json" },
        })

        // In this case we do not want the logs to be removed from the queue,
        // as they still can be transmitted to the server.
        if (!response.ok) {
          // Retry after next successful authentication...
          if (response.status == 401) {
            logError(`Logs upload failed with an ${UNAUTHENTICATED} error`)
            logoutAndFail()
            return
          }

          // ... or wait before trying to transmit again.
          throw Error(JSON.stringify(response))
        }

        // Remove the transmitted messages
        // from the buffer and from the stored state.
        updateStoredLogMessages(currentMessages => {
          currentMessages.splice(0, messagesToSend.length)
        })
      } catch (error: any) {
        const isNetworkingError = isNetworkError(error)
        const commonErrorMessage = "Uploading logs failed with"

        logError(
          isNetworkingError
            ? `${commonErrorMessage} a NetworkError`
            : `${commonErrorMessage} an unknown error`,
          transformError(error),
        )

        // (Network) Errors will persist for a while,
        // so wait at least a minute before retrying.
        await wait(LOG_DELAY)
      } finally {
        sendInProgressRef.current = false
      }
    },
    [
      sendInProgressRef,
      http,
      isOperatorNotLoggedIn,
      isInitRef,
      connected,
      storedLogMessages,
      updateStoredLogMessages,
    ],
  )
}

export function useFormattedVersionInfo() {
  const branch = versionInfo.branch != "live" ? ` (${versionInfo.branch})` : ""
  return useMemo(() => `V ${versionInfo.version}${branch}`, [branch])
}
