import at from "date-fns/locale/de-AT"
import React, { ReactNode, useMemo } from "react"
import { Platform } from "react-native"

import { createCustomContext } from "@axtesys/react-tools"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"

import { DateTimePickerOverlay } from "./input/DateTimePickerContext/DateTimePickerOverlay"
import { ThemeProvider } from "./theme"

export type KassenAppUiProviderProps = {
  strings: {
    general: {
      to: string
      ok: string
      now: string
      note: string
      from: string
      cancel: string
      today: string
      delete: string
    }
    moneyRangeForm: {
      invalidPrice: string
      invalidRange: string
    }
    dateTimeRangeForm: {
      invalidDate: string
      invalidRange: string
      selectionLimit: string
      durations: Record<any, string>
    }
  }
}

type ProviderProps = KassenAppUiProviderProps & { children: ReactNode }

const [ContextProvider, useKassenAppUi] = createCustomContext(
  (props: KassenAppUiProviderProps) => {
    const { strings } = props
    return { strings }
  },
)

export { useKassenAppUi }

export function KassenAppUiProvider(props: ProviderProps) {
  const { children, strings } = props

  const mainContext = (
    <ContextProvider {...props}>
      <DateTimePickerOverlay />
      {children}
    </ContextProvider>
  )

  return (
    <ThemeProvider>
      {Platform.OS == "web" ? (
        <PickerLocalisationProvider strings={strings}>
          {mainContext}
        </PickerLocalisationProvider>
      ) : (
        mainContext
      )}
    </ThemeProvider>
  )
}

function PickerLocalisationProvider({
  children,
  strings: { general },
}: ProviderProps) {
  const localizationProps = useMemo(
    () => ({
      adapterLocale: at,
      dateAdapter: AdapterDateFns,
      localeText: {
        okButtonLabel: general.ok,
        todayButtonLabel: general.today,
        clearButtonLabel: general.delete,
        cancelButtonLabel: general.cancel,
        datePickerDefaultToolbarTitle: "",
        timePickerDefaultToolbarTitle: "",
        dateTimePickerDefaultToolbarTitle: "",
      },
    }),
    [general],
  )

  return (
    <LocalizationProvider
      {...localizationProps}
      dateFormats={{
        shortDate: "dd MMM",
        normalDate: "EEE, dd.MMM yyy",
      }}
    >
      {children}
    </LocalizationProvider>
  )
}
