import { DependencyList, useCallback, useEffect, useRef } from "react"

// Provides Publish/Subscribe functionality.
// Listeners can subscribe to published values and unsubscribe at a later point.
// Publishing or Subscribing will not trigger a re-render of the component.
// the useSubscription hook will automatically subscribe/unsubscribe.

type Subscriber<T> = (value: T) => void
type Subscribe<T> = (subscriber: Subscriber<T>) => Unsubscribe
type Unsubscribe = () => void

export function usePubSub<T>() {
  const subscribersRef = useRef<Subscriber<T>[]>([])

  const subscribe: Subscribe<T> = subscriber => {
    subscribersRef.current.push(subscriber)

    return () => {
      subscribersRef.current = subscribersRef.current.filter(
        item => item != subscriber,
      )
    }
  }

  const publish = useCallback((value: T) => {
    for (const subscriber of subscribersRef.current) subscriber(value)
  }, [])

  const useSubscription = (
    subscriber: Subscriber<T>,
    dependencyList: DependencyList,
  ) => {
    useEffect(
      () => subscribe(subscriber),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      dependencyList,
    )
  }

  return { subscribe, publish, useSubscription }
}
