import React, { ReactNode } from "react"
import { Keyboard, useWindowDimensions } from "react-native"
import { atom, useRecoilState, useSetRecoilState } from "recoil"

import { Overlay } from "../container/Overlay"
import { RowSeparator } from "../display/RowSeparator"
import { Box } from "../layout/Box"
import { Column, Row } from "../layout/FlexBox"
import { Gap } from "../layout/Gap"
import { Label } from "../typography/Label"

type UseDialogResult = {
  // Show a dialog with arbitrary buttons
  showDialog(props: DialogProps): Promise<DialogResult>

  // Show a dialog with only an "Ok" button
  showDialogOk(
    props: DialogContentProps & { okButtonLabel?: string },
  ): Promise<void>

  // Show a dialog with "Ok" or "Cancel" button.
  // Returns true if "Ok" was pressed.
  showDialogOkCancel(
    props: DialogContentProps & {
      okButtonLabel?: string
      cancelButtonLabel?: string
    },
  ): Promise<boolean>

  // Hide the currently shown dialog, if any
  cancelDialog(): void

  // Show a dialog while a background task is running,
  // then close the dialog automatically once it finishes.
  runTaskWithDialog<T>(
    props: Omit<DialogProps, "buttons">,
    task: () => Promise<T>,
  ): Promise<T>
}

type DialogProps = {
  title?: string
  subTitle?: string | ReactNode
  text: string | ReactNode

  buttons: DialogButtonProps[]
}

type DialogButtonProps = {
  label?: string
  onPress?(): void | Promise<void>
}

type DialogContentProps = Pick<DialogProps, "title" | "subTitle" | "text">

type DialogResult = {
  // The index of the button that was pressed to close the dialog
  // null if the dialog was closed without a button press.
  pressedButtonWithIndex?: number
}

type DialogState = {
  dialogProps?: DialogProps
  resultCallback?: (result: DialogResult) => void
}

export function AppDialogProvider(props: { children: ReactNode }) {
  return (
    <>
      {props.children}
      <DialogOverlay />
    </>
  )
}

export function useAppDialog(): UseDialogResult {
  const setDialogState = useSetRecoilState(dialogStateAtom)

  return {
    // Take dialogProps as is.
    // Register a listener that resolves when the dialog is closed somehow.
    showDialog(props: DialogProps): Promise<DialogResult> {
      hideKeyboard()

      return new Promise(resolve => {
        setDialogState({
          dialogProps: props,
          resultCallback: resolve,
        })
      })
    },

    // Take dialogProps and just add the "Ok" button.
    // Register a listener that resolves once the dialog is closed somehow.
    showDialogOk(
      props: DialogContentProps & { okButtonLabel?: string },
    ): Promise<void> {
      hideKeyboard()

      return new Promise(resolve => {
        setDialogState({
          dialogProps: {
            ...props,
            buttons: [{ label: props.okButtonLabel ?? "Ok" }],
          },
          resultCallback: () => requestAnimationFrame(() => resolve()),
        })
      })
    },

    // Take dialogProps and add "Ok" and "Cancel" buttons.
    // Register a listener that resolves once the dialog is closed somehow.
    showDialogOkCancel(
      props: DialogContentProps & {
        okButtonLabel?: string
        cancelButtonLabel?: string
      },
    ): Promise<boolean> {
      hideKeyboard()

      return new Promise(resolve => {
        setDialogState({
          dialogProps: {
            ...props,
            buttons: [
              { label: props.cancelButtonLabel ?? "Abbrechen" },
              { label: props.okButtonLabel ?? "Ok" },
            ],
          },
          resultCallback: result =>
            requestAnimationFrame(() =>
              resolve(result.pressedButtonWithIndex == 1),
            ),
        })
      })
    },

    // Reset the dialog state, and call any registered listener to notify of the cancellation.
    cancelDialog() {
      let listener: DialogState["resultCallback"]

      // Reset state first
      setDialogState(currentState => {
        listener = currentState.resultCallback
        return {}
      })

      // Then notify, to avoid nested state updates
      listener?.({ pressedButtonWithIndex: undefined })
    },

    async runTaskWithDialog<T>(
      props: Omit<DialogProps, "buttons">,
      task: () => Promise<T>,
    ): Promise<T> {
      hideKeyboard()
      setDialogState({ dialogProps: { ...props, buttons: [] } })
      const result = await task()
      setDialogState({})
      return result
    },
  }
}

function DialogOverlay() {
  const { width } = useWindowDimensions()
  const [dialogState, setDialogState] = useRecoilState(dialogStateAtom)
  const { dialogProps: props } = dialogState

  if (!props) return null

  const title = props.title ? (
    <Label h4 text={props.title} padBottom="S" />
  ) : null

  const subTitle =
    typeof props.subTitle == "string" ? (
      <Label h5 text={props.subTitle} padBottom="S" />
    ) : props.subTitle ? (
      <>
        {props.subTitle}
        <Gap vertical="S" />
      </>
    ) : null

  const text =
    typeof props.text == "string" ? (
      <Label text={props.text} padBottom="S" />
    ) : props.text ? (
      <>
        {props.text}
        <Gap vertical="S" />
      </>
    ) : null

  const buttons = (
    <Row>
      {props.buttons.map((button, index) => (
        <Box
          key={index}
          expand
          alignCenter
          padVertical="S"
          onPress={async () => {
            await button.onPress?.()
            dialogState.resultCallback?.({ pressedButtonWithIndex: index })
            setDialogState({})
          }}
        >
          <Label h5 text={button.label} />
        </Box>
      ))}
    </Row>
  )

  return (
    <Overlay centerContent>
      <Column
        pad="S"
        borderRadius="M"
        padBottom="none"
        style={{
          maxWidth: 400,
          width: width - 20,
          alignSelf: "center",
          backgroundColor: "white",
        }}
      >
        {title}
        {subTitle}
        {text}
        <RowSeparator />
        {buttons}
      </Column>
    </Overlay>
  )
}

// Always hide an open keyboard when displaying the dialog component
function hideKeyboard() {
  Keyboard.dismiss()
}

const dialogStateAtom = atom<DialogState>({
  key: "dialogState",
  default: {},
})
