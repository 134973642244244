import { useEffect } from "react"
import { BackHandler, Platform } from "react-native"

// Android ONLY
// Registers a listener to hardware back button press
// and executes a callback when the button is pressed.
// If the callback returns true,
// the press event is consumed and no default actions happen.
export function useBackButtonEffect(
  handleButtonPress: () => boolean | null | undefined,
) {
  useEffect(() => {
    if (Platform.OS != "android") return undefined

    const subscription = BackHandler.addEventListener(
      "hardwareBackPress",
      handleButtonPress,
    )

    return () => subscription.remove()
  }, [handleButtonPress])
}
