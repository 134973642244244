import * as z from "zod"

import { createEntityFor } from "@axtesys/react-tools"

import { STATIC_PRINTERS } from "../feature/ReceiptPrinter/constants"

export type PrinterId = string

export type ReceiptPrinter = {
  printerId: PrinterId

  deviceName: string
  displayName: string

  hasCutter: boolean
  printingWidth: number

  bluetoothDeviceId?: string | null
}

export const ReceiptPrinterEntity = createEntityFor<ReceiptPrinter>()
  .withSchema(
    z.object({
      printerId: z.string(),
      displayName: z.string(),
      deviceName: z.string(),
      bluetoothDeviceId: z.string().optional().nullable(),
      printingWidth: z.number(),
      hasCutter: z.boolean(),
    }),
  )
  .serialize(data => data)
  .deserialize(json => json)

export function receiptPrinterSupportsEscPos(receiptPrinter: ReceiptPrinter) {
  return !STATIC_PRINTERS.includes(receiptPrinter.printerId)
}
