import { DependencyList, useEffect } from "react"

// Executes an async function on mount and whenever a dependency in DependencyList changes.
// Async functions that have been started can not be canceled!
export function useAsyncEffect(
  effect: () => Promise<void>,
  dependencyList: DependencyList,
) {
  useEffect(() => {
    effect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencyList)
}
