import { addDays, differenceInDays } from "date-fns"
import { isEqual } from "lodash"
import React, { useEffect, useMemo, useState } from "react"
import { PixelRatio } from "react-native"

import { useTransitionEffect } from "@axtesys/hooks"
import {
  formatDate,
  formatIsoDate,
  formatIsoDateTime,
  formatTime,
  interpolateString,
  validateDate,
} from "@axtesys/react-tools"

import { useKassenAppUi } from "../../Context"
import { MCIcon } from "../../MCIcon"
import { AlternativePressableOpacity } from "../../button/AlternativePressableOpacity"
import { Icon } from "../../display/Icon"
import { Column } from "../../layout/FlexBox"
import { Label } from "../../typography/Label"
import { useDateTimePicker } from "../DateTimePickerContext"
import { InputRow } from "./InputRow"

type DateTimeRangeRowProps = {
  mode: "date" | "datetime"
  value: { range: [Date, Date] }
  onChange(dateRange: { range: [Date, Date] }): void

  locale?: string
  disableFuture?: boolean
  selectionLimit?: number
  translations?: { confirm: string; cancel: string; title: string }
  onSelectionViolationChange?: (state: boolean) => void
}

type DateLabelProps = Omit<DateTimeRangeRowProps, "value" | "onChange"> & {
  value: Date
  error: boolean
  isMedium: boolean
  onChange(date: Date): void
}

export function DateTimeRangeRow(props: DateTimeRangeRowProps) {
  const { dateTimeRangeForm } = useKassenAppUi().strings
  const { mode, value, selectionLimit, onChange, onSelectionViolationChange } =
    props

  const [endDate, setEndDate] = useState(value.range[1])
  const [startDate, setStartDate] = useState(value.range[0])
  const [endDateViolation, setEndDateViolation] = useState(false)
  const [startDateViolation, setStartDateViolation] = useState(false)
  const [selectionViolation, setSelectionViolation] = useState(false)

  // Side-effects / useEffect hooks

  // Update
  // In case the range value changes, because of an update
  // outside/external to the managing logic of this component,
  // do update the controlled values inside here too.
  useTransitionEffect(value.range, (currentState, lastState) => {
    if (
      isEqual(lastState, currentState) ||
      isEqual([startDate, endDate], currentState)
    )
      return

    setStartDate(currentState[0])
    setEndDate(currentState[1])
  })

  // Validation
  // Check if the entered timespan is valid.
  // 1. Check for general date format validity
  // 2. Verify whether a possible selectionLimit constraint passes
  // 3. Verify that the start is before the end date
  useEffect(() => {
    const validStartDate = validateDate(mode, startDate)
    const validEndDate = validateDate(mode, endDate)

    setStartDateViolation(validStartDate)
    setEndDateViolation(validEndDate)

    if (!validStartDate || !validEndDate) return

    if (
      selectionLimit &&
      differenceInDays(addDays(endDate, 1), startDate) > selectionLimit
    ) {
      setSelectionViolation(true)
      onSelectionViolationChange?.(true)
      setStartDateViolation(true)
      setEndDateViolation(true)
      return
    } else {
      setSelectionViolation(false)
      onSelectionViolationChange?.(false)
      setStartDateViolation(false)
      setEndDateViolation(false)
    }

    if (startDate > endDate) {
      setEndDateViolation(true)
      return
    }

    const range: [Date, Date] =
      mode == "date"
        ? [new Date(formatIsoDate(startDate)), new Date(formatIsoDate(endDate))]
        : [
            new Date(formatIsoDateTime(startDate)),
            new Date(formatIsoDateTime(endDate)),
          ]

    onChange({ range })
  }, [
    endDate,
    onChange,
    startDate,
    mode,
    selectionLimit,
    onSelectionViolationChange,
  ])

  const icon = useMemo(() => {
    let icon: MCIcon
    switch (mode) {
      case "date":
        icon = "calendar"
        break
      case "datetime":
        icon = "calendar-clock"
        break
      default:
        throw Error("Invalid date/time mode passed")
    }
    return icon
  }, [mode])

  const isMedium = PixelRatio.getFontScale() > 1.1
  const iconColor = selectionViolation ? "error" : undefined

  return (
    <InputRow
      icon={{
        name: icon,
        color: iconColor,
        tooltip: selectionLimit
          ? interpolateString(dateTimeRangeForm.selectionLimit, selectionLimit)
          : undefined,
      }}
    >
      <DateLabel
        {...props}
        value={startDate}
        isMedium={isMedium}
        error={startDateViolation || selectionViolation}
        onChange={setStartDate}
      />
      <Icon name="arrow-right" color={iconColor} />
      <DateLabel
        {...props}
        value={endDate}
        isMedium={isMedium}
        error={endDateViolation || selectionViolation}
        onChange={setEndDate}
      />
    </InputRow>
  )
}

function DateLabel({
  mode,
  value,
  error,
  isMedium,
  translations,
  disableFuture,
  onChange,
}: DateLabelProps) {
  const { pickDateOrTime } = useDateTimePicker()

  const onPress = async () => {
    const pickedDate = await pickDateOrTime({
      value,
      translations,
      disableFuture: disableFuture ?? true,
    })

    if (!pickedDate) return

    onChange(pickedDate)
  }

  const labelColor = error ? "error" : undefined

  return (
    <AlternativePressableOpacity style={{ flex: 1 }} onPress={onPress}>
      <Column expand alignCenter>
        <Label medium={isMedium} color={labelColor} text={formatDate(value)} />
        {mode != "date" && (
          <Label
            medium={isMedium}
            color={labelColor}
            text={formatTime(value)}
          />
        )}
      </Column>
    </AlternativePressableOpacity>
  )
}
