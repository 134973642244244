import { assign } from "lodash"
import {
  atom,
  AtomEffect,
  AtomOptions,
  DefaultValue,
  RecoilState,
} from "recoil"
import z from "zod"

import { createJsonDb } from "./JsonDb"

// Marries Recoil.js and jsonDb to add persistence to an atom

// Overload 1
export function jsonDbAtom<T, TSchema extends z.ZodType<T>>(
  options: AtomOptions<T> & {
    schema: TSchema
  },
): RecoilState<T>

// Overload 2
export function jsonDbAtom<T, TSchema extends z.ZodType<any>>(
  options: AtomOptions<T> & {
    schema: TSchema
    serialize: (data: T) => z.infer<TSchema>
    deserialize: (json: z.infer<TSchema>) => T
  },
): RecoilState<T>

// Implementation
export function jsonDbAtom<T, TSchema extends z.ZodType<any>>(
  options: AtomOptions<T> & {
    schema: TSchema
    serialize?: (data: T) => z.infer<TSchema>
    deserialize?: (json: z.infer<TSchema>) => T
  },
) {
  const entity = {
    schema: options.schema,
    serialize: options.serialize ?? (data => data),
    deserialize: options.deserialize ?? (json => json),
  }

  const jsonDb = createJsonDb({
    entity,
    storageKey: options.key,
    seedData: "default" in options ? options.default : undefined,
  })

  const persistenceEffect: AtomEffect<any> = ({ onSet, setSelf, trigger }) => {
    if (trigger == "get") {
      setSelf(async () => {
        const storedData = await jsonDb.load()
        return storedData ?? new DefaultValue()
      })
    }

    onSet(data => {
      if (data instanceof DefaultValue) {
        jsonDb.reset()
      } else {
        jsonDb.store(data)
      }
    })
  }

  return assign(
    atom({
      key: options.key,
      effects: [persistenceEffect],
      default: "default" in options ? options.default : undefined,
    }),
    // add some meta info to the object, so we can reset it later in <RecoilDebuggingOverlay>
    { jsonDbDefaultState: "default" in options ? options.default : undefined },
  )
}
