import React, { useMemo } from "react"
import { FlatList, View } from "react-native"

import { RowSeparator } from "@axtesys/kassen-app-ui"

import {
  PerformanceProps,
  usePerformanceConfig,
} from "~shared/hooks/usePerformanceConfig"

type PerformanceListProps<T> = PerformanceProps<T> & {
  width?: number
  showSeparator?: boolean
  showHeaderSeparator?: boolean
  showFooterSeparator?: boolean
}

export function PerformanceList<T>(props: PerformanceListProps<T>) {
  const {
    items,
    width,
    showHeaderSeparator,
    showFooterSeparator,
    showSeparator = true,
  } = props
  const configProps = showSeparator ? { ...props, additionalOffset: 1 } : props

  const {
    generalProps,
    flatListProps,
    shortcutRefreshProps,
    spaceCorrection: paddingBottom,
    onLayout,
  } = usePerformanceConfig(configProps)

  const specializedProps = useMemo(() => {
    const isItemsNotEmpty = items.length > 0

    return {
      ItemSeparatorComponent: showSeparator ? RowSeparator : undefined,
      ListHeaderComponent:
        showHeaderSeparator && isItemsNotEmpty ? RowSeparator : undefined,
      ListFooterComponent:
        showFooterSeparator && isItemsNotEmpty ? RowSeparator : undefined,
    }
  }, [items.length, showFooterSeparator, showHeaderSeparator, showSeparator])

  return (
    <View
      style={{ width, paddingBottom, flex: !width ? 1 : undefined }}
      onLayout={onLayout}
    >
      <FlatList
        {...generalProps}
        {...flatListProps}
        {...specializedProps}
        {...shortcutRefreshProps}
      />
    </View>
  )
}
