import { produce } from "immer"
import { useCallback, useEffect, useState } from "react"
import * as z from "zod"

import AsyncStorage from "@react-native-async-storage/async-storage"

// JsonDb stores JSON objects in AsyncStorage.
//
// Data stored in and loaded from the DB is checked against a Schema (using zod)
// to make sure that no data corruption occurs.
//
// Data is versioned - when the schema changes, you must add a migration from the previous
// schema to the current one. Migrations use `immer` under the hood, so you can make any changes
// in-place.
// Migrations need to use the version number (starting with 1 for the first migration) as keys
// in the `migrateJson` record.
export type JsonDb<T> = {
  seedData: T
  store(data: T): Promise<void>
  load(): Promise<T | undefined>
  reset(): Promise<void>
}

// Configuration where storage schema is equal to runtime schema
export type JsonDbConfig<TSchema extends z.ZodType<any>> = {
  storageKey: string
  dataSchema: TSchema
  seedData: z.infer<TSchema>
  migrateJson?: Record<number, InPlaceMutation>
}

// Configuration where we convert the data into storage schema on store
export type JsonDbConfig2<TSchema extends z.ZodType<any>, T> = {
  storageKey: string
  entity: JsonDbEntity<T, TSchema>
  seedData: z.infer<TSchema>
  migrateJson?: Record<number, InPlaceMutation>
}

export type JsonConverter<Json, Runtime> = {
  deserialize(json: Json): Runtime
  serialize(data: Runtime): Json
}

type InPlaceMutation<T = any> = (entity: T) => void

// Overload 1 (Storage Schema == Runtime Schema)
export function createJsonDb<TSchema extends z.ZodType<any>>(
  config: JsonDbConfig<TSchema>,
): JsonDb<z.infer<TSchema>>
// Overload 2 (Storage Schema != Runtime Schema)
export function createJsonDb<TSchema extends z.ZodType<any>, TData>(
  config: JsonDbConfig2<TSchema, TData>,
): JsonDb<TData>
// Implementation
export function createJsonDb<
  TSchema extends z.ZodType<any>,
  TData = z.infer<TSchema>,
>(
  config: JsonDbConfig<TSchema> | JsonDbConfig2<TSchema, TData>,
): JsonDb<TData> {
  const dataSchema =
    "entity" in config ? config.entity.schema : config.dataSchema

  const deserialize =
    "entity" in config
      ? config.entity.deserialize
      : (json: z.infer<TSchema>) => json as TData

  const serialize =
    "entity" in config
      ? config.entity.serialize
      : (data: TData) => data as z.infer<TSchema>

  // the highest of all keys specified in migrations, or 0 initially
  let currentRevision = 0
  for (const key of Object.keys(config.migrateJson ?? {})) {
    currentRevision = Math.max(currentRevision, parseInt(key))
  }

  function getStorageKey(revision: number) {
    return `jsonDb/${config.storageKey}/${revision}`
  }

  async function store(data: TData) {
    // convert from runtime schema to storage schema
    const json = serialize(data)

    // validate that the data is actually what we expected
    const validated = dataSchema.safeParse(json)

    const storageKey = getStorageKey(currentRevision)

    if (!validated.success || validated.data == undefined)
      await AsyncStorage.removeItem(storageKey)
    else {
      // convert json to string and store it
      const stringified = JSON.stringify(validated.data)
      await AsyncStorage.setItem(storageKey, stringified)
    }
  }

  async function loadJsonOfRevision(revision: number): Promise<any | null> {
    // If we have the revision, return it as json
    const stringified = await AsyncStorage.getItem(getStorageKey(revision))
    if (stringified != null) return JSON.parse(stringified)

    // If we have a migration from a previous version, try to load that and migrate
    const migrateFromPrevious = config.migrateJson?.[revision]
    if (migrateFromPrevious) {
      const previousJson = await loadJsonOfRevision(revision - 1)
      if (previousJson) {
        return produce(previousJson, migrateFromPrevious)
      }
    }

    // otherwise we fail
    return null
  }

  async function load(): Promise<TData | undefined> {
    // load data as string and convert to json
    const json = await loadJsonOfRevision(currentRevision)
    if (json == null) return undefined

    // validate that the data is actually what we expected
    try {
      const validated = dataSchema.parse(json)

      // convert from storage schema to runtime schema
      return deserialize(validated)
    } catch (error) {
      if (__DEV__)
        console.error(error, {
          stateInStorage: JSON.stringify(json),
          storageKey: getStorageKey(currentRevision),
        })

      // In case of validation errors, return undefined
      return undefined
    }
  }

  async function reset(): Promise<void> {
    const promises: Promise<void>[] = []
    for (let revision = currentRevision; revision >= 0; revision--) {
      promises.push(AsyncStorage.removeItem(getStorageKey(revision)))
    }
    await Promise.all(promises)
  }

  return { seedData: config.seedData, store, load, reset }
}

// useJsonDb is a hook that loads data from the JsonDb and automatically
// writes it back to storage whenever the data is updated.
type UseJsonDbValue<T> = {
  isLoading: boolean
  state: T

  updateState(newValue: T): void
  updateState(updater: (value: T) => T | void): void
}

export function useJsonDb<T>(jsonDb: JsonDb<T>): UseJsonDbValue<T> {
  const [isLoading, setLoading] = useState(true)
  const [state, setState] = useState<T>(jsonDb.seedData)

  useEffect(() => {
    jsonDb.load().then(json => {
      if (json != null) setState(json)
      setLoading(false)
    })
  }, [jsonDb])

  const updateState = useCallback(
    (newValueOrMutate: any) => {
      if (isLoading) throw Error("Mutate called while data is not loaded")

      setState(prevState => {
        // Either replace the previous state directly with newValue,
        // or calculate the next state (using immer) from prevState with a mutate function
        const updatedValue: T =
          typeof newValueOrMutate == "function"
            ? produce(prevState, newValueOrMutate)
            : newValueOrMutate

        // Also store the updated state in AsyncStorage
        jsonDb.store(updatedValue)

        return updatedValue
      })
    },
    [isLoading, jsonDb],
  )

  return { updateState, isLoading, state }
}

// Fluent API for creating entities with a zod schema and serialize/deserialize code for
// mapping runtime types (T) to the zod schema.
type JsonDbEntityCreator<T> = {
  withSchema<TSchema extends z.ZodType<any>>(
    schema: TSchema,
  ): JsonDbEntityCreator2<T, TSchema>
}
type JsonDbEntityCreator2<T, TSchema extends z.ZodType<any>> = {
  serialize(
    converter: (data: T) => z.infer<TSchema>,
  ): JsonDbEntityCreator3<T, TSchema>
}
type JsonDbEntityCreator3<T, TSchema extends z.ZodType<any>> = {
  deserialize(
    converter: (json: z.infer<TSchema>) => T,
  ): JsonDbEntity<T, TSchema>
}
export type JsonDbEntity<T, TSchema extends z.ZodType<any>> = {
  schema: TSchema
  serialize: (data: T) => z.infer<TSchema>
  deserialize: (json: z.infer<TSchema>) => T
}
export function createEntityFor<T>(): JsonDbEntityCreator<T> {
  return {
    withSchema: schema => ({
      serialize: serialize => ({
        deserialize: deserialize => ({ schema, deserialize, serialize }),
      }),
    }),
  }
}
