import Big from "big.js"
import * as z from "zod"

import { createEntityFor } from "@axtesys/react-tools"

import { bigToEuros, Euros, eurosToBig } from "../lib/Money"
import { ArticleId } from "./Article"

export type ArticleKeyId = string

export type ArticleKeyContent = {
  articleKeyId: ArticleKeyId
  lineOne: string
  lineTwo?: string
  color: string
  articleId: ArticleId
  articlePrice: Euros
  orderIndex: number
}

export type ArticleKeyData = Pick<
  ArticleKeyContent,
  "color" | "lineOne" | "lineTwo" | "articleId"
>

export type ArticleKeyDataWithId = ArticleKeyData &
  Pick<ArticleKeyContent, "articleKeyId" | "articlePrice">

export const ArticleKeyContentEntity = createEntityFor<ArticleKeyContent>()
  .withSchema(
    z.object({
      articleKeyId: z.string(),
      lineOne: z.string(),
      lineTwo: z.ostring(),
      color: z.string(),
      orderIndex: z.number(),
      articleId: z.string(),
      articlePrice: z.string(),
    }),
  )
  .serialize(data => ({
    ...data,
    articlePrice: eurosToBig(data.articlePrice).toString(),
  }))
  .deserialize(json => ({
    ...json,
    articlePrice: bigToEuros(Big(json.articlePrice)),
  }))
