import React, { ReactElement } from "react"

import { MCIcon } from "../../MCIcon"
import { Icon } from "../../display/Icon"
import { ToolTipIcon } from "../../display/ToolTipIcon"
import { Row } from "../../layout/FlexBox"
import { Color, TextSize } from "../../types"
import { Label } from "../../typography/Label"
import { Selector, SelectorProps } from "../Selector"

interface IconConfig {
  name?: MCIcon
  color?: Color
  size?: "XS" | "S" | "M" | "L" | "XL"
}

type SelectorInputProps = {
  icon?: IconConfig
  toolTip?: string
  disabled?: boolean
  fontSize?: TextSize

  // For convenience reasons we pass a
  // possible error message to the input component
  // It has no other use than displaying an
  // error state (message is not shown in here)
  errorMessage?: string

  label?: ReactElement | string
} & SelectorProps

export function CheckBoxInput(props: Omit<SelectorInputProps, "type">) {
  return <SelectorInput {...props} type="checkBox" />
}

export function RadioButtonInput(props: Omit<SelectorInputProps, "type">) {
  return <SelectorInput {...props} type="radioButton" />
}

function SelectorInput(props: SelectorInputProps) {
  let icon
  if (props.toolTip) icon = <ToolTipIcon text={props.toolTip} {...props.icon} />
  else if (props.icon) icon = <Icon name="information" {...props.icon} />

  const fontSizeProps = {
    h1: props.fontSize == "h1",
    h2: props.fontSize == "h2",
    h3: props.fontSize == "h3",
    h4: props.fontSize == "h4",
    h5: props.fontSize == "h5",
    default: props.fontSize == "default",
    medium: props.fontSize == "medium",
    small: props.fontSize == "small",
  }
  const errorIndicator = props.errorMessage != undefined

  return (
    <Row alignCenter gap="XXXS">
      <Selector {...props} errorIndicator={errorIndicator} />
      {typeof props.label == "string" ? (
        <Label text={props.label} {...fontSizeProps} />
      ) : (
        props.label
      )}
      {icon}
    </Row>
  )
}
