import React from "react"

import { TextCircle, TextCircleProps } from "./TextCircle"

type AvatarProps = Omit<TextCircleProps, "text"> & {
  user?: { firstName: string; lastName: string }
}

export function Avatar(props: AvatarProps) {
  const initials = [
    props.user?.firstName[0].toUpperCase(),
    props.user?.lastName[0].toUpperCase(),
  ].join("")

  return <TextCircle {...props} text={initials} />
}
