import { useEffect } from "react"
import { Dimensions, Platform } from "react-native"

// Observes the change of window/screen dimensions and fires an event
export function useResizeEffect(eventEmitter: () => void) {
  useEffect(() => {
    if (Platform.OS == "web") {
      window.addEventListener("resize", eventEmitter)

      return () => window.removeEventListener("resize", eventEmitter)
    }

    const screenSizeSubscription = Dimensions.addEventListener(
      "change",
      eventEmitter,
    )
    return () => screenSizeSubscription.remove()
  })
}
