import { useRef, useState } from "react"

export function useReliableSwitchStateRef() {
  const stateRef = useRef<boolean>(false)
  const [state, setState] = useState(false)

  const switchOn = () => {
    stateRef.current = true
    setState(true)
  }
  const switchOff = () => {
    stateRef.current = false
    setState(false)
  }

  return [state, switchOn, switchOff, stateRef] as const
}
