import React, { ReactNode } from "react"

import { Column } from "../layout/FlexBox"
import { Spacer } from "../layout/Spacer"
import { Label } from "../typography/Label"

type FilterBoxProps = {
  title: string
  children: ReactNode
  zIndex?: number
}

export function FilterBox(props: FilterBoxProps) {
  return (
    <Column gap="XXXS" style={{ zIndex: props.zIndex }}>
      <Label bold text={props.title} medium />
      <Spacer />
      {props.children}
      <Spacer />
    </Column>
  )
}
