import { Platform } from "react-native"
import { getUniqueIdSync } from "react-native-device-info"
import * as z from "zod"

import { isEmptyOrBlank, jsonDbAtom } from "@axtesys/react-tools"
import AsyncStorage from "@react-native-async-storage/async-storage"

import { CardDevice } from "~shared/api/graphql"
import { createId } from "~shared/lib/Id"
import { selector } from "~shared/lib/recoil/lib"
import {
  ReceiptPrinter,
  ReceiptPrinterEntity,
} from "~shared/types/ReceiptPrinter"

// Information about the cash register that we're logged in to
export const cashRegisterState = jsonDbAtom({
  key: "cashRegister",
  default: {
    cashRegisterId: "",
    cashRegisterNumber: 0,
    name: "",
  },
  schema: z.object({
    cashRegisterId: z.string(),
    cashRegisterNumber: z.number(),
    name: z.string(),
  }),
})

export const cashRegisterWithoutNumberSelector = selector({
  key: "cashRegisterWithoutNumber",
  get: ({ get }) => {
    const cashRegisterInfo = get(cashRegisterState)

    return {
      cashRegisterId: cashRegisterInfo.cashRegisterId,
      name: cashRegisterInfo.name,
    }
  },
})

// The installation is created at install time and never changed
export const installationIdConstant = selector({
  key: "installationId",
  get: async () => {
    let installationId = await AsyncStorage.getItem("installationId")

    if (!installationId) {
      installationId = Platform.OS != "web" ? getUniqueIdSync() : createId()
      await AsyncStorage.setItem("installationId", installationId)
    }

    return installationId
  },
})

// We are paired once we know our cashRegisterId
export const isPairedSelector = selector({
  key: "isPaired",
  get: ({ get }) => !isEmptyOrBlank(get(cashRegisterState).cashRegisterId),
})

export const receiptPrintersState = jsonDbAtom({
  key: "receiptPrinters",
  default: [] as ReceiptPrinter[],
  schema: z.array(ReceiptPrinterEntity.schema),
})

export const defaultReceiptPrinterIdState = jsonDbAtom({
  key: "defaultReceiptPrinterId",
  default: "email",
  schema: z.string(),
})

export const cardDeviceState = jsonDbAtom({
  key: "cardDevice",
  default: "None" as CardDevice,
  schema: z.enum(["External", "Hobex", "ZVT", "GPtom", "None"]),
})
