import React, { useMemo } from "react"
import { GestureResponderEvent, Platform } from "react-native"

import { isIOSLowerEqual16 } from "@axtesys/react-tools"

import { PressableOpacity, PressableOpacityProps } from "./PressableOpacity"

export function AlternativePressableOpacity(props: PressableOpacityProps) {
  // Apparently the Pressable component
  // is not well optimised on iPhones on iOS version 16 and lower.
  // Therefore, apply a fixing behaviour.
  const shouldUseIPhoneWorkaround = useMemo(
    () => Platform.OS == "ios" && !Platform.isPad && isIOSLowerEqual16(),
    [],
  )

  const onPress = (event: GestureResponderEvent) => {
    if (shouldUseIPhoneWorkaround) return
    props.onPress?.(event)
  }

  const onPressOut = (event: GestureResponderEvent) => {
    props.onPressOut?.(event)
    if (!shouldUseIPhoneWorkaround) return
    props.onPress?.(event)
  }

  return (
    <PressableOpacity {...props} onPress={onPress} onPressOut={onPressOut} />
  )
}
