import React, { lazy } from "react"

import { SharedContext } from "~shared/SharedContext"

import { FrontendSpecificEntryPoint } from "./FrontendSpecificEntryPoint"

const DevSwitcher = lazy(() => import("./DevSwitcher"))

export function EntryPoint() {
  return (
    <SharedContext>
      {__DEV__ ? <DevSwitcher /> : <FrontendSpecificEntryPoint />}
    </SharedContext>
  )
}
