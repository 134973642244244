import Big from "big.js"
import * as z from "zod"

import { createEntityFor } from "@axtesys/react-tools"

import { ArticleGroupId } from "./ArticleGroup"
import { MainGroupId } from "./MainGroup"

export type ArticleId = string

export type Article = {
  articleId: ArticleId
  mainGroupId: MainGroupId
  articleGroupId: ArticleGroupId
  price: Big
  name: string
  taxRate: number
  flags: {
    zeroPriceAllowed: boolean
    negativePrice: boolean
    variablePrice: boolean
    discountBlocked: boolean
    detailsDirectAccess: boolean
  }

  number?: string
  barcodeNumbers?: string[]
  description?: string | null
}

export type ArticleFormData = {
  mainGroupId: string
  articleGroupId: string
  price: Big
  name: string
  taxRate: string
  negativePrice: boolean
  discountBlocked: boolean
  zeroPriceAllowed: boolean
  detailsDirectAccess: boolean

  description?: string
  articleNumber?: string
}

export type InvoiceItemArticle = Omit<Article, "flags" | "description">

export type ArticleData = Omit<Article, "articleId">

export const ArticleEntity = createEntityFor<Article>()
  .withSchema(
    z.object({
      articleId: z.string(), // ArticleId
      mainGroupId: z.string(), // MainGroupId
      articleGroupId: z.string(), // ArticleGroupId
      name: z.string(),
      price: z.string(), // Big
      number: z.ostring(),
      taxRate: z.number(),
      description: z.string().optional().nullable(),
      barcodeNumbers: z.optional(z.array(z.string())),
      flags: z.object({
        zeroPriceAllowed: z.boolean(),
        negativePrice: z.boolean(),
        variablePrice: z.boolean(),
        discountBlocked: z.boolean(),
        detailsDirectAccess: z.boolean().default(false),
      }),
    }),
  )
  .serialize(data => ({
    ...data,
    price: data.price?.toString(),
  }))
  .deserialize(json => ({
    ...json,
    price: Big(json.price),
  }))
