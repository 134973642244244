import { useCallback, useState } from "react"

// A special version of useState that holds an array.
// Instead of setState, it provides stable add / remove callbacks.
// The function identity of add and remove does not change when the component is re-rendered.
export function useArrayState<T>(initial: T[]) {
  const [arrayState, setArrayState] = useState(initial)

  const add = useCallback((item: T) => {
    setArrayState(array => [...array, item])
  }, [])

  const remove = useCallback((item: T) => {
    setArrayState(array => array.filter(arrayItem => arrayItem != item))
  }, [])

  return [arrayState, add, remove] as const
}
