import React, { ReactNode } from "react"
import { StyleProp, ViewStyle } from "react-native"

import { Column } from "../layout/FlexBox"
import { InputLabel, InputLabelProps } from "../typography/InputLabel"

export type TopLabeledContainerProps = InputLabelProps & {
  children: ReactNode
  containerStyle?: StyleProp<ViewStyle>
  onPress?: () => void
}
export function TopLabeledContainer(props: TopLabeledContainerProps) {
  return (
    <Column gap="XXXS" style={props.containerStyle} onPress={props.onPress}>
      <InputLabel {...props} />
      {props.children}
    </Column>
  )
}
