import { useMemo } from "react"

import { KeyLabels } from "@axtesys/kassen-app-ui"

export function useDisplayValue({
  values,
  category,
  displayKeyValuePairs,
}: {
  category: string
  values: (keyof KeyLabels)[]
  displayKeyValuePairs: KeyLabels
}) {
  return useMemo(() => {
    let value = ""
    if (values.length > 1) {
      value = `${values.length} ${category}`
    } else if (values.length == 1) {
      value = displayKeyValuePairs[values[0]] ?? ""
    }
    return value
  }, [category, displayKeyValuePairs, values])
}
