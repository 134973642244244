import React from "react"

import { PressableOpacity } from "../button/PressableOpacity"
import { Row } from "../layout/FlexBox"
import { Label } from "../typography/Label"

export type BreadcrumbsProps = {
  items: BreadCrumbItem[]
}

export type BreadCrumbItem = {
  label: string
  onPress?(): void
}

export function Breadcrumbs({ items }: BreadcrumbsProps) {
  const elements = []
  for (let i = 0; i < items.length; i++) {
    const { label, onPress } = items[i]

    // Separator element
    if (i > 0) elements.push(<Label key={`${i}/`} text=" / " />)

    // TextLink element
    elements.push(
      <PressableOpacity
        key={i}
        disabled={onPress == undefined}
        onPress={onPress}
      >
        <Label
          text={label}
          color={i == items.length - 1 ? "link" : undefined}
        />
      </PressableOpacity>,
    )
  }

  return <Row>{elements}</Row>
}
