import React, { ReactNode, RefObject, useMemo } from "react"
import {
  Pressable,
  PressableProps,
  PressableStateCallbackType,
  StyleProp,
  View,
  ViewStyle,
} from "react-native"

export type PressableOpacityProps = Omit<
  PressableProps,
  "style" | "children"
> & {
  children?: ReactNode
  ref?: RefObject<View>
  alwaysActive?: boolean
  style?: StyleProp<ViewStyle>
}

export function PressableOpacity(props: PressableOpacityProps) {
  const { children, disabled, alwaysActive } = props

  const style = useMemo(
    () =>
      !alwaysActive
        ? ({ pressed }: PressableStateCallbackType) => [
            { opacity: pressed && !disabled ? 0.6 : 1 },
            props.style,
          ]
        : props.style,
    [alwaysActive, disabled, props.style],
  )

  return (
    <Pressable {...props} style={style}>
      {children}
    </Pressable>
  )
}
