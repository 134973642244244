// This is the primary string dictionary for the application.
// You can simply add additional fields here, and the compiler
// will make sure that they are also added for other languages.
import de from "date-fns/locale/de"

import { CommandsErrorCodeMapping } from "../api/graphql/types"

export const Deutsch = {
  appName: "Max & Ben",
  enums: {
    salutations: {
      NotSpecified: "Keine Angabe",
      Mr: "Herr",
      Ms: "Frau",
      NonBinary: "Divers",
    },
    contactSalutations: {
      NotSpecified: "Keine Angabe",
      Mr: "Herr",
      Ms: "Frau",
      NonBinary: "Divers",
      Enterprise: "Firma",
      Family: "Familie",
      Club: "Verein",
    },
    durations: {
      Today: "Heute",
      Yesterday: "Gestern",
      ThisWeek: "Diese Woche",
      ThisMonth: "Dieser Monat",
      ThisYear: "Dieses Jahr",
      Custom: "Individuell",
    },
    accountState: {
      Active: "Aktiv",
      Locked: "Gesperrt",
      Deleted: "Gelöscht",
      AccountNotConfirmed: "E-Mail nicht bestätigt",
      ActivationEmailSent: "Aktivierungs-E-Mail versandt",
      Unknown: "Unbekannt",
    },
    billingReportApproach: {
      CashRegister: "Kasse",
      Operator: "Bediener",
    },
    cardDevices: {
      None: "Keine Anbindung",
      External: "Externes Gerät ohne Anbindung",
      Hobex: "Hobex ViA",
      ZVT: "Hobex ZVT",
      GPtom: "GP tom",
    },
    drawerDevices: {
      None: "Keine Anbindung",
      Sunmi: "Sunmi",
      Printer: "Drucker",
    },
    paymentTypes: {
      Deposit: "Einzahlung",
      Total: "Gesamtumsatz",
      Withdrawal: "Auszahlung",
      CashInDrawer: "Bar in Lade",
    },
    receiptTypes: {
      Paper: "Druck",
      Email: "E-Mail",
      NoOutput: "Nicht ausgegeben",
      NotSpecified: "Nicht festgelegt",
    },
    logLevels: {
      trace: "Detailliert",
      debug: "Debug",
      info: "Normal",
      error: "Nur Fehler",
    },
    adminLogoutOptions: {
      backToAdmin: "Zurück zu Admin",
      logout: "Logout",
    },
    portalLogoutOptions: {
      logout: "Logout",
    },
    printerWidths: {
      31: "31 Zeichen",
      32: "32 Zeichen",
      36: "36 Zeichen",
      41: "41 Zeichen",
      42: "42 Zeichen",
      48: "48 Zeichen",
    },
  },

  authentication: {
    validationCompanyEmail:
      "Bitte geben Sie eine gültige Unternehmer-E-Mail-Adresse ein.",
    validationCompanyPassword:
      "Bitte geben Sie eine gültiges Unternehmer-Passwort ein.",
    userLoggedOutByServer:
      "Ihre Sitzung ist abgelaufen. Sie wurden ausgeloggt.",
    deviceUnpaired: "Dieses Gerät ist nicht mehr mit einer Kasse verknüpft.",

    operatorLogoutDialogTitle: "Offene Umsätze",
    operatorLogoutDialogMessage:
      "Möchten Sie vor dem Logout die Abrechnung durchführen?",
  },

  appLoginFooter: {
    hintRegister: "Ihr Unternehmen noch nicht registriert?",
    register: "Registrieren",
  },
  profile: {
    loggedInAs: "angemeldet als",
  },

  appMenu: {
    articles: "Verkauf",
    cashFlows: "Geldbewegungen",
    receipts: "Belegübersicht",
    interimBillingReport: "Zwischenbericht",
    billingReport: "Abrechnung",
    printerManagement: "Druckerverwaltung",
    changeOperator: "Bedienerwechsel",
    management: "Verwaltung",
    logout: "Logout",
  },
  webMenu: {
    masterData: "Stammdatenverwaltung",
    analysis: "Auswertungen",
    protocols: "Protokolle",
    rksv: "RKSV",
  },

  articles: {
    articles: "Artikel",
    articleNumber: "Artikelnummer",
    articleName: "Artikelbezeichnung",
    mainGroup: "Hauptgruppe",
    articleGroup: "Warengruppe",
    taxRate: "Steuergruppe",
    noArticleForBarcodeNumber:
      "Für den gescannten Barcode liegt kein Artikel vor.",
  },
  reports: {
    closingDay: "Abrechnungstag",
    emptyReportTableText:
      "Es konnten keine Umsätze in dem angegebenen Zeitraum ermittelt werden.",
    selectionLimit: "Sie können maximal %s Tage auswählen.",
  },

  dateTimeValidation: {
    invalidRange: "Bitte einen gültigen Zeitraum eingeben.",
    invalidDate: "Bitte ein gültiges Datum eingeben.",
  },
  moneyValidation: {
    invalidRange: "Bitte geben Sie eine gültige Preisspanne ein.",
    invalidPrice: "Bitte geben Sie einen gültigen Preis ein.",
  },

  leaveDialog: {
    title: "Hinweis",
    cancelPage: "Seite verlassen",
    cancelDialog: "Dialog verlassen",
  },
  leaveAddDialog: {
    confirm: "Fertig anlegen",
    message:
      "Wenn Sie die Seite verlassen,\ngehen alle eingegebenen Daten verloren!",
  },
  leaveEditDialog: {
    confirm: "Fertig bearbeiten",
    pageMessage:
      "Wenn Sie diese Seite verlassen, gehen alle Änderungen verloren!\nDiese sind danach unwiderrufbar!",
    dialogMessage:
      "Wenn Sie diesen Dialog verlassen, gehen alle Änderungen verloren!\nDiese sind danach unwiderrufbar!",
  },

  general: {
    ok: "OK",
    confirm: "Bestätigen",
    save: "Speichern",
    cancel: "Abbrechen",
    close: "Schließen",
    warning: "Warnung",
    error: "Fehler",
    errorGeneric:
      "Ein unerwarteter Fehler ist aufgetreten. Bitte kontaktieren Sie den Support.",
    errorDuplicateCommand:
      "Diese Aktion wurde bereits durchgeführt. Bitte überprüfen Sie, ob diese zur gewünschten Reaktion geführt hat.",
    salutation: "Anrede",
    salutationPlural: "Anreden",
    firstName: "Vorname",
    lastName: "Nachname",
    operator: "Bediener",
    operatorCode: "Bediener-Code",
    password: "Passwort",
    repeatPassword: "Passwort bestätigen",
    name: "Name",
    email: "E-Mail",
    phoneNumber: "Telefon",
    company: "Firma",
    uidNumber: "UID / Steuer Nr.",
    taxIdNumberFull: "UID / Steuernummer",
    street: "Straße",
    streetNumber: "Hausnummer",
    zipCode: "PLZ",
    city: "Ort",
    newItem: "Neu anlegen",
    login: "Login",
    logout: "Logout",
    from: "Von",
    to: "Bis",
    amount: "Menge",
    sum: "Summe",
    net: "Netto",
    gross: "Brutto",
    tax: "Steuer",
    taxGroup: "Steuergruppe",
    cashRegister: "Kasse",
    cashRegisters: "Kassen",
    paymentType: "Zahlungsart",
    paymentTypes: "Zahlungsarten",
    searchPlaceholder: "Bitte geben Sie einen Suchbegriff ein.",
    touchToEnter: "Tippen zur Eingabe",
    date: "Datum",
    time: "Uhrzeit",
    datetime: "Datum / Uhrzeit",
    today: "Heute",
    now: "Jetzt",
    all: "Alle",
    discount: "Rabatt",
    unknown: "Unbekannt",
    unknownDevice: "Unbekanntes Gerät",
    value: "Betrag",
    variant: "Variante",
    discountValue: "Rabattwert",
    origin: "Herkunft",
    number: "Nummer",
    pdfDownload: "PDF-Download",
    csvDownload: "CSV-Download",
    exportMenuTitle: "Exportoption auswählen",
    exportMenuStandard: "Standard Export",
    exportMenuDetailed: "Detaillierter Export",
    printPreview: "Druckansicht",
    download: "Download",
    article: "Artikel",
    printOrMail: "Drucken / E-Mail",
    search: "Suche",
    filter: "Filter",
    country: "Land",
    default: "Standard",
    departments: "Abteilungen",
    personsInCharge: "Ansprechpartner",
    shippingAddress: "Lieferadresse",
    shippingAddresses: "Lieferadressen",
    isDefault: "Als Standard verwenden",
    landLinePhoneNumber: "Festnetz",
    landLinePhoneNumberHint: "+43 3141 1234",
    delete: "Löschen",
    management: "Verwaltung",
    remove: "Entfernen",
    exchange: "Wechseln",
    description: "Beschreibung",
    label: "Bezeichnung",
    graphic: "Grafik",
    color: "Farbe",
    none: "Keine",
    assignment: "Zuordnung",
    note: "Hinweis",
    export: "Export",
    preview: "Vorschau",
    example: "Beispiel",
    countrySelectionTitle: "Wählen Sie ein Land aus...",
    turnover: "Umsatz",
    chooseASalutation: "Wählen Sie eine Anrede aus...",
    username: "Benutzername",
    past: "Vergangenheit",
    future: "Zukunft",
    letterSalutation: "Briefanrede",
    academicTitle: "Titel",
    dateOfBirth: "Geburtsdatum",
    receipts: "Belege",
    contactPhoneNumber: "+43 316 68 28 00",
    mailAddress: "info@maxundben.at",
    websiteLink: "www.maxundben.at/kontakt",
    locale: de.code ?? "de",
    selectDate: "Bitte wählen Sie ein Datum",
    active: "Aktiv",
    showMore: "Mehr anzeigen",
    showLess: "Weniger anzeigen",
    disbursal: "Auszahlung",
    deposit: "Einzahlung",
    editCashRegister: "%s bearbeiten",
    deposits: "Einzahlungen",
    disbursals: "Auszahlungen",
    additionalText: "Bemerkung",
    additionalTextMaxCharacters: "max. 30 Zeichen möglich",
    possibleReasonsTooltip: "Mögliche Ursachen",
    interactionPreventionMessage:
      "Eine nicht unterbrechbare Aktion wird gerade ausgeführt. Bitte haben Sie einen Moment Geduld.",
    aTrust: "A-Trust",
    aTrustAccount: "A-Trust-Account",
    properties: "Eigenschaften",
    icon: "Icon",
    availableDataToolTip:
      "Die verfügbaren Auswahlmöglichkeiten können je nach ausgewähltem Zeitraum variieren.",
    accountingPeriod: "Abrechnungszeitraum von %s",
    accountingPeriodUntil: " bis %s",
    accountingPeriodStillOpen: " (noch offen)",
    client: "Kunde",
    roundingDifference: "Rundungsdifferenz",
    output: "Ausgabe",
    receiptNumber: "Belegnummer",
    account: "Konto",
  },

  screens: {
    RegistrationScreen: {
      title: "Registrierung",
      loginHint: "Sie haben sich bereits registriert? Zum ",
      headingPerson: "Angaben zur Person",
      emailHint: "meine@email.at",
      phoneNumberHint: "+43 123 4567890",
      headingCompany: "Angaben zur Firma",
      uidNumberHint: "ATU12345678 oder 72-999/9722",
      termsOfService1: "Ich habe die",
      termsOfService2: "gelesen und stimme diesen zu.",
      register: "Registrieren",
      successRegisterMessage: 'Die Firma "%s" wurde erfolgreich registriert.',

      validationPasswordsNotMatching:
        "Die eingegebenen Passwörter stimmen nicht überein.",
      validationZipCode: "Bitte geben Sie eine gültige Postleitzahl ein.",
      validationTosNotAccepted:
        "Bitte lesen Sie die Geschäftsbedingungen und stimmen Sie diesen zu.",

      tos: "Geschäftsbedingungen",
      successResendActivationMail:
        'Eine erneute Aktivierungs-E-Mail wurde an folgende E-Mail-Adresse "%s" versendet.',

      invalidEmailAddress: "Die eingegebene E-Mail-Adresse ist ungültig.",
    },
    LoginScreen: {
      title: "Anmeldung",
      registrationHintStart: "Neu hier? ",
      registrationButton: "Registrieren",
      registrationHintEnd: " Sie sich bitte!",
      forgotPassword: "Passwort vergessen?",
      mailNotReceived: "Aktivierungsmail erneut versenden",

      emailConfirmed: "Ihre Registrierung wurde erfolgreich bestätigt.",
      confirmEmail:
        "Bitte bestätigen Sie Ihre E-Mail-Adresse, bevor Sie versuchen, sich einzuloggen.\n" +
        "Überprüfen Sie bitte zusätzlich den Spam-Ordner Ihres Postfaches.",
    },
    ManagerLoginScreen: {
      companyEmail: "Unternehmer-E-Mail",
      password: "Unternehmer-Passwort",
      login: "Kasse einrichten",

      devicePairedMessage: 'Dieses Gerät wurde mit "%s" verknüpft.',

      errorPairingTitle: "Fehler beim Verifizieren der Kasse",
      errorNoAvailableCashRegistersTitle: "Sie haben keine freien Kassen mehr!",

      pairingInProgress: "Gerät wird verknüpft...",

      getInContact: "Kontakt aufnehmen",
      contactUs: "Kontaktieren Sie uns gerne!",

      explanationText:
        "Wenn Ihr Unternehmen bereits registriert ist, dann können Sie dieses Gerät mit einer Kasse verknüpfen.",

      chooseABackend: "Wählen Sie ein System aus...",
    },
    MainGroupsScreen: {
      title: "Haupt- und Warengruppen",
      mainGroups: "Hauptgruppen",
      hintGroupName: "Name der Hauptgruppe",
      successDeleteMessage: 'Die Hauptgruppe "%s" wurde erfolgreich gelöscht.',

      delete: "Hauptgruppe löschen",
      confirmDeletionMessage:
        'Möchten Sie die Hauptgruppe "%s" wirklich löschen?',
      emptyListMessage:
        "Es sind noch keine Hauptgruppen vorhanden. Bitte legen Sie eine Hauptgruppe an.",
      confirmEditMessage:
        "Das Ändern der Steuergruppe wirkt sich auf alle zugeordneten Warengruppen und Artikel aus.",
      orderIndexChanged:
        "Die Sortierreihenfolge der Hauptgruppen wurde angepasst.",
    },
    ArticleGroupsScreen: {
      titleSupport: "Warengruppen in",
      successDeleteMessage: 'Die Warengruppe "%s" wurde erfolgreich gelöscht.',
      emptyListMessage:
        "Für diese Hauptgruppe wurde noch keine Warengruppe angelegt. Bitte legen Sie eine Warengruppe an.",

      delete: "Warengruppe löschen",
      confirmDeletionMessage:
        'Möchten Sie die Warengruppe "%s" wirklich löschen?',
      confirmEditMessage:
        "Das Ändern der Steuergruppe wirkt sich auf alle zugeordneten Artikel aus.",
      orderIndexChanged:
        "Die Sortierreihenfolge der Warengruppen wurde angepasst.",
    },
    MainGroupAddScreen: {
      title: "Hauptgruppe erstellen",
      successAddMessage:
        'Die Hauptgruppe "%s" mit Steuersatz "%s%" wurde erfolgreich angelegt.',
    },
    ArticleGroupAddScreen: {
      title: "Warengruppe erstellen",
      successAddMessage:
        'Die Warengruppe "%s" mit Steuersatz "%s%" wurde erfolgreich angelegt.',
    },
    MainGroupEditScreen: {
      title: "Hauptgruppe bearbeiten",
      successEditMessage:
        'Die Hauptgruppe "%s" mit Steuersatz "%s%" wurde erfolgreich bearbeitet.',
    },
    ArticleGroupEditScreen: {
      title: "Warengruppe bearbeiten",
      successEditMessage:
        'Die Warengruppe "%s" mit Steuersatz "%s%" wurde erfolgreich bearbeitet.',
    },
    ArticleListScreen: {
      title: "Artikel",
      price: "VKP",
      delete: "Artikel löschen",
      confirmDeletionMessage:
        'Möchten Sie den Artikel "%s" wirklich löschen?\n' +
        "Ist dieser Artikel einer Artikeltaste zugeordnet, " +
        "so wird auch diese gelöscht und damit aus sämtlichen Tastenuntergruppen sowie Tastaturlayouts entfernt.",
      successDeleteMessage: 'Der Artikel "%s" wurde erfolgreich gelöscht.',
      emptyListMessage:
        "Es sind noch keine Artikel vorhanden. Bitte legen Sie einen Artikel an.",
      emptyTableMessage:
        "Es wurden keine Artikel zu diesem Suchbegriff gefunden.",
      articleList: "Artikelliste",
    },
    ArticleAddScreen: {
      title: "Artikel erstellen",
      price: "Brutto-VKP",
      successAddMessage: 'Der Artikel "%s" wurde erfolgreich angelegt.',

      validationArticlePriceInvalid:
        "Bitte geben Sie einen gültigen Preis ein (in folgendem Format xx,yy).",
      validationNoArticleGroupsAvailable:
        "Es sind keine Warengruppen für die gewählte Hauptgruppe vorhanden.",

      zeroPriceAllowed: "Nullpreis erlaubt",
      zeroPriceAllowedInfo: "Der Preis darf auch mit 0,00 eingebucht werden.",
      negativePrice: "Negativer Preis",
      negativePriceInfo: "Der eingegebene Preis ist immer negativ.",

      discountBlocked: "Rabattsperre",
      discountBlockedInfo: "Dieser Artikel wird für Rabatte gesperrt.",
      detailsDirectAccess: "Detailabfrage",
      detailsDirectAccessInfo:
        "Wird dieser Artikel berührt, so gelangt der Bediener direkt zu den Artikeldetails.",
      placeholderMainGroup: "Wählen Sie eine Hauptgruppe aus...",
      placeholderArticleGroup: "Wählen Sie eine Warengruppe aus...",
      barcodeNumber: "Barcode",
      assignedBarcodeNumbers: "Zugeordnete Barcodes",
      noBarcodeAssigned: "Es wurde noch kein Barcode zugeordnet.",
      validationGTINInvalid: "Bitte geben Sie einen gültigen Barcode ein.",
      navigateToExistingArticle: "Zum Artikel",
      removeExistingAttribution: "Zuordnung aufheben",
      removeCurrentBarcode: "Barcode löschen",
      removeCurrentArticleNumber: "Artikelnummer löschen",
    },
    ArticleEditScreen: {
      title: "Artikel bearbeiten",
      successEditMessage: 'Der Artikel "%s" wurde erfolgreich bearbeitet.',
    },
    DiscountListScreen: {
      title: "Rabatte",
      discountWasDeleted: 'Der Rabatt "%s" wurde erfolgreich gelöscht.',
      emptyTableText: "Es wurden noch keine Rabatte angelegt.",
      confirmDeletionMessage: 'Möchten Sie den Rabatt "%s" wirklich löschen?',
    },
    DiscountAddScreen: {
      title: "Rabatt erstellen",
      successAddMessage: 'Der Rabatt "%s" wurde erfolgreich angelegt.',
    },
    DiscountEditScreen: {
      title: "Rabatt bearbeiten",
      successEditMessage: 'Der Rabatt "%s" wurde erfolgreich bearbeitet.',
    },
    PaymentMethodListScreen: {
      title: "Zahlungsarten",
      standard: "Standard",
      payments: "Bezahlungen",
      payouts: "Auszahlungen",
      overpayment: "Überzahlung",
      cardPayment: "Kartenzahlung",
      openDrawer: "Kassenlade öffnen",
      description:
        "Die ausgewählten Zahlungsarten stehen in der App zur Verfügung.",
      paymentMethodWasDeleted:
        'Die Zahlungsart "%s" wurde erfolgreich bearbeitet.',
      deletionDialogTitle: "Zahlungsart löschen",
      deletionDialogMessage:
        'Wollen Sie diese Zahlungsart "%s" wirklich löschen? Sie wird zukünftig ' +
        "keinem Unternehmen mehr zur Verfügung stehen.",
      visibleOnMessage:
        'Die Zahlungsart "%s" kann nun in der App verwendet werden.',
      visibleOffMessage:
        'Die Zahlungsart "%s" kann nicht mehr in der App verwendet werden.',
    },
    PaymentMethodForm: {
      enabledByDefault: "Standardmäßig verfügbar",
      usableForPayment: "Bezahlung erlaubt",
      usableForPayout: "Rückgeld / Auszahlung erlaubt",
      overpayAllowed: "Überzahlung erlaubt",
      isCardPayment: "Kartenzahlung",
      rksvMandatory: "RKSV-Pflichtig",
      openDrawer: "Kassenlade öffnen",
    },
    PaymentMethodAddScreen: {
      title: "Zahlungsart erstellen",
      successAddMessage: 'Die Zahlungsart "%s" wurde erfolgreich angelegt.',
    },
    PaymentMethodEditScreen: {
      title: "Zahlungsart bearbeiten",
      successEditMessage: 'Die Zahlungsart "%s" wurde erfolgreich bearbeitet.',
    },
    CashFlowListScreen: {
      title: "Geldbewegungen",
      description:
        "Die ausgewählten Geldbewegungen stehen in der App zur Verfügung.",
      cashFlow: "Geldbewegung",
      standard: "Standard",
      emptyManagerTableText:
        "Es wurden noch keine unternehmensspezifischen Geldbewegungen angelegt.",
      emptyAdminTableText:
        "Es wurden noch keine Geldbewegungen durch den Administrator angelegt.",
      emptyAdminScreenTableText:
        "Es wurden noch keine Geldbewegungen angelegt.",
      createCashFlow: "Neu anlegen",

      deletionDialogTitle: "Geldbewegung löschen",
      deletionDialogMessageAdmin:
        'Wollen Sie diese Geldbewegung "%s" wirklich löschen? Sie wird zukünftig ' +
        "keinem Unternehmer mehr zur Verfügung stehen.",
      deletionDialogMessageManager:
        'Wollen Sie diese Geldbewegung "%s" wirklich löschen? Diese Aktion ist unwiderrufbar.',
      deletionMessage: 'Die Zahlungsart "%s" wurde gelöscht.',

      visibleOnMessage:
        'Die Geldbewegung "%s" kann nun in der App verwendet werden.',
      visibleOffMessage:
        'Die Geldbewegung "%s" kann nicht mehr in der App verwendet werden.',

      predefinedTooltip:
        "Die folgenden angeführten Geldbewegungen bzw. Geldbewegungsarten werden " +
        "Ihnen standardmäßig von MAX&BEN zur Verfügung gestellt. " +
        "Diese können von Ihnen jedoch nur in Ihrer 'Sichtbarkeit in der App' angepasst werden.",
      chooseDeposit: "Bitte wählen Sie die gewünschte Einzahlungsvariante.",
      chooseDisbursal: "Bitte wählen Sie die gewünschte Auszahlungsvariante.",
      emptyListText:
        "Es wurden für diese Geldbewegungsart noch keine Geldbewegungen angelegt.",
    },
    CashFlowAddScreen: {
      title: "Geldbewegung erstellen",
      creationMessage: 'Die Geldbewegung "%s" wurde erfolgreich angelegt.',
    },
    CashFlowEditScreen: {
      title: "Geldbewegung bearbeiten",
      editMessage: 'Die Geldbewegung "%s" wurde erfolgreich bearbeitet.',
    },
    CashFlowForm: {
      enabledByDefault: "Standardmäßig verfügbar",
      usableForDeposit: "Einzahlung erlaubt",
      usableForDisbursal: "Auszahlung erlaubt",
    },
    CashFlowTransferScreen: {
      enterPositive: "Bitte geben Sie einen positiven Betrag ein.",
      depositShort: "EZ",
      disbursalShort: "AZ",
      submit: "Beleg abschließen",
    },
    CashFlowTypeSelectionScreen: {
      chooseCashFlow: "Bitte wählen Sie die gewünschte Geldbewegungsart.",
    },
    ManagementTypeSelectionScreen: {
      chooseManagementOption:
        "Bitte wählen Sie den gewünschten Verwaltungsbereich.",
      emptyListMessage:
        "Es stehen für Sie im Moment keine Verwaltungsbereiche zur Verfügung.",
    },
    AppHome: {
      shoppingCart: "Warenkorb",
      discount: "Rabatt",
      articleAddedToCart: '"%s" wurde dem Warenkorb hinzugefügt.',
      articleRemovedToCart: '"%s" wurde vom Warenkorb entfernt.',
      articleGroups: "Warengruppen",

      refundMessage:
        "Während einer Gutschrift können keine weiteren Artikel hinzugefügt werden.",

      emptyListMessage:
        "Es wurden noch keine Artikel angelegt.\nSie können Artikel im Unternehmer-Interface erstellen.",
      emptyTableMessage:
        "Es wurden keine Artikel zu diesen Suchkriterien gefunden.",
      emptyArticleKeysMessage:
        "Dieser Tastenuntergruppe wurden noch keine Artikeltasten hinzugefügt.\nSie können Artikeltasten im Unternehmer-Interface erstellen und hinzufügen.",
      emptyKeysSubGroupMessage:
        "Dieser Tastenhauptgruppe wurden noch keine Tastenuntergruppen hinzugefügt.\nSie können Tastenuntergruppen im Unternehmer-Interface erstellen und hinzufügen.",
      emptyLayoutMessage:
        "Diesem Tastaturlayout wurden noch keine Elemente hinzugefügt.\nSie können Elemente im Unternehmer-Interface erstellen und hinzufügen.",

      receiptAlreadyExistsError:
        "Der Warenkorb wurde bereinigt, da dieser bereits verarbeitet wurde. Bitte kontrollieren Sie die Belegübersicht.",

      syncSuccess: "Stammdaten wurden aktualisiert.",
      syncFail: "Stammdaten konnten nicht aktualisiert werden.",

      rksvDisabled:
        "Die Signierung von Belegen wurde für diese Kasse deaktiviert.",
      rksvEnabled:
        "Die Signierung von Belegen wurde für diese Kasse aktiviert.",
    },
    ArticleIdentifierBookingScreen: {
      title: "Buchen durch Nummer",
      articleIdentifierLabel: "Artikelnummer/Barcode",
      errorArticleNotFound: "Für den eingegebenen Wert existiert kein Artikel.",
      submitLabel: "Zum Warenkorb hinzufügen",
    },
    DrawerModeEditScreen: {
      title: "Tagesanfangsstand",
      modeTitle: "Bestandsmodus",
      fixed: "Fixer Anfangsbestand",
      openingBalance: "Anfangsbestand",
      currentBalance: "Aktueller Bestand",
      continuous: "Endbestand letzte Abrechnung",
      successMessage:
        'Die Tagesanfangsstand-Konfiguration für "%s" wurde erfolgreich bearbeitet.',
    },
    BluetoothPrinterScreen: {
      title: "Druckerkonfiguration",
      connectAsPrinter: "Als Drucker verbinden",
      removePrinter: "Drucker entfernen",
      setAsDefaultOutput: "Als Standardausgabe verwenden",
      setAsDefaultPrinter: "Als Standarddrucker verwenden",
      displayName: "Anzeigename",
      cutter: "Papierabschneider",
      printingWidth: "Druckbreite",
      cutterHint:
        "Aktivieren Sie dieses Kontrollkästchen, wenn ihr Belegdrucker das automatische Abtrennen des Papiers unterstützt.",
      printerHint:
        "Aktivieren Sie dieses Kontrollkästchen, wenn Sie möchten, " +
        "dass weitere Belege mit Hilfe dieses Druckers ausgedruckt werden sollen.",
      outputHint:
        "Aktivieren Sie dieses Kontrollkästchen, wenn Sie möchten, " +
        "dass weitere Belege mit Hilfe dieser Ausgabe bereitgestellt werden sollen.",
      printSample: "Testdruck",
      connecting: "Verbindung wird hergestellt...",
      validationInvalidName: "Bitte geben Sie einen gültigen Anzeigenamen ein.",
      chooseAPrintingWidth: "Wählen Sie eine Druckbreite aus...",
    },
    CustomPrinterScreen: {
      invoiceByMail: "Rechnungen können per E-Mail versandt werden.",
      invoiceNetworkPrint:
        "Rechnungen können über einen Netzwerkdrucker im (am Drucker) konfigurierten Format ausgedruckt werden.",
    },
    ReceiptPrinterListScreen: {
      connectedPrinters: "Verbundene Drucker",
      foundDevices: "Gefundene Geräte",
      invalidLocationPermissions:
        "Der App fehlen die notwendigen Berechtigungen um Bluetooth-Geräte zu verwenden. Bitte erlauben Sie den Standortzugriff.",
      invalidNearbyDevicesPermissions:
        "Der App fehlen die notwendigen Berechtigungen um Bluetooth-Geräte zu verwenden. Bitte erlauben Sie den Zugriff auf Geräte in Ihrer Nähe.",
      bluetoothTurnedOff:
        "Bluetooth ist ausgeschaltet. Aktivieren Sie Bluetooth um nach Druckern zu suchen.",
      printerNotFoundHint: "Der gewünschte Drucker ist nicht aufgelistet?",
      printerDisabledTooltip: "Drucker ausgeschaltet?",
      nameNotFoundTooltip: "Name wird nicht angezeigt?",
      explanationTooltip:
        "Eventuell wird statt des Druckernamens eine MAC-Adresse oder Identifikationsnummer angezeigt. Diese finden Sie meist auf einem Aufkleber am Gerät oder in dessen Systemeinstellungen.",
      notConnectedTooltip: "Drucker nicht gekoppelt?",
      notConnectedExplanationTooltip1:
        "Manche Drucker benötigen zuerst eine Verbindung mit dem Betriebssystem des Gerätes.",
      notConnectedExplanationTooltip2: "Gehen Sie dazu in die",
      notConnectedExplanationTooltip3:
        "Ihres Gerätes und koppeln Sie den Drucker.\nBei möglicher PIN-Abfrage: 0000 oder überprüfen Sie die Bedienungsanleitung Ihres Druckers.",
      bluetoothSettings: "Bluetooth-Einstellungen",
    },
    OperatorLoginScreen: {
      title: "Einloggen",
      subtitle: "zu ",
      validationOperatorCode:
        "Bitte geben Sie einen gültigen Bediener-Code ein.",
      validationOperatorPassword:
        "Bitte geben Sie eine gültiges Bediener-Passwort ein.",
    },
    ChangeOperatorScreen: {
      title: "Bedienerwechsel",
      inputLabel: "Bediener-Code",
      submitLabel: "Bediener wechseln",
      validationOperatorCode:
        "Bitte geben Sie einen gültigen Bediener-Code ein.",
      successOperatorChanged:
        "Der Bedienerwechsel wurde erfolgreich durchgeführt.",
    },
    OperatorListScreen: {
      title: "Bedienerverwaltung",
      emptyListMessage:
        "Es sind noch keine Bediener vorhanden. Bitte legen Sie einen Bediener an.",
      emptyTableMessage:
        "Es wurden keine Bediener zu diesem Suchbegriff gefunden.",
    },
    OperatorAddScreen: {
      title: "Bediener anlegen",

      error: "Fehler beim Anlegen des Bedieners",
      successAddMessage: 'Der Bediener "%s" wurde erfolgreich angelegt.',

      validationPasswordsNotMatching:
        "Die eingegebenen Passwörter stimmen nicht überein.",
      validationOperatorCode:
        "Bitte geben Sie einen Bediener-Code mit einem Maximum von 8 Stellen ein.",

      permissionsHeading: "Bedienerrechte",
      permissionDiscount: "Rabatt erlaubt",
      maxDiscount: "Max. Rabatt",
      permissionManagement: "Verwaltung erlaubt",
      permissionReportAccess: "Berichte erlaubt",
      toolTipPermissionDiscount:
        "Der Bediener darf an der Kasse Rabatte vergeben.",
      toolTipPermissionManagement:
        "Der Bediener darf an der Kasse Verwaltungsaufgaben (bspw. Kundenverwaltung) durchführen.",
      toolTipPermissionReportAccess:
        "Der Bediener darf an der Kasse Berichte (Zwischenbericht & Abrechnung) einsehen sowie abschließen.",

      featureHeading: "Funktionen",
      featureInstantReceipt: "Sofortrechnung",
      toolTipFeatureInstantReceipt:
        "Ist diese Funktion aktiv, so führen die Zahlungsmöglichkeiten im Warenkorb " +
        "zu einem sofortigen Rechnungsabschluss.",
    },
    OperatorEditScreen: {
      title: "Bediener bearbeiten",
      successEditMessage: 'Der Bediener "%s" wurde erfolgreich bearbeitet.',
    },
    ContactSelectionScreen: {
      title: "Kundenauswahl",

      confirmationTitle: "Adresszuordnung löschen",
      removeConfirmationMessage:
        "Wollen Sie die derzeitige Adresszuordnung wirklich wieder entfernen? Der derzeitige Warenkorb " +
        "besitzt danach keine Kundenzuordnung (inklusive Abteilung, Lieferadresse und Warenkorbrabatt) mehr.",

      successAssignmentMessage:
        'Der Kunde "%s" wurde dem derzeitigen Warenkorb zugeordnet.',
      successUndoMessage: "Kundenzuordnung wurde rückgängig gemacht.",

      successChangeContactMessage:
        'Die Kundenzuordnung des Belegs "%s" wurde aktualisiert.',

      changeConfirmationTitle: "Kundenauswahl wechseln",
      changeConfirmationMessageSale:
        "Es wurde bereits ein Kunde ausgewählt. Sind Sie sich sicher, dass Sie die derzeitige Auswahl wechseln möchten?\n\n" +
        "Die potenziell zugeordnete Abteilung, Lieferadresse und der Warenkorbrabatt werden dadurch ebenfalls geändert.",
      changeConfirmationMessageAssignment:
        "Dieser Beleg wurde bereits einem Kunden zugeordnet. Sind Sie sich sicher, dass Sie die derzeitige Auswahl wechseln möchten?\n\n" +
        "Die potenziell zugeordnete Abteilung und Lieferadresse werden dadurch ebenfalls geändert.",

      discountPresentTitle: "Warenkorbrabatt existent",
      discountPresentMessage:
        "Der derzeitige Warenkorb besitzt bereits einen Rabatt, " +
        "dieser wird durch die Zuordnung dieses Kunden ersetzt.\nMöchten Sie fortfahren?",
      okButtonLabelDiscount: "Fortfahren",
    },
    DepartmentSelectionScreen: {
      title: "Abteilungsauswahl",

      successAssignmentMessage:
        'Die Abteilung "%s" wurde dem derzeitigen Warenkorb zugeordnet.',
      successUndoMessage: "Abteilungszuordnung wurde rückgängig gemacht.",

      confirmationTitle: "Abteilungszuordnung löschen",
      removeConfirmationMessage:
        "Wollen Sie die derzeitige Abteilungszuordnung wirklich entfernen? Der derzeitige Warenkorb " +
        "besitzt danach keine Zuordnung zu einer Abteilung.",
    },
    ShippingAddressSelectionScreen: {
      title: "Lieferadressauswahl",

      successAssignmentMessage:
        'Die Lieferadresse "%s" wurde dem derzeitigen Warenkorb zugeordnet.',
      successUndoMessage: "Lieferadresszuordnung wurde rückgängig gemacht.",

      confirmationTitle: "Lieferadresszuordnung löschen",
      removeConfirmationMessage:
        "Wollen Sie die derzeitige Lieferadresszuordnung wirklich entfernen? Der derzeitige Warenkorb " +
        "besitzt danach keine Zuordnung zu einer Lieferadresse.",
    },
    ContactListScreen: {
      mainTitle: "Adressverwaltung",
      clientHeadline: "Kundenverwaltung",
      clientTitle: "Kunden",
      referenceData: "Stammdaten",
      emptyListMessage:
        "Es sind noch keine Kunden vorhanden. Bitte legen Sie einen Kunden an.",
      emptyTableMessage:
        "Es wurden keine Kunden zu diesen Filterkriterien gefunden.",
      clientNumber: "Kundennummer",
      additionalInfo: "Zusatz",
      receiptText: "Bemerkung",
      text: "Text",
      printOnReceipt: "Text übernehmen",
      application: "Übernahme",
      periodOfCashback: "Zahlungsziel",
      periodOfCashbackDiscount: "Skonto in %",
      clientDiscount: "Rabatt in %",
      nameOfBank: "Name der Bank",
      iban: "IBAN",
      bic: "BIC",
      contactType: "Adresstyp",
      currentMonthTurnover: "Derzeitiger Monatsumsatz",
      currentYearTurnover: "Derzeitiger Jahresumsatz",
      totalTurnover: "Gesamter Umsatz",
      client: "Kunde",
      supplier: "Lieferant",
      clientSupplier: "Kunde & Lieferant",
      billingAddress: "Rechnungsadresse",
      bankingDetails: "Bankdaten",
      financialTerms: "Konditionen",
      turnoverOverview: "Umsatzübersicht",
      ibanHint: "AT12 3456 7890 1234 5678",
      bicHint: "ABCD AD A1 B34",
      enableTurnoverFilters: "Umsatzfilter aktivieren",

      successDeleteMessage: 'Der Kunde "%s" wurde erfolgreich gelöscht.',
      deleteConfirmationTitle: "Kunde löschen",
      deleteConfirmationMessage:
        'Möchten Sie den Kunden "%s" wirklich löschen?',

      receiptTextHint:
        "Die angegebene Bemerkung wird für Belege mit Kundenzuordnung automatisch vorgeschlagen.",

      contactList: "Kundenliste",
      sendByMailTitle: "Kundenliste per E-Mail versenden",
      successSendMessage: 'Die Kundenliste wurde an "%s" versendet.',
    },
    ContactAddScreen: {
      clientHeadingAdd: "Kunde anlegen",
      successAddMessage: 'Der Kunde "%s" wurde erfolgreich angelegt.',
      validationContactNumber:
        "Ein Kontakt mit dieser Kundennummer ist bereits vorhanden.",
    },
    ContactEditScreen: {
      clientHeadingEdit: "Kunde bearbeiten",
      successEditMessage: 'Der Kunde "%s" wurde erfolgreich bearbeitet.',
    },
    DepartmentListScreen: {
      title: "Abteilungen",
      emptyListMessage:
        "Es sind noch keine Abteilungen vorhanden. Bitte legen Sie eine Abteilung an.",
      emptyTableMessage:
        "Es wurden keine Abteilungen zu diesem Suchbegriff gefunden.",
      successDeleteMessage: 'Die Abteilung "%s" wurde erfolgreich gelöscht.',
      deleteConfirmationTitle: "Abteilung löschen",
      deleteConfirmationMessage:
        'Möchten Sie die Abteilung "%s" wirklich löschen?',
    },
    DepartmentAddScreen: {
      headingAdd: "Abteilung anlegen",
      successAddMessage: 'Die Abteilung "%s" wurde erfolgreich angelegt.',
    },
    DepartmentEditScreen: {
      headingEdit: "Abteilung bearbeiten",
      successEditMessage: 'Die Abteilung "%s" wurde erfolgreich bearbeitet.',
    },
    PersonInChargeListScreen: {
      title: "Ansprechpartner",
      emptyListMessage:
        "Es sind noch keine Ansprechpartner vorhanden. Bitte legen Sie einen Ansprechpartner an.",
      emptyTableMessage:
        "Es wurden keine Ansprechpartner zu diesem Suchbegriff gefunden.",
      successDeleteMessage:
        'Der Ansprechpartner "%s" wurde erfolgreich gelöscht.',
      deleteConfirmationTitle: "Ansprechpartner löschen",
      deleteConfirmationMessage:
        'Möchten Sie den Ansprechpartner "%s" wirklich löschen?',
    },
    PersonInChargeAddScreen: {
      headingAdd: "Ansprechpartner anlegen",
      successAddMessage: 'Der Ansprechpartner "%s" wurde erfolgreich angelegt.',
    },
    PersonInChargeEditScreen: {
      headingEdit: "Ansprechpartner bearbeiten",
      successEditMessage:
        'Der Ansprechpartner "%s" wurde erfolgreich bearbeitet.',
    },
    ShippingAddressListScreen: {
      title: "Lieferadressen",
      emptyListMessage:
        "Es sind noch keine Lieferadressen vorhanden. Bitte legen Sie eine Lieferadresse an.",
      emptyTableMessage:
        "Es wurden keine Lieferadressen zu diesem Suchbegriff gefunden.",
      successDeleteMessage:
        'Die Lieferadresse "%s" wurde erfolgreich gelöscht.',
      deleteConfirmationTitle: "Lieferadresse löschen",
      deleteConfirmationMessage:
        'Möchten Sie die Lieferadresse "%s" wirklich löschen?',
    },
    ShippingAddressAddScreen: {
      headingAdd: "Lieferadresse anlegen",
      successAddMessage: 'Die Lieferadresse "%s" wurde erfolgreich angelegt.',
    },
    ShippingAddressEditScreen: {
      headingEdit: "Lieferadresse bearbeiten",
      successEditMessage:
        'Die Lieferadresse "%s" wurde erfolgreich bearbeitet.',
    },
    PortalDashboardScreen: {
      title: "Dashboard",
    },
    CashRegisterListScreen: {
      title: "Kassenverwaltung",
      listTitle: "Kassen",
      cashRegisterNumber: "Kassennummer",
      device: "Gerät",
      notPaired: "Nicht verknüpft",
      dialogTitle: 'Wollen Sie die Verknüpfung der "%s" mit "%s" löschen?',
      dialogMessage:
        'Nur die Verbindung wird gelöscht, nicht die Kasse selbst.\nDiese bleibt mit der Nummer "%s" im Archiv erhalten.\n\nSie können "%s" danach mit einem neuen Gerät verknüpfen.',
      emptyListMessage: "Es sind noch keine Kassen vorhanden.",
      successUnpairMessage:
        'Kassen-Verknüpfung mit "%s" wurde erfolgreich aufgehoben.',
      pairedWith: "Verknüpft mit",
      emptyTableMessage:
        "Es wurden keine Kassen zu diesem Suchbegriff gefunden.",
      successUnpairManagerInfo:
        "Bitte löschen Sie nun die Max&Ben-App von dem entknüpften Gerät: %s",
    },
    CashRegisterEditScreen: {
      terminalId: "Terminal-ID",
      terminalPassword: "Terminal-Passwort",
      terminalIP: "Terminal-IP-Adresse",
      terminalPort: "Terminal-Port",
      nameOfPrinter: "Name des Druckers",
      integration: "Anbindung",
      drawerDevice: "Kassenlade",
      receiptDesign: "Bon-Belegdesign",
      a4ReceiptDesign: "A4-Belegdesign",
      deviceLog: "Gerätelog",
      cardPayment: "Kartenzahlung",
      printerName: "Druckername",
      displayName: "Anzeigename",
      defaultPrinter: "Standarddrucker",
      paperCutter: "Papierabschneider",
      chooseAPrinterPlaceholder: "Wählen Sie einen Drucker aus...",
      gpTomTooltip:
        "Um die GP tom Anbindung nützen zu können, müssen Sie sowohl " +
        "die GP tom App als auch die GP tom PIN App auf Ihrem Kassengerät installiert haben.",
      cashRegisterEditSuccess:
        'Die Einstellungen der "Kasse %s" wurden erfolgreich bearbeitet.',
      cashRegisterManagement: "Kassenverwaltung",
      cashRegisters: "Kassen",
      breadCrumbHeading: "%s bearbeiten",
      pairedWith: "Verknüpft mit %s",
      printerSelectionErrorMessage: "Bitte wählen Sie einen Drucker aus.",
      defaultPrinterChangeSuccess:
        'Der Drucker "%s" wurde als neuer Standarddrucker festgelegt.',
    },
    PrinterEditScreen: {
      title: "Druckerdetails",
      successEditMessage: 'Der Drucker "%s" wurde erfolgreich bearbeitet.',
    },
    CashRegisterPhysicalReceiptDesignScreen: {
      title: "Bon-Belegdesign",
      footers: "Fußzeilen",
      head: "Belegkopf",
      newDraft: "Neuer Entwurf",
      deleteDesign: "Design verwerfen",
      size: "Größe",
      imageContrastTooltip:
        "Sollte Ihre Grafik auf dem Beleg nicht sichtbar sein, " +
        "überprüfen Sie bitte den Kontrast des Bildes. " +
        "Sehr helle Grafiken können Probleme während des Druckvorganges hervorrufen.",
      successEditMessage:
        'Das Belegdesign von "%s" wurde erfolgreich bearbeitet.',
      successDiscardMessage:
        'Das Belegdesign von "%s" wurde erfolgreich verworfen.',
      confirmResetMessage:
        "Wenn Sie jetzt abbrechen gehen alle bisherigen Änderungen verloren!\n" +
        "Diese sind unwiderrufbar!",
      confirmDiscardMessage:
        "Wenn Sie dieses Design verwerfen, " +
        "gehen alle bisherigen Änderungen sowie der aktuelle Entwurf verloren.\n" +
        "Diese Aktion ist unwiderrufbar!",
      discardDraft: "Entwurf verwerfen",
      discardDesign: "Design verwerfen",
    },
    CashRegisterA4ReceiptDesignScreen: {
      title: "A4-Belegdesign",
      sideLayoutTitle: "Seitenränder",
      sideLayoutTooltip: "Die Seitenmaße werden in Zentimeter (cm) angegeben.",
      topEdge: "Oberkante",
      bottomEdge: "Unterkante",
      letterHeadMode: "Briefkopfmodus",
      extraSettingsPlaceholder: "Grafik & Seitenränder",
      headerEntriesTooltip:
        "Werden Zeilen des Belegkopfes farblich hervorgehoben, " +
        "so werden diese Zeilen am A4-Beleg abgeschnitten, " +
        "sollte der Beleg an einen Kunden adressiert sein.",
      imageDimensionTooltip:
        "Werden der Hintergrund oder die Umrandung der Grafik farblich hervorgehoben, " +
        "so kann diese in den Bereich des Adresskopfes hineinragen, " +
        "sollte der Beleg an einen Kunden adressiert sein.",
    },
    CashRegisterSystemConfigurationScreen: {
      title: "Kassensystemeinstellungen",
      headline: "Kassen- und Tagesabschluss",
      endOfDay: "Tagesabschluss",
      dailyAt: "Täglich um",
      reportVariant: "Abrechnungsart",
      cashRegisterTooltip: "Kassenabschluss erfolgt je Kasse",
      operatorTooltip: "Kassenabschluss erfolgte je Bediener",
      endOfDaySuccess:
        "Der globale Zeitpunkt des täglichen Kassenabschlusses wurde erfolgreich auf %s Uhr angepasst.",
      endOfDayTimeTooltip:
        "Wird eine sich in der Vergangenheit befindliche Tageszeit gewählt " +
        "oder wurde der Tagesabschluss für den aktuellen Tag bereits durchgeführt, " +
        "wird der Tagesabschluss am nächsten Tag zur eingestellten Zeit ausgeführt.",
      billingReportApproachSuccess:
        'Die globale Abrechnungsart wurde erfolgreich zu "%s" geändert.',
    },
    KeyboardLayoutListScreen: {
      title: "Tastaturlayouts",
      articleKeyManagement: "Artikeltastenverwaltung",
      keysGroupManagement: "Tastengruppenverwaltung",
      confirmDeletionTitle: "Tastaturlayout löschen",
      confirmDeletionMessage:
        'Möchten Sie das Tastaturlayout "%s" wirklich löschen?',
      successDeleteMessage:
        'Das Tastaturlayout "%s" wurde erfolgreich gelöscht.',
      emptyListMessage:
        "Es sind noch keine Tastaturlayouts vorhanden. Bitte legen Sie ein Tastaturlayout an.",
    },
    KeyboardLayoutAddScreen: {
      title: "Tastaturlayout hinzufügen",
      columnCount: "Spaltenanzahl",
      columnCountPortrait: "Hochformat",
      columnCountLandscape: "Querformat",
      assignedCashRegisters: "Zugeordnete Kassen",
      assignmentOfCashRegisters: "Zuordnung von Kassen",
      assignmentOfKeysMainGroups: "Zuordnung von Tastenhauptgruppen",

      successAddMessage: 'Das Tastaturlayout "%s" wurde erfolgreich angelegt.',
    },
    KeyboardLayoutEditScreen: {
      title: "Tastaturlayout bearbeiten",
      successEditMessage:
        'Das Tastaturlayout "%s" wurde erfolgreich bearbeitet.',
    },
    KeysGroupListScreen: {
      title: "Tastenhaupt- und Tastenuntergruppen",
      keysMainGroupsTitle: "Tastenhauptgruppen",
      keysMainGroupSuccessDeleteMessage:
        'Die Tastenhauptgruppe "%s" wurde erfolgreich gelöscht.',
      deleteKeysMainGroup: "Tastenhauptgruppe löschen",
      confirmKeysMainGroupDeletionMessage:
        'Möchten Sie die Tastenhauptgruppe "%s" wirklich löschen?\n' +
        "Diese und alle dazugehörenden Objekte (Tastenuntergruppen sowie Artikeltasten) " +
        "werden aus allen potenziell vorhandenen Tastaturlayouts entfernt.",
      emptyKeysMainGroupListMessage:
        "Es sind noch keine Tastenhauptgruppen vorhanden. Bitte legen Sie eine Tastenhauptgruppe an.",

      keysSubGroupTitle: "Tastenuntergruppen in %s",
      keysSubGroupSuccessDeleteMessage:
        'Die Tastenuntergruppe "%s" wurde erfolgreich gelöscht.',
      deleteKeysSubGroup: "Tastenuntergruppe löschen",
      confirmKeysSubGroupDeletionMessage:
        'Möchten Sie die Tastenuntergruppe "%s" wirklich löschen?\n' +
        "Diese und alle dazugehörenden Objekte (Artikeltasten) " +
        "werden aus allen potenziell vorhandenen Tastaturlayouts entfernt.",
      emptyKeysSubGroupListMessage:
        "Für diese Tastenhauptgruppe wurde noch keine Tastenuntergruppe angelegt. Bitte legen Sie eine Tastenuntergruppe an.",
    },
    KeysMainGroupAddScreen: {
      title: "Tastenhauptgruppe erstellen",
      successAddMessage:
        'Die Tastenhauptgruppe "%s" wurde erfolgreich angelegt.',

      placeholderMainGroup: "Wählen Sie eine Hauptgruppe aus...",
      placeholderArticleGroup: "Wählen Sie eine Warengruppe aus...",

      previewTooltip:
        "Die Breite der Tastenhauptgruppe ist fixiert. " +
        "Um eine optimale Darstellung zu garantieren, " +
        "wird die Textlänge der Bezeichnung reglementiert.",

      validationInvalidLineWidth:
        "Der eingegebene Text überschreitet die optimale Länge.",
    },
    KeysMainGroupEditScreen: {
      title: "Tastenhauptgruppe bearbeiten",
      successEditMessage:
        'Die Tastenhauptgruppe "%s" wurde erfolgreich bearbeitet.',
    },
    KeysSubGroupAddScreen: {
      title: "Tastenuntergruppe erstellen",
      successAddMessage:
        'Die Tastenuntergruppe "%s" wurde erfolgreich angelegt.',

      emptyListMessage:
        "Der Tastenuntergruppe wurden noch keine Artikeltasten zugeordnet.",
      emptyTableMessage:
        "Es wurden keine zugeordneten Artikeltasten zu diesen Suchkriterien gefunden.",

      selectArticleKeys: "Artikeltasten zuordnen",
      articleKeyTableTitle: "Zugeordnete Artikeltasten",

      previewTooltip:
        "Die Breite der Tastenuntergruppe ist fixiert. " +
        "Um eine optimale Darstellung zu garantieren, " +
        "wird die Textlänge der Bezeichnung reglementiert.",

      validationInvalidLineWidth:
        "Der eingegebene Text überschreitet die optimale Länge.",
    },
    KeysSubGroupEditScreen: {
      title: "Tastenuntergruppe bearbeiten",
      successEditMessage:
        'Die Tastenuntergruppe "%s" wurde erfolgreich bearbeitet.',
    },
    ArticleKeyListScreen: {
      title: "Artikeltasten",
      articleKeyLineOne: "Zeile 1",
      articleKeyLineTwo: "Zeile 2",
      confirmDeletionTitle: "Artikeltaste löschen",
      confirmDeletionMessage:
        'Möchten Sie die Artikeltaste "%s" wirklich löschen?\n' +
        "Ist diese Taste Teil einer Tastenuntergruppe oder eines Tastaturlayouts, " +
        "wird diese weiters auch aus diesen gelöscht.",
      successDeleteMessage: 'Die Artikeltaste "%s" wurde erfolgreich gelöscht.',
      emptyListMessage:
        "Es sind noch keine Artikeltasten vorhanden. " +
        "Bitte legen Sie eine Artikeltaste an oder " +
        "lassen Sie diese über die Tastengruppenverwaltung automatisch generieren.",
      emptyTableMessage:
        "Es wurden keine Artikeltasten zu diesem Suchbegriff gefunden.",
      articleSelectionTitle: "Ordnen Sie bitte einen Artikel zu",
      assignmentTooltip:
        "Artikel, welche bereits einer Artikeltaste zugeordnet sind, können nicht ein weiteres Mal zugeordnet werden.",
      exampleTooltip:
        "Die Breite der Artikeltasten orientiert sich " +
        "an jener des Kassenendgerätes. " +
        "Deshalb ist die Darstellung der Textzeilen " +
        "rein exemplarisch zu interpretieren.",
    },
    ArticleKeyAddScreen: {
      title: "Artikeltaste erstellen",
      successAddMessage: 'Die Artikeltaste "%s" wurde erfolgreich angelegt.',
    },
    ArticleKeyEditScreen: {
      title: "Artikeltaste bearbeiten",
      successEditMessage: 'Die Artikeltaste "%s" wurde erfolgreich bearbeitet.',
    },
    ShoppingCart: {
      title: "Warenkorb",
      cash: "Bar",
      itemSum: "Artikelsumme",
      shoppingCartSum: "Gesamt",
      emptyShoppingCart: "Der Warenkorb ist leer",
      genericDiscount: "Rabatt",
      shoppingCartDiscount: "Warenkorbrabatt",
      invoiceItemUpdated: 'Die Position "%s" wurde erfolgreich aktualisiert.',
      invoiceItemUpdateReverted:
        'Die Änderungen betreffend "%s" wurden rückgängig gemacht.',
      discountInvalidReset:
        "Der Warenkorbrabatt darf nicht größer als die Summe der rabattfähigen Artikel sein. Der höchstmögliche Rabatt wurde gesetzt.",
      cartDiscountUpdate: "Der Warenkorbrabatt wurde erfolgreich aktualisiert.",
      cartDiscountUpdateReverted:
        "Die Änderungen bezüglich des Warenkorbrabatts wurden rückgängig gemacht.",
      refundNoEditMessage:
        "Während einer Gutschrift können keine Artikeleigenschaften verändert werden.",
    },
    Payment: {
      titlePayout: "Auszahlung",
      titlePayment: "Zahlung",
      total: "Gesamt",
      remaining: "Restbetrag",
      stillPending: "noch offen!",
      change: "Rückgeld",
      submit: "Rechnung abschließen",
      payWithHobex: "Mit Hobex bezahlen",
      payoutWithHobex: "Mit Hobex ausbezahlen",
      payWithGPtom: "Mit GP tom bezahlen",
      changeStatement: "Rückgeld ausgeben mit",
      payoutStatement: "Auszahlung abwickeln mit",
      cash: "Bar",
      payRemainingAmount: "Restbetrag bezahlen mit",
      given: "Gegeben",
      warningSelectionAttempt:
        "Dieses Zahlungsmittel wurde bereits ausgewählt.",
      hobexUnavailableTooltip:
        "Es konnte keine Verbindung hergestellt werden. " +
        "Die konfigurierten Terminal-Einstellungen im Portal sind nicht valide oder Ihr Gerät unterstützt diese Bankomat Anbindung nicht. " +
        "Bitte überprüfen Sie Ihre Angaben und synchronisieren und / oder starten Sie die App danach erneut.",
      gpTomUnavailableTooltip:
        "Es konnte keine Verbindung zur GP tom App hergestellt werden. " +
        "Bitte überprüfen Sie, ob Ihr Gerät im Portal für die GP tom Anbindung konfiguriert wurde, Sie die GP tom App installiert " +
        "haben und darin ordnungsgemäß angemeldet sind. Synchronisieren und / oder starten Sie die App danach bitte erneut.",
      hobexPaymentDialogAdvice:
        "Führen Sie die Zahlung nun auf dem Kartenterminal durch.",
      hobexPayoutDialogAdvice:
        "Führen Sie die Auszahlung nun auf dem Kartenterminal durch.",
      valueTransferDisabledExplanation: "Warum ist diese Funktion deaktiviert?",
      cardPaymentError:
        "Der Zahlungsvorgang wurde abgebrochen oder ist fehlgeschlagen.",
      cardPayoutError:
        "Der Auszahlungsvorgang wurde abgebrochen oder ist fehlgeschlagen.",
      cardCancelError:
        "Der Stornovorgang wurde abgebrochen, bereits durchgeführt oder ist fehlgeschlagen.",
      checkConnectionErrorAddition:
        " Bitte überprüfen Sie Ihre Verbindung und führen Sie den Vorgang erneut aus.",
      gpTomTransactionErrorAddition:
        " Stellen Sie bitte zusätzlich via GP tom App sicher, dass keine ungewünschten Transaktionen vorgenommen wurden.",
      dialogSuccessCutter:
        "Der Kundenbeleg wurde soeben gedruckt. Bitte den Bon jetzt abreißen und bestätigen, um den Unternehmer-Beleg zu drucken.",
      changeError:
        "Es sind keine Zahlungsmittel für eine Rückgeldausgabe verfügbar.",
      payoutError: "Es sind keine auszahlungsfähigen Zahlungsmittel verfügbar.",
      generalChangeAndPayoutError:
        "Bitte überprüfen Sie den Status der Zahlungsarten in der Stammdatenverwaltung des Portals " +
        "und synchronisieren Sie danach die App erneut. ",

      cardPaymentUnableToRecoverFraudError:
        "Die Karte des Kunden wurde belastet und kann nun nicht mehr manuell storniert werden. Bitte kontaktieren Sie den Zahlungsdienstleister.",

      continueToPayout: "Weiter zur Auszahlung",
    },
    EntrepreneurListScreen: {
      title: "Unternehmerverwaltung",
      companyName: "Unternehmen",
      managerName: "Name des Unternehmers",
      registrationDate: "Registrierungsdatum",
      licenceModel: "Lizenzmodell",
      managerAccountState: "Status",
      emptyListMessage: "Es haben sich noch keine Unternehmen registriert.",
      emptyTableMessage:
        "Es wurden keine Kunden zu diesem Suchbegriff gefunden.",
      appVersions: "App Versionen",
      noCashRegisters: "Keine Kasse vorhanden",
    },
    EntrepreneurDetails: {
      title: "Unternehmerdetails",
      licenceModel: "Lizenzmodell",
      registrationDate: "Registriert seit",
      licence: "Lizenz",
      noModules: "Keine",
      modules: "Module",
      rksv: "RKSV",
      moduleSelectionTitle: "Modulauswahl",
      moduleButtonText: "Module freischalten",
      moduleText: "%s Modul(e) freigeschalten.",
      moduleUnlockSuccess:
        'Die Modulfreigabe für den Kunden "%s" wurde erfolgreich aktualisiert.',

      cashRegisterCount: "Anzahl aktiver Kassen",
      enableCashRegister: "Eine Kasse aktivieren",
      disableCashRegister: "Eine Kasse deaktivieren",

      confirmationEnableCashRegister:
        'Sind Sie sich sicher, dass dem Unternehmen "%s" eine weitere Kasse freigeschaltet werden soll?',
      successEnableCashRegister:
        'Die Kasse mit der Nummer "%s" wurde aktiviert.',

      confirmationDisableCashRegister:
        'Sind Sie sich sicher, dass dem Unternehmen "%s" eine Kasse deaktiviert werden soll?',
      successDisableCashRegister:
        'Die Kasse mit der Nummer "%s" wurde deaktiviert.',

      requestATrust: "A-Trust-Account anfordern",
      licenceBasic: "Basic",
      tenThousandSignatures: "10.000 Signaturen / Jahr",
      licenceAdvanced: "Advanced",
      thirtyThousandSignatures: "30.000 Signaturen / Jahr",
      licencePremium: "Premium",
      unlimitedSignatures: "Unbegrenzte Signaturen / Jahr",

      rksvATrustHint:
        "Um die Fiskalisierung zu aktivieren muss zunächst ein A-Trust-Account hinzugefügt werden.",
      resetRksv: "Fiskalisierung zurücksetzen",
      enableRksv: "Fiskalisierung aktivieren",
      disableRksv: "Fiskalisierung deaktivieren",
      rksvEnabledState: "Die RKSV ist für diesen Kunden aktiviert.",
      rksvDisabledState: "Die RKSV ist für diesen Kunden derzeit deaktiviert.",
      rksvEnableConfirmation:
        "Soll die Fiskalisierung für diesen Kunden jetzt aktiviert werden?\n" +
        "Es wird ein neuer Startbeleg erstellt.",
      rksvDisableConfirmation:
        "Soll die Fiskalisierung für diesen Kunden jetzt deaktiviert werden?\n" +
        "Es wird ein Schlussbeleg erstellt.",
      rksvResetEnabledConfirmation:
        "Soll der A-Trust-Account entfernt und die Fiskalisierung für diesen Kunden jetzt beendet werden?\n" +
        "Es wird ein Schlussbeleg erstellt.",
      rksvResetDisabledConfirmation:
        "Soll der A-Trust-Account entfernt werden?",
      rksvEnabledMessage:
        "Die Fiskalisierung wurde mit einem Startbeleg aktiviert.",
      rksvDisabledMessage:
        "Die Fiskalisierung wurde mit einem Schlussbeleg deaktiviert.",

      successATrust:
        'Der A-Trust-Account des Unternehmers "%s" wurde erfolgreich bearbeitet und die Fiskalisierung mit einem Startbeleg aktiviert.',
      successATrustResetEnabled:
        'Der A-Trust-Account des Unternehmers "%s" wurde erfolgreich entfernt und die Fiskalisierung mit einem Schlussbeleg beendet.',
      successATrustResetDisabled:
        'Der A-Trust-Account des Unternehmers "%s" wurde erfolgreich entfernt.',
      successData:
        'Die Daten des Unternehmers "%s" wurden erfolgreich bearbeitet.',

      successATRustRequest:
        "Ein A-Trust-Account wurde angelegt und die Fiskalisierung mit einem Startbeleg aktiviert.",

      specificCustomerNumber: "Kundennummer %s",
      resendActivationEmail: "Erneute Aktivierung versenden",
      successActivationMailSend:
        'Eine erneute Aktivierungs-E-Mail wurde an folgende E-Mail-Adresse "%s" versendet.',
      successLicence:
        'Das Lizenzmodell des Unternehmers "%s" wurde erfolgreich bearbeitet.',

      validationTaxIdATrustPresent:
        "Sie können dieses Feld nicht bearbeiten, solange ein A-Trust-Account zugeordnet ist.",
    },
    RksvScreen: {
      title: "RKSV",
      successMasterAccount:
        "Der A-Trust-Master-Account wurde erfolgreich bearbeitet.",
    },
    ModuleListScreen: {
      title: "Modulverwaltung",
      price: "Preis",
      emptyTableMessage:
        "Es wurden noch keine Module angelegt. Bitte kontaktieren Sie den Entwickler!",
    },
    ModuleDetailsScreen: {
      title: "Moduldetails",
      successEditMessage: 'Das Modul "%s" wurde erfolgreich bearbeitet.',
      validationModulePriceInvalid:
        "Modulpreise dürfen keinen negativen Wert annehmen.",
    },
    ArticleReportScreen: {
      title: "Artikelbericht",
      all: "Alle",
      mainGroups: "Hauptgruppen",
      sendByMailTitle: "Bericht per E-Mail versenden",
      successSendMessage: 'Der Bericht wurde an "%s" versendet.',
    },
    GroupReportScreen: {
      drawerTitle: "Gruppenbericht",
      mainGroupTitle: "Hauptgruppenbericht",
      articleGroupTitle: "Warengruppenbericht",
      title: "Haupt- und Warengruppenbericht",
      groupsToggle: "Warengruppen anzeigen",
      groupsToggleTip:
        "Wird diese Checkbox deselektiert, werden nur mehr Hauptgruppen ausgewertet.",
    },
    FinancialReportScreen: {
      title: "Finanzbericht",
      total: "Gesamtumsatz",
      cashFlow: "Einzahlung / Auszahlung",
    },
    AccountingReportScreen: {
      title: "Buchhaltungsbericht",
      customerCount: "Rechnungen",
    },
    CashFlowReportScreen: {
      title: "Geldbewegungsbericht",
      showDetailed: "Detailliert anzeigen",
      showDetailedTooltip:
        "Wird diese Checkbox selektiert, " +
        "wird jede einzelne Bewegung inkl. Zusatztext und Datum / Uhrzeit dargestellt.",
      total: "Gesamt",
      cashFlowType: "Geldbewegungsart",
      emptyTableText:
        "Es konnten keine Geldbewegungen in dem angegebenen Zeitraum ermittelt werden.",
    },
    BillingReportScreen: {
      discounts: "Rabatte",
      number: "Nr. %s",
      statementNumber: "Abrechnung Nr. %s",
      closedAt: "Abgerechnet am %s um %s Uhr",
      updatedAt: "Aktualisiert am %s um %s Uhr",
      closeNow: "Jetzt Abrechnung machen",
      previousStatement: "Vorherige Abrechnungen",
      ongoingTurnovers: "Laufende Umsätze",
      closeDialogMessage:
        "Nach der Abrechnung werden alle Umsätze zurückgesetzt.",
      dailyTotal: "Tagessumme",
      onAccountingDay: "vom %s",
      printerHintTitle: "Mögliche Ursachen",
      printerHintSubTitle: "Kein Drucker eingerichtet?",
      printerHintEscRequiredMessage:
        "Zum Ausdrucken muss ein Bon-Drucker als Standarddrucker eingerichtet sein.",
      printerHintEscNotRequiredMessage:
        "Zum Ausdrucken muss ein Drucker in der Druckerverwaltung hinzugefügt werden.",
      printerHintLabel: "Warum ist diese Funktion deaktiviert?",
      noSaleIndicator:
        "Für den derzeit aktiven Abrechnungstag (%s) fanden noch keine finanziellen Ereignisse statt.",
      cashBalance: "Bar Saldo",
      openingBalance: "Anfangsbestand",
      closingBalance: "Endbestand",
      receiptVoids: "Belegstornos",
      itemVoids: "Positionsstornos",
      refunds: "Gutschriften",
      roundingDifferences: "Rundungsdifferenzen",
      generalBalance: "Saldo",
      printReport: "Abrechnung drucken",
    },
    InterimBillingReportScreen: {
      printReport: "Zwischenbericht drucken",
    },
    EndOfDayReportScreen: {
      title: "Tagesabschluss",
      closedAtLabel: "durchgeführt am %s",
      openedAt: "Eröffnet am",
      updatedAt: "Aktualisiert am",
      closedAt: "Abgeschlossen am",
      closed: "Abschluss",
      incomeOfToday: "Tagesumsatz",
      closeDayNow: "Jetzt Tagesabschluss machen",
      emptyPaymentTableMessage: "Für diesen Tag wurde kein Umsatz gefunden.",
      closeDayDialogMessage:
        "Es gibt nicht abgerechnete %s.\nTagesabschluss trotzdem durchführen?",
      closeDayDialogConfirm: "Ja, Abschluss machen",
      successClosedDay: "Tagesabschluss für den %s erfolgreich durchgeführt.",
    },
    CashRegisterLedgerScreen: {
      title: "Kassenjournal",
      receiptNumber: "R-Nummer",
      discount: "Rabatt % (€)",
      emptyTableMessage:
        "Es wurden keine Aktivitäten zu diesen Filterkriterien gefunden.",
      sendByMailTitle: "Journal per E-Mail versenden",
      successSendMessage: 'Das Journal wurde an "%s" versendet.',
      onlyXEntriesShown: "Es werden nur die ersten %s Einträge angezeigt.",
    },
    ManagerProtocolScreen: {
      title: "Verwaltung",
      headline: "Verwaltungsprotokoll",
      emptyTableMessage:
        "Es wurden keine Aktivitäten zu diesen Filterkriterien gefunden.",
      table: {
        action: "Aktivität",
        user: "Benutzer",
      },
    },
    CashRegisterProtocolScreen: {
      title: "Kasse",
      headline: "Kassenprotokoll",
      emptyTableMessage:
        "Es wurden keine Aktivitäten zu diesen Filterkriterien gefunden.",
      table: {
        action: "Aktivität",
        user: "Benutzer",
        device: "Gerät",
      },
    },
    ReceiptListScreen: {
      title: "Belegübersicht",
      clientTitle: "Kundenbelege",
      operator: "Bediener",
      emptyListMessage: "Es sind keine Belege für diesen Zeitraum vorhanden.",
      emptyTableMessage:
        "Es wurden keine Belege zu diesem Suchbegriff gefunden.",
      nullReceipt: "Jetzt Nullbeleg erstellen",
      filterAfter: "Filtern nach %s",
      receiptList: "Belegliste",
      receiptsExport: "Belegexport",
      a4ReceiptDesign: "A4-Belegdesign",
      a4DetailedExportTooltip:
        "Um die Exportzeit zu reduzieren, werden A4-Belege ohne Belegdesigns exportiert.",
      sendByMailTitle: "Belegliste per E-Mail versenden",
      successSendMessage: 'Die Belegliste wurde an "%s" versendet.',
    },
    ReceiptDetailScreen: {
      title: "Belegnummer",
      print: "Druckansicht",
      email: "Per E-Mail",
      receiptFrom: "Beleg vom",
      sendCopyOrPrint: "Beleg als Kopie drucken / senden",
      cancelDialogTitle: "Beleg stornieren",
      cancelDialogMessage:
        "Wollen Sie diesen Beleg über %s wirklich komplett stornieren?",
      cancelTaskDialogMessage:
        "Führen Sie die Stornierung nun auf dem Zahlungsterminal durch.",
      successCancelMessage: "Der Beleg %s wurde durch %s storniert.",
      outputCashAmount: "Bitte Betrag %s in Bar ausgeben.",
      outputVoucherAmount: "Bitte Gutschein in Höhe von %s ausgeben.",
      outputCardAmount:
        "Der Rechnungsbetrag in der Höhe von %s wurde dem Konto des Kunden gutgeschrieben.",
      cancelVoid: "Storno",

      refundDialogTitle: "Beleg-Gutschrift",
      refundDialogMessage:
        "Es ist noch ein aktiver Warenkorb in Bearbeitung. " +
        "Schließen oder brechen Sie diesen Warenkorb erst ab bevor Sie eine Beleg-Gutschrift erstellen.",
      refundDialogOk: "Zum Warenkorb",
      refundDialogCancel: "Zurück",

      zeroReceiptSuccess: "Ein Nullbeleg wurde erfolgreich erstellt.",
      refunded: "GUTSCHRIFTSERSTELLUNG",
      refundedSmall: "GE",
      cancelled: "STORNO",
      cancelledSmall: "ST",
      refund: "GUTSCHRIFT",
      refundSmall: "GS",
      cancelRefund: "STORNO-GUTSCHRIFT",
      cancelRefundSmall: "ST-GS",

      cancelledBy: "Storniert durch",
      refundedBy: "Gutgeschrieben durch",
      refundedFor: "Gutschrift für",

      receiptNumberFileName: "Rechnung-Nr.",
      sendByMailTitle: "Rechnungsbeleg per E-Mail versenden",
      sendCopy: "Beleg als Kopie senden",
      successSendMessage: 'Der Rechnungsbeleg wurde an "%s" versendet.',
    },
    RksvDataScreen: {
      title: "RKSV-FinanzOnline",

      fonData: "RKSV-FON-Daten",
      cashBoxId: "Kassenidentifikationsnummer",
      aesKey: "Benutzerschlüssel AES-256",
      checkValueForAes: "Prüfwert für Benutzerschlüssel",
      typeOfSafetyMeasurementLabel: "Art der Sicherheitseinrichtung",
      typeOfSafetyMeasurementValue:
        "Hardware-Sicherheitsmodul (HSM) eines Dienstleisters",
      trustSupplierLabel: "Vertrauensdienstanbieter",
      trustSupplierValue: "AT1 A-TRUST",
      serialNumberOfCertificate:
        "Seriennummer der Signatur- bzw. Siegelzertifikats",

      copiedToClipboard: "Text wurde in die Zwischenablage kopiert.",

      sendByMailTitle: "RKSV-FON-Daten per E-Mail versenden",
      successSendMessage: 'Die RKSV-FON-Daten wurden an "%s" versendet.',
    },
    RksvExportScreen: {
      title: "RKSV-Export",
      exportAsDep7Label: "Export als DEP7",
      exportAsDep7Title: "Datenerfassungsprotokoll",
      exportAs131Label: "Export als CSV",
      exportAs131Title: "§131 Kassenjournal",
      rksvDeactivatedMessage:
        "RKSV ist nicht aktiv, bitte fügen Sie einen " +
        "A-Trust-Account hinzu oder fordern Sie einen an.",
    },
    RksvReceiptListScreen: {
      title: "RKSV-Belege",
      rksvReceiptList: "RKSV-Belegliste",
      sendByMailTitle: "RKVS-Belegliste per E-Mail versenden",
      successSendMessage: 'Die RKSV-Belegliste wurde an "%s" versendet.',
    },
    AdminReport: {
      title: "Auswertungen",
    },
    AdminUserManagement: {
      title: "Benutzerverwaltung",
    },
    AdminDashboardScreen: {
      title: "Dashboard",
    },
    DetailForm: {
      discountInput: "Rabattvergabe",
      undiscountedPrice: "Artikelpreis",
      zeroPriceAllowed: "Nullpreis erlaubt",
      amount: "Menge",
      discount: "Rabatt",
      discounts: "Rabatte",
      discountedPrice: "Preis",
      percentDiscount: "Rabatt in %",
      absoluteDiscount: "Rabatt in €",
      noDiscountTitle: "Kein Rabatt möglich",
      noDiscountDescription: "Artikel für Rabatt gesperrt",
      validationDiscountInvalid:
        "Der eingegebene Rabatt übersteigt Ihren festgelegten Maximalrabatt.",
    },
    CartDiscountScreen: {
      cartTotalWithItemDiscount: "Artikelsumme",
      discountableCartTotalWithAppliedItemDiscount: "davon rabattfähig",
      cartTotalWithAllDiscounts: "Gesamt",
      discountableDiscountedTotal: "Total",
    },
    ArticleDetailsScreen: {
      submit: "Zum Warenkorb hinzufügen",
    },
    ReceiptPrintSelectionScreen: {
      title: "Druckerauswahl",
      sendByMail: "Per E-Mail",
      printWithNetworkPrinter: "Netzwerkdrucker",
    },
    ReceiptSendByMailScreen: {
      title: "Belegversand",
    },
    Receipt: {
      amount: "Menge",
      article: "Artikel",
      pricePerUnit: "EZP",
      price: "Preis",
      taxRate: "St",
      lineItemTotal: "Pos-Summe",
      moneyAmountRequested: "Rechnungsbetrag",
      receiptTotal: "Rechnungssumme",
      moneyAmountReturned: "Rückgeld",
      vatIncluded: "Enthaltene MwSt.",
      net: "Netto",
      tax: "Steuer",
      gross: "Brutto",
      hobexTransaction: "Hobex Transaktion",
      gpTomTransaction: "GP tom Transaktion",
      transactionTime: "Transaktionszeit",
      transactionNumber: "Rechnungsnummer",
      europayAppId: "App-ID",
      batchNumber: "Abschlussnummer",
      terminalId: "Terminal-ID",
      cardIssuer: "Kartenherausgeber",
      cardNumber: "Kartennummer",
      transactionType: "Transaktionstyp",
      authorizationCode: "Authorisierungscode",
      sequenceNumber: "Sequenznummer",
      responseCode: "Antwortcode",
      discountTotal: "Rabattsumme",
      articleTotal: "Warensumme",
      positionTotal: "Gesamt",
    },
    SettingsScreen: {
      title: "Einstellungen",
      versionLabel: "Version",
      linkageLabel: "Verknüpfung",
      logLevelLabel: "Log-Level",

      cardDeviceLabel: "Kartenzahlungs-Terminal",
      noIntegration: "Keine Anbindung",
      cardNotUsed: "Nicht verwendet",
      cardConnecting: "Verbinde...",
      cardConnected: "Verbunden",
      cardConnectionFailed: "Fehler bei Verbindungsaufbau",
      cardExternal: "Externe Anbindung",
      unknownStatus: "Unbekannter Status",

      drawerDeviceLabel: "Kassenlade",
      drawerSunmi: "Anbindung via Sunmi",
      drawerPrinter: "Anbindung via Drucker\n(%s)",

      deadMutationsLabel: "Sendefehler",
      deadMutationsInfo:
        "%s Datensätze konnten nicht erfolgreich übermittelt werden. " +
        "Diese werden bei stabiler Verbindung automatisch an das Support-Team übermittelt.",
      failedDeadMutationLabel: "Spezieller Sendefehler",
      failedDeadMutationsInfo:
        "%s Datensätze konnten nicht erfolgreich übermittelt werden. " +
        "Lassen Sie diese von unserem Team untersuchen um etwaige Fehler auszuschließen.",

      transmitNow: "Jetzt übertragen",

      chooseADebugSetting: "Wählen Sie ein Log-Level...",
    },
    PasswordResetScreen: {
      successPasswordChange:
        "Passwortänderung erfolgreich. Sie können sich nun einloggen.",
    },
    PasswordRequestResetScreen: {
      explanatoryTitle: "Passwort zurücksetzen",
      explanatoryText:
        "Bitte geben Sie im nachfolgenden Feld die E-Mail-Adresse " +
        "Ihres Unternehmer-Accounts ein, um Ihr Kennwort in weiterer Folge zurückzusetzen.",
      requestReset: "Zurücksetzung anfordern",
      successPasswordRequest:
        "Passwortänderung beantragt. Sie erhalten in Kürze eine E-Mail mit weiteren Informationen.",
    },
  },

  generalValidationMessages: {
    validationEmpty: "Bitte geben Sie einen gültigen Wert ein.",
    validationEmail: "Geben Sie bitte eine gültige E-Mail-Adresse ein.",
    validationIBAN: "Geben Sie bitte einen gültigen IBAN ein.",
    validationBIC: "Geben Sie bitte einen gültigen BIC ein.",
    validationTaxNumber:
      "Bitte geben Sie eine gültige UID- oder Steuer-Identifikationsnummer ein.",
    validationDiscount: "Rabatte dürfen nur zwischen 0 und 100 Prozent liegen.",
    validationPhoneNumber: "Bitte geben Sie eine gültige Telefonnummer ein.",
    validationName: "Bitte geben Sie einen Namen ein.",
    validationIdentifier: "Bitte geben Sie eine Bezeichnung ein.",
    validationColor: "Bitte wählen Sie eine valide Farbe aus.",
    validationInvalid: "Bitte wählen Sie einen validen Wert aus.",
    validationMainGroupMissing:
      "Bitte wählen Sie eine Hauptgruppe aus der Liste aus.",
    validationArticleGroupMissing:
      "Bitte wählen Sie eine Warengruppe aus der Liste aus.",
    validationUsername: "Bitte geben Sie einen gültigen Username ein.",
    validationPassword: "Bitte geben Sie ein gültiges Passwort ein.",
    validationMutationInPastOrFuture:
      "Es können keine Operationen durchgeführt werden, welche sich in der %s befinden. " +
      "Bitte überprüfen Sie die Systemzeit Ihres Gerätes.",
    validationMutationInPastDeadlock:
      "Sollten Sie zuvor Offline-Operationen in der Zukunft vorgenommen haben, " +
      "können Sie unter diesen Umständen keine weiteren Operationen durchführen, welche unter der Zeitmarke '%s' einzuordnen wären.\n" +
      "Bitte überprüfen Sie Ihre Internetverbindung und warten Sie die Dauer der Verarbeitung der Offline-Operationen ab.",
    validationArticleNumber:
      "Bitte geben Sie eine ganzzahlige Artikelnummer ein.",
  },

  commandsErrorCodeMapping: commandsErrorCodeMapping({
    RegisterCompanyWithManager: {
      CompanyNameExists:
        "Der eingegebene Firmenname ist bereits vergeben. Bitte wählen Sie einen anderen Firmennamen.",
      InvalidTaxId:
        "Die eingegebene UID- oder Steuer-Identifikationsnummer ist ungültig.",
      TaxIdExists:
        "Die eingegebene UID- oder Steuer-Identifikationsnummer wird bereits verwendet.",
      ManagerEmailExists:
        "Die eingegebene E-Mail-Adresse wird bereits verwendet. Wenn Sie bereits registriert sind, loggen Sie sich ein. Ansonsten wählen Sie bitte eine andere E-Mail-Adresse.",
      InvalidPassword:
        "Ein Passwort muss aus mindestens 10 Zeichen bestehen und eine Zahl, einen Groß- und Kleinbuchstaben, sowie ein Sonderzeichen beinhalten.",
      InvalidEmailAddress: "Die eingegebene E-Mail-Adresse ist ungültig.",
    },

    ChangeManagerRegistration: {
      ManagerNotFound:
        "Der Unternehmer konnte nicht bearbeitet werden, da dieser nicht mehr existiert.",
      EmailExists:
        "Die eingegebene E-Mail-Adresse wird bereits von einem anderen Unternehmer verwendet.",
      InvalidEmailAddress: "Die eingegebene E-Mail-Adresse ist ungültig.",
    },
    ChangeLicenceModel: {
      ManagerNotFound:
        "Das Lizenzmodell des Unternehmers konnte nicht bearbeitet werden, da dieser nicht mehr existiert.",
    },
    ChangeCompanyRegistration: {
      CompanyNotFound:
        "Die Bearbeitung dieser Daten kann nicht vorgenommen werden, da das Unternehmen nicht mehr existiert.",
      CompanyNotRegistered:
        "Die Bearbeitung dieser Daten kann nicht vorgenommen werden, da das Unternehmen nicht mehr existiert.",
      CompanyNameExists:
        "Der eingegebene Firmenname ist bereits vergeben. Bitte wählen Sie einen anderen Firmennamen.",
      TaxIdExists:
        "Die eingegebene UID- oder Steuer-Identifikationsnummer wird bereits verwendet.",
      InvalidTaxId:
        "Die eingegebene UID- oder Steuer-Identifikationsnummer ist ungültig.",
      ATrustAccountPresent:
        "Sie können die eingegebene UID- oder Steuer-Identifikationsnummer nicht bearbeiten, solange ein A-Trust-Account zugeordnet ist.",
    },
    ChangeCompanyModuleSelection: {
      CompanyNotFound:
        "Die Modulauswahl kann nicht übernommen werden, da das Unternehmen nicht mehr existiert.",
      ModuleNotFound:
        "Mindestens ein Modul aus der Auswahl konnte nicht hinzugefügt werden, da dieses nicht mehr existiert.",
    },
    ResendActivationEmail: {
      ManagerNotFound:
        "Es konnte keine Aktivierungs-E-Mail versandt werden, da dieser Unternehmer nicht mehr existiert.",
      AlreadyConfirmed:
        "Dieser Kunde hat sein Benutzerkonto bereits aktiviert.",
      InvalidEmailAddress: "Die eingegebene E-Mail-Adresse ist ungültig.",
    },
    PublicResendActivationEmail: {
      ManagerNotFound:
        "Es konnte keine Aktivierungs-E-Mail versandt werden, da dieser Unternehmer nicht mehr existiert.",
      AlreadyConfirmed:
        "Der Kunde hat sein Benutzerkonto, welches mit der eingegebenen E-Mail-Adresse verknüpft ist, bereits aktiviert.",
      InvalidEmailAddress: "Die eingegebene E-Mail-Adresse ist ungültig.",
    },

    EnableRksv: {
      CompanyNotFound:
        "Die Fiskalisierung kann nicht aktiviert werden, da das Unternehmen nicht mehr existiert.",
      AlreadyEnabled:
        "Die Fiskalisierung wurde für diesen Unternehmer bereits aktiviert.",
    },
    DisableRksv: {
      CompanyNotFound:
        "Die Fiskalisierung kann nicht deaktiviert werden, da das Unternehmen nicht mehr existiert.",
      NotEnabled:
        "Die Fiskalisierung wurde für diesen Unternehmer bereits deaktiviert.",
    },
    SendRksvInfoByMail: {
      InvalidEmailAddress: "Die eingegebene E-Mail-Adresse ist ungültig.",
      NoStartReceiptPresent:
        "Es wurde noch kein Startbeleg für dieses Unternehmen erstellt.",
    },

    EnableCashRegister: {
      CompanyNotFound:
        "Es konnte keine weitere Kasse aktiviert werden, da das Unternehmen nicht mehr existiert.",
      CashRegisterNotFound:
        "Die angepeilte Kasse kann nicht aktiviert werden, da diese nicht mehr existiert.",
      AlreadyEnabled:
        "Es wurde versucht eine Kasse zu aktivieren, welche bereits aktiviert wurde.",
    },
    DisableCashRegister: {
      CompanyNotFound:
        "Es konnte keine Kasse deaktiviert werden, da das Unternehmen nicht mehr existiert.",
      CashRegisterNotFound:
        "Die angepeilte Kasse kann nicht deaktiviert werden, da diese nicht mehr existiert.",
      AlreadyDisabled:
        "Es wurde versucht eine Kasse zu deaktivieren, welche bereits deaktiviert wurde.",
      NoEnabledCashRegistersAvailable:
        "Es ist keine Kasse mehr übrig, welche noch deaktiviert werden könnte.",
    },

    ChangeATrustMasterCredentials: {
      // Currently no specific ErrorCode implemented
    },
    RequestATrustAccount: {
      CompanyNotFound:
        "Es konnte kein A-Trust-Account angefordert werden, da das Unternehmen nicht mehr existiert.",
      CheckMasterCredentials:
        "Es konnte kein A-Trust-Account angefordert werden. Bitte überprüfen Sie die Daten des hinterlegten Partner-A-Trust-Accounts.",
    },
    ChangeATrustAccount: {
      CompanyNotFound:
        "Der A-Trust-Account des Unternehmers konnte nicht aktualisiert werden, da das Unternehmen nicht mehr existiert.",
      InvalidCredentials:
        "Der A-Trust-Account des Unternehmers konnte nicht aktualisiert werden. Bitte überprüfen Sie den Benutzernamen und das Passwort.",
      UnknownError:
        "Ein unbekannter Fehler ist während der Authentifizierung mit den A-Trust-Servern aufgetreten.",
    },

    CreatePaymentMethod: {
      IdExists:
        "Es existiert bereits eine Zahlungsart mit dieser eindeutigen ID. Bitte laden Sie die Seite neu.",
      NameAlreadyExists:
        "Es existiert bereits eine Zahlungsart mit diesem Namen.",
    },
    ChangePaymentMethod: {
      PaymentMethodNotFound:
        "Die Zahlungsart konnte nicht bearbeitet werden, da diese nicht mehr existiert.",
      AlreadyDeleted:
        "Die Zahlungsart konnte nicht bearbeitet werden, da diese nicht mehr existiert.",
      NameAlreadyExists:
        "Es existiert bereits eine Zahlungsart mit diesem Namen.",
    },
    ChangePaymentMethodVisibility: {
      // Currently no specific ErrorCode implemented
    },
    DeletePaymentMethod: {
      PaymentMethodNotFound: 'Die Zahlungsart "%s" wurde bereits gelöscht.',
      AlreadyDeleted: 'Die Zahlungsart "%s" wurde bereits gelöscht.',
    },

    CreateAdminCashFlow: {
      IsCompanyCashFlow:
        "Sie können im Admin-Interface keine unternehmensspezifischen Geldbewegungen erstellen.",
    },
    ChangeAdminCashFlow: {
      CashFlowNotFound:
        "Die Geldbewegung konnte nicht bearbeitet werden, da diese nicht mehr existiert.",
      IsGlobal:
        "Sie können keine globalen Geldbewegungen zu unternehmensspezifischen Geldbewegungen konvertieren.",
      IsCompanyCashFlow:
        "Sie können im Admin-Interface keine unternehmensspezifischen Geldbewegungen bearbeiten.",
    },
    DeleteAdminCashFlow: {
      CashFlowNotFound: 'Die Geldbewegung "%s" wurde bereits gelöscht.',
      IsGlobal: "Sie können keine globalen Geldbewegungen löschen.",
    },

    ChangeModule: {
      ModuleNotFound:
        "Das Modul konnte nicht bearbeitet werden, da dieses nicht mehr existiert.",
      InvalidPrice:
        "Das Modul konnte nicht bearbeitet werden, da negative Modulpreise nicht erlaubt sind.",
    },

    LoginAdminAccount: {
      InvalidCredentials: "Falsche E-Mail-Adresse oder falsches Passwort.",
      InvalidEmailAddress: "Die eingegebene E-Mail-Adresse ist ungültig.",
    },
    AssumeCompany: {
      // Currently no specific ErrorCode implemented
    },
    LoginManagerAccount: {
      InvalidCredentials: "Falsche E-Mail-Adresse oder falsches Passwort.",
      EmailNotConfirmed:
        "E-Mail-Adresse noch nicht bestätigt. " +
        "Klicken Sie auf den Bestätigungslink in Ihrer Aktivierungs-E-Mail und versuchen Sie sich erneut einzuloggen. " +
        "Überprüfen Sie bitte zusätzlich den Spam-Ordner Ihres Postfaches.",
      AccountLocked:
        "Sie können sich nicht mehr einloggen, da Ihr Unternehmer-Account gesperrt wurde.",
      InvalidEmailAddress: "Die eingegebene E-Mail-Adresse ist ungültig.",
    },
    LoginOperatorAccount: {
      DeviceNotPaired: "Dieses Gerät ist nicht mehr mit einer Kasse verknüpft.",
      InvalidPassword: "Bediener-Code oder Passwort ist falsch.",
      // Do not reveal to the user which part of the credentials is invalid.
      OperatorCodeNotAssigned: "Bediener-Code oder Passwort ist falsch.",
    },
    LoginToAnotherOperatorAccount: {
      // Here it is allowed, because an authentication is already present.
      OperatorCodeNotAssigned:
        "Dieser Bediener-Code ist nicht mehr zugewiesen.",
    },
    RequestManagerPasswordReset: {
      InvalidEmailAddress: "Die eingegebene E-Mail-Adresse ist ungültig.",
    },
    ResetManagerPassword: {
      ResetCodeInvalid:
        "Das Passwort konnte nicht zurückgesetzt werden. Beginnen Sie den Vorgang bitte erneut.",
      InvalidPassword:
        "Ein Passwort muss aus mindestens 10 Zeichen bestehen und eine Zahl, einen Groß- und Kleinbuchstaben, sowie ein Sonderzeichen beinhalten.",
    },

    PairDevice: {
      CashRegisterNotFound:
        "Die angepeilte Kasse kann nicht verknüpft werden, da diese nicht mehr existiert.",
      NoUnpairedCashRegisterAvailable:
        "Alle verfügbaren Kassen sind bereits verknüpft. " +
        "Im Unternehmer-Interface haben Sie die Möglichkeit, die Verknüpfung ungenutzter Geräte aufzuheben. " +
        "Sollte dies für Sie keine Option sein, benötigen Sie ein Upgrade Ihres Accounts in Form von zusätzlichen Kassen.",
      CashRegisterAlreadyPaired:
        "Dieses Gerät ist bereits mit einer Kasse verknüpft.",
      CashRegisterDisabled:
        "Es wurde versucht, sich mit einer deaktivierten Kasse zu verbinden.",
    },
    UnpairDevice: {
      CashRegisterNotFound:
        "Die Verknüpfung mit der Kasse kann nicht aufgehoben werden, da diese nicht mehr existiert.",
      DeviceNotPaired: 'Die Kasse "%s" ist derzeit mit keinem Gerät verknüpft.',
    },

    ConnectReceiptPrinter: {
      CashRegisterNotFound:
        "Dieser Drucker kann nicht verbunden werden, da die dazugehörige Kasse nicht mehr existiert.",
      CashRegisterNotPaired:
        "Diese Kasse ist derzeit mit keinem Endgerät verknüpft. " +
        "Verknüpfen Sie ein neues Gerät, um diese Aktion durchführen zu können.",
      PrinterAlreadyConnected:
        "Dieser Drucker wurde bereits mit der Kasse verbunden.",
    },
    SetDefaultReceiptPrinter: {
      CashRegisterNotFound:
        "Dieser Drucker kann nicht als Standarddrucker gesetzt werden, da die dazugehörige Kasse nicht mehr existiert.",
      CashRegisterNotPaired:
        "Diese Kasse ist derzeit mit keinem Endgerät verknüpft. " +
        "Verknüpfen Sie ein neues Gerät, um diese Aktion durchführen zu können.",
      PrinterNotFound:
        "Dieser Drucker kann nicht als Standarddrucker gesetzt werden, da dieser nicht mehr existiert.",
    },
    ChangeReceiptPrinterConfiguration: {
      CashRegisterNotFound:
        "Dieser Drucker kann nicht bearbeitet werden, da die dazugehörige Kasse nicht mehr existiert.",
      CashRegisterNotPaired:
        "Diese Kasse ist derzeit mit keinem Endgerät verknüpft. " +
        "Verknüpfen Sie ein neues Gerät, um diese Aktion durchführen zu können.",
      PrinterNotFound:
        "Dieser Drucker kann nicht bearbeitet werden, da dieser nicht mehr existiert.",
    },
    DisconnectReceiptPrinter: {
      CashRegisterNotFound:
        "Dieser Drucker kann nicht entfernt werden, da die dazugehörige Kasse nicht mehr existiert.",
      CashRegisterNotPaired:
        "Diese Kasse ist derzeit mit keinem Endgerät verknüpft. " +
        "Verknüpfen Sie ein neues Gerät, um diese Aktion durchführen zu können.",
      PrinterNotFound:
        "Dieser Drucker kann nicht entfernt werden, da dieser bereits entfernt wurde.",
    },

    ChangePhysicalReceiptDesign: {
      CashRegisterNotFound:
        "Sie können das Bon-Belegdesign nicht bearbeiten, da die dazugehörige Kasse nicht mehr existiert.",
      CashRegisterDisabled:
        "Sie können das Bon-Belegdesign nicht bearbeiten, da diese Kasse derzeit deaktiviert ist.",
    },
    ChangeA4ReceiptDesign: {
      CashRegisterNotFound:
        "Sie können das A4-Belegdesign nicht bearbeiten, da die dazugehörige Kasse nicht mehr existiert.",
      CashRegisterDisabled:
        "Sie können das A4-Belegdesign nicht bearbeiten, da diese Kasse derzeit deaktiviert ist.",
    },

    ChangeCashRegisterConfiguration: {
      CashRegisterNotFound:
        "Sie können diese Kasse nicht bearbeiten, da diese nicht mehr existiert.",
      CashRegisterDisabled:
        "Sie können diese Kasse nicht bearbeiten, da diese derzeit deaktiviert ist.",
    },
    ChangeCashRegisterDrawerMode: {
      OperatorNotFound:
        "Der Tagesanfangsstand konnte nicht bearbeitet werden, da dieser Bediener nicht mehr existiert.",
      CashRegisterNotFound:
        "Der Tagesanfangsstand konnte nicht bearbeitet werden, da diese Kasse nicht existiert.",
      AmountNegative:
        "Der Tagesanfangsstand konnte nicht bearbeitet werden, da negative Bestände nicht erlaubt sind.",
      CashRegisterDisabled:
        "Sie können den Tagesanfangsstand nicht bearbeiten, da diese Kasse derzeit deaktiviert ist.",
    },
    ChangeCloseOfDayTime: {
      // Currently no specific ErrorCode implemented
    },
    ChangeBillingReportApproach: {
      // Currently no specific ErrorCode implemented
    },

    CreateOperator: {
      OperatorCodeExists: "Dieser Bediener-Code ist bereits vergeben.",
      DiscountPercentageNegative:
        "Eine negative Prozentangabe von Rabatten ist nicht möglich.",
      InvalidPassword:
        "Das Passwort darf weder leer sein, noch aus Leerzeichen bestehen.",
      NoOperatorCodesLeft: "Es sind keine Bediener-Codes mehr frei.",
    },
    ChangeOperator: {
      AccountNotFound:
        "Der Bediener konnte nicht bearbeitet werden, da dieser nicht mehr existiert.",
      OperatorCodeExists: "Dieser Bediener-Code ist bereits vergeben.",
      DiscountPercentageNegative:
        "Eine negative Prozentangabe von Rabatten ist nicht möglich.",
    },
    ChangeOperatorDrawerMode: {
      OperatorNotFound:
        "Der Tagesanfangsstand konnte nicht bearbeitet werden, da dieser Bediener nicht mehr existiert.",
      CashRegisterNotFound:
        "Der Tagesanfangsstand konnte nicht bearbeitet werden, da diese Kasse nicht existiert.",
      AmountNegative:
        "Der Tagesanfangsstand konnte nicht bearbeitet werden, da negative Bestände nicht erlaubt sind.",
      CashRegisterDisabled:
        "Sie können den Tagesanfangsstand nicht bearbeiten, da diese Kasse derzeit deaktiviert ist.",
    },

    CreateMainGroup: {
      MainGroupNameExists:
        "Die eingegebene Bezeichnung für die Hauptgruppe ist bereits vergeben.",
      InvalidTaxRate: "Steuergruppen dürfen keinen negativen Wert annehmen.",
    },
    ChangeMainGroup: {
      MainGroupNotFound:
        "Die Hauptgruppe konnte nicht bearbeitet werden, da diese nicht mehr existiert.",
      MainGroupNameExists:
        "Die eingegebene Bezeichnung für die Hauptgruppe ist bereits vergeben.",
      InvalidTaxRate: "Steuergruppen dürfen keinen negativen Wert annehmen.",
    },
    ChangeMainGroupOrderIndex: {
      MainGroupNotFound:
        "Diese Hauptgruppe kann nicht verschoben werden, da sie nicht mehr existiert.",
      InvalidOrderIndex:
        "Diese Hauptgruppe kann nicht an die gewünschte Position verschoben werden.",
    },
    DeleteMainGroup: {
      MainGroupNotFound: 'Die Hauptgruppe "%s" wurde bereits gelöscht.',
      MainGroupNotEmpty:
        "Hauptgruppen können erst gelöscht werden,\n" +
        "wenn sie keine Warengruppen enthalten.\n" +
        "Bitte löschen Sie zuerst alle zugeordneten Warengruppen und deren inkludierte Artikel.",
    },

    CreateArticleGroup: {
      MainGroupNotFound: "Die referenzierte Hauptgruppe existiert nicht mehr.",
      ArticleGroupNameExists:
        "Die eingegebene Bezeichnung für die Warengruppe ist unter dieser Hauptgruppe bereits vergeben.",
      InvalidTaxRate: "Steuergruppen dürfen keinen negativen Wert annehmen.",
    },
    ChangeArticleGroup: {
      ArticleGroupNotFound:
        "Die Warengruppe konnte nicht aktualisiert werden, da diese nicht mehr existiert.",
      ArticleGroupNameExists:
        "Die eingegebene Bezeichnung für die Warengruppe ist unter dieser Hauptgruppe bereits vergeben.",
      InvalidTaxRate: "Steuergruppen dürfen keinen negativen Wert annehmen.",
    },
    ChangeArticleGroupOrderIndex: {
      ArticleGroupNotFound:
        "Diese Warengruppe kann nicht verschoben werden, da sie nicht mehr existiert.",
      InvalidOrderIndex:
        "Diese Warengruppe kann nicht an die gewünschte Position verschoben werden.",
    },
    DeleteArticleGroup: {
      ArticleGroupNotFound: 'Die Warengruppe "%s" wurde bereits gelöscht.',
      ArticleGroupNotEmpty:
        "Warengruppen können erst gelöscht werden,\n" +
        "wenn sie keine Artikel enthalten.\n" +
        "Bitte löschen Sie zuerst alle zugehörigen Artikel oder verschieben Sie sie in andere Warengruppen.",
    },

    CreateArticle: {
      MainGroupNotFound: "Die referenzierte Hauptgruppe existiert nicht mehr.",
      ArticleGroupNotFound:
        "Die referenzierte Warengruppe existiert nicht mehr.",
      ArticleNameExists:
        "Die eingegebene Bezeichnung für den Artikel ist bereits vergeben.",
      ArticleNumberExists:
        'Die Artikelnummer "%s" wurde bereits dem Artikel "%s" zugeordnet.',
      ArticleGroupNotGroupOfMainGroup:
        "Die referenzierte Warengruppe ist nicht Teil der referenzierten Hauptgruppe.",
      InvalidPrice: "Bitte geben Sie einen gültigen Artikelpreis ein.",
      InvalidTaxRate: "Steuergruppen dürfen keinen negativen Wert annehmen.",
      InvalidArticleNumber:
        "Die eingegebene Artikelnummer darf ausschließlich einer Ganzzahl entsprechen.",
      InvalidBarcodeNumber:
        "Mindestens einer der zugeordneten Barcodes ist ungültig.",
      BarcodeNumberAlreadyExists:
        'Der Barcode "%s" wurde bereits dem Artikel "%s" zugeordnet.',
      ArticleAndBarcodeNumberCollision:
        "Die eingegebene Artikelnummer darf nicht in den zugeordneten Barcodes enthalten sein.",
      ArticleNumberBarcodeNumberCollision:
        'Der Barcode "%s" wurde bereits als Artikelnummer dem Artikel "%s" zugeordnet.',
      BarcodeNumberArticleNumberCollision:
        'Die Artikelnummer "%s" wurde bereits als Barcode dem Artikel "%s" zugeordnet.',
    },
    ChangeArticle: {
      MainGroupNotFound: "Die referenzierte Hauptgruppe existiert nicht mehr.",
      ArticleGroupNotFound:
        "Die referenzierte Warengruppe existiert nicht mehr.",
      ArticleNotFound:
        "Der Artikel konnte nicht bearbeitet werden, da dieser nicht mehr existiert.",
      ArticleNameExists:
        "Die eingegebene Bezeichnung für den Artikel ist bereits vergeben.",
      ArticleNumberExists:
        'Die Artikelnummer "%s" wurde bereits dem Artikel "%s" zugeordnet.',
      ArticleGroupNotGroupOfMainGroup:
        "Die referenzierte Warengruppe ist nicht Teil der referenzierten Hauptgruppe.",
      InvalidPrice: "Bitte geben Sie einen gültigen Artikelpreis ein.",
      InvalidTaxRate: "Steuergruppen dürfen keinen negativen Wert annehmen.",
      InvalidArticleNumber:
        "Die eingegebene Artikelnummer darf ausschließlich einer Ganzzahl entsprechen.",
      InvalidBarcodeNumber:
        "Mindestens einer der zugeordneten Barcodes ist ungültig.",
      BarcodeNumberAlreadyExists:
        'Der Barcode "%s" wurde bereits dem Artikel "%s" zugeordnet.',
      ArticleAndBarcodeNumberCollision:
        "Die eingegebene Artikelnummer darf nicht in den zugeordneten Barcodes enthalten sein.",
      ArticleNumberBarcodeNumberCollision:
        'Der Barcode "%s" wurde bereits als Artikelnummer dem Artikel "%s" zugeordnet.',
      BarcodeNumberArticleNumberCollision:
        'Die Artikelnummer "%s" wurde bereits als Barcode dem Artikel "%s" zugeordnet.',
    },
    RemoveArticleNumber: {
      ArticleNotFound:
        "Der Artikel konnte nicht bearbeitet werden, da dieser nicht mehr existiert.",
    },
    RemoveArticleBarcodeNumbers: {
      ArticleNotFound:
        "Der Artikel konnte nicht bearbeitet werden, da dieser nicht mehr existiert.",
      InvalidBarcodeNumber:
        "Mindestens einer der übergebenen Barcodes ist ungültig.",
    },
    DeleteArticle: {
      ArticleNotFound: 'Der Artikel "%s" wurde bereits gelöscht.',
    },
    CreateKeyboardLayout: {
      KeyboardNameAlreadyExists:
        "Die eingegebene Bezeichnung für das Tastaturlayout ist bereits vergeben.",
      InvalidPortraitColumnCount:
        "Das Hochformat-Design eines Tastaturlayouts kann höchstens 3 Spalten besitzen.",
      InvalidLandscapeColumnCount:
        "Das Querformat-Design eines Tastaturlayouts kann höchstens 6 Spalten besitzen.",
      InvalidLayoutContent:
        "Es befinden sich Elemente (Tastenhauptgruppen, Tastenuntergruppen oder Artikeltasten) " +
        "im Tastaturlayout, welche nicht mehr existieren oder ungültig sind.",
    },
    ChangeKeyboardLayout: {
      KeyboardLayoutNotFound:
        "Das Tastaturlayout konnte nicht bearbeitet werden, da dieses nicht mehr existiert.",
      KeyboardNameAlreadyExists:
        "Die eingegebene Bezeichnung für das Tastaturlayout ist bereits vergeben.",
      InvalidPortraitColumnCount:
        "Das Hochformat-Design eines Tastaturlayouts kann höchstens 3 Spalten besitzen.",
      InvalidLandscapeColumnCount:
        "Das Querformat-Design eines Tastaturlayouts kann höchstens 6 Spalten besitzen.",
      InvalidLayoutContent:
        "Es befinden sich Elemente (Tastenhauptgruppen, Tastenuntergruppen oder Artikeltasten) " +
        "im Tastaturlayout, welche nicht mehr existieren oder ungültig sind.",
    },
    DeleteKeyboardLayout: {
      KeyboardLayoutNotFound: 'Das Tastaturlayout "%s" wurde bereits gelöscht.',
    },

    CreateKeysMainGroup: {
      KeysMainGroupNameExists:
        "Die eingegebene Bezeichnung für die Tastenhauptgruppe ist bereits vergeben.",
      InvalidColorCode:
        "Die ausgewählte Farbe entspricht keinem korrekten Farbcode.",
      InvalidGroupAssignment:
        "Einer Tastenhauptgruppe können nicht sowohl eine Hauptgruppe als auch Warengruppe hinzugefügt werden.",
      MainGroupNotFound: "Die referenzierte Hauptgruppe existiert nicht mehr.",
      ArticleGroupNotFound:
        "Die referenzierte Warengruppe existiert nicht mehr.",
    },
    ChangeKeysMainGroup: {
      KeysMainGroupNotFound:
        "Die Tastenhauptgruppe konnte nicht bearbeitet werden, da diese nicht mehr existiert.",
      KeysMainGroupNameExists:
        "Die eingegebene Bezeichnung für die Tastenhauptgruppe ist bereits vergeben.",
      InvalidColorCode:
        "Die ausgewählte Farbe entspricht keinem korrekten Farbcode.",
    },
    DeleteKeysMainGroup: {
      KeysMainGroupNotFound:
        'Die Tastenhauptgruppe "%s" wurde bereits gelöscht.',
    },

    CreateKeysSubGroup: {
      KeysSubGroupNameExists:
        "Die eingegebene Bezeichnung für die Tastenuntergruppe ist unter dieser Tastenhauptgruppe bereits vergeben.",
      InvalidColorCode:
        "Die ausgewählte Farbe entspricht keinem korrekten Farbcode.",
      ArticleGroupNotFound:
        "Die referenzierte Warengruppe existiert nicht mehr.",
      KeysMainGroupNotFound:
        "Die referenzierte Tastenuntergruppe existiert nicht mehr.",
      ArticleKeyNotFound:
        "Mindestens eine der referenzierten Artikeltasten existiert nicht mehr.",
    },
    ChangeKeysSubGroup: {
      KeysSubGroupNotFound:
        "Die Tastenuntergruppe konnte nicht bearbeitet werden, da diese nicht mehr existiert.",
      KeysSubGroupNameExists:
        "Die eingegebene Bezeichnung für die Tastenuntergruppe ist unter dieser Tastenhauptgruppe bereits vergeben.",
      InvalidColorCode:
        "Die ausgewählte Farbe entspricht keinem korrekten Farbcode.",
      ArticleKeyNotFound:
        "Mindestens eine der referenzierten Artikeltasten existiert nicht mehr.",
    },
    DeleteKeysSubGroup: {
      KeysSubGroupNotFound:
        'Die Tastenuntergruppe "%s" wurde bereits gelöscht.',
    },

    CreateArticleKey: {
      InvalidColorCode:
        "Die ausgewählte Farbe entspricht keinem korrekten Farbcode.",
      MainGroupNotFound: "Die referenzierte Hauptgruppe existiert nicht mehr.",
      ArticleGroupNotFound:
        "Die referenzierte Warengruppe existiert nicht mehr.",
      ArticleNotFound: "Der referenzierte Artikel existiert nicht mehr.",
      ArticleKeyAlreadyExists:
        "Es existiert bereits eine Artikeltaste zu diesem referenzierten Artikel.",
    },
    ChangeArticleKey: {
      ArticleKeyNotFound:
        "Die Artikeltaste konnte nicht bearbeitet werden, da diese nicht mehr existiert.",
      InvalidColorCode:
        "Die ausgewählte Farbe entspricht keinem korrekten Farbcode.",
      MainGroupNotFound: "Die referenzierte Hauptgruppe existiert nicht mehr.",
      ArticleGroupNotFound:
        "Die referenzierte Warengruppe existiert nicht mehr.",
      ArticleNotFound: "Der referenzierte Artikel existiert nicht mehr.",
      ArticleKeyAlreadyExists:
        "Es existiert bereits eine Artikeltaste zu diesem referenzierten Artikel.",
    },
    DeleteArticleKey: {
      ArticleKeyNotFound: 'Die Artikeltaste "%s" wurde bereits gelöscht.',
    },

    CreateContact: {
      InvalidEmail: "Die eingegebene E-Mail-Adresse ist ungültig.",
      InvalidTaxId:
        "Die eingegebene UID- oder Steuer-Identifikationsnummer ist ungültig.",
      InvalidCartDiscount: "Der eingegebene Warenkorbrabatt ist ungültig.",
      InvalidCashbackDiscount: "Das eingegebene Skonto ist ungültig.",
      InvalidIBAN: "Die eingegebene IBAN ist ungültig.",
      InvalidBIC: "Der eingegebene BIC ist ungültig.",
      ContactNumberExists: "Die eingegebene Kundennummer ist bereits vergeben.",
    },
    ChangeContact: {
      ContactNotFound:
        "Der Kunde konnte nicht bearbeitet werden, da dieser nicht mehr existiert.",
      InvalidEmail: "Die eingegebene E-Mail-Adresse ist ungültig.",
      InvalidTaxId:
        "Die eingegebene UID- oder Steuer-Identifikationsnummer ist ungültig.",
      InvalidCartDiscount: "Der eingegebene Warenkorbrabatt ist ungültig.",
      InvalidCashbackDiscount: "Das eingegebene Skonto ist ungültig.",
      InvalidIBAN: "Die eingegebene IBAN ist ungültig.",
      InvalidBIC: "Der eingegebene BIC ist ungültig.",
      ContactNumberExists: "Die eingegebene Kundennummer ist bereits vergeben.",
    },
    DeleteContact: {
      ContactNotFound: 'Der Kunde "%s" wurde bereits gelöscht.',
    },
    SendContactsPerMail: {
      InvalidEmailAddress: "Die eingegebene E-Mail-Adresse ist ungültig.",
    },

    CreatePersonInCharge: {
      ContactNotFound: "Der referenzierte Kunde existiert nicht mehr.",
      InvalidDateOfBirth:
        "Ein Geburtsdatum eines lebenden Ansprechpartners kann nicht in der Zukunft liegen.",
      InvalidEmail: "Die eingegebene E-Mail-Adresse ist ungültig.",
    },
    ChangePersonInCharge: {
      ContactPersonInChargeNotFound:
        "Der Ansprechpartner konnte nicht bearbeitet werden, da dieser nicht mehr existiert.",
      ContactNotFound: "Der referenzierte Kunde existiert nicht mehr.",
      InvalidDateOfBirth:
        "Ein Geburtsdatum einer lebenden Person kann nicht in der Zukunft liegen.",
      InvalidEmail: "Die eingegebene E-Mail-Adresse ist ungültig.",
    },
    DeletePersonInCharge: {
      ContactPersonInChargeNotFound:
        'Der Ansprechpartner "%s" wurde bereits gelöscht.',
      ContactNotFound: "Der referenzierte Kunde existiert nicht mehr.",
    },

    CreateShippingAddress: {
      ContactNotFound: "Der referenzierte Kunde existiert nicht mehr.",
    },
    ChangeShippingAddress: {
      ContactShippingAddressNotFound:
        "Die Lieferadresse konnte nicht bearbeitet werden, da diese nicht mehr existiert.",
      ContactNotFound: "Der referenzierte Kunde existiert nicht mehr.",
    },
    DeleteShippingAddress: {
      ContactShippingAddressNotFound:
        'Die Lieferadresse "%s" wurde bereits gelöscht.',
      ContactNotFound: "Der referenzierte Kunde existiert nicht mehr.",
    },

    CreateDepartment: {
      ContactNotFound: "Der referenzierte Kunde existiert nicht mehr.",
    },
    ChangeDepartment: {
      ContactDepartmentNotFound:
        "Die Abteilung konnte nicht bearbeitet werden, da diese nicht mehr existiert.",
      ContactNotFound: "Der referenzierte Kunde existiert nicht mehr.",
    },
    DeleteDepartment: {
      ContactDepartmentNotFound: 'Die Abteilung "%s" wurde bereits gelöscht.',
      ContactNotFound: "Der referenzierte Kunde existiert nicht mehr.",
    },

    CreateDiscount: {
      InvalidValues: "Überprüfen Sie bitte den Wertebereich des Rabatts.",
      DiscountNameExists:
        "Die eingegebene Bezeichnung für den Rabatt ist bereits vergeben.",
    },
    ChangeDiscount: {
      DiscountNotFound:
        "Der Rabatt konnte nicht bearbeitet werden, da dieser nicht mehr existiert.",
      InvalidValues: "Überprüfen Sie bitte den Wertebereich des Rabatts.",
      DiscountNameExists:
        "Die eingegebene Bezeichnung für den Rabatt ist bereits vergeben.",
    },
    DeleteDiscount: {
      DiscountNotFound: 'Der Rabatt "%s" wurde bereits gelöscht.',
    },

    CreateManagerCashFlow: {
      IsCompanyCashFlow:
        "Sie können im Admin-Interface keine unternehmensspezifischen Geldbewegungen erstellen.",
    },
    ChangeManagerCashFlow: {
      CashFlowNotFound:
        "Die Geldbewegung konnte nicht bearbeitet werden, da diese nicht mehr existiert.",
      IsGlobal:
        "Sie können keine globalen Geldbewegungen zu unternehmensspezifischen Geldbewegungen konvertieren.",
      IsCompanyCashFlow:
        "Sie können im Admin-Interface keine unternehmensspezifischen Geldbewegungen bearbeiten.",
    },
    DeleteManagerCashFlow: {
      CashFlowNotFound: 'Die Geldbewegung "%s" wurde bereits gelöscht.',
      IsGlobal: "Sie können keine globalen Geldbewegungen löschen.",
    },
    ChangeCashFlowVisibility: {
      CashFlowNotFound:
        "Die Sichtbarkeit der Geldbewegung kann nicht verändert werden, da diese nicht mehr existiert.",
    },

    CreateReceipt: {
      ReceiptAlreadyExists:
        "Dieser Beleg existiert bereits. Bitte kontrollieren Sie die Belegübersicht.",
      InvalidReceiptType:
        "Der Belegtyp stimmt nicht mit dessen Sollwert überein.",
      MismatchedReceiptData:
        "Der Beleg enthält eine fehlerhafte Komposition an Daten.",
      RksvIsDisabled:
        "Die Erstellung eines Nullbelegs ist nicht mehr möglich, da die Fiskalisierung für dieses Unternehmen deaktiviert wurde.",
      InvalidReceiptNumberFormat:
        "Das Format der Belegnummer entspricht nicht der definierten Spezifikation.",
    },
    CreateRefundingReceipt: {
      OriginalReceiptDoesNotExist:
        "Der Beleg kann nicht gutgeschrieben werden, da dieser nicht mehr existiert.",
      OriginalReceiptAlreadyRefunded:
        "Der Beleg kann nicht gutgeschrieben werden, da dieser bereits gutgeschrieben wurde.",
      OriginalReceiptAlreadyCancelled:
        "Der Beleg kann nicht gutgeschrieben werden, da dieser bereits storniert wurde.",
      InvalidReceiptNumberFormat:
        "Das Format der Belegnummer entspricht nicht der definierten Spezifikation.",
      MismatchedReceiptData:
        "Der Beleg enthält eine fehlerhafte Komposition an Daten.",
      PositiveRefundNotAllowed:
        "Belege mit einer nicht positiven Gesamtsumme dürfen keiner Gutschriftserstellung unterzogen werden.",
    },
    CreateZeroReceipt: {
      ReceiptAlreadyExists:
        "Dieser Beleg existiert bereits. Bitte kontrollieren Sie die Belegübersicht.",
      InvalidReceiptType:
        "Der Belegtyp stimmt nicht mit dessen Sollwert überein.",
      MismatchedReceiptData:
        "Der Beleg enthält eine fehlerhafte Komposition an Daten.",
      RksvIsDisabled:
        "Die Erstellung eines Nullbelegs ist nicht mehr möglich, da die Fiskalisierung für dieses Unternehmen deaktiviert wurde.",
      InvalidReceiptNumberFormat:
        "Das Format der Belegnummer entspricht nicht der definierten Spezifikation.",
    },
    CancelReceipt: {
      OriginalReceiptDoesNotExist:
        "Der Beleg kann nicht storniert werden, da dieser nicht mehr existiert.",
      OriginalReceiptAlreadyRefunded:
        "Der Beleg kann nicht storniert werden, da dieser bereits gutgeschrieben wurde.",
      OriginalReceiptAlreadyCancelled:
        "Der Beleg kann nicht storniert werden, da dieser bereits storniert wurde.",
      InvalidReceiptNumberFormat:
        "Das Format der Belegnummer entspricht nicht der definierten Spezifikation.",
      MismatchedReceiptData:
        "Der Beleg enthält eine fehlerhafte Komposition an Daten.",
    },
    ChangeReceiptContactAssignment: {
      ContactNotFound:
        "Der Beleg kann nicht aktualisiert werden, da der referenzierte Kunde nicht mehr existiert.",
      ReceiptNotFound:
        "Der Beleg kann nicht aktualisiert werden, da dieser nicht mehr existiert.",
      DepartmentNotFound:
        "Der Beleg kann nicht aktualisiert werden, da die referenzierte Abteilung nicht mehr existiert.",
      ShippingAddressNotFound:
        "Der Beleg kann nicht aktualisiert werden, da die referenzierte Lieferadresse nicht mehr existiert.",
      InvalidChangeRequest:
        "Der Beleg kann nicht aktualisiert werden, da die Anfrage an den Server fehlerhaft war.",
    },
    SendReceiptPerMail: {
      ReceiptNotFound:
        "Der Beleg kann nicht versandt werden, da dieser nicht mehr existiert.",
      InvalidEmailAddress: "Die eingegebene E-Mail-Adresse ist ungültig.",
    },
    SendReceiptsPerMail: {
      InvalidEmailAddress: "Die eingegebene E-Mail-Adresse ist ungültig.",
    },
    SendRksvReceiptsPerMail: {
      InvalidEmailAddress: "Die eingegebene E-Mail-Adresse ist ungültig.",
    },

    CreateCashFlowReceipt: {
      InvalidReceiptNumberFormat:
        "Das Format der Belegnummer entspricht nicht der definierten Spezifikation.",
      CashFlowReceiptAlreadyExists:
        "Der Beleg für diese Geldbewegung existiert bereits. Bitte kontrollieren Sie die Belegübersicht.",
      MismatchedCashFlowReceiptData:
        "Der Beleg für diese Geldbewegung enthält eine fehlerhafte Komposition an Daten.",
    },

    CloseBillingReport: {
      ReportNotOpen: "Die Abrechnung wurde bereits durchgeführt.",
    },
    CloseEndOfDayReport: {
      DateNotActive:
        "Der Tagesabschluss für den ausgewählten Abrechnungstag wurde bereits durchgeführt.",
    },

    SendArticleReportByMail: {
      InvalidEmailAddress: "Die eingegebene E-Mail-Adresse ist ungültig.",
    },
    SendGroupReportByMail: {
      InvalidEmailAddress: "Die eingegebene E-Mail-Adresse ist ungültig.",
    },
    SendAccountingReportByMail: {
      InvalidEmailAddress: "Die eingegebene E-Mail-Adresse ist ungültig.",
    },
    SendCashFlowReportByMail: {
      InvalidEmailAddress: "Die eingegebene E-Mail-Adresse ist ungültig.",
    },
    SendCashRegisterLedgerPerMail: {
      InvalidEmailAddress: "Die eingegebene E-Mail-Adresse ist ungültig.",
    },
  }),
}

// Helper function to ensure that the object passed
// here actually handles all commands.
function commandsErrorCodeMapping(data: CommandsErrorCodeMapping): any {
  return data
}
