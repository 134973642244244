import { useFonts } from "expo-font"
import React, { createElement, ReactNode } from "react"
import { Platform } from "react-native"

import {
  KassenAppUiProvider,
  KassenAppUiProviderProps,
} from "@axtesys/kassen-app-ui"
import {
  RobotoMono_300Light,
  RobotoMono_300Light_Italic,
  RobotoMono_400Regular,
  RobotoMono_400Regular_Italic,
  RobotoMono_600SemiBold,
  RobotoMono_600SemiBold_Italic,
} from "@expo-google-fonts/roboto-mono"
import {
  SourceSansPro_300Light,
  SourceSansPro_300Light_Italic,
  SourceSansPro_400Regular,
  SourceSansPro_400Regular_Italic,
  SourceSansPro_600SemiBold,
  SourceSansPro_600SemiBold_Italic,
} from "@expo-google-fonts/source-sans-pro"

import { LoadingIndicator } from "~shared/components/LoadingIndicator"

import { useTranslation } from "../../contexts/TranslationContext"

export function StyleProvider(props: { children: ReactNode }) {
  return createElement(
    Platform.OS != "ios" ? WebAndroidStyleProvider : IOSStyleProvider,
    props,
  )
}

function WebAndroidStyleProvider({ children }: { children: ReactNode }) {
  const strings = useKassenAppUiStrings()

  // Fonts used in theme: Only has effect on Android and Web applications
  const [fontsLoaded] = useFonts({
    "SourceSansPro-Regular": SourceSansPro_400Regular,
    "SourceSansPro-Light": SourceSansPro_300Light,
    "SourceSansPro-SemiBold": SourceSansPro_600SemiBold,
    "RobotoMono-Regular": RobotoMono_400Regular,
    "RobotoMono-Light": RobotoMono_300Light,
    "RobotoMono-SemiBold": RobotoMono_600SemiBold,
    "SourceSansPro-Italic": SourceSansPro_400Regular_Italic,
    "SourceSansPro-LightItalic": SourceSansPro_300Light_Italic,
    "SourceSansPro-SemiBoldItalic": SourceSansPro_600SemiBold_Italic,
    "RobotoMono-Italic": RobotoMono_400Regular_Italic,
    "RobotoMono-LightItalic": RobotoMono_300Light_Italic,
    "RobotoMono-SemiBoldItalic": RobotoMono_600SemiBold_Italic,
  })

  if (!fontsLoaded) return <LoadingIndicator />

  return <KassenAppUiProvider strings={strings}>{children}</KassenAppUiProvider>
}

function IOSStyleProvider({ children }: { children: ReactNode }) {
  return (
    <KassenAppUiProvider strings={useKassenAppUiStrings()}>
      {children}
    </KassenAppUiProvider>
  )
}

function useKassenAppUiStrings(): KassenAppUiProviderProps["strings"] {
  const strings = useTranslation()

  return {
    general: {
      ok: strings.general.ok,
      to: strings.general.to,
      now: strings.general.now,
      note: strings.general.note,
      from: strings.general.from,
      today: strings.general.today,
      cancel: strings.general.cancel,
      delete: strings.general.delete,
    },
    moneyRangeForm: {
      invalidPrice: strings.moneyValidation.invalidPrice,
      invalidRange: strings.moneyValidation.invalidRange,
    },
    dateTimeRangeForm: {
      durations: strings.enums.durations,
      selectionLimit: strings.reports.selectionLimit,
      invalidDate: strings.dateTimeValidation.invalidDate,
      invalidRange: strings.dateTimeValidation.invalidRange,
    },
  }
}
