import {
  lockAsync,
  OrientationLock,
  unlockAsync,
} from "expo-screen-orientation"
import { useEffect } from "react"
import { Dimensions, Platform } from "react-native"

type UseDeviceOrientationLockArgs = {
  // Screens larger than this number of pixels (max extension) are considered large
  sizeThreshold: number

  // set the locked orientation for different screen sizes
  smallScreenOrientation?: OrientationLock
  largeScreenOrientation?: OrientationLock
}

// Locks the Orientation of small screens to PORTRAIT mode
export function useDeviceOrientationLock(args: UseDeviceOrientationLockArgs) {
  useEffect(() => {
    if (Platform.OS == "web") return undefined

    const isSmallScreen = getScreenMaxDimension() < args.sizeThreshold
    if (isSmallScreen && args.smallScreenOrientation) {
      lockAsync(args.smallScreenOrientation)
    }
    if (!isSmallScreen && args.largeScreenOrientation) {
      lockAsync(args.largeScreenOrientation)
    }
    return () => {
      unlockAsync()
    }
  }, [
    args.sizeThreshold,
    args.smallScreenOrientation,
    args.largeScreenOrientation,
  ])
}

function getScreenMaxDimension() {
  const windowWidth = Dimensions.get("window").width
  const windowHeight = Dimensions.get("window").height
  return Math.max(windowWidth, windowHeight)
}
