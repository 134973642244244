import React, { lazy } from "react"

import { Column, Label } from "@axtesys/kassen-app-ui"

import { useFrontend } from "./shared/feature/Config"

// We lazy load all components,
// because most of the time we only use a single application.
const AdminEntryPoint = lazy(() => import("./application/admin/EntryPoint"))
const WebEntryPoint = lazy(() => import("./application/web/EntryPoint"))
const AppEntryPoint = lazy(() => import("./application/app/EntryPoint"))
const DesignerEntryPoint = lazy(
  () => import("./application/designer/EntryPoint"),
)

export function FrontendSpecificEntryPoint() {
  switch (useFrontend()) {
    case "admin":
      return <AdminEntryPoint />
    case "web":
      return <WebEntryPoint />
    case "app":
      return <AppEntryPoint />
    case "designer":
      return <DesignerEntryPoint />
    default:
      return <ErrorEntryPoint />
  }
}

function ErrorEntryPoint() {
  return (
    <Column expand gap="XS" alignCenter style={{ justifyContent: "center" }}>
      <Label bold text={`Could not load application ${useFrontend()}`} />
      <Label text="Did you forget to set the EXPO_APPLICATION environment variable or did you set it wrongly?" />
    </Column>
  )
}
