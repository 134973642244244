import React, { Fragment, ReactNode } from "react"

import { RowSeparator } from "../display/RowSeparator"
import { Box } from "../layout/Box"
import { Column, Row } from "../layout/FlexBox"
import { Gap } from "../layout/Gap"
import { Spacing } from "../types"

// A simple table component with arbitrary ReactNodes as cells.

export type TableProps<T> = {
  rows: T[]
  columns: TableColumn<T>[]

  gap?: Spacing
  gapBetweenRows?: Spacing
  gapHeaderAndData?: Spacing
  headerRowBold?: boolean
  showEndSeparator?: boolean
  showSeparatorsBetweenRows?: boolean
}

export type TableColumn<T> = {
  flex?: number
  header: ReactNode
  renderCell(row: T): ReactNode
}

export function Table<T>({
  rows,
  columns,
  gap = "XXXXS",
  headerRowBold = false,
  showEndSeparator = false,
  showSeparatorsBetweenRows,
  gapBetweenRows = undefined,
  gapHeaderAndData = "XXXXS",
}: TableProps<T>) {
  return (
    <Column gap={gap}>
      <HeaderRow />
      <RowSeparator color={headerRowBold ? "default" : undefined} />
      <Gap vertical={gapHeaderAndData} />
      <DataRows />
      {showEndSeparator && <EndSeparator />}
    </Column>
  )

  function HeaderRow() {
    return (
      <Row alignCenter gap={gap}>
        {columns.map((column, x) => (
          <Box key={x} flex={column.flex ?? 1}>
            {column.header}
          </Box>
        ))}
      </Row>
    )
  }

  function DataRows() {
    return (
      <>
        {rows.map((row, y) => (
          <Fragment key={y}>
            {showSeparatorsBetweenRows && y > 0 && <RowEntrySeparator />}
            <DataRowEntry row={row} />
            {!showSeparatorsBetweenRows && y != rows.length - 1 && (
              <Gap vertical={gapBetweenRows ?? gap} />
            )}
          </Fragment>
        ))}
      </>
    )
  }

  function DataRowEntry(props: { row: T }) {
    return (
      <Row gap={gap}>
        {columns.map((column, x) => (
          <Box key={x} flex={column.flex ?? 1}>
            {column.renderCell(props.row)}
          </Box>
        ))}
      </Row>
    )
  }

  function RowEntrySeparator() {
    return (
      <>
        <RowSeparator />
        <Gap vertical={gap} />
      </>
    )
  }

  function EndSeparator() {
    return (
      <>
        <Gap vertical="XXXXS" />
        <RowSeparator />
      </>
    )
  }
}
