import React from "react"

import { useToggle } from "@axtesys/hooks"

import { TextInput, TextInputProps } from "./TextInput"

export type PasswordInputProps = TextInputProps & {
  forceHidden?: boolean
}

export function PasswordInput(props: PasswordInputProps) {
  const [passwordHidden, togglePasswordHidden] = useToggle(true)
  const icon = passwordHidden ? "eye-outline" : "eye-off-outline"

  return (
    <TextInput
      {...props}
      secureTextEntry={passwordHidden}
      iconRight={
        props.forceHidden
          ? undefined
          : { name: icon, onPress: togglePasswordHidden }
      }
    />
  )
}
