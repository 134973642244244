import React, { useMemo } from "react"

import { MCIcon } from "../MCIcon"
import { Icon } from "../display/Icon"

export type SelectorProps = {
  value: boolean
  type: "checkBox" | "radioButton"

  onChange: (value: boolean) => void

  disabled?: boolean
  errorIndicator?: boolean
}

export function Selector(props: SelectorProps) {
  const icon = useMemo(() => {
    let iconName: MCIcon
    if (props.type == "radioButton") {
      iconName = props.value ? "radiobox-marked" : "radiobox-blank"
    } else {
      iconName = props.value
        ? "checkbox-marked-outline"
        : "checkbox-blank-outline"
    }
    return iconName
  }, [props.type, props.value])

  const disabled = props.disabled ?? false

  return (
    <Icon
      name={icon}
      disabled={disabled}
      onPress={() => props.onChange(!props.value)}
      color={props.errorIndicator ? "error" : undefined}
    />
  )
}
