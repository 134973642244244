import React from "react"
import { FallbackProps } from "react-error-boundary"

import { useNetworkStatus, useTransitionEffect } from "@axtesys/hooks"
import {
  Box,
  Column,
  ColumnProps,
  Icon,
  Label,
  Row,
} from "@axtesys/kassen-app-ui"
import { useQueryErrorResetBoundary } from "@tanstack/react-query"

import { RetryButton } from "./RetryButton"

type ReducedFallbackProps = Pick<FallbackProps, "resetErrorBoundary">

export function NetworkErrorFallback(props: ReducedFallbackProps) {
  const { connected } = useNetworkStatus()
  const resetFallback = useResetErrorFallback(props.resetErrorBoundary)

  useTransitionEffect(connected, (isConnected, wasConnected) => {
    // When we're coming online after the error occurred
    if (!(isConnected && !wasConnected)) return

    resetFallback()
  })

  const networkError = (
    <Row alignCenter pad="S" gap="XXXS">
      <Icon name="wifi-strength-alert-outline" color="medium" />
      <Label h5 color="medium" text="Netzwerkfehler" />
    </Row>
  )

  const noInternet = (
    <Row alignCenter pad="S" gap="XXXS">
      <Icon name="wifi-off" color="medium" />
      <Label h5 color="medium" text="Keine Internetverbindung vorhanden." />
    </Row>
  )

  return connected ? networkError : noInternet
}

export function TimeoutErrorFallback(
  props: ReducedFallbackProps & Pick<ColumnProps, "pad" | "backgroundColor">,
) {
  const resetFallback = useResetErrorFallback(props.resetErrorBoundary)

  return (
    <Column
      expand
      gap="XS"
      pad={props.pad}
      backgroundColor={props.backgroundColor}
    >
      <Row alignCenter gap="XXXS">
        <Icon name="signal-cellular-outline" color="medium" />
        <Label
          h5
          color="medium"
          text="Die Anfrage an den Server ist fehlgeschlagen."
        />
      </Row>

      <Box style={{ maxWidth: 200 }}>
        <RetryButton onPress={resetFallback} />
      </Box>
    </Column>
  )
}

function useResetErrorFallback(
  resetBoundary: FallbackProps["resetErrorBoundary"],
) {
  const { reset } = useQueryErrorResetBoundary()

  return () => {
    // Reset the react-query cache
    reset()

    // And re-render the subtree to fetch again
    resetBoundary()
  }
}
