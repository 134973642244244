import { print } from "graphql/language/printer"

import { ResultOf, VariablesOf } from "@graphql-typed-document-node/core"

import * as GeneratedOperations from "./GeneratedOperations"

// This is a companion file to GeneratedOperations.ts
// While THAT file got generated by a tool, THIS file is written by me to provide
// a nice type API over the whole bunch of types that are exported by the code generator.

type G = typeof GeneratedOperations

// General Nodes
type NameOfDocumentNode<Export> = Export extends `${infer DocumentName}Document`
  ? DocumentName
  : never
type DocumentNodeOfName<DocumentName extends string> = `${DocumentName}Document`

type DocumentNodes = {
  [Export in keyof G]: Export extends `${infer Name}Document` ? Export : never
}[keyof G]

export function getOperationQueryString(operationName: OperationNames): string {
  const documentNode = (GeneratedOperations as any)[`${operationName}Document`]
  return print(documentNode)
}

// Specialized Nodes
type OperationNodes = DocumentNodes
type QueryNodes = {
  [DocumentNode in DocumentNodes]: ResultOf<G[DocumentNode]> extends {
    __typename?: "Query"
  }
    ? DocumentNode
    : never
}[DocumentNodes]
type MutationNodes = {
  [DocumentNode in DocumentNodes]: ResultOf<G[DocumentNode]> extends {
    __typename?: "Mutation"
  }
    ? DocumentNode
    : never
}[DocumentNodes]

// Union of Names
export type OperationNames = {
  [OperationNode in OperationNodes]: NameOfDocumentNode<OperationNode>
}[OperationNodes]
export type QueryNames = {
  [QueryNode in QueryNodes]: NameOfDocumentNode<QueryNode>
}[QueryNodes]
export type MutationNames = {
  [MutationNode in MutationNodes]: NameOfDocumentNode<MutationNode>
}[MutationNodes]

// Variables to a Name
export type OperationVariables<O extends OperationNames> = VariablesOf<
  G[DocumentNodeOfName<O>]
>
export type QueryVariables<Q extends QueryNames> = VariablesOf<
  G[DocumentNodeOfName<Q>]
>
export type MutationVariables<M extends MutationNames> = VariablesOf<
  G[DocumentNodeOfName<M>]
>

// Data (Result) to a Name
export type OperationData<O extends OperationNames> = ResultOf<
  G[DocumentNodeOfName<O>]
>
export type QueryData<Q extends QueryNames> = ResultOf<G[DocumentNodeOfName<Q>]>
export type MutationData<M extends MutationNames> = ResultOf<
  G[DocumentNodeOfName<M>]
>
