import * as z from "zod"

import { createEntityFor } from "@axtesys/react-tools"

export type HobexResponse = {
  acquirer: string
  actionCode: string
  amount: number
  applicationIdentifier: string
  authCode: string
  brand: string
  cardNumber: string
  clientId: string
  connectionStatus: string
  countryCode?: string
  currency: string
  cvm: string
  expiry: string
  foreignAmount: number
  host: string
  isContactless: boolean
  isOk: boolean
  isSignature: boolean
  language: string
  macAddress: string
  merchantName: string
  networkMedia: string
  operator: string
  packageVersion: string
  pan: string
  receiptNo: string
  reference: string
  responseCode: string
  responseText: string
  serialNo: string
  serviceCode: string
  stan: string
  swVersion: string
  terminal: string
  terminalGateway: string
  terminalID: string
  terminalIpAddress: string
  terminalSubnetMask: string
  tipAmount: number
  transactionCount: number
  transactionDate: string
  transactionId: string
  transactionType: string
  vu: string
}

export type HobexReceiptData = {
  transactionDate?: string
  amount?: number
  currency?: string
  terminalID?: string
  receiptNo?: string
  transactionType?: string
  brand?: string
  pan?: string
  responseCode?: string
  transactionId?: string
}

export const HobexReceiptDataEntity = createEntityFor<HobexReceiptData>()
  .withSchema(
    z.object({
      transactionDate: z.ostring(),
      amount: z.onumber(),
      currency: z.ostring(),
      terminalID: z.ostring(),
      receiptNo: z.ostring(),
      transactionType: z.ostring(),
      brand: z.ostring(),
      pan: z.ostring(),
      responseCode: z.ostring(),
      transactionId: z.ostring(),
    }),
  )
  .serialize(data => data)
  .deserialize(json => json)
