import { useScreenSizeDependentValue } from "@axtesys/kassen-app-ui"

export function useToolTipFontConfig() {
  const titleProps = useScreenSizeDependentValue({
    S: { h4: true },
    M: { h4: true },
    L: { h5: true },
  })
  const messageProps = useScreenSizeDependentValue({
    S: { medium: false },
    M: { medium: false },
    L: { medium: true },
  })

  return { titleProps, messageProps }
}
