import React from "react"

import Slider, { SliderProps } from "@react-native-community/slider"

import { useTheme } from "../theme"

export type SliderInputProps = Pick<
  SliderProps,
  "value" | "step" | "maximumValue" | "minimumValue" | "style"
> & { onChange?: (value: number) => void }

export function SliderInput(props: SliderInputProps) {
  const { color } = useTheme()

  return (
    <Slider
      {...props}
      value={props.value}
      thumbTintColor={color.secondary1}
      maximumTrackTintColor={color.accent}
      minimumTrackTintColor={color.primary}
      style={[{ maxHeight: 30 }, props.style]}
      onValueChange={props.onChange}
    />
  )
}
