import { useCallback } from "react"
import { useRecoilCallback, useSetRecoilState } from "recoil"

import { useSnackbar } from "../../contexts/SnackbarContext"
import { useTranslation } from "../../contexts/TranslationContext"
import { useIsInteractionPrevention } from "./queries"
import { interactionPreventionState } from "./state"

// Used to restrict user interactions (touch or click input)
// during critical operations.

export function useInteractionPrevention() {
  const setInteractionPrevention = useSetRecoilState(interactionPreventionState)

  const allowInteraction = () => setInteractionPrevention(false)
  const preventInteraction = () => setInteractionPrevention(true)

  return { allowInteraction, preventInteraction }
}

export function useExecuteSafeAction() {
  const showInteractionPreventionSnackbar = useShowIntPrevSnackbar()

  return useRecoilCallback(
    ({ snapshot }) =>
      (action?: () => void) =>
        !snapshot.getLoadable(interactionPreventionState).getValue()
          ? action?.()
          : showInteractionPreventionSnackbar(),
    [showInteractionPreventionSnackbar],
  )
}

export function useShowIntPrevWarning() {
  const isInteractionPrevention = useIsInteractionPrevention()
  const showInteractionPreventionSnackbar = useShowIntPrevSnackbar()

  return useCallback(() => {
    if (!isInteractionPrevention) return
    showInteractionPreventionSnackbar()
  }, [isInteractionPrevention, showInteractionPreventionSnackbar])
}

function useShowIntPrevSnackbar() {
  const { showSnackbar } = useSnackbar()
  const { interactionPreventionMessage } = useTranslation().general

  return useCallback(
    () =>
      showSnackbar({
        mode: "warning",
        key: "interactionPrevention",
        message: interactionPreventionMessage,
      }),
    [interactionPreventionMessage, showSnackbar],
  )
}
