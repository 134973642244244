import Big from "big.js"
import React, { useRef } from "react"
import { InputAccessoryView, Keyboard, Platform } from "react-native"
import { v4 as uuidv4 } from "uuid"

import {
  useNumberInput,
  UseNumberInputProps,
  UseNumberInputResult,
} from "@axtesys/hooks"

import { Row } from "../../layout/FlexBox"
import { LinkLabel } from "../../typography/LinkLabel"
import { FocusBlurEvent, TextInput, TextInputProps } from "./TextInput"

export type NumberInputProps<T extends Big | number> = Omit<
  TextInputProps,
  "value" | "keyboardType" | "onChange"
> &
  UseNumberInputProps<T> & {
    // Used to hide the iOS specific 'Done' button
    // above a number or decimal keyboard layout.
    hideIOSDoneButton?: boolean
  }

type SpecialisationNumberInputProps<T extends Big | number> =
  NumberInputProps<T> & {
    customizedNumberInputProps: UseNumberInputResult
  }

export function NumberInput<T extends Big | number>(
  props: NumberInputProps<T>,
) {
  const customizedNumberInputProps = useNumberInput<T>({
    maxValue: 999999999 as T,
    ...props,
  })

  return Platform.OS == "ios" && !Platform.isPad ? (
    <IOSNumberInput
      {...props}
      customizedNumberInputProps={customizedNumberInputProps}
    />
  ) : (
    <GeneralNumberInput
      {...props}
      customizedNumberInputProps={customizedNumberInputProps}
    />
  )
}

function GeneralNumberInput<T extends Big | number>({
  customizedNumberInputProps,
  ...props
}: SpecialisationNumberInputProps<T>) {
  const optimizedGeneralProps = {
    ...props,
    value: undefined,
    onChange: undefined,
  }

  const onFocus = (event?: FocusBlurEvent) => {
    customizedNumberInputProps.onFocus()
    optimizedGeneralProps.onFocus?.(event)
  }
  const onBlur = (event?: FocusBlurEvent) => {
    customizedNumberInputProps.onBlur()
    optimizedGeneralProps.onBlur?.(event)
  }

  return (
    <TextInput
      {...optimizedGeneralProps}
      {...customizedNumberInputProps}
      // Do not show completion suggestions
      // above the numeric keyboard layout
      autoCorrect={false}
      onBlur={onBlur}
      onFocus={onFocus}
    />
  )
}

function IOSAccessoryView({
  nativeId,
  returnKeyLabel,
  onSubmitEditing,
}: {
  nativeId: string
  returnKeyLabel?: string
  onSubmitEditing?: () => void
}) {
  const onPress = () => {
    onSubmitEditing?.()
    Keyboard.dismiss()
  }

  return (
    <InputAccessoryView nativeID={nativeId} backgroundColor="white">
      <Row
        alignCenter
        padRight="XS"
        padVertical="XXS"
        style={{ justifyContent: "flex-end" }}
      >
        <LinkLabel
          h5
          color="primary"
          textAlign="center"
          text={returnKeyLabel ?? "Fertig"}
          onPress={onPress}
        />
      </Row>
    </InputAccessoryView>
  )
}

// Show accessory view on iOS for following keyboard layouts
const LAYOUTS_ACCESSORY_VIEW = ["number-pad", "decimal-pad"]

function IOSNumberInput<T extends Big | number>({
  returnKeyLabel,
  hideIOSDoneButton = false,
  customizedNumberInputProps,
  ...props
}: SpecialisationNumberInputProps<T>) {
  const accessoryViewId = useRef(uuidv4()).current
  const showAccessoryView =
    !hideIOSDoneButton &&
    LAYOUTS_ACCESSORY_VIEW.includes(customizedNumberInputProps.keyboardType)

  return (
    <>
      <GeneralNumberInput
        {...props}
        returnKeyType={undefined}
        customizedNumberInputProps={customizedNumberInputProps}
        inputAccessoryViewID={showAccessoryView ? accessoryViewId : undefined}
      />
      {showAccessoryView && (
        <IOSAccessoryView
          nativeId={accessoryViewId}
          returnKeyLabel={returnKeyLabel}
          onSubmitEditing={customizedNumberInputProps.onSubmitEditing}
        />
      )}
    </>
  )
}
