import { DependencyList, useEffect, useState } from "react"

// Like useState(), but can take dependencies and re-initialize the state to initialValue if they change
export function useFreshState<T>(
  initialValue: T | (() => T),
  dependencyList: DependencyList,
) {
  const [state, setState] = useState(initialValue)
  useEffect(() => {
    if (typeof initialValue == "function") {
      setState((initialValue as any)())
    } else {
      setState(initialValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencyList)
  return [state, setState] as const
}
