import {
  ReadOnlySelectorFamilyOptions,
  ReadOnlySelectorOptions,
  ReadWriteSelectorFamilyOptions,
  ReadWriteSelectorOptions,
  RecoilState,
  RecoilValueReadOnly,
  selector as recoilSelector,
  selectorFamily as recoilSelectorFamily,
  SerializableParam,
} from "recoil"

// Recoil selector with default cachePolicy set to "most-recent"
export function selector<T>(
  options: ReadWriteSelectorOptions<T>,
): RecoilState<T>
export function selector<T>(
  options: ReadOnlySelectorOptions<T>,
): RecoilValueReadOnly<T>
export function selector<T>(
  options: ReadWriteSelectorOptions<T> | ReadOnlySelectorOptions<T>,
): RecoilState<T> | RecoilValueReadOnly<T> {
  return recoilSelector<T>({
    ...options,
    cachePolicy_UNSTABLE: options.cachePolicy_UNSTABLE ?? {
      eviction: "most-recent",
    },
  })
}

// Recoil read-only selectorFamily with default cachePolicy set to "most-recent"
export function selectorFamilyReadOnly<T, P extends SerializableParam>(
  options: ReadOnlySelectorFamilyOptions<T, P>,
): (param: P) => RecoilValueReadOnly<T> {
  return recoilSelectorFamily<T, P>({
    ...options,
    cachePolicy_UNSTABLE: options.cachePolicy_UNSTABLE ?? {
      eviction: "most-recent",
    },
  })
}

// Recoil read-write selectorFamily with default cachePolicy set to "most-recent"
export function selectorFamily<T, P extends SerializableParam>(
  options: ReadWriteSelectorFamilyOptions<T, P>,
): (param: P) => RecoilState<T> {
  return recoilSelectorFamily<T, P>({
    ...options,
    cachePolicy_UNSTABLE: options.cachePolicy_UNSTABLE ?? {
      eviction: "most-recent",
    },
  })
}
