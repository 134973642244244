import base64 from "base-64"
import { fromUnixTime } from "date-fns"

import { AuthenticatedUserFragment } from "../../api/graphql/types"
import { JWT, JWTDecoded } from "./types"

export function transformAuthenticatedUser({
  companyName,
  salutation,
  firstName,
  lastName,
  modules,
  accessToken,
  refreshToken,
}: AuthenticatedUserFragment) {
  const {
    companyId,
    features,
    permissions,
    sub: userId,
    role: userRole,
    exp: accessExpiry,
  } = decodeJwt(accessToken)
  const decodedRefresh = decodeJwt(refreshToken)

  const accessExpiresAt = fromUnixTime(accessExpiry).toISOString()
  const refreshExpiresAt = fromUnixTime(decodedRefresh.exp).toISOString()

  return {
    companyId,
    userId,
    companyName,
    salutation,
    firstName,
    lastName,
    userRole,
    modules,
    accessToken,
    accessExpiresAt,
    refreshToken,
    refreshExpiresAt,
    permissions: {
      grantDiscount: permissions?.grantDiscount,
      grantManagement: permissions?.grantManagement ?? false,
      grantReportAccess: permissions?.grantReportAccess ?? true,
    },
    features: {
      printReceiptInstantly: features?.printReceiptInstantly ?? false,
    },
  }
}

export function decodeJwt(token: JWT): JWTDecoded {
  const [_, data] = token.split(".")

  const jwt = JSON.parse(base64.decode(data))
  const features = jwt.features ? JSON.parse(jwt.features) : undefined
  const permissions = jwt.permissions ? JSON.parse(jwt.permissions) : undefined

  return { ...jwt, features, permissions }
}
