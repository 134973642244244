import { isEqual, keys } from "lodash"
import React from "react"

import { useSelection } from "~shared/components/SelectionContext"

import { InputRow } from "./InputRow"

// Alternative to DropdownField in App

export function SingleSelectRow({
  title,
  value,
  defaultOverwrite,
  displayKeyValuePairs,
  onChange,
  ...props
}: {
  value: string
  onChange: (value: string) => void
  displayKeyValuePairs: Record<string, string>

  title?: string
  disabled?: boolean
  defaultOverwrite?: boolean
}) {
  const { selectSingle } = useSelection()

  const disabled =
    props.disabled ?? isEqual(keys(displayKeyValuePairs), ["default"])

  const items = Object.keys(displayKeyValuePairs)
  const onPress = async () => {
    const selectedValue = await selectSingle({
      title,
      items,
      selection: value,
      confirmMode: false,
      displayMapping: item => displayKeyValuePairs[item],
    })
    onChange(selectedValue)
  }

  return (
    <InputRow
      disabled={disabled}
      icon={{ name: "format-list-checkbox" }}
      text={
        displayKeyValuePairs[value] ??
        (defaultOverwrite ? value : displayKeyValuePairs?.default)
      }
      onPress={!disabled ? onPress : undefined}
    />
  )
}
