import { DeviceType, getDeviceTypeAsync } from "expo-device"
import { createContext, createElement, ReactNode, useContext } from "react"
import { Dimensions, PixelRatio, Platform } from "react-native"

import { useAsyncValue } from "../state/useAsyncValue"

export type DeviceInfo = {
  deviceType: "desktop" | "tablet" | "phone"
}

// Returns information about the device this application is running on.
// Requires the DeviceInfoContextProvider to be mounted above the component.
export function useDeviceInfo(): DeviceInfo {
  return useContext(deviceInfoContext)
}

export function DeviceInfoProvider(props: { children: ReactNode }) {
  const deviceType = useAsyncValue(getDeviceTypeAsync, [])

  // Delay rendering until deviceType is detected
  if (deviceType == null) return null

  const deviceInfo: DeviceInfo = {
    deviceType: mapDeviceType(deviceType),
  }

  return createElement(
    deviceInfoContext.Provider,
    { value: deviceInfo },
    props.children,
  )
}

const deviceInfoContext = createContext<DeviceInfo>({
  deviceType: defaultDeviceType(),
})

// Some tablets are misinterpreted by 'expo-device'
// Workaround:
// For iOS: Use the standard 'expo-device' implementation as it seems to work properly
// For Android: Use the pixel density in combination with the screen dimensions of the device to retrieve the device type
// https://stackoverflow.com/questions/44562769/react-native-check-if-tablet-or-screen-in-inches
function mapDeviceType(deviceType: DeviceType): "desktop" | "tablet" | "phone" {
  if (Platform.OS == "android") {
    const { width, height } = Dimensions.get("screen")
    const pixelDensity = PixelRatio.get()
    const adjustedWidth = width * pixelDensity
    const adjustedHeight = height * pixelDensity

    if (pixelDensity < 2 && (adjustedWidth >= 1000 || adjustedHeight >= 1000)) {
      return "tablet"
    } else if (
      pixelDensity == 2 &&
      (adjustedWidth >= 1920 || adjustedHeight >= 1920)
    ) {
      return "tablet"
    } else {
      return "phone"
    }
  }

  switch (deviceType) {
    case DeviceType.DESKTOP:
      return "desktop"
    case DeviceType.TABLET:
      return "tablet"
    case DeviceType.PHONE:
      return "phone"
    default:
      return defaultDeviceType()
  }
}

function defaultDeviceType() {
  if (Platform.OS == "android" || Platform.OS == "ios") return "phone"
  else return "desktop"
}
