import { Platform } from "react-native"

type Key = "android" | "ios" | "web" | "default"
type Options = { [Platform in Key]?: any }
type ReturnValue<T extends Options> = {
  [Platform in Key]: unknown extends T[Platform] ? undefined : T[Platform]
}[Key]

export function decideOnPlatform<T extends Options>(
  options: T,
): ReturnValue<T> {
  switch (Platform.OS) {
    case "ios":
      return options.ios ?? options.default
    case "android":
      return options.android ?? options.default
    case "web":
      return options.web ?? options.default
    default:
      return options.default
  }
}

export function isIOSGreaterEqual16() {
  return (
    Platform.OS == "ios" &&
    parseInt(Platform.constants.osVersion.split(".")?.[0]) >= 16
  )
}

export function isIOSLowerEqual16() {
  return (
    Platform.OS == "ios" &&
    parseInt(Platform.constants.osVersion.split(".")?.[0]) <= 16
  )
}
