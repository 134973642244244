import { atom } from "recoil"

import { selector } from "~shared/lib/recoil/lib"

import { cardDeviceState } from "../CashRegister/state"

export const gpTomEnabledSelector = selector({
  key: "gpTomEnabled",
  get: ({ get }) => get(cardDeviceState) == "GPtom",
})

export const gpTomConnectionState = atom({
  key: "gpTomConnection",
  default: "not used" as
    | "not used"
    | "connecting"
    | "connected"
    | "failed to connect",
})
