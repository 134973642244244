import Big from "big.js"
import { concat, groupBy, keys, last, orderBy, uniq } from "lodash"

import { formatIsoDateTime } from "@axtesys/react-tools"

import {
  ArticleInput,
  DetailedReceiptItemFragment,
  DiscountInput,
  InvoiceItemInput,
  ReceiptInput,
  ValueTransferFlagsInput,
  ValueTransferFragment,
} from "~shared/api/graphql"
import { maxOfBigs, sumBigArray, toBig } from "~shared/lib/Big"
import { bigToEuros, eurosToBig } from "~shared/lib/Money"
import {
  ArticleGroup,
  ArticleGroupId,
  DetailedArticleReceiptItem,
  Discount,
  InputReceipt,
  InvoiceItem,
  InvoiceItemArticle,
  MainGroup,
  MainGroupId,
  OutputInvoiceItem,
  OutputReceipt,
  PaymentMethod,
  PreOutputReceipt,
  TaxInfo,
  TotalTaxInfo,
  ValueTransfer,
} from "~shared/types"

import { determineOutputType } from "../Printers/lib"
import {
  evaluateInvoiceItem,
  transformDiscountsToDiscountsWithType,
} from "../ShoppingCart/lib"

export function createReceipt(receipt: InputReceipt): PreOutputReceipt {
  // Items which should be marked as voided or zero in the cash register ledger
  const zeroAndVoidedItems = retrieveZeroAndVoidedItems(
    receipt.items,
    receipt.itemsHistory,
  )

  // Calculate the summarized taxInfo of the receipt (for TaxInfoTable) and total sums
  // groupedTaxInfo: TaxInfo sums grouped by their corresponding taxRate
  // totalTaxInfo: Total sums of all taxRate groups
  const { taxInfos, totalTaxInfo } = calculateTaxInfos(
    receipt.items,
    receipt.roundingDifference,
  )

  // The type in which a receipt is passed to the customer (email, paper [print])
  const receivedAs = determineOutputType(receipt.printerId)

  // Omit empty payments (they should not be on the receipt).
  // This is needed in case of multi payment method receipts.
  // The initial payment method could be set to zero.
  const payments = receipt.payments.filter(payment => !payment.amount.eq(0))

  // Order itemsHistory by its modifiedAt timestamp
  // to make sure that we send it correctly to the backend.
  const itemsHistory = orderBy(
    receipt.itemsHistory,
    historyEntry => historyEntry.modifiedAt,
  )

  // Order cartDiscountsHistory by its createdAt timestamp
  // to make sure that we send it correctly to the backend.
  const cartDiscountsHistory = orderBy(
    receipt.cartDiscountsHistory,
    historyEntry => historyEntry.createdAt,
  )

  return {
    ...receipt,
    payments,
    taxInfos,
    receivedAs,
    totalTaxInfo,
    itemsHistory,
    zeroAndVoidedItems,
    cartDiscountsHistory,
    signatureDeviceFailed: false,
  }
}

function retrieveZeroAndVoidedItems(
  finalItems: OutputInvoiceItem[],
  itemsHistory: InvoiceItem[],
) {
  const itemsToVoid = retrieveVoidedItems(finalItems, itemsHistory)
  const itemsToZero = retrieveZeroAmountItems(finalItems)

  return concat(itemsToZero, itemsToVoid)
}

function retrieveZeroAmountItems(
  items: OutputInvoiceItem[],
): OutputInvoiceItem[] {
  return items.filter(item => item.amount.eq(0))
}

function retrieveVoidedItems(
  finalItems: OutputInvoiceItem[],
  itemsHistory: InvoiceItem[],
): OutputInvoiceItem[] {
  const voidedItems: OutputInvoiceItem[] = []

  // Find the unique item ids from the history
  const invoiceItemIds = uniq(
    itemsHistory.map(invoiceItem => invoiceItem.invoiceItemId),
  )

  // For each id, check the difference between max and final amount
  for (const invoiceItemId of invoiceItemIds) {
    const history = itemsHistory.filter(
      invoiceItem => invoiceItem.invoiceItemId == invoiceItemId,
    )
    const final = finalItems.find(
      invoiceItem => invoiceItem.invoiceItemId == invoiceItemId,
    )

    // There should always be a max
    const maxAmount =
      maxOfBigs(history.map(invoiceItem => invoiceItem.amount)) ?? Big(0)

    // But possibly not a final (empty items get stripped), in this case final is actually 0
    const finalAmount = final?.amount ?? Big(0)

    // The difference is the amount of voided items
    const voidAmount = maxAmount.sub(finalAmount)
    if (voidAmount.eq(0)) continue

    const lastItemConfiguration = last(history)!

    // Transform all item discounts from the last configuration
    // We only want to include the last item discounts as otherwise
    // the cart discount calculations would get too complex and not really practical
    const discounts = transformDiscountsToDiscountsWithType(
      lastItemConfiguration.discounts,
      "ItemDiscount",
    )

    // Use the last configuration,
    // overwrite the amount and re-evaluate to calculate the correct tax info
    voidedItems.push(
      evaluateInvoiceItem({
        ...lastItemConfiguration,
        discounts,
        amount: voidAmount,
      }),
    )
  }
  return voidedItems
}

function calculateTaxInfos(
  items: OutputInvoiceItem[],
  roundingDifference?: Big,
) {
  const groupedTaxInfos = groupBy(
    items.map(item => item.taxInfo),
    taxInfo => taxInfo.taxRate,
  )

  const taxInfos = keys(groupedTaxInfos).map(taxRate => {
    const taxGroupTaxInfos = groupedTaxInfos[taxRate]

    return {
      ...calculateSharedTaxInfoSums(taxGroupTaxInfos),
      taxRate: +taxRate,
    } as TaxInfo
  })

  const preTotalTaxInfo = calculateSharedTaxInfoSums(taxInfos)
  const totalTaxInfo = {
    ...preTotalTaxInfo,
    correctedGross: preTotalTaxInfo.gross.minus(roundingDifference ?? Big(0)),
  } as TotalTaxInfo

  return { taxInfos, totalTaxInfo }
}

function calculateSharedTaxInfoSums(
  taxInfos: TaxInfo[],
): Omit<TotalTaxInfo, "correctedGross"> {
  const tax = sumBigArray(taxInfos.map(info => info.tax))
  const net = sumBigArray(taxInfos.map(info => info.net))
  const gross = sumBigArray(taxInfos.map(info => info.gross))
  const discount = sumBigArray(taxInfos.map(info => info.discount))
  const undiscountedGross = sumBigArray(
    taxInfos.map(info => info.undiscountedGross),
  )

  return { net, tax, gross, discount, undiscountedGross }
}

export function transformOutputToGraphQLReceipt(
  receipt: OutputReceipt,
  mainGroups: Record<string, MainGroup>,
  articleGroups: Record<string, ArticleGroup>,
  unknownTranslation: string,
): ReceiptInput {
  return {
    receiptId: receipt.receiptId,
    company: receipt.company,
    operator: receipt.operator,
    createdAt: receipt.createdAt,
    department: receipt.department,
    receivedAs: receipt.receivedAs,
    receiptText: receipt.receiptText,
    cashRegister: receipt.cashRegister,
    receiptNumber: receipt.receiptNumber,
    billingAddress: receipt.billingAddress,
    gpTomReceiptData: receipt.gpTomResponse,
    hobexReceiptData: receipt.hobexResponse,
    shippingAddress: receipt.shippingAddress,
    items: transformOutputToGraphQLInvoiceItems(
      receipt.items,
      mainGroups,
      articleGroups,
      unknownTranslation,
    ),
    cartDiscountsHistory: receipt.cartDiscountsHistory.map(historyEntry => ({
      createdAt: formatIsoDateTime(historyEntry.createdAt),
      discounts: transformDiscountsToGraphQLDiscount(historyEntry.discounts),
    })),
    zeroAndVoidedItems: transformOutputToGraphQLInvoiceItems(
      receipt.zeroAndVoidedItems,
      mainGroups,
      articleGroups,
      unknownTranslation,
    ),
    itemsHistory: transformInvoiceItemHistoryToGraphQLInvoiceItemHistory(
      receipt.itemsHistory,
      mainGroups,
      articleGroups,
      unknownTranslation,
    ),
    payments: receipt.payments.map(payment => ({
      paymentMethodId: payment.paymentMethodId,
      moneyAmount: bigToEuros(payment.amount),
      paymentMethodName: payment.methodName,
      flags: payment.flags,
    })),
    payouts: receipt.payouts?.map(payout => ({
      paymentMethodId: payout.paymentMethodId,
      moneyAmount: bigToEuros(payout.amount),
      paymentMethodName: payout.methodName,
      flags: payout.flags,
    })),
    taxInfos: receipt.taxInfos.map(taxInfo => ({
      taxRate: taxInfo.taxRate,
      net: bigToEuros(taxInfo.net),
      tax: bigToEuros(taxInfo.tax),
      gross: bigToEuros(taxInfo.gross),
      discount: bigToEuros(taxInfo.discount),
      undiscountedGross: bigToEuros(taxInfo.undiscountedGross),
    })),
    totalTaxInfo: {
      net: bigToEuros(receipt.totalTaxInfo.net),
      tax: bigToEuros(receipt.totalTaxInfo.tax),
      gross: bigToEuros(receipt.totalTaxInfo.gross),
      discount: bigToEuros(receipt.totalTaxInfo.discount),
      correctedGross: bigToEuros(receipt.totalTaxInfo.correctedGross),
      undiscountedGross: bigToEuros(receipt.totalTaxInfo.undiscountedGross),
    },
  }
}

function transformOutputToGraphQLInvoiceItems(
  items: OutputInvoiceItem[],
  mainGroups: Record<MainGroupId, MainGroup>,
  articleGroups: Record<ArticleGroupId, ArticleGroup>,
  unknownTranslation: string,
): InvoiceItemInput[] {
  return items.map(item => ({
    amount: item.amount.toNumber(),
    invoiceItemId: item.invoiceItemId,
    isDiscountBlocked: item.isDiscountBlocked,
    createdAt: item.createdAt.toISOString(),
    modifiedAt: item.modifiedAt.toISOString(),
    article: transformArticleToGraphQLArticle(
      item.article,
      mainGroups,
      articleGroups,
      unknownTranslation,
    ),
    discounts: item.discounts.map(discount => ({
      name: discount.name,
      initialType: discount.initialType,
      discountType: discount.discountType,
      percent: discount.percent?.toNumber(),
      absolute: bigToEuros(discount.absolute),
    })),
    additionalText: item.additionalText,
    discountedPricePerUnit: bigToEuros(
      item.discountedPricePerUnit ?? item.article.price,
    ),
    taxInfo: {
      net: bigToEuros(item.taxInfo.net),
      tax: bigToEuros(item.taxInfo.tax),
      gross: bigToEuros(item.taxInfo.gross),
      discount: bigToEuros(item.taxInfo.discount),
      undiscountedGross: bigToEuros(item.taxInfo.undiscountedGross),
    },
  }))
}

function transformDiscountsToGraphQLDiscount(
  discounts: Discount[],
): DiscountInput[] {
  return discounts.map(discount => ({
    name: discount.name,
    absolute: bigToEuros(discount.absolute),
    percent: discount.percent?.toNumber(),
  }))
}

function transformInvoiceItemHistoryToGraphQLInvoiceItemHistory(
  items: InvoiceItem[],
  mainGroups: Record<MainGroupId, MainGroup>,
  articleGroups: Record<ArticleGroupId, ArticleGroup>,
  unknownTranslation: string,
) {
  return items.map(item => ({
    invoiceItemId: item.invoiceItemId,
    amount: item.amount.toNumber(),
    additionalText: item.additionalText,
    isDiscountBlocked: item.isDiscountBlocked,
    modifiedAt: item.modifiedAt.toISOString(),
    article: transformArticleToGraphQLArticle(
      item.article,
      mainGroups,
      articleGroups,
      unknownTranslation,
    ),
    discounts: transformDiscountsToGraphQLDiscount(item.discounts),
  }))
}

function transformArticleToGraphQLArticle(
  article: InvoiceItemArticle,
  mainGroups: Record<MainGroupId, MainGroup>,
  articleGroups: Record<ArticleGroupId, ArticleGroup>,
  unknownTranslation: string,
): ArticleInput {
  return {
    mainGroupId: article.mainGroupId,
    articleGroupId: article.articleGroupId,
    articleId: article.articleId,
    name: article.name,
    number: article.number,
    taxRate: article.taxRate,
    price: bigToEuros(article.price),
    mainGroupName: mainGroups[article.mainGroupId]?.name ?? unknownTranslation,
    articleGroupName:
      articleGroups[article.articleGroupId]?.name ?? unknownTranslation,
  }
}

export function transformFragmentToDetailedReceiptItem(
  item: DetailedReceiptItemFragment,
  unknownTranslation: string,
): DetailedArticleReceiptItem {
  return {
    invoiceItemId: item.id,
    amount: toBig(item.amount)!,
    isNegativeAmount: item.amount < 0,
    additionalText: item.additionalText,
    article: {
      mainGroupId: item.initialArticle.mainGroupId,
      articleGroupId: item.initialArticle.articleGroupId,
      articleId: item.initialArticle.articleId,
      name: item.initialArticle.name,
      taxRate: item.initialArticle.taxRate,
      price: eurosToBig(item.initialArticle.price),
      number: item.initialArticle.number,
    },
    discountNames: item.discountNames.map(name => name ?? unknownTranslation),
    taxInfo: {
      taxRate: item.initialArticle.taxRate,
      net: eurosToBig(item.taxInfo.net),
      tax: eurosToBig(item.taxInfo.tax),
      gross: eurosToBig(item.taxInfo.gross),
      discount: eurosToBig(item.taxInfo.discount),
      undiscountedGross: eurosToBig(item.taxInfo.undiscountedGross),
    },
  }
}

export function transformFragmentToValueTransfers(
  valueTransfer: ValueTransferFragment[],
  paymentMethods: PaymentMethod[],
): ValueTransfer[] {
  return valueTransfer.map(transfer => {
    const paymentMethod = !transfer.flags
      ? paymentMethods?.find(
          paymentMethod =>
            paymentMethod.paymentMethodId == transfer.paymentMethodId,
        )
      : undefined

    let flags: ValueTransferFlagsInput
    if (transfer.flags) flags = transfer.flags
    else if (paymentMethod)
      flags = {
        ...paymentMethod.properties,
        ...paymentMethod.programmaticProperties,
      }
    else throw Error("Value transfer flags missing")

    return {
      paymentMethodId: transfer.paymentMethodId,
      flags,
      methodName: transfer.paymentMethodName,
      amount: eurosToBig(transfer.moneyAmount),
    }
  })
}
