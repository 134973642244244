import Big from "big.js"
import * as z from "zod"

import { createEntityFor } from "@axtesys/react-tools"

import { InvoiceItemArticle } from "./Article"
import { OutputInvoiceItem } from "./InvoiceItem"
import { TaxInfoEntity } from "./TaxInfo"

export type ReceiptItem = Omit<
  OutputInvoiceItem,
  "createdAt" | "modifiedAt" | "isDiscountBlocked" | "article" | "discounts"
> & {
  discountNames: string[]
  article: Omit<
    InvoiceItemArticle,
    "articleId" | "articleGroupId" | "mainGroupId" | "number"
  >
}

export type DetailedArticleReceiptItem = Omit<ReceiptItem, "article"> & {
  article: InvoiceItemArticle
}

export const ReceiptItemEntity = createEntityFor<ReceiptItem>()
  .withSchema(
    z.object({
      invoiceItemId: z.string(),
      amount: z.string(), // Big
      taxInfo: TaxInfoEntity.schema,
      discountNames: z.array(z.string()),
      article: z.object({
        name: z.string(),
        price: z.string(), // Big
        taxRate: z.number(),
      }),
      additionalText: z.optional(z.string()),
      isNegativeAmount: z.boolean(),
    }),
  )
  .serialize(data => {
    const taxInfo = TaxInfoEntity.serialize(data.taxInfo)

    return {
      ...data,
      taxInfo,
      amount: data.amount.toString(),
      article: {
        name: data.article.name,
        price: data.article.price.toString(),
        taxRate: data.article.taxRate,
      },
    }
  })
  .deserialize(json => {
    const taxInfo = TaxInfoEntity.deserialize(json.taxInfo)

    return {
      ...json,
      taxInfo,
      amount: Big(json.amount),
      article: {
        name: json.article.name,
        taxRate: json.article.taxRate,
        price: Big(json.article.price),
      },
    }
  })
