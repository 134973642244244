import React, { lazy } from "react"

import {
  CreateNavigationArgs,
  CreateNavigationResult,
  ScreenDef,
  ScreenStackProps,
} from "../types"
import { createNavigation } from "./createNavigation"

export const AppSuspenseBoundary = lazy(
  () => import("../boundaries/AppSuspenseBoundary"),
)

export function createAppNavigation<S extends ScreenDef>(
  args: CreateNavigationArgs<S>,
): CreateNavigationResult<S> {
  const { ScreenStackComponent, ...navigation } = createNavigation<S>(args)

  return {
    ...navigation,

    ScreenStackComponent(props: ScreenStackProps<S>) {
      // Note: Mind a possible Android back button handling
      // in a specialised implementation.

      return <ScreenStackComponent {...props} fallback={AppSuspenseBoundary} />
    },
  }
}
