import React, { ReactNode, useCallback } from "react"

import {
  Box,
  Button,
  DialogContextProvider,
  Gap,
  Label,
  Overlay,
  Row,
  Spacer,
  useDialogContext,
  useTheme,
} from "@axtesys/kassen-app-ui"

import { useSelection } from "../../../components/SelectionContext"

export function SimpleConfirmDialogProvider(props: { children: ReactNode }) {
  return (
    <DialogContextProvider>
      {props.children}
      <SimpleConfirmDialogOverlay />
    </DialogContextProvider>
  )
}

export function useSimpleConfirmDialog() {
  const { showDialog } = useDialogContext()

  const showDialogOk = useCallback(
    (props: { text: string; title?: string; buttonLabel?: string }) =>
      new Promise<boolean>(resolve => {
        showDialog(
          <SimpleConfirmDialog
            title={props.title}
            primaryAction={{
              label: props.buttonLabel ?? "Verstanden",
              onPress: () => resolve(true),
            }}
          >
            {props.text}
          </SimpleConfirmDialog>,
        )
      }),
    [showDialog],
  )

  return { showDialogOk }
}

function SimpleConfirmDialog({
  children,
  primaryAction,
  ...props
}: {
  children: string
  primaryAction: {
    label: string
    onPress?(): void | boolean | Promise<void> | Promise<boolean>
  }

  title?: string
}) {
  const { color } = useTheme()
  const { closeTopDialog } = useDialogContext()

  const title = props.title ? <Label h4 text={props.title} /> : null
  const content = <Label text={children} />
  const primaryButton = (
    <Button
      text={primaryAction.label}
      onPress={async () => {
        const result = await primaryAction.onPress?.()
        if (result != false) closeTopDialog()
      }}
    />
  )
  const confirmButton = (
    <Row gap="XS">
      <Spacer />
      {primaryButton}
    </Row>
  )

  return (
    <Box
      padTop="SM"
      padLeft="S"
      padRight="S"
      padBottom="S"
      elevation={20}
      borderRadius="L"
      borderColor={color.primary}
      backgroundColor={color.background}
      style={{ width: "100%", maxWidth: 800 }}
    >
      {title}
      <Gap vertical="S" />
      {content}
      {confirmButton}
    </Box>
  )
}

function SimpleConfirmDialogOverlay() {
  const { activeDialogs } = useDialogContext()
  const { selectionInProgress } = useSelection()

  const activeDialogsLength = activeDialogs.length

  if (activeDialogsLength <= 0) return null

  const dialogOverlays = activeDialogs.map((node, index) => {
    const isNotLastElement = index != activeDialogsLength - 1

    return (
      <Overlay
        zIndex={1000}
        centerContent
        noBackdrop={isNotLastElement}
        key={`activeSimpleConfirmDialog_${index}`}
      >
        <Box
          pad="XS"
          style={{
            alignItems: "center",
            justifyContent: "center",
            display:
              isNotLastElement || selectionInProgress ? "none" : undefined,
          }}
        >
          {node}
        </Box>
      </Overlay>
    )
  })

  return <>{dialogOverlays}</>
}
