import { atom } from "recoil"

import { DateTimeModes } from "../../types"

export type DateTimePickerConfig = {
  type: DateTimeModes

  value?: Date

  disableFuture?: boolean

  translations?: { confirm: string; cancel: string; title: string }

  onChange: (date?: Date) => void
}

export const dateTimePickerConfigState = atom({
  key: "dateTimePickerConfig",
  default: null as DateTimePickerConfig | null,
})

export const dateTimePickerVisibility = atom({
  key: "dateTimePickerVisibility",
  default: false,
})
