import { InteractionManager } from "react-native"
import { consoleTransport, logger } from "react-native-logs"

import { isEmptyOrBlank } from "@axtesys/react-tools"

import { createId } from "~shared/lib/Id"

import { logMessageSubject } from "./state"

export const log = logger.createLogger({
  levels: {
    trace: 0,
    debug: 1,
    info: 1,
    warn: 2,
    error: 3,
  },
  severity: "error",
  dateFormat: "local",

  // Added for performance reasons,
  // see https://github.com/onubo/react-native-logs
  async: true,
  asyncFunc: InteractionManager.runAfterInteractions,

  stringifyFunc,
  transport: props => {
    if (__DEV__) consoleTransport({ ...props, options: { colors: "ansi" } })

    logMessageSubject.next([
      {
        level: props.level.text,
        message: props.msg.toString(),
      },
    ])
  },
})

// Helper functions

// Add a short identifier to the message,
// so when log message duplication happens
// (e.g. when app is getting closed just at the moment,
// when the success response from
// the log message transmission is received OR
// a transmission went through but because of
// bad connection the response is never received)
function addIdToString(message: string) {
  return !isEmptyOrBlank(message)
    ? `${createId().substring(0, 7)} | ${message}`
    : ""
}

// Adapted from original function
// https://github.com/onubo/react-native-logs/blob/d4501fde6c30df009e4e1551b2715dd14dec52bc/src/index.ts
function stringifyFunc(message: any) {
  let formattedOutput = ""

  if (typeof message === "string") {
    formattedOutput = addIdToString(message)
  } else if (typeof message === "function") {
    formattedOutput = "[function]"
  } else if (message && message.stack && message.message) {
    formattedOutput = addIdToString(message.message.toString())
  } else {
    try {
      formattedOutput = `\n${JSON.stringify(message, undefined, 2)}\n`
    } catch (error) {
      formattedOutput += "Undefined Message"
    }
  }

  return formattedOutput
}
