import {
  differenceInSeconds,
  format,
  formatISO,
  isValid,
  parse,
} from "date-fns"

// Constant definitions

const DATE_FORMAT = "dd.MM.yyyy"
const TIME_FORMAT = "HH:mm"
const DATETIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`
const DATETIME_FORMAT_SECONDS = `${DATE_FORMAT} ${TIME_FORMAT}:ss`

const dateTimeRegex =
  /^(?:(?:(?:(?:(?:(?:0[1-9]|[1-2][0-9]|3[01])\.(?:0[13578]|1[02]))|(?:(?:0[1-9]|[1-2][0-9]|30)\.(?:0[469]|11))|(?:(?:0[1-9]|1[0-9]|2[0-8])\.02))\.\d{4})|(?:29\.02\.(?:(?:\d{2}(?:0[48]|[2468][048]|[13579][26]))|(?:(?:[02468][048])|[13579][26])00))) (0[0-9]|1[0-9]|2[0-3]):([0-9]|[0-5][0-9]))$/
const dateRegex =
  /^(?:(?:(?:(?:0[1-9]|[1-2][0-9]|3[01])\.(?:0[13578]|1[02]))|(?:(?:0[1-9]|[1-2][0-9]|30)\.(?:0[469]|11))|(?:(?:0[1-9]|1[0-9]|2[0-8])\.02))\.\d{4})|(?:29\.02\.(?:(?:\d{2}(?:0[48]|[2468][048]|[13579][26]))|(?:(?:[02468][048])|[13579][26])00))$/ // Checks if the given dateString is a valid date with the help of a regular expression
const timeRegex = /^(([0-1][0-9])|(2[0-3])):[0-5][0-9]$/

// Type definitions

// ISO 8601 encoded date
export type ISODate = string

export type DateLike = Date | ISODate | number

// Logic definitions

// Returns the number of seconds left until the given date is passed
export function secondsToDate(date: DateLike): number {
  return differenceInSeconds(asDate(date), Date.now())
}

// Format as human-readable date/time
export function formatDate(date: DateLike, midnightToPrevious?: boolean) {
  const parsedDate = asDate(date)
  const midnightBehavior = midnightToPrevious ?? false

  const isMidnight =
    midnightBehavior &&
    parsedDate.getHours() == 0 &&
    parsedDate.getMinutes() == 0

  if (isMidnight) parsedDate.setDate(parsedDate.getDate() - 1)

  return format(parsedDate, DATE_FORMAT)
}
export function formatDateTime(
  date: DateLike,
  opts?: { midnightToPrevious?: boolean; showSeconds?: boolean },
) {
  const parsedDate = asDate(date)
  const includeSeconds = opts?.showSeconds ?? false
  const midnightBehavior = opts?.midnightToPrevious ?? false

  const formatString = includeSeconds
    ? DATETIME_FORMAT_SECONDS
    : DATETIME_FORMAT

  const isMidnight =
    midnightBehavior &&
    parsedDate.getHours() == 0 &&
    parsedDate.getMinutes() == 0

  if (isMidnight) parsedDate.setDate(parsedDate.getDate() - 1)

  let formattedDate = format(parsedDate, formatString)

  if (isMidnight) {
    const splitDateTime = formattedDate.split(" ")
    const splitTime = splitDateTime[1].split(":")

    splitTime[0] = "24"

    formattedDate = `${splitDateTime[0]} ${splitTime.join(":")}`
  }

  return formattedDate
}
export function formatTime(date: DateLike, midnightToPrevious?: boolean) {
  const parsedDate = asDate(date)
  const midnightBehavior = midnightToPrevious ?? false
  const formattedTime = format(parsedDate, TIME_FORMAT)

  const isMidnight =
    midnightBehavior &&
    parsedDate.getHours() == 0 &&
    parsedDate.getMinutes() == 0

  return isMidnight ? formattedTime.replace("00:", "24:") : formattedTime
}

// parses a string in the format returned by `formatDate` back into Date
export function parseDate(dateString: string): Date {
  return parse(dateString, DATE_FORMAT, new Date())
}

export function parseTime(timeString: string): Date {
  return parse(timeString, TIME_FORMAT, new Date())
}

// Checks not only for date format, but also for validity of the date string (including leap year validation)
export function isValidDate(dateString: string): boolean {
  return dateString.match(dateRegex) != null
}

export function isValidTime(dateString: string): boolean {
  return dateString.match(timeRegex) != null
}

export function isValidDateTime(dateString: string): boolean {
  return dateString.match(dateTimeRegex) != null
}

// format as ISO date string (excluding time)
export function formatIsoDate(date: DateLike): string
export function formatIsoDate(date?: DateLike): string | undefined
export function formatIsoDate(date?: DateLike): string | undefined {
  if (!date) return undefined
  return formatISO(asDate(date), { representation: "date" })
}

// format as ISO date string (including time)
export function formatIsoDateTime(date: DateLike): string
export function formatIsoDateTime(date?: DateLike): string | undefined
export function formatIsoDateTime(date?: DateLike): string | undefined {
  if (!date) return undefined
  return asDate(date).toISOString()
}

function asDate(date: DateLike): Date {
  if (date instanceof Date) return date
  if (typeof date == "string") {
    const parsedDate = new Date(date)
    if (isValid(parsedDate)) return parsedDate
    // This usually happens when we get timestamps from hobex
    else return parse(date, "yyyy-MM-dd'T'HH:mm:ssXX", new Date())
  }
  if (typeof date == "number") return new Date(date)
  else throw Error(`Invalid dateLike ${date}`)
}

export function validateDate(mode: "date" | "datetime", date: Date) {
  return mode == "date"
    ? isValidDate(formatDate(date))
    : isValidDateTime(formatDateTime(date))
}
