import React, { ReactNode } from "react"
import {
  LayoutChangeEvent,
  StyleProp,
  View,
  ViewProps,
  ViewStyle,
} from "react-native"

import { PressableOpacity } from "../button/PressableOpacity"
import { useScreenSize } from "../hooks/useScreenSize"
import { Theme, useTheme } from "../theme"
import { BorderRadius, ResponsiveSpacing, ScreenSize } from "../types"

// A Box is the most basic UI element possible.
export type BoxProps = BoxStyleProps & {
  // A box can have children
  children?: ReactNode

  onPress?(): void
  onLayout?(event: LayoutChangeEvent): void
}

// Style props, which could also be applied to other components (via `useBoxStyle`)
export type BoxStyleProps = Pick<ViewProps, "pointerEvents"> & {
  // Regulates onPress handler of PressableOpacity
  // and in case of true sets the opacity to a disabled value
  disabled?: boolean

  // A box can grow to fill the available space
  expand?: boolean

  // Or it can specifiy a numeric flex value
  flex?: number

  // Children of the box can be centered
  alignCenter?: boolean

  // A box can have a background color
  backgroundColor?: string

  // A box can have a border color and radius
  borderColor?: string
  borderRadius?: BorderRadius

  // A box can be elevated, casting a shadow
  elevation?: number

  // A Box can have inner space (padding).
  // Specific Padding overwrites general padding
  // (all -> horizontal/vertical -> left/right/top/bottom)
  pad?: ResponsiveSpacing
  padHorizontal?: ResponsiveSpacing
  padVertical?: ResponsiveSpacing
  padLeft?: ResponsiveSpacing
  padRight?: ResponsiveSpacing
  padTop?: ResponsiveSpacing
  padBottom?: ResponsiveSpacing

  // Allow custom style overrides
  style?: StyleProp<ViewStyle>
}

export function Box(props: BoxProps) {
  const style = useBoxStyle(props)

  return props.onPress ? (
    <PressableOpacity
      disabled={props.disabled}
      style={[style, props.style]}
      onPress={props.onPress}
      onLayout={props.onLayout}
    >
      {props.children}
    </PressableOpacity>
  ) : (
    <View
      style={[style, props.style]}
      pointerEvents={props.pointerEvents}
      onLayout={props.onLayout}
    >
      {props.children}
    </View>
  )
}

const spacingPixels = (
  theme: Theme,
  screenSize: ScreenSize,
  spacing: ResponsiveSpacing,
): number => {
  if (typeof spacing == "string") {
    return theme.spacing[spacing]
  } else if (spacing.length == 2) {
    if (screenSize == "S" || screenSize == "M") {
      return theme.spacing[spacing[0]]
    } else {
      return theme.spacing[spacing[1]]
    }
  } else if (spacing.length == 3) {
    if (screenSize == "S") {
      return theme.spacing[spacing[0]]
    } else if (screenSize == "M") {
      return theme.spacing[spacing[1]]
    } else {
      return theme.spacing[spacing[2]]
    }
  } else {
    throw Error("invalid ResponsiveSpacing passed")
  }
}

export function useSpacing(spacing: ResponsiveSpacing): number {
  const theme = useTheme()
  const screenSize = useScreenSize()
  return spacingPixels(theme, screenSize, spacing)
}

export function useBoxStyle(props: BoxStyleProps): StyleProp<ViewStyle> {
  const theme = useTheme()
  const screenSize = useScreenSize()

  const style: ViewStyle = {}

  if (props.expand) {
    style.flex = 1
  }
  if (props.flex != null) {
    style.flex = props.flex
  }

  if (props.backgroundColor) {
    style.backgroundColor = props.backgroundColor
  }

  if (props.borderColor) {
    style.borderWidth = 1
    style.borderColor = props.borderColor
  }
  if (props.borderRadius) {
    style.borderRadius = theme.borderRadius[props.borderRadius]
  }

  if (props.elevation) {
    style.shadowColor = "black"
    style.shadowOffset = { width: 0, height: props.elevation / 2 }
    style.shadowOpacity = 0.2
    style.shadowRadius = props.elevation
    style.elevation = props.elevation
  }

  if (props.pad) {
    style.padding = spacingPixels(theme, screenSize, props.pad)
  }
  if (props.padHorizontal) {
    style.paddingHorizontal = spacingPixels(
      theme,
      screenSize,
      props.padHorizontal,
    )
  }
  if (props.padVertical) {
    style.paddingVertical = spacingPixels(theme, screenSize, props.padVertical)
  }
  if (props.padLeft) {
    style.paddingLeft = spacingPixels(theme, screenSize, props.padLeft)
  }
  if (props.padRight) {
    style.paddingRight = spacingPixels(theme, screenSize, props.padRight)
  }
  if (props.padTop) {
    style.paddingTop = spacingPixels(theme, screenSize, props.padTop)
  }
  if (props.padBottom) {
    style.paddingBottom = spacingPixels(theme, screenSize, props.padBottom)
  }
  if (props.alignCenter) {
    style.alignItems = "center"
  }
  if (props.disabled == true) {
    style.opacity = 0.35
  }

  return style
}
