import React, { ReactNode } from "react"
import { Modal, Platform, Pressable } from "react-native"

export type TransparentModalProps = {
  children: ReactNode
  isVisible: boolean
  onPressOutside: () => void
}

export function TransparentModal({
  children,
  isVisible,
  onPressOutside,
}: TransparentModalProps) {
  return (
    <Modal
      transparent
      visible={isVisible}
      animationType="none"
      supportedOrientations={
        Platform.OS == "ios"
          ? [
              "portrait",
              "landscape",
              "landscape-left",
              "landscape-right",
              "portrait-upside-down",
            ]
          : undefined
      }
    >
      <Pressable
        style={{ width: "100%", height: "100%" }}
        onPress={onPressOutside}
      >
        {children}
      </Pressable>
    </Modal>
  )
}
