import "react-native-gesture-handler"
import "react-native-get-random-values"
import { registerRootComponent } from "expo"
import { createRoot } from "react-dom/client"
import { Platform } from "react-native"

import App from "./App"

if (Platform.OS === "web") {
  // Web requires the buffer package in order to work properly.
  // This line seems to be necessary,
  // because otherwise it would lead to the following error:
  // Can't find variable: Buffer.
  if (typeof Buffer === "undefined") global.Buffer = require("buffer").Buffer

  const container = document.getElementById("root")
  const root = createRoot(container)
  root.render(<App />)
} else {
  registerRootComponent(App)
}
