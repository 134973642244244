import React, { ReactNode, useCallback } from "react"
import { useSetRecoilState } from "recoil"

import { useIntervalEffect } from "@axtesys/hooks"

import { useIsAppFrontend } from "../../Config/queries"
import {
  useLogoutAndFail,
  useLogoutSilently,
  useVerifyRefreshToken,
} from "../hooks"
import {
  useAuthenticatedUser,
  useIsAdminOrManagerLoggedIn,
  useIsOperatorLoggedIn,
} from "../queries"
import { userLoggedInState } from "../state"

const VERIFY_LOGIN_STATE_INTERVAL = 30 * 1000

export function AuthenticationProvider({ children }: { children: ReactNode }) {
  const isAppFrontend = useIsAppFrontend()
  const logoutAndFail = useLogoutAndFail()
  const logoutSilently = useLogoutSilently()
  const authenticatedUser = useAuthenticatedUser()
  const verifyRefreshToken = useVerifyRefreshToken()
  const isOperatorLoggedIn = useIsOperatorLoggedIn()
  const setIsLoggedIn = useSetRecoilState(userLoggedInState)
  const isAdminOrManagerLoggedIn = useIsAdminOrManagerLoggedIn()

  const verifyLoginState = useCallback(() => {
    const isNextLoggedIn = verifyRefreshToken()

    if (!isNextLoggedIn) {
      if (
        (isAppFrontend && isOperatorLoggedIn) ||
        (!isAppFrontend && isAdminOrManagerLoggedIn)
      )
        logoutAndFail()
      else logoutSilently()
    }

    setIsLoggedIn(isNextLoggedIn)
  }, [
    isAdminOrManagerLoggedIn,
    isAppFrontend,
    isOperatorLoggedIn,
    logoutSilently,
    logoutAndFail,
    setIsLoggedIn,
    verifyRefreshToken,
  ])

  useIntervalEffect(
    VERIFY_LOGIN_STATE_INTERVAL,
    verifyLoginState,
    [verifyLoginState],
    [authenticatedUser],
  )

  return <>{children}</>
}
