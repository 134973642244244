import { RefObject } from "react"
import { View } from "react-native"

import { ScreenCoordinates } from "./types"

export function calculatePosition(args: {
  viewRef: RefObject<View>
  setPosition: (position: ScreenCoordinates) => void

  yAddition?: number
  xReduction?: number
  viewHeightAddition?: boolean
}) {
  args.viewRef.current?.measure(
    (
      _fx: number,
      _fy: number,
      _w: number,
      viewHeight: number,
      xPosition: number,
      yPosition: number,
    ) =>
      args.setPosition({
        x: xPosition - (args.xReduction ?? 0),
        y:
          yPosition +
          (args.viewHeightAddition || args.yAddition
            ? viewHeight + (args.yAddition ?? 0)
            : 0),
      }),
  )
}
