import { ReceiptPrinter } from "~shared/types"

export const STATIC_PRINTERS = ["email", "nwprinter"]
export const EMAIL_PRINTER = {
  printerId: "email",
  displayName: "E-Mail",
  deviceName: "E-Mail",
  printingWidth: 0,
  hasCutter: false,
} as ReceiptPrinter
