import { MutableRefObject, Ref } from "react"

// Utility function to convert multiple Ref<T> (which might be functions of MutableRefObject)
// into a single Ref<T> that can be passed into a Component.
export function mergeRefs<T = any>(
  ...refs: Array<Ref<T> | undefined | null>
): Ref<T> {
  return (instance: T | null) => {
    for (const ref of refs) {
      if (ref != null) {
        if (typeof ref == "function") {
          ref(instance)
        } else {
          const refObject = ref as MutableRefObject<T | null>
          refObject.current = instance
        }
      }
    }
  }
}

export function setRefValue<T>(
  ref: Ref<T> | undefined | null,
  value: T | null | undefined,
) {
  if (ref == null) return
  if (typeof ref == "function") {
    ref(value ?? null)
  }
  const refObject = ref as MutableRefObject<T | null>
  refObject.current = value ?? null
}
