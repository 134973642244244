import * as z from "zod"

import { jsonDbAtom } from "@axtesys/react-tools"

import { selector } from "~shared/lib/recoil/lib"

import { cashRegisterState } from "../CashRegister/state"

export const cashFlowReceiptCountState = jsonDbAtom({
  key: "cashFlowReceiptCount",
  default: 1,
  schema: z.number(),
})

// The effective `receiptNumber` we print on the receipt.
// Something like "K02-EA-000124".
export const cashFlowReceiptNumberSelector = selector({
  key: "cashFlowReceiptNumber",
  get: ({ get }) => {
    const { cashRegisterNumber } = get(cashRegisterState)
    const receiptNumber = get(cashFlowReceiptCountState)

    const registerPart = cashRegisterNumber.toString().padStart(2, "0")
    const receiptPart = receiptNumber.toString().padStart(6, "0")

    return `K${registerPart}-EA-${receiptPart}`
  },
})
