import React, { Children, Fragment, ReactNode } from "react"
import { ScrollView, StyleProp, ViewStyle } from "react-native"

import { useTheme } from "../theme"
import { ResponsiveSpacing } from "../types"
import { Box, BoxProps, useBoxStyle } from "./Box"
import { Gap } from "./Gap"

export type FlexBoxProps = Omit<BoxProps, "children"> & {
  flexDirection: "row" | "column" | "row-reverse" | "column-reverse"
  gap?: ResponsiveSpacing

  alignCenter?: boolean

  spaceBetween?: boolean
  spaceEvenly?: boolean

  scrollable?: boolean
  showVerticalScrollIndicator?: boolean

  children?: ReactNode
}

export function FlexBox(props: FlexBoxProps) {
  const {
    spacing: { S },
  } = useTheme()

  const {
    gap,
    style,
    children,
    scrollable,
    spaceEvenly,
    alignCenter,
    spaceBetween,
    flexDirection,
    showVerticalScrollIndicator,
    ...boxProps
  } = props

  const content = insertGaps(
    children,
    gap && (
      <Gap
        horizontal={flexDirection == "row" ? gap : undefined}
        vertical={flexDirection == "column" ? gap : undefined}
      />
    ),
  )

  const flexBoxStyle: StyleProp<ViewStyle> = [
    useBoxStyle(boxProps),
    {
      flexDirection,
      alignItems: alignCenter ? "center" : undefined,
      justifyContent: spaceBetween
        ? "space-between"
        : spaceEvenly
        ? "space-evenly"
        : undefined,
    },
    style,
  ]

  return scrollable ? (
    <ScrollView
      style={flexBoxStyle}
      keyboardShouldPersistTaps="handled"
      contentContainerStyle={{ paddingBottom: S }}
      showsVerticalScrollIndicator={showVerticalScrollIndicator}
    >
      {content}
    </ScrollView>
  ) : (
    <Box {...boxProps} style={flexBoxStyle}>
      {content}
    </Box>
  )
}

export type RowProps = Omit<FlexBoxProps, "flexDirection" | "scrollable">
export function Row(props: RowProps) {
  return <FlexBox {...props} flexDirection="row" />
}

export type ColumnProps = Omit<FlexBoxProps, "flexDirection">
export function Column(props: ColumnProps) {
  return <FlexBox {...props} flexDirection="column" />
}

function insertGaps(children: ReactNode, gapElement?: ReactNode): ReactNode {
  if (!gapElement) return children

  const childrenArray = Children.toArray(children)
  const elements: ReactNode[] = []

  for (let i = 0; i < childrenArray.length; i++) {
    if (i != 0) elements.push(<Fragment key={`${i}_`}>{gapElement}</Fragment>)
    elements.push(<Fragment key={`${i}`}>{childrenArray[i]}</Fragment>)
  }
  return elements
}
