import * as z from "zod"

import { createEntityFor } from "@axtesys/react-tools"

type Permissions = {
  grantManagement: boolean
  grantReportAccess: boolean

  grantDiscount?: { maxPercent: number } | null
}

export const PermissionsEntity = createEntityFor<Permissions>()
  .withSchema(
    z.object({
      grantManagement: z.boolean(),
      grantReportAccess: z.boolean(),
      grantDiscount: z.object({ maxPercent: z.number() }).nullable().optional(),
    }),
  )
  .serialize(data => data)
  .deserialize(json => json)
