import Big, { BigSource } from "big.js"

import { Discount } from "../types/Discount"
import { bigEquals } from "./Big"
import { Euros, eurosToBig, formatMoney } from "./Money"

export function discountEquals(a: Discount, b: Discount) {
  return (
    a.name == b.name &&
    bigEquals(a.percent, b.percent) &&
    bigEquals(a.absolute, b.absolute)
  )
}

export function graphqlToDiscount(graphql: {
  name?: string
  absolute?: Euros
  percent?: number
}): Discount {
  return {
    name: graphql.name,
    absolute: eurosToBig(graphql.absolute),
    percent: graphql.percent ? Big(graphql.percent) : undefined,
  }
}

export function formatDiscount(
  discount: Discount,
  opts?: { omitMinusSign?: boolean },
) {
  const minusSign = opts?.omitMinusSign || discount?.percent?.eq(0) ? "" : "-"

  const absolute = opts?.omitMinusSign
    ? discount.absolute
    : discount.absolute?.mul(-1)

  let formattedDiscount = absolute
    ? `${formatMoney(absolute)}`
    : `${minusSign}${discount.percent?.toNumber()} %`

  if (formattedDiscount.includes("--"))
    formattedDiscount = formattedDiscount.replace("-", "")

  return formattedDiscount
}

export function isValidPercentDiscount(percent?: BigSource) {
  if (!percent) return false
  const bigPercent = Big(percent)
  return bigPercent.gte(0) && bigPercent.lte(100)
}
