import { atom } from "recoil"

import { Deutsch } from "../strings/Deutsch"

type Strings = typeof Deutsch

// Bridges TranslationContextProvider with Recoil,
// allowing Recoil nodes to read the current dictionary
export const stringsState = atom({ key: "stringsState", default: Deutsch })

export function useTranslation() {
  return Deutsch
}

export function useScreenStrings<S extends keyof Strings["screens"]>(
  screenName: S,
): Strings["screens"][S] & { general: Strings["general"] } {
  const strings = useTranslation()
  return {
    ...strings.screens[screenName],
    general: strings.general,
  }
}
