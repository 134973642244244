import React from "react"
import { TextInput } from "react-native"

import { isEmptyOrBlank } from "@axtesys/react-tools"

import { Icon } from "../display/Icon"
import { Row } from "../layout/FlexBox"
import { useTheme } from "../theme"

export type MobileSearchBarProps = {
  value: string

  autoFocus?: boolean
  placeholder?: string

  onFocus?(): void
  onClose?(): void
  onChange?(value: string): void
  onSubmit?(value: string): void
}

const HEIGHT = 48

export function MobileSearchBar({
  value,
  autoFocus,
  placeholder,
  onFocus,
  onClose,
  onChange,
  onSubmit,
}: MobileSearchBarProps) {
  const {
    color: { base4, background },
  } = useTheme()

  const leftIcon = onClose ? (
    <Icon name="arrow-left" pad="XXXS" onPress={onClose} />
  ) : (
    <Icon name="magnify" pad="XXXS" />
  )

  const rightIcon = !isEmptyOrBlank(value) && (
    <Icon
      name="close"
      pad="XXXS"
      onPress={() => {
        onChange?.("")
        onSubmit?.("")
      }}
    />
  )

  return (
    <Row
      expand
      alignCenter
      backgroundColor={background}
      style={{ minHeight: HEIGHT, maxHeight: HEIGHT }}
    >
      {leftIcon}

      <TextInput
        value={value}
        autoFocus={autoFocus}
        returnKeyType="search"
        placeholder={placeholder}
        placeholderTextColor={base4}
        underlineColorAndroid={background}
        style={{ flex: 1, backgroundColor: background, paddingLeft: 3 }}
        onFocus={onFocus}
        onChangeText={onChange}
        onSubmitEditing={() => onSubmit?.(value)}
      />

      {rightIcon}
    </Row>
  )
}
