import { clamp, cloneDeep, difference, isArray, isEqual } from "lodash"
import React, { ReactNode, useRef, useState } from "react"
import { Keyboard, Platform, Pressable, StyleSheet } from "react-native"
import { useSafeAreaInsets } from "react-native-safe-area-context"

import { useScreenOrientation } from "@axtesys/hooks"
import {
  Box,
  ButtonBar,
  Card,
  Column,
  Gap,
  Icon,
  Label,
  MCIcon,
  MobileSearchBar,
  Overlay,
  Row,
  RowSeparator,
  ToolTipIcon,
} from "@axtesys/kassen-app-ui"
import { createCustomContext, isEmptyOrBlank } from "@axtesys/react-tools"

import { PerformanceList } from "~shared/components/PerformanceList"
import { contains, indexOf } from "~shared/lib/Collections"
import { filterSearch } from "~shared/lib/Search"

export { SelectionProvider, useSelection }

type SingleSelectionConfig<T> = {
  selection?: T
  confirmMode?: boolean
}

type MultiSelectionConfig<T> = {
  selection?: T[]
}

type SharedSelectionConfig<T> = {
  items: T[]
  displayMapping(item: T): ReactNode | string

  title?: string
  tooltip?: string
  itemKey?: keyof T
  preventCancel?: boolean
  filterSelector?: (item: T) => Array<string | undefined>
}

export type SingleSelectionModeConfig<T> = SharedSelectionConfig<T> &
  SingleSelectionConfig<T>

type MultiSelectionModeConfig<T> = SharedSelectionConfig<T> &
  MultiSelectionConfig<T>

function SelectionProvider(props: { children: ReactNode }) {
  return (
    <SelectionContextProvider>
      {props.children}
      <SelectionOverlay />
    </SelectionContextProvider>
  )
}

const [SelectionContextProvider, useSelectionInternal] = createCustomContext(
  () => {
    const initialValueRef = useRef<any | any[] | undefined>()
    const [searchString, setSearchString] = useState<string>("")
    const [currentSelectionConfig, setCurrentSelectionConfig] = useState<
      | (SingleSelectionModeConfig<any> & { resolve?(item: any): void })
      | (MultiSelectionModeConfig<any> & { resolve?(items: any[]): void })
      | undefined
    >(undefined)
    const selectionInProgress = currentSelectionConfig != undefined

    // Always hide an open keyboard when
    // displaying the selection component
    function hideKeyboard() {
      Keyboard.dismiss()
    }

    function resetSelection() {
      setSearchString("")
      setCurrentSelectionConfig(undefined)
    }

    async function selectSingle<T>(
      config: SingleSelectionModeConfig<T>,
    ): Promise<T> {
      hideKeyboard()
      initialValueRef.current = cloneDeep(config.selection)

      return new Promise<T>(resolve => {
        setCurrentSelectionConfig({
          confirmMode: config.confirmMode,
          ...config,
          resolve,
        })
      })
    }

    async function selectMulti<T>(
      config: MultiSelectionModeConfig<T>,
    ): Promise<T[]> {
      hideKeyboard()
      initialValueRef.current = cloneDeep(config.selection)

      return new Promise<T[]>(resolve => {
        setCurrentSelectionConfig({ ...config, resolve })
      })
    }

    function onSingleSelection<T>(item: T) {
      setCurrentSelectionConfig(
        prevState =>
          ({
            ...prevState,
            selection: item,
          }) as SingleSelectionModeConfig<T>,
      )
      if (
        currentSelectionConfig &&
        "confirmMode" in currentSelectionConfig &&
        !currentSelectionConfig.confirmMode
      )
        onResolveItemSelection(item)
    }

    function onMultiSelection<T>(item: T) {
      setCurrentSelectionConfig(prevState => {
        const selection = (prevState?.selection ?? []) as T[]

        if (!contains(selection, item)) {
          selection.push(item)
        } else {
          const index = indexOf(selection, item)
          if (index > -1) selection.splice(index, 1)
        }

        return {
          ...prevState,
          selection,
        } as MultiSelectionModeConfig<T>
      })
    }

    function onResolveItemSelection<T>(selection: T | T[]) {
      if (!currentSelectionConfig || !currentSelectionConfig.resolve) return
      if ("confirmMode" in currentSelectionConfig) {
        currentSelectionConfig.resolve(selection)
      } else if (isArray(selection)) {
        currentSelectionConfig.resolve(selection)
      }
      resetSelection()
    }

    const onCancelSelection = () => {
      if (currentSelectionConfig?.preventCancel == true) return

      if (
        currentSelectionConfig &&
        currentSelectionConfig.resolve &&
        initialValueRef.current
      ) {
        currentSelectionConfig.resolve(initialValueRef.current)
      }
      resetSelection()
    }

    return {
      searchString,
      selectionInProgress,
      currentSelectionConfig,

      selectMulti,
      selectSingle,
      setSearchString,
      onMultiSelection,
      onSingleSelection,
      onCancelSelection,
      onResolveItemSelection,
    } as const
  },
)

function useSelection() {
  const { selectSingle, selectMulti, selectionInProgress } =
    useSelectionInternal()

  return { selectionInProgress, selectSingle, selectMulti }
}

function SelectionOverlay() {
  const {
    searchString,
    currentSelectionConfig,
    setSearchString,
    onMultiSelection,
  } = useSelectionInternal()
  const { top } = useSafeAreaInsets()
  const orientation = useScreenOrientation()

  if (!currentSelectionConfig) return null

  const { title, items, itemKey, tooltip, selection, filterSelector } =
    currentSelectionConfig

  const isWeb = Platform.OS == "web"
  const cardStyle = [
    selectionStyle.cardOuterSpacing,
    isWeb && selectionStyle.webCard,
    { marginTop: clamp(top, 24, Number.MAX_VALUE) },
    !isWeb && orientation == "landscape" && selectionStyle.tabletCard,
  ]

  const showFilter = filterSelector != undefined
  const itemsToShow = showFilter
    ? filterSearch(items, searchString, filterSelector)
    : items
  const itemsPresent = itemsToShow.length > 0

  const heading = title && <Label h4 bold text={title} />
  const headingComponent = isEmptyOrBlank(tooltip) ? (
    heading
  ) : (
    <Row alignCenter gap="XXS">
      {heading}
      <ToolTipIcon text={tooltip!} />
    </Row>
  )

  // In case the selection context is in multi selection mode,
  // we do want to display the aggregate selection button (select/deselect all)
  const titleComponent = !("confirmMode" in currentSelectionConfig) ? (
    <Row expand alignCenter spaceBetween>
      {headingComponent}
      {itemsPresent ? (
        <AggregateSelectionIcon
          selection={selection}
          itemsToShow={itemsToShow}
          onMultiSelection={onMultiSelection}
        />
      ) : (
        <Gap size="S" />
      )}
    </Row>
  ) : (
    headingComponent
  )

  const emptyListText = (
    <Column gap="XS">
      <RowSeparator />
      <Box padLeft="XS">
        <Label
          bold
          color="medium"
          text={
            !showFilter || items.length == 0
              ? "Für diese Auswahl sind keine Einträge vorhanden."
              : "Für diesen Suchbegriff wurden keine Einträge gefunden."
          }
        />
      </Box>
      <RowSeparator />
    </Column>
  )

  return (
    <Overlay zIndex={200} centerContent={Platform.OS == "web"}>
      <Card expand style={cardStyle}>
        <Column padTop="XS" gap="XS" padBottom={showFilter ? "none" : "S"}>
          {titleComponent}

          {showFilter && (
            <Box style={{ height: 48 }}>
              <MobileSearchBar
                value={searchString}
                placeholder="Bitte geben Sie einen Suchbegriff ein"
                onChange={setSearchString}
              />
            </Box>
          )}
        </Column>

        <Column expand gap="XS">
          <PerformanceList
            itemKey={itemKey}
            itemDimension={48}
            items={itemsToShow}
            showFooterSeparator
            showHeaderSeparator
            renderItem={SelectionItem}
            renderListEmptyComponent={emptyListText}
          />

          <SelectionButtonBar />
        </Column>
      </Card>
    </Overlay>
  )
}

function AggregateSelectionIcon<T>(props: {
  selection: T[]
  itemsToShow: T[]
  onMultiSelection: (item: T) => void
}) {
  const { selection, itemsToShow, onMultiSelection } = props
  const isFullSelection = difference(itemsToShow, selection).length == 0

  return (
    <Icon
      color="primary"
      name={isFullSelection ? "collapse-all" : "expand-all"}
      onPress={() => {
        const itemsToSelectionChange = !isFullSelection
          ? itemsToShow.filter(item => !selection.includes(item))
          : itemsToShow

        for (const item of itemsToSelectionChange) onMultiSelection(item)
      }}
    />
  )
}

function SelectionItem<T>(item: T) {
  const { currentSelectionConfig } = useSelectionInternal()

  return "confirmMode" in currentSelectionConfig! ? (
    <SingleSelectionItem item={item} />
  ) : (
    <MultiSelectionItem item={item} />
  )
}

function SharedSelectionItem<T>(props: {
  item: T
  icon: MCIcon
  onSelection: (item: T) => void
}) {
  const { item, icon, onSelection } = props
  const { currentSelectionConfig } = useSelectionInternal()

  const displayValue = currentSelectionConfig!.displayMapping(item)

  return (
    <Pressable onPress={() => onSelection(item)}>
      <Row alignCenter padHorizontal="XXXS" gap="XXS" style={{ height: 48 }}>
        <Icon name={icon} />
        {typeof displayValue == "string" ? (
          <Label text={displayValue} />
        ) : (
          displayValue
        )}
      </Row>
    </Pressable>
  )
}

function SingleSelectionItem<T>(props: { item: T }) {
  const { currentSelectionConfig, onSingleSelection } = useSelectionInternal()

  const selectionConfig =
    currentSelectionConfig! as SingleSelectionModeConfig<T>
  const isSelected =
    selectionConfig.selection && isEqual(selectionConfig.selection, props.item)
  const icon = isSelected
    ? "checkbox-marked-circle-outline"
    : "checkbox-blank-circle-outline"

  return (
    <SharedSelectionItem
      icon={icon}
      item={props.item}
      onSelection={onSingleSelection}
    />
  )
}

function MultiSelectionItem<T>(props: { item: T }) {
  const { currentSelectionConfig, onMultiSelection } = useSelectionInternal()

  const selectionConfig = currentSelectionConfig! as MultiSelectionModeConfig<T>
  const isSelected = contains(selectionConfig.selection, props.item)
  const icon = isSelected ? "checkbox-marked-outline" : "checkbox-blank-outline"

  return (
    <SharedSelectionItem
      icon={icon}
      item={props.item}
      onSelection={onMultiSelection}
    />
  )
}

function SelectionButtonBar() {
  const { currentSelectionConfig, onResolveItemSelection, onCancelSelection } =
    useSelectionInternal()

  const selectionConfig = currentSelectionConfig!
  const isSubmitButtonVisible =
    !("confirmMode" in selectionConfig) || selectionConfig.confirmMode

  const actionButtons = isSubmitButtonVisible
    ? [
        {
          label: "Bestätigen",
          onPress: () => onResolveItemSelection(selectionConfig.selection),
          disabled: isSubmitButtonVisible && !currentSelectionConfig?.selection,
        },
      ]
    : undefined

  const cancelButton =
    selectionConfig.preventCancel != true
      ? { label: "Schließen", onPress: onCancelSelection }
      : undefined

  return (
    <ButtonBar
      cancelButton={cancelButton}
      actionButtons={actionButtons}
      contentContainer={{ padLeft: "S", padBottom: "XS" }}
    />
  )
}

const selectionStyle = StyleSheet.create({
  webCard: {
    width: "30%",
    minWidth: 350,
    maxHeight: "80%",
    alignSelf: "center",
  },
  tabletCard: { width: 400, alignSelf: "center" },
  cardOuterSpacing: { marginBottom: 24, marginHorizontal: 16 },
})
