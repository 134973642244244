import * as z from "zod"

import { createEntityFor } from "@axtesys/react-tools"

export type CashRegisterId = string

export type CashRegisterInfo = {
  cashRegisterId: CashRegisterId
  name: string
}

export const CashRegisterInfoEntity = createEntityFor<CashRegisterInfo>()
  .withSchema(
    z.object({
      cashRegisterId: z.string(),
      name: z.string(),
    }),
  )
  .serialize(data => data)
  .deserialize(json => json)
