import { useCallback } from "react"
import { Linking } from "react-native"
import { InAppBrowser } from "react-native-inappbrowser-reborn"

import { useTheme } from "@axtesys/kassen-app-ui"

export function useOpenInAppBrowser() {
  const { color } = useTheme()

  return useCallback(
    async (url: string) => {
      if (await InAppBrowser.isAvailable())
        await InAppBrowser.open(url, {
          // iOS / iPadOS properties
          animated: true,
          readerMode: false,
          modalEnabled: true,
          enableBarCollapsing: true,
          dismissButtonStyle: "done",
          preferredControlTintColor: "white",
          modalPresentationStyle: "fullScreen",
          preferredBarTintColor: color.primary,
          modalTransitionStyle: "coverVertical",

          // Android properties
          showTitle: true,
          enableUrlBarHiding: true,
          enableDefaultShare: true,
          toolbarColor: color.primary,
          forceCloseOnRedirection: false,
          navigationBarDividerColor: "white",
        })
      else await Linking.openURL(url)
    },
    [color.primary],
  )
}
