import React, { ReactNode } from "react"

import { MCIcon } from "../MCIcon"
import { Box } from "../layout/Box"
import { useTheme } from "../theme"
import { Color } from "../types"
import { Label } from "../typography/Label"
import { Icon } from "./Icon"
import { ToolTip } from "./ToolTip"

type ToolTipIconProps = {
  icon?: MCIcon
  color?: Color
  size?: "XS" | "S" | "M" | "L" | "XL"
} & ({ text: string } | { children: ReactNode })

export function ToolTipIcon(props: ToolTipIconProps) {
  const { color } = useTheme()

  const content = (
    <Box
      pad="XXXS"
      borderColor={color.border}
      backgroundColor={color.background}
    >
      {"text" in props && <Label text={props.text} small />}
      {"children" in props && props.children}
    </Box>
  )

  return (
    <ToolTip text={content}>
      <Icon
        name={(props.icon as MCIcon) ?? "information"}
        size={props.size ?? "S"}
        color={props.color ?? "primary"}
      />
    </ToolTip>
  )
}
