import React from "react"

import { Column } from "../layout/FlexBox"
import { useTheme } from "../theme"
import { Label } from "../typography/Label"

// A small, quadratic-ish button with a short text.

export type QuickSelectButtonProps = {
  text: string
  subText?: string | null
  isSelected?: boolean
  onPress(): void
}

export function QuickSelectButton(props: QuickSelectButtonProps) {
  const theme = useTheme()

  const borderColor = theme.color.primary
  const backgroundColor = props.isSelected
    ? theme.color.primary
    : theme.color.background

  const textColor = props.isSelected ? "inverted" : "default"

  return (
    <Column
      borderRadius="L"
      onPress={props.onPress}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      pad="XS"
      alignCenter
    >
      <Label h5 text={props.text} color={textColor} />
      {props.subText && (
        <Label small text={props.subText} color={textColor} singleLine />
      )}
    </Column>
  )
}
