import { useEffect, useRef } from "react"

import { logDebug } from "~shared/feature/Logging/hooks"

// Can be added to a component (or custom hook) to log whenever one of the props
// changed. This is used to hunt down unnessecary re-renders when optimizing for performance.
export function useRerenderInfo(name: string, props: Record<string, any> = {}) {
  const lastProps = useRef(props)
  const lastKeys = Object.keys(lastProps.current)
  const keys = Object.keys(props)
  const renderCount = useRef(0)

  if (keys.length != lastKeys.length) {
    logDebug(
      `[${name}] Keys have changed: [${lastKeys.join(",")}] -> [${keys.join(
        ",",
      )}]`,
    )
  }

  const changedKeys: string[] = []
  for (const key of keys) {
    const lastValue = lastProps.current[key]
    const value = props[key]
    if (lastValue != value) {
      changedKeys.push(key)
    }
  }

  const numRender = renderCount.current++
  if (numRender > 0) {
    if (changedKeys.length == 0) {
      logDebug(`[${name}] Rerendered for unknown reasons (${numRender})`)
    } else {
      logDebug(`[${name}] Changed: ${changedKeys.join(",")} (${numRender})`)
    }
  }

  lastProps.current = props

  useEffect(() => {
    logDebug(`[${name}] mounted`)
    return () => logDebug(`[${name}] unmounted`)
  }, [name])
}
