import React, { ReactElement, ReactNode, Suspense } from "react"
import {
  ErrorBoundary as RErrorBoundary,
  FallbackProps,
} from "react-error-boundary"

import { Row, Spacing } from "@axtesys/kassen-app-ui"

import { LoadingIndicator } from "~shared/components/LoadingIndicator"
import { logError } from "~shared/feature/Logging/hooks"
import {
  isNetworkError,
  isTimeoutError,
  transformError,
} from "~shared/lib/Errors"

import { GenericErrorFallback } from "../fallbacks/GenericErrorFallback"
import {
  NetworkErrorFallback,
  TimeoutErrorFallback,
} from "../fallbacks/NetworkErrorFallback"

type SuspenseBoundaryProps = {
  children: ReactNode
  pad?: Spacing
}

export function SuspenseBoundary(props: SuspenseBoundaryProps) {
  return (
    <ErrorBoundary pad={props.pad}>
      <Suspense fallback={<LoadingIndicator />}>{props.children}</Suspense>
    </ErrorBoundary>
  )
}

function ErrorBoundary(props: SuspenseBoundaryProps) {
  return (
    <RErrorBoundary
      FallbackComponent={fallbackProps =>
        Fallback({ ...fallbackProps, pad: props.pad })
      }
      onError={(error, info) =>
        logError("SuspenseBoundary: Caught an error", {
          error: transformError(error).error,
          componentStack: info.componentStack,
        })
      }
    >
      {props.children}
    </RErrorBoundary>
  )
}

function Fallback(props: FallbackProps & Pick<SuspenseBoundaryProps, "pad">) {
  let fallback: ReactElement

  if (isNetworkError(props.error)) {
    fallback = isTimeoutError(props.error) ? (
      <TimeoutErrorFallback {...props} />
    ) : (
      <NetworkErrorFallback {...props} />
    )
  } else fallback = <GenericErrorFallback {...props} />

  return props.pad ? <Row pad={props.pad}>{fallback}</Row> : fallback
}
