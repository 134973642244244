import { produce } from "immer"
import { isEqual } from "lodash"
import React, { ReactNode, useCallback, useEffect } from "react"
import {
  atom,
  DefaultValue,
  GetRecoilValue,
  RecoilState,
  RecoilValue,
  RecoilValueReadOnly,
  SetRecoilState,
  useRecoilCallback,
  useRecoilState,
  useRecoilTransactionObserver_UNSTABLE,
  useRecoilValue,
  useResetRecoilState,
} from "recoil"

import { useMountEffect, useUnmountEffect } from "@axtesys/hooks"

import { logTrace } from "~shared/feature/Logging/hooks"

import { Snackbar, useSnackbar } from "../contexts/SnackbarContext"
import { useCompanyId } from "../feature/Authentication"
import { CompanyId } from "../types"
import { selector } from "./recoil/lib"

type Mutation<T> = (draft: T) => T | void
type Updater<T> = ((prev?: T) => T | undefined) | undefined

type SnackbarWithoutAction = Omit<Snackbar, "action">
type SharedSnackbarConfig = { snackKey?: string; duration?: number }

export function useMutableRecoilState<T>(recoilState: RecoilState<T>) {
  const state = useRecoilValue(recoilState)
  const update = useUpdateRecoilState(recoilState)
  return [state, update] as const
}

// Updates a RecoilState object by direct mutation of the current state.
export function useUpdateRecoilState<T>(recoilState: RecoilState<T>) {
  return useRecoilCallback(
    ({ set }) =>
      (recipe: Mutation<T>) => {
        set(recoilState, currentState => {
          const nextState = produce(currentState, recipe)
          return nextState as T
        })
      },
    [recoilState],
  )
}

// Implements a Do/Undo action on an atom, using direct mutation of the current state.
export function useUpdateRecoilStateWithUndo<T>(atom: RecoilState<T>) {
  const { showSnackbar } = useSnackbar()

  return useRecoilCallback(
    ({ snapshot, set }) =>
      (args: {
        mutateState: Mutation<T>
        snack: SnackbarWithoutAction
        undoSnack: SnackbarWithoutAction

        sharedSnackbarConfig?: SharedSnackbarConfig
      }) => {
        // Remember the state of the atom before our action
        const lastState = snapshot.getLoadable(atom).getValue()

        // Perform the action
        set(atom, currentState => produce(currentState, args.mutateState))

        // Show a success snack, from where we can undo the action
        showSnackbar({
          ...args.snack,
          key: args.snack.key ?? args.sharedSnackbarConfig?.snackKey,
          action: { icon: "undo", onPress: undo, disableInstantFadeOut: true },
          duration: args.snack.duration ?? args.sharedSnackbarConfig?.duration,
        })

        function undo() {
          // Restore the initial state
          set(atom, lastState)
          showSnackbar({
            ...args.undoSnack,
            key: args.undoSnack.key ?? args.sharedSnackbarConfig?.snackKey,
            duration:
              args.undoSnack.duration ?? args.sharedSnackbarConfig?.duration,
          })
        }
      },
    [atom, showSnackbar],
  )
}

// NOTE: Set will be used too, because a direct mutation with the help of immer producer,
//       will lead to unwanted behavior when dealing with void / undefined returns.
//       Producer does ignore undefined and therefore will act as nothing has happened.
//       The overall usage is more explicit as well.
export function useMultiRecoilStateManipulation<T>(
  // Atoms for updater actions (no direct mutation)
  atomsForSet: Array<RecoilState<T | undefined>>,
  // Atoms for mutation actions (direct mutation)
  atomsForUpdate: Array<RecoilState<T | undefined>>,

  countConstraintOverwrite?: boolean,
) {
  return useRecoilCallback(
    ({ set }) =>
      (args: {
        // Set action via an updater -> will not directly mutate the previous state
        updaters?: Array<Updater<T>>
        // Mutation action -> will directly mutate the previous state
        mutations?: Array<Mutation<T>>
      }) => {
        const atomsForSetLength = atomsForSet?.length ?? 0
        const atomsForUpdateLength = atomsForUpdate?.length ?? 0

        if (
          countConstraintOverwrite != true &&
          atomsForSet.length + atomsForUpdate.length !=
            atomsForSetLength + atomsForUpdateLength
        )
          throw Error("Atom and updater / mutation action counts are not equal")

        let updater: Updater<T>
        let atom: RecoilState<T | undefined>
        for (let i = 0; i < atomsForSetLength; i++) {
          updater = args.updaters?.[i]

          // In case the updater is currently undefined,
          // skip it to preserve the order of the updaters / atoms
          if (updater == undefined) continue

          // Assign the atom for re-usage
          atom = atomsForSet[i]

          // Perform the set action
          set(atom, updater)
        }

        for (let i = 0; i < atomsForUpdateLength; i++) {
          const mutation = args.mutations?.[i]

          // In case the mutation is currently undefined,
          // skip it to preserve the order of the mutations / atoms
          if (mutation == undefined) continue

          // Assign the atom for re-usage
          atom = atomsForUpdate[i]

          // Perform the set action
          set(atom, prevState => produce(prevState, mutation))
        }
      },
    [atomsForSet, atomsForUpdate, countConstraintOverwrite],
  )
}

// Implements a Do/Undo action on multiple atoms,
// using updater and/or mutation actions to manipulate current states.
// Undo action will reset all included atoms to their prior state
// (before action execution).
// NOTE: Set will be used too, because a direct mutation with the help of immer producer,
//       will lead to unwanted behavior when dealing with void / undefined returns.
//       Producer does ignore undefined and therefore will act as nothing has happened.
//       The overall usage is more explicit as well.
export function useMultiRecoilStateManipulationWithUndo<T>(
  // Atoms for updater actions (no direct mutation)
  atomsForSet: Array<RecoilState<T | undefined>>,
  // Atoms for mutation actions (direct mutation)
  atomsForUpdate: Array<RecoilState<T | undefined>>,
  countConstraintOverwrite?: boolean,
) {
  const { showSnackbar } = useSnackbar()

  return useRecoilCallback(
    ({ snapshot, set }) =>
      (args: {
        snack: SnackbarWithoutAction
        undoSnack: SnackbarWithoutAction

        // Set action via an updater -> will not directly mutate the previous state
        updaters?: Array<Updater<T>>

        // Mutation action -> will directly mutate the previous state
        mutations?: Array<Mutation<T>>

        sharedSnackbarConfig?: SharedSnackbarConfig

        // Additional possibility to include logic in the undo logic / execution
        customUndo?: () => void
      }) => {
        const atomsForSetLength = atomsForSet?.length ?? 0
        const atomsForUpdateLength = atomsForUpdate?.length ?? 0

        if (
          countConstraintOverwrite != true &&
          atomsForSet.length + atomsForUpdate.length !=
            atomsForSetLength + atomsForUpdateLength
        )
          throw Error("Atom and updater / mutation action counts are not equal")

        let updater: Updater<T>
        let atom: RecoilState<T | undefined>
        const updaterStates = [] as (T | undefined)[]
        for (let i = 0; i < atomsForSetLength; i++) {
          updater = args.updaters?.[i]

          // In case the updater is currently undefined,
          // skip it to preserve the order of the updaters / atoms
          if (updater == undefined) continue

          // Assign the atom for re-usage
          atom = atomsForSet[i]

          // Remember the state of the atom before th upcoming action
          // and save it to the correct atom / index location
          updaterStates[i] = snapshot.getLoadable(atom).getValue()

          // Perform the set action
          set(atom, updater)
        }

        const mutationStates = [] as (T | undefined)[]
        for (let i = 0; i < atomsForUpdateLength; i++) {
          const mutation = args.mutations?.[i]

          // In case the mutation is currently undefined,
          // skip it to preserve the order of the mutations / atoms
          if (mutation == undefined) continue

          // Assign the atom for re-usage
          atom = atomsForUpdate[i]

          // Remember the state of the atom before th upcoming action
          // and save it to the correct atom / index location
          mutationStates[i] = snapshot.getLoadable(atom).getValue()

          // Perform the set action
          set(atom, prevState => produce(prevState, mutation))
        }

        // Show a success snack, from where we can undo the action
        showSnackbar({
          ...args.snack,
          key: args.snack.key ?? args.sharedSnackbarConfig?.snackKey,
          action: { icon: "undo", onPress: undo, disableInstantFadeOut: true },
          duration: args.snack.duration ?? args.sharedSnackbarConfig?.duration,
        })

        function undo() {
          let atom: RecoilState<T | undefined>
          for (let i = 0; i < atomsForSetLength; i++) {
            // In case the updater is currently undefined,
            // skip restoring the indexed state, as it has not changed
            if (args.updaters![i] == undefined) continue

            // Assign the atom for re-usage
            atom = atomsForSet[i]

            // Restore the initial state
            set(atom, updaterStates[i])
          }

          for (let i = 0; i < atomsForUpdateLength; i++) {
            // In case the mutation is currently undefined,
            // skip restoring the indexed state, as it has not changed
            if (args.mutations![i] == undefined) continue

            // Assign the atom for re-usage
            atom = atomsForUpdate[i]

            // Restore the initial state
            set(atom, mutationStates[i])
          }

          args.customUndo?.()

          showSnackbar({
            ...args.undoSnack,
            key: args.undoSnack.key ?? args.sharedSnackbarConfig?.snackKey,
            duration:
              args.undoSnack.duration ?? args.sharedSnackbarConfig?.duration,
          })
        }
      },
    [atomsForSet, atomsForUpdate, countConstraintOverwrite, showSnackbar],
  )
}

// Implements a Do/Undo action on multiple atoms,
// using updater actions to manipulate current states.
// Undo action will reset all included atoms to their prior state
// (before action execution).
export function useMultiRecoilStateSetWithUndo<T>(
  atoms: Array<RecoilState<T | undefined>>,
  countConstraintOverwrite?: boolean,
) {
  const { showSnackbar } = useSnackbar()

  return useRecoilCallback(
    ({ snapshot, set }) =>
      (args: {
        snack: SnackbarWithoutAction
        undoSnack: SnackbarWithoutAction

        updaters?: Array<Updater<T>>
        sharedSnackbarConfig?: SharedSnackbarConfig
      }) => {
        const atomsLength = atoms?.length ?? 0

        if (
          countConstraintOverwrite != true &&
          args.updaters?.length != atomsLength
        )
          throw Error("Atom and updater action counts are not equal")

        let updater: Updater<T>
        let atom: RecoilState<T | undefined>
        const updaterStates = [] as (T | undefined)[]
        for (let i = 0; i < atomsLength; i++) {
          updater = args.updaters?.[i]

          // In case the updater is currently undefined,
          // skip it to preserve the order of the updaters / atoms
          if (updater == undefined) continue

          // Assign the atom for re-usage
          atom = atoms[i]

          // Remember the state of the atom before th upcoming action
          // and save it to the correct atom / index location
          updaterStates[i] = snapshot.getLoadable(atom).getValue()

          // Perform the set action
          set(atom, updater)
        }

        // Show a success snack, from where we can undo the action
        showSnackbar({
          ...args.snack,
          key: args.snack.key ?? args.sharedSnackbarConfig?.snackKey,
          action: { icon: "undo", onPress: undo, disableInstantFadeOut: true },
          duration: args.snack.duration ?? args.sharedSnackbarConfig?.duration,
        })

        function undo() {
          let atom: RecoilState<T | undefined>
          for (let i = 0; i < atomsLength; i++) {
            // In case the updater is currently undefined,
            // skip restoring the indexed state, as it has not changed
            if (args.updaters![i] == undefined) continue

            // Assign the atom for re-usage
            atom = atoms[i]

            // Restore the initial state
            set(atom, updaterStates[i])
          }

          showSnackbar({
            ...args.undoSnack,
            key: args.undoSnack.key ?? args.sharedSnackbarConfig?.snackKey,
            duration:
              args.undoSnack.duration ?? args.sharedSnackbarConfig?.duration,
          })
        }
      },
    [atoms, countConstraintOverwrite, showSnackbar],
  )
}

// Implements a Do/Undo action on multiple atoms,
// using mutation actions to directly manipulate / mutate current states.
// Undo action will reset all included atoms to their prior state
// (before action execution).
export function useMultiRecoilStateUpdateWithUndo<T>(
  atoms: Array<RecoilState<T | undefined>>,
  countConstraintOverwrite?: boolean,
) {
  const { showSnackbar } = useSnackbar()

  return useRecoilCallback(
    ({ snapshot, set }) =>
      (args: {
        snack: SnackbarWithoutAction
        undoSnack: SnackbarWithoutAction

        mutations?: Array<Mutation<T>>
        sharedSnackbarConfig?: SharedSnackbarConfig
      }) => {
        const atomsLength = atoms?.length ?? 0

        if (
          countConstraintOverwrite != true &&
          args.mutations?.length != atomsLength
        )
          throw Error("Atom and mutation action counts are not equal")

        let atom: RecoilState<T | undefined>
        const mutationStates = [] as (T | undefined)[]
        for (let i = 0; i < atomsLength; i++) {
          const mutation = args.mutations?.[i]

          // In case the mutation is currently undefined,
          // skip it to preserve the order of the mutations / atoms
          if (mutation == undefined) continue

          // Assign the atom for re-usage
          atom = atoms[i]

          // Remember the state of the atom before th upcoming action
          // and save it to the correct atom / index location
          mutationStates[i] = snapshot.getLoadable(atom).getValue()

          // Perform the set action
          set(atom, prevState => produce(prevState, mutation))
        }

        // Show a success snack, from where we can undo the action
        showSnackbar({
          ...args.snack,
          key: args.snack.key ?? args.sharedSnackbarConfig?.snackKey,
          action: { icon: "undo", onPress: undo, disableInstantFadeOut: true },
          duration: args.snack.duration ?? args.sharedSnackbarConfig?.duration,
        })

        function undo() {
          let atom: RecoilState<T | undefined>

          for (let i = 0; i < atomsLength; i++) {
            // In case the mutation is currently undefined,
            // skip restoring the indexed state, as it has not changed
            if (args.mutations![i] == undefined) continue

            // Assign the atom for re-usage
            atom = atoms[i]

            // Restore the initial state
            set(atom, mutationStates[i])
          }

          showSnackbar({
            ...args.undoSnack,
            key: args.undoSnack.key ?? args.sharedSnackbarConfig?.snackKey,
            duration:
              args.undoSnack.duration ?? args.sharedSnackbarConfig?.duration,
          })
        }
      },
    [atoms, countConstraintOverwrite, showSnackbar],
  )
}

export function useRecoilTransitionEffect<T>(
  currentValue: T,
  recoilState: RecoilState<T>,
  effect: (currentValue: T, previousValue: T) => void,
) {
  const [previousValue, setPreviousValue] = useRecoilState(recoilState)

  useEffect(() => {
    if (isEqual(currentValue, previousValue)) return

    // This timeout is required in order to ensure a
    // proper update outcome of the targeted recoil state(s).
    setTimeout(() => {
      effect(currentValue, previousValue)
      setPreviousValue(currentValue)
    })
  }, [currentValue, effect, previousValue, setPreviousValue])
}

// An recoilContext is a collection of atoms and selectors that are bound to the lifetime of an useEffect hook.
// It can be used to create a state tree on mount, and reset the state on unmount, or when the rootValue is updated.
//
// Any number of additional atoms can be created from the contextAtom, and can derive their initial state from the contextAtom.
// Selectors can also be created from the contextAtom, and they will be reset as well during unmount.
//
const UNINITIALIZED = Symbol("UNINITIALIZED")

export function createRecoilContext<T>(prefix: string) {
  // We store the created recoil nodes here, so we can log/reset all of them.
  const states: RecoilState<any>[] = []
  const values: RecoilValue<any>[] = []
  const hookBlocks: (() => void)[] = []

  // This root atom will hold the rootValue, other atoms/selectors can read from it.
  // The default value is a never-resolving promise, so derived atoms will wait until we
  // explicitly set the value in useAtomScope().
  const rootOrUninitializedState = atom<any>({
    key: prefix,
    default: UNINITIALIZED,
  })

  const rootSelector = selector({
    key: `${prefix}.root`,
    get: ({ get }) => {
      const rootValue = get(rootOrUninitializedState)
      if (rootValue == UNINITIALIZED) throw Error("root is uninitialized")
      return rootValue as T
    },
  })

  // Used to track for which company the context has been created for.
  const persistedCompanyIdState = atom<CompanyId | undefined>({
    key: `${prefix}.persistedCompanyId`,
    default: undefined,
  })

  // Used to use the persistStateBetweenMounts flag throughout the Recoil context.
  const persistStateBetweenMountsState = atom<boolean>({
    key: `${prefix}.persistStateBetweenMounts`,
    default: false,
  })

  // Components using the context nodes must be wrapped in the Provider, which sets
  // the value of the root atom.
  function Provider(props: {
    children: ReactNode

    rootValue?: T
    useLogging?: boolean
    persistStateBetweenMounts?: boolean
  }) {
    const { children, rootValue, persistStateBetweenMounts = false } = props

    const currentCompanyId = useCompanyId()

    // Resets all screen states to their initial states before modification,
    // in case it should not persist (e.g. screen changes, company change, etc.).
    const resetAllStates = useRecoilCallback(
      ({ set, reset }) =>
        () => {
          set(rootOrUninitializedState, UNINITIALIZED)

          for (const state of states) reset(state)
        },
      [persistStateBetweenMounts],
    )

    // Initialises the root value of the Provider and
    // possibly executes the company switch side effect,
    // which leads to a reset of all states and
    // update of the now outdated persistedCompanyIdState.
    const onMount = useRecoilCallback(
      ({ set, snapshot }) =>
        () => {
          if (currentCompanyId) {
            const previousCompanyId = snapshot
              .getLoadable(persistedCompanyIdState)
              .getValue()

            if (currentCompanyId != previousCompanyId) {
              if (previousCompanyId) resetAllStates()
              set(persistedCompanyIdState, currentCompanyId)
            }
          }

          set(rootOrUninitializedState, rootValue)
          set(persistStateBetweenMountsState, persistStateBetweenMounts)
        },
      [rootValue],
    )

    // If states should not persist between mounts,
    // they will be reset during unmounting of the screen.
    const onUnmount = useCallback(() => {
      if (persistStateBetweenMounts) return

      resetAllStates()
    }, [persistStateBetweenMounts, resetAllStates])

    useEffect(() => {
      onMount()
      return onUnmount
    }, [onMount, onUnmount])

    return <ProviderChild {...props}>{children}</ProviderChild>
  }

  function HookBlocks() {
    for (const hookBlock of hookBlocks) hookBlock()
    return null
  }

  // Optionally log out the state of the context whenever a state change occurs
  function Logger() {
    useRecoilTransactionObserver_UNSTABLE(({ snapshot }) => {
      const scopeContents: Record<string, string> = {}

      for (const value of values) {
        const loadable = snapshot.getLoadable(value)

        let contents: any = ""
        if (loadable.state == "hasValue") {
          contents = loadable.contents
        }
        if (loadable.state == "loading") {
          contents = "<pending...>"
        }
        if (loadable.state == "hasError") {
          contents = `<error: ${loadable.contents.message}>`
        }
        scopeContents[value.key] = contents
      }

      logTrace(JSON.stringify(scopeContents, null, 2))
    })

    return null
  }

  function ProviderChild({
    children,
    rootValue,
    useLogging,
  }: {
    children: ReactNode

    rootValue?: T
    useLogging?: boolean
  }) {
    const contextReady =
      useRecoilValue(rootOrUninitializedState) != UNINITIALIZED

    if (rootValue != undefined && !contextReady) return null

    return (
      <>
        <HookBlocks />
        {useLogging && <Logger />}
        {children}
      </>
    )
  }

  // state() creates global state that can be initialized from a function, and then overwritten at runtime.
  function state<T>(
    key: string,
    defaultValue: T | RecoilValueReadOnly<T> | ((get: GetRecoilValue) => T),
  ): RecoilState<T> {
    const state = atom<T>({
      key: `${prefix}.${key}`,
      default:
        typeof defaultValue == "function"
          ? selector({
              key: `${prefix}.${key}.default`,
              get: opts => (defaultValue as any)(opts.get),
            })
          : defaultValue,
    })

    states.push(state)
    values.push(state)

    return state
  }

  // derived() has two overloads. The simple one just returns a read-only selector that is computed from other nodes.
  function derived<T>(
    key: string,
    computeValue: (get: GetRecoilValue) => T,
  ): RecoilValueReadOnly<T>
  // the second one returns a writeable selector, that can update other nodes on write
  function derived<T>(
    key: string,
    computeValue: (get: GetRecoilValue) => T,
    setValue?: (value: T, get: GetRecoilValue, set: SetRecoilState) => void,
  ): RecoilState<T>
  // Implementation:
  function derived<T>(
    key: string,
    computeValue: (get: GetRecoilValue) => T,
    setValue?: (value: T, get: GetRecoilValue, set: SetRecoilState) => void,
  ) {
    if (!setValue) {
      // Variant 1: ReadOnly Selector
      const value = selector<T>({
        key: `${prefix}.${key}`,
        get: opts => computeValue(opts.get),
      })

      values.push(value)

      return value
    } else {
      // Variant 2: ReadWrite Selector
      const value = selector<T>({
        key: `${prefix}.${key}`,
        get: opts => computeValue(opts.get),
        set: (opts, newValue) => {
          // Resetting selectors is out of scope - we don't have a usecase for this yet
          if (newValue instanceof DefaultValue) return
          setValue(newValue, opts.get, opts.set)
        },
      })

      values.push(value)

      return value
    }
  }

  // Creates a state atom whose value is generated by hook calls.
  function hooked<T>(key: string, block: () => T) {
    let resolveInitialValue: (value: T | PromiseLike<T>) => void
    const initialValuePromise = new Promise<T>(resolve => {
      resolveInitialValue = resolve
    })

    // This atom state represents the
    // currently present value of the hooked state.
    const valueState = atom<T>({
      key: `${prefix}.${key}`,
      default: initialValuePromise,
    })

    // This atom state represents the
    // current default value of the hooked state
    // (= the value that resulted from the hook block execution).
    const defaultValueState = atom<T>({
      key: `${prefix}.${key}.default`,
      default: initialValuePromise,
    })

    // Call this hook block during initialization of the Provider,
    // setting up the state and its corresponding maintenance behaviour.
    hookBlocks.push(() => {
      const blockResult = block()
      resolveInitialValue(blockResult)

      const currentCompanyId = useCompanyId()
      const persistStateBetweenMounts = useRecoilValue(
        persistStateBetweenMountsState,
      )
      const resetValue = useResetRecoilState(valueState)
      const [value, setValue] = useRecoilState(valueState)
      const resetDefaultValue = useResetRecoilState(defaultValueState)
      const previousCompanyId = useRecoilValue(persistedCompanyIdState)

      const resetAllHookedValues = useCallback(() => {
        resetValue()
        resetDefaultValue()
      }, [resetDefaultValue, resetValue])

      useMountEffect(() => {
        // On mount check whether there was a company change or not.
        // Hooked states that are not bound to a company specific implementation,
        // should not trigger the reset logic.
        if (!previousCompanyId || currentCompanyId == previousCompanyId) return

        // If so reset both states in order to re-evaluate them.
        resetAllHookedValues()
      })

      // In case the state should not persist between mounts,
      // do reset all persisted hooked and hooked default values.
      useUnmountEffect(() => {
        if (persistStateBetweenMounts) return

        resetAllHookedValues()
      })

      // Track whether the executed hook block changed its result,
      // if so execute the effect block and update the default value after.
      useRecoilTransitionEffect(
        blockResult,
        defaultValueState,
        (currentValue, previousValue) => {
          // In this case the actual hooked state value should only be updated,
          // when...
          // 1. the previous value is not equal to the upcoming one (hence outdated) AND
          // 2. the current hook state value is equal to the current default value
          // (do not update, when a state change happens externally in relation
          // to the evaluation of the actual hook block, e.g. user input).
          if (
            isEqual(currentValue, previousValue) ||
            !isEqual(value, previousValue)
          )
            return

          // Update the hooked state with the evaluated hook block result.
          setValue(currentValue)
        },
      )
    })

    states.push(valueState)
    values.push(valueState)

    return valueState
  }

  return { root: rootSelector, state, hooked, derived, Provider }
}
