import Big from "big.js"
import * as z from "zod"

import { createEntityFor } from "@axtesys/react-tools"

import { InvoiceItemArticle } from "./Article"
import { CompleteDiscountInfo, Discount, DiscountEntity } from "./Discount"
import { TaxInfo } from "./TaxInfo"

export type InvoiceItemId = string

export type InvoiceItem = {
  invoiceItemId: InvoiceItemId
  amount: Big
  createdAt: Date
  modifiedAt: Date
  discounts: Discount[]
  isNegativeAmount: boolean
  isDiscountBlocked: boolean
  article: InvoiceItemArticle
  additionalText?: string
}

export type InvoiceItemData = Omit<
  InvoiceItem,
  "invoiceItemId" | "createdAt" | "modifiedAt"
>

export type InvoiceItemConfiguration = Pick<
  InvoiceItem,
  "article" | "discounts" | "additionalText" | "amount" | "isNegativeAmount"
>

export type OutputInvoiceItem = Omit<InvoiceItem, "discounts"> & {
  taxInfo: TaxInfo
  discounts: CompleteDiscountInfo[]
  discountedPricePerUnit?: Big
}

export const InvoiceItemEntity = createEntityFor<InvoiceItem>()
  .withSchema(
    z.object({
      invoiceItemId: z.string(),
      amount: z.string(), // Big
      article: z.object({
        articleId: z.string(),
        mainGroupId: z.string(),
        articleGroupId: z.string(),
        name: z.string(),
        price: z.string(), // Big
        number: z.ostring(),
        taxRate: z.number(),
      }),
      discounts: z.array(DiscountEntity.schema),
      additionalText: z.optional(z.string()),
      isDiscountBlocked: z.boolean(),
      isNegativeAmount: z.boolean(),
      createdAt: z.string(),
      modifiedAt: z.string(),
    }),
  )
  .serialize(data => ({
    ...data,
    amount: data.amount.toString(),
    article: {
      ...data.article,
      price: data.article.price.toString(),
    },
    discounts: data.discounts.map(DiscountEntity.serialize),
    createdAt: data.createdAt.toISOString(),
    modifiedAt: data.modifiedAt.toISOString(),
  }))
  .deserialize(json => ({
    ...json,
    amount: Big(json.amount),
    discounts: json.discounts?.map(DiscountEntity.deserialize),
    createdAt: new Date(json.createdAt),
    modifiedAt: new Date(json.modifiedAt),
    article: {
      ...json.article,
      articleId: json.article.articleId,
      price: Big(json.article.price),
    },
    additionalText: json.additionalText,
  }))
