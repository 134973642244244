import { useDeviceInfo } from "./useDeviceInfo"

interface Device<T = any> {
  desktop: T
  tablet: T
  phone: T
}

export function useDeviceDependentValue(values: Device): any {
  const { deviceType: type } = useDeviceInfo()
  return values[type]
}
