import { map, mapValues, merge, orderBy, reduce } from "lodash"
import * as z from "zod"

import { isEmptyOrBlank, jsonDbAtom } from "@axtesys/react-tools"

import { selector } from "~shared/lib/recoil/lib"
import {
  Article,
  ArticleEntity,
  ArticleGroup,
  ArticleGroupEntity,
  ArticleGroupId,
  ArticleId,
  CashFlow,
  CashFlowEntity,
  ContactEntity,
  ContactId,
  ContactInfo,
  Discount,
  DiscountEntity,
  MainGroup,
  MainGroupEntity,
  MainGroupId,
  PaymentMethod,
  PaymentMethodEntity,
  ReceiptDesignEntity,
  SharedReceiptDesign,
  SpecificKeyboardLayout,
  SpecificKeyboardLayoutEntity,
} from "~shared/types"

export const mainGroupsState = jsonDbAtom({
  key: "mainGroupsState",
  default: {} as Record<MainGroupId, MainGroup>,
  schema: z.record(MainGroupEntity.schema),
})

// All mainGroups ordered by their orderIndex
export const mainGroupsOrderedSelector = selector({
  key: "mainGroupsOrderedSelector",
  get: ({ get }) =>
    orderBy<MainGroup>(
      Object.values(get(mainGroupsState)),
      ["orderIndex"],
      ["asc"],
    ),
})

export const articleGroupsState = jsonDbAtom({
  key: "articleGroupsState",
  default: {} as Record<ArticleGroupId, ArticleGroup>,
  schema: z.record(ArticleGroupEntity.schema),
})

// All articleGroups ordered by their orderIndex
export const articleGroupsOrderedSelector = selector({
  key: "articleGroupsOrderedSelector",
  get: ({ get }) =>
    orderBy<ArticleGroup>(
      Object.values(get(articleGroupsState)),
      ["orderIndex"],
      ["asc"],
    ),
})

export const articlesState = jsonDbAtom({
  key: "articlesState",
  default: {} as Record<ArticleId, Article>,
  schema: z.record(ArticleEntity.schema),
  serialize: data => mapValues(data, ArticleEntity.serialize),
  deserialize: json => mapValues(json, ArticleEntity.deserialize),
})

export const gtinArticleIdsSelector = selector({
  key: "gtinArticleIdsSelector",
  get: ({ get }) =>
    reduce(
      get(articlesState),
      (record, article) => {
        for (const barcodeNumber of article.barcodeNumbers ?? []) {
          if (isEmptyOrBlank(barcodeNumber)) continue
          record[barcodeNumber] = article.articleId
        }
        return record
      },
      {} as Record<string, ArticleId>,
    ),
})

export const identifierArticleIdsSelector = selector({
  key: "identifierArticleIdsSelector",
  get: ({ get }) =>
    merge(
      {},
      reduce(
        get(articlesState),
        (record, article) => {
          if (isEmptyOrBlank(article.number)) return record
          record[article.number!] = article.articleId
          return record
        },
        {} as Record<string, ArticleId>,
      ),
      get(gtinArticleIdsSelector),
    ),
})

export const predefinedDiscountsState = jsonDbAtom({
  key: "predefinedDiscountsState",
  default: [] as Discount[],
  schema: z.array(DiscountEntity.schema),
  serialize: data => map(data, DiscountEntity.serialize),
  deserialize: json => map(json, DiscountEntity.deserialize),
})

export const paymentMethodsState = jsonDbAtom({
  key: "paymentMethodsState",
  default: [] as PaymentMethod[],
  schema: z.array(PaymentMethodEntity.schema),
  serialize: data => map(data, PaymentMethodEntity.serialize),
  deserialize: json => map(json, PaymentMethodEntity.deserialize),
})

export const cashFlowsState = jsonDbAtom({
  key: "cashFlowsState",
  default: [] as CashFlow[],
  schema: z.array(CashFlowEntity.schema),
})

export const isRksvEnabledState = jsonDbAtom({
  key: "isRksvEnabled",
  default: false,
  schema: z.boolean(),
})

export const taxOfficeCashBoxIdState = jsonDbAtom({
  key: "taxOfficeCashBoxIdState",
  default: "maxundben",
  schema: z.string(),
})

export const taxOfficeCashBoxIdSelector = selector({
  key: "taxOfficeCashBoxIdSelector",
  get: ({ get }) =>
    get(isRksvEnabledState) ? get(taxOfficeCashBoxIdState) : undefined,
})

export const closeOfDayTimeState = jsonDbAtom({
  key: "closeOfDayTime",
  default: "00:00",
  schema: z.string(),
})

export const receiptDesignState = jsonDbAtom({
  key: "receiptDesign",
  default: {} as SharedReceiptDesign,
  schema: ReceiptDesignEntity.schema,
})

export const companyInfoState = jsonDbAtom({
  key: "companyInfoState",
  default: {
    companyId: "",
    name: "",
    taxId: "",
    manager: {
      managerId: "",
      email: "",
      phoneNumber: "" as string | undefined,
    },
    address: {
      street: "",
      streetNumber: "",
      zipCode: "",
      city: "",
    },
  },
  schema: z.object({
    companyId: z.string(),
    name: z.string(),
    taxId: z.string(),
    manager: z.object({
      managerId: z.string(),
      email: z.string(),
      phoneNumber: z.string(),
    }),
    address: z.object({
      street: z.string(),
      streetNumber: z.string(),
      zipCode: z.string(),
      city: z.string(),
    }),
  }),
})

export const contactsState = jsonDbAtom({
  key: "contactsState",
  default: {} as Record<ContactId, ContactInfo>,
  schema: z.record(ContactEntity.schema),
  serialize: data => mapValues(data, ContactEntity.serialize),
  deserialize: json => mapValues(json, ContactEntity.deserialize),
})

export const keyboardLayoutState = jsonDbAtom({
  key: "keyboardLayoutState",
  default: undefined as SpecificKeyboardLayout | undefined,
  schema: SpecificKeyboardLayoutEntity.schema,
  serialize: SpecificKeyboardLayoutEntity.serialize,
  deserialize: SpecificKeyboardLayoutEntity.deserialize,
})
