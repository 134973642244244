import Big from "big.js"
import React from "react"

import { Icon } from "../../display/Icon"
import { Row } from "../../layout/FlexBox"
import { MoneyInput } from "../field/MoneyInput"
import { InputRow } from "./InputRow"

type MoneyRangeRowProps = {
  value: readonly [Big | undefined, Big | undefined]
  onChange(moneyRange: [Big | undefined, Big | undefined]): void
}

export function MoneyRangeRow(props: MoneyRangeRowProps) {
  return (
    <InputRow icon={{ name: "currency-eur" }}>
      <PriceInput {...props} index={0} />
      <Icon name="arrow-right" />
      <PriceInput {...props} index={1} />
    </InputRow>
  )
}

function PriceInput(props: MoneyRangeRowProps & { index: 0 | 1 }) {
  return (
    <Row expand>
      <MoneyInput
        mini
        noValueNeeded
        style={{ maxWidth: 90 }}
        value={props.value[props.index]}
        onSubmit={big => {
          const range = [...props.value] as [Big | undefined, Big | undefined]
          range[props.index] = big
          props.onChange(range)
        }}
      />
    </Row>
  )
}
