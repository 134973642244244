import { entries, filter, join, map } from "lodash"

import { isEmptyOrBlank } from "./StringManipulation"

export function urlWithQueryParams(
  url: string,
  params: Record<string, string | number | boolean | undefined | string[]>,
) {
  const queryString = join(
    map(
      filter(entries(params), ([_, value]) => value != undefined),
      ([key, value]) => {
        if (Array.isArray(value))
          return value.map(paramValue => `${key}=${paramValue}`).join("&")

        return `${key}=${value}`
      },
    ),
    "&",
  )

  return isEmptyOrBlank(queryString) ? url : `${url}?${queryString}`
}
