import React from "react"

import { Box, BoxProps } from "../layout/Box"

type CircleProps = BoxProps & { size: number }

export function Circle(props: CircleProps) {
  const { size, style, children } = props

  return (
    <Box
      {...props}
      style={[style, { width: size, height: size, borderRadius: size / 2 }]}
    >
      {children}
    </Box>
  )
}
