import { Ref, useRef } from "react"
import { TextInput } from "react-native"

// A FocusChain links together multiple TextInput elements.
// When one element raises the `onSubmitEditing` event, the next link in the chain will get focused.
export interface FocusChain {
  link(index: number): FocusChainLink
}

// A FocusChainLink is one element in a FocusChain. Each TextInput element corresponds to one FocusChainLink.
// Pass these props to the TextInput element to automatically implement the focus transfer.
export interface FocusChainLink {
  ref: Ref<TextInput>

  onSubmitEditing: () => void
}

export default function useFocusChain(): FocusChain {
  // Lookup that maps an index to a TextInput element
  const indexedElementMapRef = useRef(
    {} as Record<number, TextInput | null>,
  ).current

  return {
    link(index: number) {
      return {
        ref(element) {
          indexedElementMapRef[index] = element
        },
        onSubmitEditing() {
          const nextElement = indexedElementMapRef[index + 1]
          nextElement?.focus()
        },
      }
    },
  }
}
