import React, { ReactNode, useCallback, useEffect } from "react"

import { useShowIntPrevWarning } from "../hooks"
import { useIsInteractionPrevention } from "../queries"

export function InteractionPreventionProvider({
  children,
}: {
  children: ReactNode
}) {
  usePrevention()

  return <>{children}</>
}

function usePrevention() {
  const isInteractionPrevention = useIsInteractionPrevention()
  const showInteractionPreventionWarning = useShowIntPrevWarning()

  const eventListener = useCallback(
    (event: Event) => {
      event.stopPropagation()
      event.stopImmediatePropagation()
      event.preventDefault()
      showInteractionPreventionWarning()
      return false
    },
    [showInteractionPreventionWarning],
  )

  const addEventListeners = useCallback(() => {
    const addEventListener = (type: keyof GlobalEventHandlersEventMap) =>
      window.addEventListener(type, eventListener, true)

    addEventListener("click")
    addEventListener("keydown")
  }, [eventListener])

  const removeEventListeners = useCallback(() => {
    const removeEventListener = (type: keyof GlobalEventHandlersEventMap) =>
      window.removeEventListener(type, eventListener, true)

    removeEventListener("click")
    removeEventListener("keydown")
  }, [eventListener])

  useEffect(() => {
    if (isInteractionPrevention) addEventListeners()
    else removeEventListeners()

    return removeEventListeners
  }, [addEventListeners, isInteractionPrevention, removeEventListeners])
}
