import React from "react"

import { Label } from "./Label"

export type InputLabelProps = { label?: string; mandatory?: boolean }

export function InputLabel(props: InputLabelProps) {
  const text = props.mandatory && props.label ? `${props.label}*` : props.label

  return <Label text={text} bold {...props} />
}
