import React from "react"

import { MCIcon } from "../MCIcon"
import { Icon } from "../display/Icon"
import { Row } from "../layout/FlexBox"
import { Label } from "../typography/Label"

// A Button without background or border. Text and Icon are optional.
export type FlatButtonProps = {
  icon?: MCIcon
  text?: string
  fluid?: boolean
  onPress?(): void
}

export function FlatButton(props: FlatButtonProps) {
  const icon = props.icon && <Icon name={props.icon} />
  const text = props.text && <Label text={props.text} />

  return (
    <Row
      gap="XXXS"
      alignCenter
      padRight="XXS"
      padLeft={icon ? undefined : "XXS"}
      style={{
        minHeight: 48,
        justifyContent: "center",
        width: props.fluid ? "100%" : undefined,
      }}
      onPress={props.onPress}
    >
      {icon}
      {text}
    </Row>
  )
}
