import * as z from "zod"

import { jsonDbAtom } from "@axtesys/react-tools"

// The app version that was last started on this device.
// Useful to determine if an updated happened.
export const lastAppVersionState = jsonDbAtom({
  key: "lastAppVersion",
  default: "unknown",
  schema: z.string(),
})
