import { DependencyList, useEffect, useRef } from "react"

// Executes side effects after the initial mount and
// whenever a dependency in DependencyList changes.
export function useSubsequentEffect(
  effect: () => void,
  dependencyList: DependencyList,
) {
  const mountRef = useRef<boolean>(false)

  useEffect(() => {
    // Skip the first side effect run
    if (!mountRef.current) {
      mountRef.current = true
      return
    }

    effect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencyList)
}
