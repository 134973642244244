import { isEqual } from "lodash"
import React, { useState } from "react"

import { useOnOff } from "@axtesys/hooks"

import { Box } from "../layout/Box"
import { useTheme } from "../theme"
import { Label } from "../typography/Label"
import { Button, ButtonProps } from "./Button"

const MAX_WIDTH = 175
const INIT_SPACING = { height: 0, width: 0 }

type ToolTipButtonProps = ButtonProps & {
  tooltip: string
  hideToolTip?: boolean
}

export function TooltipButton(props: ToolTipButtonProps) {
  const theme = useTheme()
  const showToolTip = !(props.hideToolTip ?? false)
  const [hoverSpacing, setHoverSpacing] = useState(INIT_SPACING)
  const [isHovered, onHoverIn, onHoverOut] = useOnOff(false)

  const hoverText = showToolTip && (
    <Box
      pad="XXXS"
      borderColor={theme.color.border}
      backgroundColor={theme.color.background}
      onLayout={({ nativeEvent }) =>
        // 1.35 is totally a magic number. I am sorry for that!
        // However, it is best suited for that
        setHoverSpacing({
          width: nativeEvent.layout.width + 5,
          height: nativeEvent.layout.height / 1.35,
        })
      }
    >
      <Label small text={props.tooltip} />
    </Box>
  )

  return (
    <Box>
      <Button {...props} onHoverIn={onHoverIn} onHoverOut={onHoverOut} />

      {isHovered && (
        <Box
          style={{
            width: MAX_WIDTH,
            position: "absolute",
            opacity: !isEqual(hoverSpacing, INIT_SPACING) ? 1 : 0,
          }}
        >
          <Box
            style={{
              maxWidth: MAX_WIDTH,
              position: "absolute",
              left: -hoverSpacing.width,
              bottom: -hoverSpacing.height,
            }}
          >
            {hoverText}
          </Box>
        </Box>
      )}
    </Box>
  )
}
