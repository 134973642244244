import React, { ReactNode } from "react"
import { StyleProp, ViewStyle } from "react-native"

import { Column } from "../../layout/FlexBox"
import { Gap } from "../../layout/Gap"
import { Label } from "../../typography/Label"

export type ErrorFieldProps = {
  children: ReactNode
  errorMessage?: string
  style?: StyleProp<ViewStyle>
}

// A form input / field component paired with an optional error message
export function ErrorField(props: ErrorFieldProps) {
  const input = props.children

  // Replace an error message label with a Gap element
  // if there is no error message present (nasty element shifting is prevented)
  const optionalErrorMessage =
    props.errorMessage != null ? (
      <Label text={props.errorMessage} small color="error" />
    ) : (
      <Gap vertical="XXS" />
    )

  return (
    <Column gap="XXXXS" style={props.style}>
      {input}
      {optionalErrorMessage}
    </Column>
  )
}
