import React from "react"
import { FallbackProps } from "react-error-boundary"

import { Column, Icon, Label, Row } from "@axtesys/kassen-app-ui"

export function GenericErrorFallback(props: FallbackProps) {
  return (
    <Column borderRadius="L" pad="S" gap="XS" backgroundColor="#f88">
      <Row alignCenter>
        <Icon name="bug" color="medium" />
        <Label h5 color="medium" text="Ein Fehler ist aufgetreten." />
      </Row>

      <Label
        italic
        text={
          "Überprüfen Sie Ihre Aktion und versuchen Sie diese gegebenenfalls zu einem späteren Zeitpunkt erneut.\n" +
          "Sollte dieser Fehler erneut auftreten, bitten wir Sie, diese Seite geöffnet zu lassen und den Support zu kontaktieren."
        }
      />

      {__DEV__ && (
        <Column gap="XS">
          <Label italic text={props.error.message} />
          <Label text={props.error.stack} />
        </Column>
      )}
    </Column>
  )
}
