import { useEffect } from "react"

import { useResizeEffect } from "@axtesys/hooks"

export function useReCalculationEffect<T>({
  calculate,
  dependency,
  executeIfNotVisible,
}: {
  dependency: T
  calculate: () => void
  executeIfNotVisible: () => void
}) {
  // Required for correctly position an element
  // (usually beneath a dynamic placed interceptor)
  useResizeEffect(() => {
    calculate()
    if (!dependency) return
    executeIfNotVisible()
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(calculate, [dependency])
}
