import { concat, initial } from "lodash"
import { ReactNode, useCallback, useRef, useState } from "react"

import { createCustomContext } from "@axtesys/react-tools"

// showDialog returns a Promise that resolves when the dialog is closed.
type ShowDialog = (dialogNode: ReactNode) => Promise<void>

export const [DialogContextProvider, useDialogContext] = createCustomContext(
  () => {
    // The callbacks to be executed
    // when the currently active dialogs need to be closed
    const closureResolversRef = useRef<(() => void)[]>([])

    // All currently displayed dialogs (if any)
    const [activeDialogs, setActiveDialogs] = useState<ReactNode[]>([])

    // Shows a new dialog to the screen
    // (adds a new dialog component to the active dialog stack)
    const showDialog: ShowDialog = useCallback(
      dialogToShow =>
        new Promise(resolve => {
          closureResolversRef.current.push(resolve)
          setActiveDialogs(renderedDialogs =>
            concat(renderedDialogs, dialogToShow),
          )
        }),
      [],
    )

    // Closes the most recent dialog on the screen
    const closeTopDialog = useCallback(() => {
      closureResolversRef.current.pop()?.()
      setActiveDialogs(renderedDialogs => initial(renderedDialogs))
    }, [])

    return { activeDialogs, showDialog, closeTopDialog }
  },
)
