import React from "react"
import { isIOS } from "react-device-detect"
import { KeyboardTypeOptions, Platform } from "react-native"

import { TextInput, TextInputProps } from "./TextInput"

export function PhoneInput(props: TextInputProps) {
  let keyboardType: KeyboardTypeOptions
  if (Platform.OS == "ios") {
    keyboardType = "numbers-and-punctuation"
  } else keyboardType = isIOS ? "default" : "phone-pad"

  return <TextInput {...props} keyboardType={keyboardType} />
}
