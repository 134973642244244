import { entries } from "lodash"
import React, { useMemo } from "react"
import { Platform, ScrollView, View } from "react-native"

import { useOnOff } from "@axtesys/hooks"

import { Icon } from "../display/Icon"
import { Row } from "../layout/FlexBox"
import { useTheme } from "../theme"
import { ScreenCoordinates } from "../types"
import { Label } from "../typography/Label"
import { HoverablePressableOpacity } from "./HoverablePressableOpacity"
import { TransparentModal } from "./TransparentModal"

export type KeyLabels = Record<string, string>

type MenuProps = {
  visible: boolean
  position: ScreenCoordinates
  displayKeyValuePairs: KeyLabels

  onDismiss(): void
  onSelect(key: keyof KeyLabels): void

  minWidth?: number
  excludedValues?: (keyof KeyLabels)[]
  selectedValues?: (keyof KeyLabels)[]
}

export function Menu(props: MenuProps) {
  const {
    visible,
    minWidth,
    position,
    selectedValues,
    excludedValues,
    displayKeyValuePairs,
    onSelect,
    onDismiss,
  } = props

  const displayItems = useMemo(
    () =>
      visible
        ? entries(displayKeyValuePairs).filter(
            ([key, _]) => !excludedValues || !excludedValues.includes(key),
          )
        : [],
    [displayKeyValuePairs, excludedValues, visible],
  )

  if (!visible) return null

  const items =
    displayItems.length > 0
      ? displayItems.map(([key, label]) => (
          <MenuItem
            key={key}
            label={label}
            isSelected={
              selectedValues && !!selectedValues.find(value => value == key)
            }
            onPress={() => onSelect(key)}
          />
        ))
      : [
          <MenuItem
            disabled
            key="noItemsPresentItem"
            label="Keine Elemente vorhanden"
          />,
        ]

  return (
    <TransparentModal isVisible={visible} onPressOutside={onDismiss}>
      <View
        style={{
          minWidth,
          top: position.y,
          shadowRadius: 4,
          left: position.x,
          shadowOpacity: 0.5,
          position: "absolute",
          shadowColor: "black",
          backgroundColor: "white",
          borderBottomLeftRadius: 5,
          borderBottomRightRadius: 5,
          shadowOffset: { height: 2, width: 0 },
          elevation: Platform.OS == "android" ? 5 : undefined,
        }}
      >
        <ScrollView style={{ maxHeight: 250, minWidth }}>{items}</ScrollView>
      </View>
    </TransparentModal>
  )
}

type MenuItemProps = {
  label: string

  disabled?: boolean
  isSelected?: boolean
  onPress?(): void
}

function MenuItem(props: MenuItemProps) {
  const { label, disabled, isSelected, onPress } = props

  const { color } = useTheme()
  const [isHovered, onHoverIn, onHoverOut] = useOnOff(false)

  const item = (
    <Row
      alignCenter
      padHorizontal="XXXS"
      style={{ height: 48 }}
      disabled={props.disabled}
      gap={isSelected ? "XXS" : "none"}
    >
      {isSelected && <Icon name="check" />}
      <Label medium text={label} selectable={false} />
    </Row>
  )

  return (
    <HoverablePressableOpacity
      disabled={disabled}
      style={[
        {
          borderLeftWidth: 4,
          borderLeftColor: "transparent",
        },
        isHovered && {
          backgroundColor: color.base5,
          borderLeftColor: color.secondary2,
        },
      ]}
      onPress={onPress}
      onPressIn={onHoverIn}
      onPressOut={onHoverOut}
      onHoverIn={onHoverIn}
      onHoverOut={onHoverOut}
    >
      {item}
    </HoverablePressableOpacity>
  )
}
