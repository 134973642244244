export const NETWORK_ERROR_DISPLAY_TEXT =
  "Verbindung konnte nicht hergestellt werden. Bitte prüfen Sie Ihre Internetverbindung."

export const NETWORK_ERROR_TIMEOUT = "RequestTimeoutError"

export const NETWORK_ERRORS = [
  "Load failed", // Safari, Mobile Safari
  "Failed to fetch", // Chromium (Chrome, Opera, Edge, etc.)
  "Network request failed", // Native (iOS/iPadOS und Android)
  "NetworkError when attempting to fetch resource.", // Firefox

  NETWORK_ERROR_TIMEOUT,
  NETWORK_ERROR_DISPLAY_TEXT,
]

export function isNetworkError(error: any) {
  return NETWORK_ERRORS.some(errorPart => error.toString().includes(errorPart))
}

export function isTimeoutError(error: any) {
  return error.toString().includes(NETWORK_ERROR_TIMEOUT)
}

export function transformError(error: unknown | any) {
  return {
    error: {
      name: error?.name,
      message: error?.message,
    },
  }
}
