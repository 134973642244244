import Big from "big.js"

export function interpolateString(
  templateString: string,
  ...parameters: (string | number | Big)[]
) {
  let formattedString = templateString
  for (let i = 0; i < parameters.length; i++) {
    // Replaces first appearance of the literal formatter string
    // with i element of the parameter array.
    formattedString = formattedString.replace("%s", parameters[i].toString())
  }
  return formattedString
}

export function isEmptyOrBlank(
  input: string | boolean | number | Big | undefined | null,
) {
  return input == undefined || input.toString().trim().length == 0
}
