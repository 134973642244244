import { ListIteratee, sortBy as sort } from "lodash"
import { MutableRefObject, useEffect, useMemo, useState } from "react"

// Provides a sorted and filtered view on the items.
// Sorting is stable: If you change the sortBy method,
// items move as little as possible to match the new sorting requirement.
export function useSortedAndFilteredData<T>({
  items,
  sortBy,
  filterBy,
  sortDirection,
  skipFilteredAndSortedUpdateRef,
}: {
  items: T[]
  sortDirection: "asc" | "desc"

  filterBy?: (item: T) => boolean
  sortBy?: ListIteratee<T> | ListIteratee<T>[]
  skipFilteredAndSortedUpdateRef?: MutableRefObject<boolean>
}) {
  // These items are sorted in-place (ignoring sortDirection)
  const [sortedItems, setSortedItems] = useState(items)

  // Sort the (previously sorted) items whenever
  // the sort function or items changes
  useEffect(() => {
    setSortedItems(sortBy ? sort(items, sortBy) : items)
  }, [items, sortBy])

  // Filter sorted items
  const filteredItems = useMemo(
    () => (filterBy ? sortedItems.filter(filterBy) : sortedItems),
    [filterBy, sortedItems],
  )

  return useMemo(() => {
    // Returns the passed version of items without
    // applying filtering or sorting steps
    // (but only if we explicitly want to skip this step;
    //  mainly implemented to improve rendering time
    //  when a drag/reordering action occurs)
    if (skipFilteredAndSortedUpdateRef?.current == true) {
      skipFilteredAndSortedUpdateRef.current = false
      return items
    }

    // Reverse the order of the items if required by sortDirection
    return sortDirection == "desc"
      ? [...filteredItems].reverse()
      : filteredItems
  }, [skipFilteredAndSortedUpdateRef, sortDirection, items, filteredItems])
}
