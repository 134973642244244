import React, { ReactNode } from "react"
import { StyleProp, ViewStyle } from "react-native"

import { Column } from "../../layout/FlexBox"
import { Gap } from "../../layout/Gap"

type GapContainerProps = {
  children: ReactNode
  style?: StyleProp<ViewStyle>
}

// Convenience container acting as a counter measurement for `ErrorField`s
export function GapContainer(props: GapContainerProps) {
  return (
    <Column gap="XXXXS" style={props.style}>
      {props.children}
      <Gap vertical="XXS" />
    </Column>
  )
}
