import { isArray } from "lodash"
import { useCallback, useMemo } from "react"
import { useRecoilCallback } from "recoil"

import {
  createCustomContext,
  formatIsoDateTime,
  interpolateString,
} from "@axtesys/react-tools"
import {
  QueryFunctionContext,
  useQueryClient,
  useSuspenseQuery as useRQSuspenseQuery,
} from "@tanstack/react-query"

import { useSnackbar } from "../../contexts/SnackbarContext"
import { useTranslation } from "../../contexts/TranslationContext"
import { useIsLoggedIn, useLogoutAndFail } from "../../feature/Authentication"
import { useErrorNoticeDialog, useSystemTimeDialog } from "../../feature/Dialog"
import {
  FRAUD_PROTECTION,
  MAX_ALLOWED_FRAUD_DELTA,
  MUTATION_IN_FUTURE,
  MUTATION_IN_PAST,
} from "../../feature/FraudProtection/constants"
import { lastServerInteractionState } from "../../feature/FraudProtection/state"
import {
  logError,
  logInfo,
  logTrace,
  logWarn,
} from "../../feature/Logging/hooks"
import {
  isNetworkError,
  NETWORK_ERROR_DISPLAY_TEXT,
  transformError,
} from "../../lib/Errors"
import { createId } from "../../lib/Id"
import { useHttp } from "../http/useHttp"
import {
  getOperationQueryString,
  OperationVariables,
  QueryData,
  QueryNames,
} from "./GeneratedOperations.helper"
import {
  DUPLICATE_COMMAND,
  NO_DATA_ERROR,
  QUERY_REQUEST_ERROR,
  UNAUTHENTICATED,
  UNEXPECTED_RESPONSE_BODY,
} from "./constants"
import {
  GraphQLHttpResponse,
  MutationInvalidation,
  SendMutation,
  SendMutationInternal,
  SendOperation,
  SendQuery,
} from "./types"

export * from "./types"
export * from "./constants"

const mutationSeenCodeRecord: Record<string, boolean> = {}
const mutationSeenUnknownRecord: Record<string, boolean> = {}

export const [GraphQLContextProvider, useGraphQL] = createCustomContext(() => {
  const http = useHttp()
  const strings = useTranslation()
  const isLoggedIn = useIsLoggedIn()
  const { showSnackbar } = useSnackbar()
  const logoutPlusFail = useLogoutAndFail()
  const showSystemTimeDialog = useSystemTimeDialog()
  const showErrorNoticeDialog = useErrorNoticeDialog()
  const invalidateReactQueries = useInvalidateQueries()
  const { general, generalValidationMessages } = useTranslation()

  const pastFutureErrorMessages = useMemo(
    () => [
      interpolateString(
        generalValidationMessages.validationMutationInPastOrFuture,
        general.past,
      ),
      interpolateString(
        generalValidationMessages.validationMutationInPastOrFuture,
        general.future,
      ),
    ],
    [
      general.future,
      general.past,
      generalValidationMessages.validationMutationInPastOrFuture,
    ],
  )

  const showErrorSnackbar = useCallback(
    (message?: string, key?: string) => {
      if (message == undefined || pastFutureErrorMessages.includes(message))
        return

      showSnackbar({
        message,
        mode: "error",
        duration: 5000,
        key: key ?? createId(),
      })
    },
    [pastFutureErrorMessages, showSnackbar],
  )

  const showErrorDialog = useCallback(
    (message?: string) => {
      if (message == undefined || pastFutureErrorMessages.includes(message))
        return

      showErrorNoticeDialog(message)
    },
    [pastFutureErrorMessages, showErrorNoticeDialog],
  )

  const logoutAndFail = useCallback(() => {
    logoutPlusFail()

    return {
      data: undefined,
      errors: [
        {
          message: UNAUTHENTICATED,
          extensions: { errorCode: UNAUTHENTICATED },
        },
      ],
    } as GraphQLHttpResponse<any>
  }, [logoutPlusFail])

  // Internal function to send any kind of GraphQL Operation.
  // Deals with token refresh and automatically logs out the user
  // if we get an 'Unauthenticated' error.
  const sendOperation: SendOperation = useCallback(
    async (operationName, variables, commandId, createdAt, timeout) => {
      // Send the Request
      const response = await http({
        method: "POST",
        path: "/api/graphql",

        // GraphQL requests should always be designed
        // to be completed before a maximum execution /
        // wait duration of 30 seconds is reached,
        // if that is not the case the request
        // should be improved / sized down to an acceptable size.
        //
        // If the request in general is designed accordingly,
        // but the network connection is awful (but still present)
        // also cancel the request after 30 seconds
        // to prevent an accumulation of pending requests in the background.
        //
        // For certain requests the timeout needs
        // to be configured with a more strict approach (e.g. receipt creation),
        // therefore allow configuring the timeout,
        // but also set a standard timeout of 30 seconds.
        //
        // However, configure this value wisely as a too low timeout value,
        // could lead to repeated fails (in terms of offline mutations) or
        // the request never getting through at all.
        timeout: timeout ?? 30000,

        headers: {
          // Created-At header field should only be added
          // in case of a mutation (createdAt != undefined).
          // Queries do not require such a field.
          // Therefore unnecessary transmission can be omitted.
          "X-Created-At": createdAt,

          "X-Command-Id": commandId,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          variables,
          query: getOperationQueryString(operationName),
        }),
      })

      if (isLoggedIn && response.status == 401) return logoutAndFail()

      // Parse into GraphQLHttpResponse
      const responseBody = await response.text()
      if (!responseBody.startsWith("{"))
        throw Error(`${UNEXPECTED_RESPONSE_BODY}: ${responseBody}`)

      let responseJson: GraphQLHttpResponse<any> = JSON.parse(responseBody)
      if (!("data" in responseJson))
        throw Error(`${NO_DATA_ERROR}: ${responseBody}`)

      logTrace(
        `Received following GraphQL response for operation '${operationName}'`,
        responseJson,
      )

      responseJson = {
        errors: responseJson.errors,
        data: JSON.parse(JSON.stringify(responseJson.data), (_, value) =>
          value != null ? value : undefined,
        ),
      } as GraphQLHttpResponse<any>

      if (
        isLoggedIn &&
        responseJson.errors &&
        responseJson.errors.some(
          error => error.extensions?.errorCode == UNAUTHENTICATED,
        )
      )
        return logoutAndFail()

      return responseJson
    },
    [http, isLoggedIn, logoutAndFail],
  )

  const sendQuery: SendQuery = useCallback(
    async (operationName, variables, timeout) => {
      const { data, errors } = await sendOperation(
        operationName,
        variables,
        undefined,
        undefined,
        timeout,
      )

      if (errors) {
        logError(`Error when fetching query '${operationName}'`, errors)

        throw Error(
          `${QUERY_REQUEST_ERROR} (${
            errors.length > 0
              ? errors.map(error => error.extensions?.errorCode ?? "unknown")
              : "unknown"
          })`,
        )
      }

      return data
    },
    [sendOperation],
  )

  const sendMutationInternal = useRecoilCallback(
    ({ snapshot }) =>
      async (
        operationName,
        variables,
        commandId,
        createdAt,
        timeout,
        excludeFromLogging,
        offlineMutation,
      ) => {
        try {
          // Verify that the mutation is not in the past,
          // otherwise it should not be accepted,
          // but rather logged.

          const lastServerInteraction = snapshot
            .getLoadable(lastServerInteractionState)
            .getValue()
          const currentClientInteraction = Date.now()

          if (
            offlineMutation != true &&
            lastServerInteraction - currentClientInteraction >
              MAX_ALLOWED_FRAUD_DELTA
          ) {
            // In that case, display a dialog that notifies the potential fraudster,
            // that such an action cannot be carried out
            // and adjustments to the system time need to be performed in order to proceed.
            // In addition, log the possible fraudulent attempt.

            logWarn(
              `${FRAUD_PROTECTION}: A GraphQL mutation was tried to be set off in the past`,
              {
                operationName,
                createdAt,
                commandId,
                variables,
                lastServerInteraction: formatIsoDateTime(lastServerInteraction),
                currentClientInteraction: formatIsoDateTime(
                  currentClientInteraction,
                ),
              },
            )
            showSystemTimeDialog("past")

            return {
              status: "failed",
              additionalInfo: undefined,
              errorCode: MUTATION_IN_PAST,
              errorMessage: interpolateString(
                strings.generalValidationMessages
                  .validationMutationInPastOrFuture,
                strings.general.past,
              ),
            }
          }

          const { data, errors } = await sendOperation(
            operationName,
            variables,
            commandId,
            createdAt,
            timeout,
          )

          // Happy path
          if (!errors) {
            logInfo(`Successfully executed mutation '${operationName}'`)
            return { status: "success", data }
          } else {
            // Sad path
            const errorCode = extractErrorCode(errors)
            const additionalInfo = extractErrorAdditionalInfo(errors)

            let errorMessage: string
            if (errorCode == undefined) {
              logError(
                `Unexpected GraphQL error occurred during execution of mutation '${operationName}'`,
                errors,
              )
              errorMessage = strings.general.errorGeneric
            } else {
              // Error code side effects
              switch (errorCode) {
                case UNAUTHENTICATED:
                  logWarn(
                    `A GraphQL mutation was tried to be set off, but the user is no longer authenticated`,
                    { operationName, createdAt, commandId },
                  )
                  break
                case MUTATION_IN_FUTURE:
                  logWarn(
                    `${FRAUD_PROTECTION}: A GraphQL mutation was tried to be set off in the future`,
                    { operationName, createdAt, commandId },
                  )

                  // We do not want to await the result of this confirmation dialog,
                  // as otherwise the upcoming logs could be omitted via closing the application
                  showSystemTimeDialog("future")
                  break
                default:
                  break
              }

              errorMessage = errorCodeToMessage(errorCode)
            }

            if (excludeFromLogging) {
              const excludedFields = !isArray(excludeFromLogging)
                ? [excludeFromLogging]
                : excludeFromLogging

              for (const field of excludedFields) delete variables[field]
            }

            if (!mutationSeenCodeRecord[commandId]) {
              mutationSeenCodeRecord[commandId] = true
              logError(
                `Mutation '${operationName}' created on '${
                  createdAt ?? formatIsoDateTime(Date.now())
                }' failed to execute with an ErrorCode '${errorCode}'`,
                {
                  commandId,
                  variables,
                  errors,
                  errorCode,
                  errorMessage,
                  additionalInfo,
                },
              )
            } else
              logError(
                `Failed to execute mutation '${commandId}' (${errorCode})`,
              )

            return { errorCode, errorMessage, additionalInfo, status: "failed" }
          }
        } catch (error: any) {
          // Extra sad path
          const errorMessage = isNetworkError(error)
            ? NETWORK_ERROR_DISPLAY_TEXT
            : `${strings.general.errorGeneric} (${error?.message})`

          if (!mutationSeenUnknownRecord[commandId]) {
            mutationSeenUnknownRecord[commandId] = true
            logError(
              `Mutation '${operationName}' created on '${
                createdAt ?? formatIsoDateTime(Date.now())
              }' failed to execute`,
              {
                commandId,
                variables,
                error: transformError(error).error,
              },
            )
          } else logError(`Failed to execute mutation '${commandId}'`)

          return { errorMessage, status: "failed", errorCode: undefined }
        }

        function extractErrorCode(
          errors: GraphQLHttpResponse<any>["errors"],
        ): string | undefined {
          for (const error of errors ?? []) {
            if (error.extensions?.errorCode) {
              return error.extensions.errorCode
            }
          }
          return undefined
        }

        function extractErrorAdditionalInfo(
          errors: GraphQLHttpResponse<any>["errors"],
        ): string | undefined {
          for (const error of errors ?? []) {
            if (error.extensions?.additionalInfo) {
              return error.extensions.additionalInfo
            }
          }
          return undefined
        }

        function errorCodeToMessage(errorCode: string) {
          const mappedError =
            strings.commandsErrorCodeMapping?.[operationName]?.[errorCode]

          if (mappedError) return mappedError

          let message
          switch (errorCode) {
            case UNAUTHENTICATED:
              message = strings.authentication.userLoggedOutByServer
              break
            case DUPLICATE_COMMAND:
              message = strings.general.errorDuplicateCommand
              break
            case MUTATION_IN_FUTURE:
              message = interpolateString(
                strings.generalValidationMessages
                  .validationMutationInPastOrFuture,
                strings.general.future,
              )
              break
            default:
              message = `${strings.general.errorGeneric} (${errorCode})`
              break
          }

          return message
        }
      },
    [
      sendOperation,
      showSystemTimeDialog,
      strings.commandsErrorCodeMapping,
      strings.general.errorGeneric,
      strings.general.future,
      strings.general.past,
      strings.generalValidationMessages.validationMutationInPastOrFuture,
    ],
  ) as SendMutationInternal

  const sendMutation: SendMutation = useCallback(
    async (
      operationName,
      {
        timeout,
        commandId,
        createdAt,
        variables,
        snackMessage,
        offlineMutation,
        invalidateQueries,
        excludeFromLogging,
        genericErrorHandling = true,
        throwFraudRelatedErrors = false,
        onError,
        onSuccess,
      },
    ) => {
      const result = await sendMutationInternal(
        operationName,
        variables,
        commandId ?? createId(),
        createdAt ?? formatIsoDateTime(Date.now()),
        timeout,
        excludeFromLogging,
        offlineMutation,
      )

      if (result.status == "success") {
        await invalidateReactQueries(invalidateQueries)

        await onSuccess?.(result)

        if (snackMessage) {
          const message = snackMessage(result)

          if (message != undefined)
            showSnackbar({
              message,
              mode: "success",
              key: `mutation${operationName}Success`,
            })
        }

        return result
      }

      const isNotFraudErrorCode =
        result.errorCode != MUTATION_IN_PAST &&
        result.errorCode != MUTATION_IN_FUTURE

      if (
        onError != undefined &&
        (throwFraudRelatedErrors || isNotFraudErrorCode)
      )
        await onError(result, showErrorSnackbar, showErrorDialog)

      if (genericErrorHandling) {
        if (result.errorCode == UNAUTHENTICATED)
          showErrorSnackbar(
            strings.authentication.userLoggedOutByServer,
            "loggedOutByServerError",
          )
        else if (isNotFraudErrorCode)
          showErrorSnackbar(
            result.errorMessage,
            `mutation${operationName}${result.errorCode}`,
          )
      }

      return result
    },
    [
      invalidateReactQueries,
      sendMutationInternal,
      showErrorDialog,
      showErrorSnackbar,
      showSnackbar,
      strings.authentication.userLoggedOutByServer,
    ],
  )

  return { sendQuery, sendMutation }
})

// internal helper for useSuspenseQuery
function queryFunction({ queryKey }: QueryFunctionContext) {
  const [operationName, variables, sendQuery] = queryKey
  const send = sendQuery as Function
  return send(operationName, variables)
}

export function useSuspenseQuery<Q extends QueryNames>(
  operationName: Q,
  variables: OperationVariables<Q>,
): QueryData<Q> {
  const { sendQuery } = useGraphQL()

  const { data } = useRQSuspenseQuery({
    queryKey: [operationName, variables, sendQuery],
    queryFn: queryFunction,
  })

  return data
}

export function useInvalidateQueries() {
  const queryClient = useQueryClient()

  return useCallback(
    async (invalidateQueries?: MutationInvalidation) => {
      if (!invalidateQueries) return

      if (typeof invalidateQueries == "string") {
        await queryClient.invalidateQueries({ queryKey: [invalidateQueries] })
      } else if (Array.isArray(invalidateQueries)) {
        invalidateQueries.forEach(queryName =>
          queryClient.invalidateQueries({ queryKey: [queryName] }),
        )
      }
    },
    [queryClient],
  )
}
