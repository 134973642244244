import React, { ReactNode } from "react"
import { Text } from "react-native"

import { useColor } from "../hooks/useColor"
import { BoxStyleProps, useBoxStyle } from "../layout/Box"
import { useTheme } from "../theme"
import { Color } from "../types"

export type LabelProps = BoxStyleProps & {
  text: ReactNode
  h1?: boolean
  h2?: boolean
  h3?: boolean
  h4?: boolean
  h5?: boolean
  medium?: boolean
  small?: boolean
  tiny?: boolean

  textAlign?: "left" | "center" | "right"

  bold?: boolean

  uppercase?: boolean
  lowercase?: boolean

  italic?: boolean
  underline?: boolean
  selectable?: boolean
  singleLine?: boolean
  numberOfLines?: number
  strikethrough?: boolean

  color?: Color

  // Overwrites theme related fontSize flags,
  // only use if really necessary
  fontSize?: number
  fontFamily?: "normal" | "mono"
}

export function Label(props: LabelProps) {
  const theme = useTheme()

  const boxStyle = useBoxStyle(props)
  const color = useColor(props.color)

  let fontSize: number
  if (props.fontSize) fontSize = props.fontSize
  else if (props.h1) fontSize = theme.fontSize.h1
  else if (props.h2) fontSize = theme.fontSize.h2
  else if (props.h3) fontSize = theme.fontSize.h3
  else if (props.h4) fontSize = theme.fontSize.h4
  else if (props.h5) fontSize = theme.fontSize.h5
  else if (props.medium) fontSize = theme.fontSize.medium
  else if (props.small) fontSize = theme.fontSize.small
  else if (props.tiny) fontSize = theme.fontSize.tiny
  else fontSize = theme.fontSize.default

  const fontFamilyType =
    props.fontFamily == "mono"
      ? theme.fontFamily.robotoMono
      : theme.fontFamily.sourceSansPro

  let fontFamily: string = fontFamilyType.regular
  const isBold =
    props.bold != false &&
    (props.h1 || props.h2 || props.h3 || props.h4 || props.h5 || props.bold)
  if (isBold && props.italic) fontFamily = fontFamilyType.boldItalic
  else if (isBold) fontFamily = fontFamilyType.bold
  else if (props.italic) fontFamily = fontFamilyType.italic

  let text = props.text
  if (typeof props.text == "string") {
    if (props.uppercase) text = props.text.toUpperCase()
    else if (props.lowercase) text = props.text.toLowerCase()
  }

  const marginTop = -theme.lineHeightCorrection[0] * fontSize
  const marginBottom = -theme.lineHeightCorrection[1] * fontSize
  const textDecorationLine = props.strikethrough
    ? "line-through"
    : props.underline
    ? "underline"
    : "none"

  let numberOfLines: number | undefined
  if (props.singleLine) numberOfLines = 1
  else if (props.numberOfLines) numberOfLines = props.numberOfLines
  else numberOfLines = undefined

  return (
    <Text
      style={[
        {
          color,
          fontSize,
          marginTop,
          fontFamily,
          marginBottom,
          textDecorationLine,
          textAlign: props.textAlign,
        },
        boxStyle,
      ]}
      numberOfLines={numberOfLines}
      selectable={props.selectable}
    >
      {text}
    </Text>
  )
}
