import * as z from "zod"

import { createEntityFor } from "@axtesys/react-tools"

import { Salutation, ShippingAddressFragment } from "../api/graphql/types"
import { ManipulationMode } from "./ManipulationMode"

export type ShippingAddressId = string

export type Address = {
  salutation: Salutation
  firstName?: string
  lastName: string
  city: string
  street: string
  streetNumber: string
  zipCode: string
  countryCode?: string
}

export type ShippingAddress = Address & {
  shippingAddressId: ShippingAddressId
  isDefault: boolean
}

export type ShippingAddressInfo = Omit<ShippingAddress, "isDefault">

export type ShippingAddressFormData = Omit<
  ShippingAddress,
  "shippingAddressId"
> & {
  shippingAddressId?: ShippingAddressId
}

export type GeneralShippingAddressFormProps = {
  mode: ManipulationMode
  initialValue?: ShippingAddressFragment

  onSubmit(contact: ShippingAddressFormData): void
}

export const ShippingAddressEntity = createEntityFor<ShippingAddress>()
  .withSchema(
    z.object({
      shippingAddressId: z.string(), // DepartmentId
      salutation: z.enum([
        "Mr",
        "Ms",
        "NonBinary",
        "Enterprise",
        "NotSpecified",
        "Family",
        "Club",
      ]),
      firstName: z.optional(z.string()),
      lastName: z.string(),
      city: z.string(),
      street: z.string(),
      streetNumber: z.string(),
      zipCode: z.string(),
      countryCode: z.optional(z.string()),
      isDefault: z.boolean(),
    }),
  )
  .serialize(data => data)
  .deserialize(json => json)

export const ShippingAddressInfoEntity = createEntityFor<ShippingAddressInfo>()
  .withSchema(
    z.object({
      shippingAddressId: z.string(), // DepartmentId
      salutation: z.enum([
        "Mr",
        "Ms",
        "NonBinary",
        "Enterprise",
        "NotSpecified",
        "Family",
        "Club",
      ]),
      firstName: z.ostring(),
      lastName: z.string(),
      city: z.string(),
      street: z.string(),
      streetNumber: z.string(),
      zipCode: z.string(),
      countryCode: z.ostring(),
    }),
  )
  .serialize(data => data)
  .deserialize(json => json)
