import React, { ComponentType, createElement, ReactNode } from "react"

// Given an ordered list of container components (which only take children),
// nests the components inside each other in order, so that the first component
// given is the outer-most (mounted first), and the children is the inner-most (mounted last).
export function ContextStack(props: {
  containers: Array<ComponentType<{ children: ReactNode }>>
  children: ReactNode
}) {
  let element = <>{props.children}</>
  let nextWrapper = props.containers.pop()

  while (nextWrapper) {
    // eslint-disable-next-line react/no-children-prop
    element = createElement(nextWrapper, { children: element })
    nextWrapper = props.containers.pop()
  }

  return element
}
