import { useRecoilValue } from "recoil"

import { backendState, BackendType, baseUrls } from "./backend"
import { frontendState, FrontendType } from "./frontend"

export function useFrontend(): FrontendType {
  return useRecoilValue(frontendState) as FrontendType
}

export function useIsAppFrontend() {
  return useFrontend() == "app"
}

export function useBackend(): BackendType {
  return useRecoilValue(backendState) as BackendType
}

// Hook to get the full backend url
// from an installation-independent path
export function useBackendUrl(path?: string, subdomain?: string) {
  const [protocol, hostname] = baseUrls[useBackend()].split("://")

  if (subdomain) {
    return `${protocol}://${subdomain}.${hostname}${path ?? ""}`
  } else {
    return `${protocol}://${hostname}${path ?? ""}`
  }
}

export function useRksvBaseUrl() {
  return useBackendUrl("", "rksv")
}
