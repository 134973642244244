import { NativeModules } from "react-native"

import { logDebug } from "~shared/feature/Logging/hooks"

// Creates a Proxy of type T from a NativeModule.
// Assuming that all properties on T are functions, these functions are
// directly called on the Native Module, with all inputs and outputs being
// logged.
export function wrapNativeModule<T>(moduleName: string): T {
  const nativeModule = NativeModules[moduleName]

  return new Proxy(
    {},
    {
      get(target: {}, property: string | symbol, receiver: any): any {
        return async function (...args: any[]) {
          const methodName = property.toString()
          logDebug(`[${moduleName}] Calling method '${methodName}' with`, args)
          const result = await nativeModule[property](...args)
          logDebug(`[${moduleName}] Method '${methodName}' returned`, result)
          return result
        }
      },
    },
  ) as T
}

// Creates a Proxy of type T, where all methods on T just throw.
// Used to mock a Native Module where it is not available (i.e. Web or iOS)
export function mockNativeModule<T>(): T {
  return new Proxy(
    {},
    {
      get(target: {}, property: string | symbol, receiver: any): any {
        return async function (...args: any[]) {
          throw Error(
            "The selected integration is currently not supported on this type of device",
          )
        }
      },
    },
  ) as T
}
