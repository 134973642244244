import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { Platform, StyleSheet, useWindowDimensions, View } from "react-native"
import { Animation } from "react-native-animatable"
import { useSafeAreaInsets } from "react-native-safe-area-context"

import {
  useDeviceDependentValue,
  useMountEffect,
  useOnOff,
  useOrientationDependentValue,
  usePubSub,
  useTimeoutEffect,
} from "@axtesys/hooks"
import {
  AnimationContainer,
  Box,
  HoverablePressableOpacity,
  Icon,
  Label,
  MCIcon,
  Row,
  useAnimationController,
  useScreenSize,
  useScreenSizeDependentValue,
  useTheme,
} from "@axtesys/kassen-app-ui"
import { createCustomContext } from "@axtesys/react-tools"

import { useIsAppFrontend } from "../feature/Config/queries"
import { useIsInteractionPrevention } from "../feature/InteractionPrevention/queries"
import { addOrReplaceInList, removeFromList } from "../lib/Collections"

// Public API

export { SnackbarProvider, useSnackbar }

export type SnackbarAction = {
  onPress(): void

  // Do not instantly execute
  // the hiding/fadeOut animation,
  // when onPress is executed.
  disableInstantFadeOut?: boolean
} & ({ text: string } | { icon: MCIcon })

export type Snackbar = {
  message: string
  mode: "success" | "warning" | "info" | "error"

  // An optional key - overrides any existing snackbar with the same key
  key?: string

  // Duration before automatic closure in milliseconds
  duration?: number

  action?: SnackbarAction
}

// This is the public API hook. Consumers can only publish new snackbars.
function useSnackbar() {
  const { showSnackbar } = useSnackbarInternal()
  return { showSnackbar }
}

// The provider renders a SnackbarOverlay over its children.
function SnackbarProvider(props: { children: ReactNode }) {
  return (
    <SnackbarContextProvider>
      {props.children}
      <SnackbarOverlay />
    </SnackbarContextProvider>
  )
}

// Internal API

type SnackbarWithId = Snackbar & { id: number }

// The SnackbarOverlay subscribes to published snack bars and renders them.
function SnackbarOverlay() {
  return (
    <SnackbarOverlayContextProvider>
      <OverlayLayout>
        <StackedSnacks />
      </OverlayLayout>
    </SnackbarOverlayContextProvider>
  )
}

function OverlayLayout(props: { children: ReactNode }) {
  const { width, height } = useWindowDimensions()
  const { snackbarAlignment } = useSnackbarOverlayContext()

  // This fixes an issue (KAPE-684) perceivable in certain web browsers,
  // when applying additional window zoom.
  // Without this correctional value,
  // the whole application window starts to shake/re-render infinitely.
  const webCorrectionValue = Platform.OS == "web" ? 0.5 : 0

  return (
    <View
      pointerEvents="box-none"
      style={{
        position: "absolute",
        width: width - webCorrectionValue,
        height: height - webCorrectionValue,
      }}
    >
      <Box style={[styles.overlay, snackbarAlignment]}>{props.children}</Box>
    </View>
  )
}

function StackedSnacks() {
  const { snackbars } = useSnackbarOverlayContext()

  return (
    <>
      {snackbars.map(snackbar => (
        <SnackbarView key={snackbar.key ?? snackbar.id} snackbar={snackbar} />
      ))}
    </>
  )
}

function SnackbarView(props: { snackbar: SnackbarWithId }) {
  const { message, mode, action, duration } = props.snackbar

  const fontSize = {
    S: { medium: true },
    M: { medium: true },
    L: {},
  }[useScreenSize()]
  const { color } = useTheme()
  const animationConfig = useAnimationConfig()
  const { removeSnackbar } = useSnackbarOverlayContext()
  const [isHovered, onHoverIn, onHoverOut] = useOnOff(false)

  const fadeOut = async (hideInstant?: boolean) => {
    await animationConfig.controllerRef.current?.animateOut(
      hideInstant ? 1 : undefined,
    )
    removeSnackbar(props.snackbar)
  }
  const fadeOutInstantly = async () => fadeOut(true)

  // Automatically remove the Snackbar after a while.
  useTimeoutEffect(duration ?? 4000, fadeOut, [props.snackbar.id])

  const indicator = (
    <Box style={styles.snackbarIndicator} backgroundColor={color[mode]} />
  )

  const snackbarText = (
    <Box expand pad="S">
      <Label text={message} {...fontSize} />
    </Box>
  )

  const actionButton = action ? (
    <Row gap="XXXS">
      <HoverablePressableOpacity
        style={isHovered && { opacity: 0.6 }}
        onHoverIn={onHoverIn}
        onHoverOut={onHoverOut}
        onPress={async () => {
          action.onPress()
          if (action?.disableInstantFadeOut) return
          await fadeOutInstantly()
        }}
      >
        {"text" in action && (
          <Label text={action.text} {...fontSize} bold uppercase color="link" />
        )}
        {"icon" in action && <Icon name={action.icon} />}
      </HoverablePressableOpacity>
    </Row>
  ) : undefined

  const closeIcon = (
    <Box padHorizontal="XS">
      <Icon name="close" onPress={fadeOutInstantly} />
    </Box>
  )

  return (
    <AnimationContainer style={{ width: "100%" }} {...animationConfig}>
      <Row style={styles.snackbar}>
        {indicator}
        {snackbarText}
        {actionButton}
        {closeIcon}
      </Row>
    </AnimationContainer>
  )
}

// Context shared across the application
const [SnackbarContextProvider, useSnackbarInternal] = createCustomContext(
  () => {
    const idRef = useRef(0)
    const { publish, subscribe } = usePubSub<SnackbarWithId>()

    // Add a sequential id to each snackbar published.
    const showSnackbar = useCallback(
      (snackbar: Snackbar) =>
        publish({
          ...snackbar,
          id: idRef.current++,
        }),
      [publish],
    )

    return { showSnackbar, subscribe }
  },
)

// Context shared among the overlay UI
const [SnackbarOverlayContextProvider, useSnackbarOverlayContext] =
  createCustomContext(() => {
    const { top } = useSafeAreaInsets()
    const { subscribe } = useSnackbarInternal()
    const isInteractionPrevention = useIsInteractionPrevention()
    const [snackbars, setSnackbars] = useState<SnackbarWithId[]>([])

    useMountEffect(() => {
      subscribe(snackbar => {
        setSnackbars(list =>
          addOrReplaceInList(
            list,
            snackbar,
            ({ key: keyA }, { key: keyB }) => keyA == keyB,
          ),
        )
      })
    })

    // Automatically remove all snackbars
    // in case the interaction prevention is triggered.
    useEffect(() => {
      if (!isInteractionPrevention) return
      setSnackbars([])
    }, [isInteractionPrevention])

    const phoneAlignment = useMemo(
      () => ({ top, bottom: undefined, alignSelf: "center" }),
      [top],
    )
    const tabletLandscape = useMemo(
      () => ({ ...phoneAlignment, top: 0 }),
      [phoneAlignment],
    )
    const tabletAlignment = useOrientationDependentValue({
      portrait: phoneAlignment,
      landscape: tabletLandscape,
    })
    const snackbarAlignment = useDeviceDependentValue({
      phone: phoneAlignment,
      tablet: tabletAlignment,
      desktop: styles.desktopAlignment,
    })

    const removeSnackbar = useCallback((snackbar: SnackbarWithId) => {
      setSnackbars(list => removeFromList(list, snackbar))
    }, [])

    return { snackbars, snackbarAlignment, removeSnackbar }
  })

function useAnimationConfig() {
  const controllerRef = useAnimationController()
  const screenSizeAppMode = useScreenSizeDependentValue({
    S: true,
    M: true,
    L: false,
  })
  const isAppMode = useIsAppFrontend() && screenSizeAppMode
  const exitAnimation: Animation = isAppMode ? "slideOutUp" : "fadeOut"
  const entryAnimation: Animation =
    // On Android every movement including animation has an issue,
    // leading to touchables that are included
    // inside the AnimationContainer being unusable.
    isAppMode && Platform.OS != "android" ? "slideInDown" : "fadeIn"

  return {
    isAppMode,
    controllerRef,
    exitAnimation,
    entryAnimation,
    exitAnimationDuration: 150,
    entryAnimationDuration: 150,
    mountState: "entry" as "entry",
  }
}

const styles = StyleSheet.create({
  overlay: {
    width: "95%",
    maxWidth: 700,
    position: "absolute",
    alignItems: "center",
    flexDirection: "column-reverse",
  },
  snackbar: {
    marginTop: 5,
    width: "100%",
    borderWidth: 1,
    borderRadius: 8,
    borderColor: "#0002",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "white",

    elevation: 5,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 3.84,
    shadowColor: "#000",
    shadowOpacity: 0.25,
  },
  desktopAlignment: {
    bottom: 20,
    paddingLeft: 15,
    alignSelf: "flex-start",
  },
  snackbarIndicator: {
    position: "absolute",
    top: 0,
    left: 0,
    width: 4,
    bottom: 0,
    marginLeft: -1,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
})
