export const UNAUTHENTICATED = "Unauthenticated"
export const DUPLICATE_COMMAND = "DuplicateCommand"

export const UNEXPECTED_RESPONSE_BODY =
  "Unexpected response body in /graphql endpoint"
export const NO_DATA_ERROR = "GraphQL response did not contain data"
export const QUERY_REQUEST_ERROR = "Query request did not complete as expected"

export const QUERY_RETRY_EXEMPTIONS = [
  NO_DATA_ERROR,
  QUERY_REQUEST_ERROR,
  UNEXPECTED_RESPONSE_BODY,
]
