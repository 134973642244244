import React, { useMemo, useRef } from "react"
import { TextInput as RNTextInput } from "react-native"

import { isEmptyOrBlank } from "@axtesys/react-tools"

import { useScreenSize } from "../hooks/useScreenSize"
import { useTheme } from "../theme"
import { TextInput } from "./field/TextInput"

export type SearchBarProps = {
  value: string
  onChange(value: string): void

  placeholder?: string
  includeLeftPadding?: boolean
}

const HEIGHT = 48

export function SearchBar(props: SearchBarProps) {
  const { fontSize } = useTheme()
  const screenSize = useScreenSize()
  const ref = useRef<RNTextInput>(null)

  const { value, placeholder, includeLeftPadding, onChange } = props

  // Custom width of search bar depending
  // on the screen size of the used device
  const width = useMemo(() => {
    const searchbarWidth = {
      S: 300,
      M: 300,
      L: 400,
    }
    return searchbarWidth[screenSize]
  }, [screenSize])

  const clearText = () => {
    onChange("")
    ref.current?.blur()
  }

  const style = {
    width,
    height: HEIGHT,
    fontSize: fontSize.medium,
    paddingLeft: includeLeftPadding ? 10 : 0,
  }

  const isCleared = isEmptyOrBlank(value)

  return (
    <TextInput
      style={style}
      value={value}
      placeholder={placeholder}
      iconRight={{
        name: isCleared ? "magnify" : "close",
        onPress: !isCleared ? clearText : undefined,
      }}
      containerStyle={{ height: HEIGHT, paddingRight: 10 }}
      onChange={onChange}
    />
  )
}
