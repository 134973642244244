import * as z from "zod"

import { createEntityFor } from "@axtesys/react-tools"

import {
  ArticleKeyContent,
  ArticleKeyContentEntity,
  ArticleKeyId,
} from "./ArticleKey"

export type KeysSubGroupId = string

export type KeysSubGroupContent = {
  keysSubGroupId: KeysSubGroupId
  name: string
  color: string
  orderIndex: number
  articleKeys: ArticleKeyContent[]
}

export type KeysSubGroupBasicInfo = Pick<KeysSubGroupContent, "name" | "color">

export type KeysSubGroupData = KeysSubGroupBasicInfo & {
  articleKeys?: ArticleKeyId[]
  keysMainGroupId?: string
}

export type KeysSubGroupDataWithId = KeysSubGroupData & {
  keysSubGroupId: KeysSubGroupId
}

export const SpecificKeysSubGroupContentEntity =
  createEntityFor<KeysSubGroupContent>()
    .withSchema(
      z.object({
        keysSubGroupId: z.string(),
        name: z.string(),
        color: z.string(),
        orderIndex: z.number(),
        articleKeys: z.array(ArticleKeyContentEntity.schema),
      }),
    )
    .serialize(data => ({
      ...data,
      articleKeys: data.articleKeys.map(ArticleKeyContentEntity.serialize),
    }))
    .deserialize(json => ({
      ...json,
      articleKeys: json.articleKeys.map(ArticleKeyContentEntity.deserialize),
    }))
