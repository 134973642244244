import React, { ReactNode, useCallback } from "react"
import { StyleProp, ViewStyle } from "react-native"

import { useSelection } from "~shared/components/SelectionContext"

import { MCIcon } from "../MCIcon"
import { Button } from "../button/Button"
import { Icon } from "../display/Icon"
import { Box } from "../layout/Box"
import { Row } from "../layout/FlexBox"
import { Gap } from "../layout/Gap"
import { Spacer } from "../layout/Spacer"
import {
  DialogContextProvider,
  useDialogContext,
} from "../providers/DialogContextProvider"
import { useTheme } from "../theme"
import { ResponsiveSpacing } from "../types"
import { Label } from "../typography/Label"
import { Overlay } from "./Overlay"

// A Dialog has can have a title, content and one or two actions.
// The content can be text or any React Node.
type DialogProps = {
  primaryAction: DialogAction
  children: string | ReactNode

  title?: string
  showCloseIcon?: boolean
  style?: StyleProp<ViewStyle>
  actionGap?: ResponsiveSpacing
  secondaryAction?: DialogAction
  onCloseIconPress?: () => void
}

type DialogAction = {
  label: string

  icon?: MCIcon
  disabled?: boolean

  onPress?(): void | boolean | Promise<void> | Promise<boolean>
}

export function useDialogFunctions() {
  const { showDialog, closeTopDialog } = useDialogContext()

  return { showDialog, closeTopDialog }
}

export function useDialog() {
  const { showDialog, closeTopDialog } = useDialogContext()

  // In case you only need a simple dialog with Ok and Cancel buttons, use this one.
  const showDialogOkCancel = useCallback(
    (props: { text: string; title?: string }) =>
      new Promise<boolean>(resolve => {
        showDialog(
          <Dialog
            title={props.title}
            primaryAction={{
              label: "Bestätigen",
              onPress: () => {
                resolve(true)
              },
            }}
            secondaryAction={{
              label: "Abbrechen",
              onPress: () => {
                resolve(false)
              },
            }}
          >
            {props.text}
          </Dialog>,
        )
      }),
    [showDialog],
  )

  // In case you only need a simple dialog with one button, use this one.
  const showDialogOk = useCallback(
    (props: {
      text: string
      title?: string
      showCloseIcon?: boolean
      buttonLabel?: string
    }) =>
      new Promise<boolean>(resolve => {
        showDialog(
          <Dialog
            title={props.title}
            showCloseIcon={props.showCloseIcon}
            primaryAction={{
              label: props.buttonLabel ?? "Verstanden",
              onPress: () => resolve(true),
            }}
          >
            {props.text}
          </Dialog>,
        )
      }),
    [showDialog],
  )

  return { showDialog, showDialogOkCancel, showDialogOk, closeTopDialog }
}

export function Dialog(props: DialogProps) {
  const {
    style,
    children,
    actionGap,
    primaryAction,
    secondaryAction,
    showCloseIcon = true,
    onCloseIconPress,
  } = props

  const { color } = useTheme()
  const { closeTopDialog } = useDialogContext()

  const performAction = useCallback(
    async (action: DialogAction) => {
      const result = await action.onPress?.()
      if (result != false) closeTopDialog()
    },
    [closeTopDialog],
  )

  const onClosePress = showCloseIcon
    ? () => {
        if (!onCloseIconPress) closeTopDialog()
        else onCloseIconPress?.()
      }
    : undefined

  const closeIcon = showCloseIcon && (
    <Row style={{ position: "absolute", right: 16, top: 16 }}>
      <Icon name="close" color="base3" onPress={onClosePress} />
    </Row>
  )

  const title = props.title ? <Label h4 text={props.title} /> : null

  const content =
    typeof children == "string" ? <Label text={children} /> : children

  const primaryButton = (
    <Button
      icon={primaryAction.icon}
      text={primaryAction.label}
      disabled={primaryAction.disabled}
      onPress={() => performAction(primaryAction)}
    />
  )

  const secondaryButton = secondaryAction && (
    <Button
      outlined
      icon={secondaryAction.icon}
      text={secondaryAction.label}
      disabled={secondaryAction.disabled}
      onPress={() => performAction(secondaryAction)}
    />
  )

  const buttonRow = (
    <Row gap="XS">
      <Spacer />
      {secondaryButton}
      {primaryButton}
    </Row>
  )

  return (
    <Box
      padTop="SM"
      padLeft="S"
      padRight="S"
      padBottom="S"
      elevation={20}
      borderRadius="L"
      borderColor={color.primary}
      backgroundColor={color.background}
      style={[{ width: "100%", maxWidth: 800 }, style]}
    >
      {closeIcon}
      {title}
      <Gap vertical="S" />
      {content}
      <Gap vertical={actionGap ?? "XL"} />
      {buttonRow}
    </Box>
  )
}

export function DialogProvider(props: { children: ReactNode }) {
  return (
    <DialogContextProvider>
      <DialogOverlay />
      {props.children}
    </DialogContextProvider>
  )
}

function DialogOverlay() {
  const { activeDialogs } = useDialogContext()
  const { selectionInProgress } = useSelection()
  const activeDialogsLength = activeDialogs.length

  if (activeDialogsLength <= 0) return null

  const dialogOverlays = activeDialogs.map((node, index) => {
    const isNotLastElement = index != activeDialogsLength - 1

    return (
      <Overlay
        zIndex={100}
        centerContent
        key={`activeDialog_${index}`}
        noBackdrop={isNotLastElement}
      >
        <Box
          pad="XS"
          style={{
            alignItems: "center",
            justifyContent: "center",
            display:
              isNotLastElement || selectionInProgress ? "none" : undefined,
          }}
        >
          {node}
        </Box>
      </Overlay>
    )
  })

  return <>{dialogOverlays}</>
}
