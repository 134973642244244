import { useRef } from "react"

// Make sure we're re-using the same object instance of `value` if
// it didn't change using a custom isEqual function.
// Used to avoid unnessecary re-renders.
export function useCustomEqual<T>(
  value: T,
  isEqual: (previous: T, next: T) => boolean,
) {
  const valueRef = useRef(value)
  if (!isEqual(valueRef.current, value)) {
    valueRef.current = value
  }
  return valueRef.current
}
