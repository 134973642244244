import React, { ReactNode } from "react"
import { SafeAreaProvider } from "react-native-safe-area-context"
import { RecoilRoot } from "recoil"

import { DeviceInfoProvider, NetworkStatusProvider } from "@axtesys/hooks"
import { DialogProvider } from "@axtesys/kassen-app-ui"

import { GraphQLContextProvider } from "./api/graphql"
import { ReactQueryProvider } from "./api/graphql/components"
import { AbsoluteAccessorProvider } from "./components/AbsoluteAccessorContext"
import { ContextStack } from "./components/ContextStack"
import { SelectionProvider } from "./components/SelectionContext"
import { JsonDbLoadingGateProvider } from "./components/providers/JsonDbLoadingGateProvider"
import { StyleProvider } from "./components/providers/StyleProvider"
import { SuspenseProvider } from "./components/providers/SuspenseProvider"
import { SnackbarProvider } from "./contexts/SnackbarContext"
import { AuthenticationProvider } from "./feature/Authentication"
import { SimpleConfirmDialogProvider } from "./feature/Dialog"
import { InteractionPreventionProvider } from "./feature/InteractionPrevention"
import { LogProvider } from "./feature/Logging"

export function SharedContext(props: { children: ReactNode }) {
  return (
    <ContextStack
      containers={[
        // Provides recoil.js hooks
        RecoilRoot,

        // Provides functionality for `react-native-safe-area-context` components
        SafeAreaProvider,

        // Provides top-level suspense functionality for other contexts
        SuspenseProvider,

        // Preloads all JsonDb atoms
        JsonDbLoadingGateProvider,

        // Presets severity and enabled state of the logging logic
        LogProvider,

        // Provides `useDeviceInfo` hook
        DeviceInfoProvider,

        // Provides `useNetworkStatus` hook
        NetworkStatusProvider,

        // Provides functionality for the usage of react-query
        ReactQueryProvider,

        // Loads fonts and configures kassen-app-ui
        StyleProvider,

        // Provides `useSnackbar` hook
        SnackbarProvider,

        // Provides the possibility to block user input
        InteractionPreventionProvider,

        // Provides `useSelection` hook
        SelectionProvider,

        // Provides `useSimpleConfirmDialog` hook
        SimpleConfirmDialogProvider,

        // Provides functionality regarding the login state of the user
        AuthenticationProvider,

        // Provides `useSuspenseQuery` and `useGraphQL` hooks
        GraphQLContextProvider,

        // Provides `useDialog` hook
        DialogProvider,

        // Provides `useAbsoluteAccessor` hook
        AbsoluteAccessorProvider,
      ]}
    >
      {props.children}
    </ContextStack>
  )
}
