import { useEffect } from "react"

// Like useEffect, but will only ever run once on mount.
// Use this if you don't care for change tracking.
export function useMountEffect(effect: () => any | Promise<any> | Function) {
  useEffect(() => {
    const result = effect()
    if (typeof result == "function") return result
    else return undefined
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
