import React, { useMemo } from "react"
import MaterialCommunityIcon from "react-native-vector-icons/MaterialCommunityIcons"

import { MCIcon } from "../MCIcon"
import { useColor } from "../hooks/useColor"
import { Box } from "../layout/Box"
import { Color } from "../types"

type PaddingSpacing = "XS" | "XXS" | "XXXS" | "XXXXS"
export type IconSize = "XS" | "SX" | "S" | "SM" | "M" | "L" | "XL"

type IconProps = {
  name: MCIcon

  color?: Color
  size?: IconSize
  disabled?: boolean
  disabledColor?: Color

  // Only useful when onPress is not undefined
  pad?: PaddingSpacing
  padVertical?: PaddingSpacing
  padHorizontal?: PaddingSpacing
  onPress?(): void
}

export function Icon(props: IconProps) {
  const color = useColor(
    !props.disabled
      ? props.color ?? "default"
      : props.disabledColor ?? "disabled",
  )

  const spacing = useMemo(
    () => ({
      XS: 16,
      SX: 20,
      S: 24,
      SM: 28,
      M: 32,
      L: 38,
      XL: 48,
    }),
    [],
  )

  const icon = (
    <MaterialCommunityIcon
      color={color}
      name={props.name}
      size={spacing[props.size ?? "S"]}
    />
  )

  const isRenderBox =
    props.pad ||
    props.disabled ||
    props.onPress ||
    props.padVertical ||
    props.padHorizontal

  return isRenderBox ? (
    <Box
      pad={props.pad}
      disabled={props.disabled}
      padVertical={props.padVertical}
      padHorizontal={props.padHorizontal}
      onPress={props.onPress}
    >
      {icon}
    </Box>
  ) : (
    icon
  )
}
