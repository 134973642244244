import { useState } from "react"

type ToggleValue = () => void
type SetValue = (value: boolean) => void

export function useToggle(
  initialValue: boolean,
): [boolean, ToggleValue, SetValue] {
  const [value, setValue] = useState(initialValue)
  const toggleValue = () => setValue(!value)
  return [value, toggleValue, setValue]
}
