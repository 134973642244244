import React, { useCallback } from "react"
import { useRecoilState, useRecoilValue } from "recoil"

import { DateTimeInput } from "@axtesys/kassen-app-ui"

import { dateTimePickerConfigState, dateTimePickerVisibility } from "./state"

export function DateTimePickerOverlay() {
  const config = useRecoilValue(dateTimePickerConfigState)
  const [isVisible, setIsVisible] = useRecoilState(dateTimePickerVisibility)
  const dismiss = useCallback(() => setIsVisible(false), [setIsVisible])

  if (!config) return null

  return (
    <DateTimeInput
      {...config}
      overlayMode
      visible={isVisible}
      onClose={dismiss}
    />
  )
}
