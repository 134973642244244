import React from "react"
import MaterialCommunityIcon from "react-native-vector-icons/MaterialCommunityIcons"

import { Grid, GridProps } from "../../container/Grid"
import { Box } from "../../layout/Box"
import { TextInputProps } from "./TextInput"

type ColorPaletteInputProps = Pick<TextInputProps, "value" | "onChange"> &
  Omit<GridProps, "children"> & {
    numCols: number
    colors: string[]
    colorItemSize?: number
  }

export function ColorPaletteInput(props: ColorPaletteInputProps) {
  const {
    gap,
    value,
    colors,
    numCols,
    verticalGap,
    horizontalGap,
    colorItemSize,
    onChange,
  } = props

  return (
    <Grid
      gap={gap}
      alignRowsCenter
      numCols={numCols}
      verticalGap={verticalGap}
      horizontalGap={horizontalGap}
    >
      {colors.map(color => (
        <PaletteItem
          key={color}
          color={color}
          size={colorItemSize}
          isSelected={color == value}
          onChange={onChange}
        />
      ))}
    </Grid>
  )
}

type PaletteItemProps = Pick<ColorPaletteInputProps, "onChange"> & {
  color: string
  size?: number
  isSelected?: boolean
}

function PaletteItem(props: PaletteItemProps) {
  const { size, color, isSelected, onChange } = props

  const itemSize = size ?? 36

  return (
    <Box
      style={{ width: itemSize, height: itemSize }}
      onPress={() => onChange?.(color)}
    >
      <MaterialCommunityIcon
        color={color}
        size={itemSize}
        name={isSelected ? "check-circle" : "circle"}
      />
    </Box>
  )
}
