import React, { ReactNode } from "react"
import { Pressable, StyleSheet, View } from "react-native"

export type OverlayProps = {
  children: ReactNode

  zIndex?: number
  noBackdrop?: boolean
  centerContent?: boolean

  onPressOutside?(): void
}

export function Overlay({
  zIndex,
  children,
  noBackdrop,
  centerContent,
  onPressOutside,
}: OverlayProps) {
  return (
    <View
      style={[
        styles.fullscreen,
        !noBackdrop && styles.backdrop,
        centerContent && styles.centerContent,
        { zIndex },
      ]}
    >
      {onPressOutside && (
        <Pressable style={styles.fullscreen} onPress={onPressOutside} />
      )}

      {children}
    </View>
  )
}

const styles = StyleSheet.create({
  fullscreen: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: "absolute",
  },
  backdrop: { backgroundColor: "#0008" },
  centerContent: { justifyContent: "center" },
})
