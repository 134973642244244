import Big from "big.js"
import { useRef } from "react"

// Makes sure we're re-using the same Big instance if the value didn't change,
// to avoid unnessecary re-renders.
export function useBigMemo(value: Big): Big {
  const valueRef = useRef(value)
  if (!valueRef.current.eq(value)) {
    valueRef.current = value
  }
  return valueRef.current
}
