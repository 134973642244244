import Big from "big.js"
import { isEqual, last, map, mapValues, maxBy, orderBy, uniqBy } from "lodash"
import { DefaultValue } from "recoil"
import * as z from "zod"

import { jsonDbAtom } from "@axtesys/react-tools"

import { authenticatedUserState } from "~shared/feature/Authentication"
import { sumBigArray } from "~shared/lib/Big"
import {
  selector,
  selectorFamily,
  selectorFamilyReadOnly,
} from "~shared/lib/recoil/lib"
import {
  BillingAddress,
  CartDiscountEntity,
  CartDiscountHistoryEntry,
  ContactId,
  DepartmentId,
  DepartmentInfo,
  InvoiceItem,
  InvoiceItemEntity,
  InvoiceItemId,
  ShippingAddressId,
  ShippingAddressInfo,
} from "~shared/types"

import { contactsState } from "../OfflineData/state"
import {
  evaluateGrossPositionTotal,
  evaluateInvoiceItems,
  transformToCartDiscount,
} from "./lib"

// Cart items

// A history of all invoice item configurations /
// modifications throughout a shopping cart.
export const cartItemsHistoryState = jsonDbAtom({
  key: "cartItemsHistory",
  default: [] as InvoiceItem[],
  schema: z.array(InvoiceItemEntity.schema),
  serialize: data => map(data, InvoiceItemEntity.serialize),
  deserialize: json => map(json, InvoiceItemEntity.deserialize),
})

// All current / distinct InvoiceItems
// which are currently included in the shopping cart
export const cartItemsSelector = selector({
  key: "cartItems",
  get: ({ get }) => {
    const cartItemsHistory = get(cartItemsHistoryState)

    // First, orderBy modification date in order to
    // get the unique / distinct current state of the shopping cart
    // After that orderBy creation date
    // to preserve the addition order in the shopping cart
    // (otherwise items would frequently switch positions around)
    return orderBy(
      uniqBy(
        orderBy(cartItemsHistory, item => item.modifiedAt, ["desc"]),
        item => item.invoiceItemId,
      ),
      item => item.createdAt,
    )
  },
})

// Only those items in cart that can receive discounts
export const cartItemsDiscountableSelector = selector({
  key: "cartItemsDiscountable",
  get: ({ get }) => {
    const cartItems = get(cartItemsSelector)

    return cartItems.filter(
      item =>
        // The cart discount can only be applied to items that
        // - got a positive article price and
        // - a positive amount and
        // - are discountable
        item.article.price.gt(0) &&
        item.amount.gt(0) &&
        !item.isDiscountBlocked,
    )
  },
})

export const discountableCartItemsPresentSelector = selector({
  key: "discountableCartItemsPresent",
  get: ({ get }) => get(cartItemsDiscountableSelector).length > 0,
})

// Fully evaluated cart items (including all discounts)
export const evaluatedCartItemsSelector = selector({
  key: "evaluatedCartItems",
  get: ({ get }) => {
    // Only non-zero amount items should be included
    // in the receipt and all calculations
    const nonZeroItems = get(cartItemsSelector).filter(
      item => !item.amount.eq(0),
    )

    // In case of absolute cart discounts convert them to fixed discount rates
    const cartDiscountTransformed = transformToCartDiscount(
      get(cartItemsWithItemDiscountDiscountableTotalSelector),
      get(cartDiscountSelector),
    )

    // Retrieve all items with pre-calculated discount values (cart and individual) and taxInfo
    return evaluateInvoiceItems(nonZeroItems, cartDiscountTransformed)
  },
})

// Single item in shopping cart
// (memoized, prefer to use this if you only need a single item).
export const cartItemState = selectorFamily<InvoiceItem, InvoiceItemId>({
  key: "cartItem",
  get:
    invoiceItemId =>
    ({ get }) =>
      get(cartItemsSelector).find(item => item.invoiceItemId == invoiceItemId)!,
  set:
    () =>
    ({ set }, newValue) =>
      // Implement a setter: When we set a single InvoiceItem,
      // it will be replaced in `itemsInCartState`.
      set(cartItemsHistoryState, historicCartItems => {
        const newInvoiceItem = newValue as InvoiceItem
        const isDefault = newValue instanceof DefaultValue
        const updatedItemsInCart = [...historicCartItems] as InvoiceItem[]

        if (!isDefault)
          updatedItemsInCart.push({ ...newInvoiceItem, modifiedAt: new Date() })

        return updatedItemsInCart
      }),
})

export const cartItemMinMaxAmountSelector = selectorFamilyReadOnly<
  { minAmount: Big; maxAmount: Big },
  InvoiceItemId
>({
  key: "cartItemMinMaxAmount",
  get:
    invoiceItemId =>
    ({ get }) => {
      const refundInfo = get(cartRefundInfoState)
      const { isNegativeAmount } = get(cartItemState(invoiceItemId))
      const maxRefundableAmount =
        refundInfo?.invoiceItemAmounts?.[invoiceItemId]

      let minAmount = Big(-99999.999)
      let maxAmount = Big(99999.999)

      if (isNegativeAmount) {
        maxAmount = Big(0)
      } else {
        minAmount = Big(0)
      }

      if (maxRefundableAmount) {
        // we are allowed to go toward zero, so
        // if maxAmount < 0, we can go negative, but not lower than max
        // if maxAmount > 0, we can go positive, but not higher than max
        if (maxRefundableAmount.lt(0)) {
          minAmount = maxRefundableAmount
        } else {
          maxAmount = maxRefundableAmount
        }
      }

      return { minAmount, maxAmount }
    },
})

// Number of items in cart, with an amount > 0.
// This not the same as the total amount of articles!
export const cartItemCountSelector = selector({
  key: "cartItemCount",
  get: ({ get }) =>
    get(cartItemsSelector).filter(item => item.amount.gt(0)).length,
})

export const cartItemsPresentSelector = selector({
  key: "cartItemsPresent",
  get: ({ get }) => get(cartItemsSelector).length > 0,
})

// Cart discount

export const cartDiscountHistoryState = jsonDbAtom({
  key: "cartDiscountHistory",
  default: [] as CartDiscountHistoryEntry[],
  schema: z.array(CartDiscountEntity.schema),
  serialize: data => map(data, CartDiscountEntity.serialize),
  deserialize: json => map(json, CartDiscountEntity.deserialize),
})

// The latest cartDiscountEntry from the cartDiscountHistoryState
export const cartDiscountSelector = selector({
  key: "cartDiscount",
  get: ({ get }) =>
    get(discountableCartItemsPresentSelector)
      ? last(
          maxBy(
            get(cartDiscountHistoryState),
            discountEntry => discountEntry.createdAt,
          )?.discounts,
        )
      : undefined,
})

// Represents the absolute cart discount amount,
// if there is a cart discount active.
export const cartDiscountAbsoluteSelector = selector({
  key: "cartDiscountAbsolute",
  get: ({ get }) => {
    const cartDiscount = get(cartDiscountSelector)

    return !cartDiscount?.absolute
      ? cartDiscount?.percent
        ? get(cartItemsWithItemDiscountDiscountableTotalSelector).times(
            cartDiscount?.percent.div(100),
          )
        : undefined
      : cartDiscount.absolute
  },
})

// Maximum allowed cart discount
export const cartDiscountMaxSelector = selector<{
  absolute: Big
  percent: Big
  status:
    | "can receive discount"
    | "cart is empty"
    | "missing permissions"
    | "no discountable items"
    | "no discounts in refund mode"
}>({
  key: "cartDiscountMax",
  get: ({ get }) => {
    const cartItems = get(cartItemsSelector)
    const discountableCartItemsPresent = get(
      discountableCartItemsPresentSelector,
    )
    const refundMode = get(refundModeSelector)
    const permissions = get(authenticatedUserState)?.permissions
    const discountableCartTotalWithItemDiscount = get(
      cartItemsWithItemDiscountDiscountableTotalSelector,
    )

    if (refundMode)
      return {
        absolute: Big(0),
        percent: Big(0),
        status: "no discounts in refund mode",
      }
    if (cartItems.length == 0)
      return {
        absolute: Big(0),
        percent: Big(0),
        status: "cart is empty",
      }
    if (!permissions?.grantDiscount)
      return {
        absolute: Big(0),
        percent: Big(0),
        status: "missing permissions",
      }
    if (!discountableCartItemsPresent)
      return {
        absolute: Big(0),
        percent: Big(0),
        status: "no discountable items",
      }

    // We can only grant that so discount that all
    // discountable items are set to zero (which will be a discount of 100%).
    let absolute = discountableCartTotalWithItemDiscount
    let percent = Big(100)

    if (percent.gt(permissions?.grantDiscount.maxPercent)) {
      // If the operator does not have the rights
      // to grant a discount this large, we have to reduce it accordingly.
      const factor = Big(permissions?.grantDiscount.maxPercent).div(percent)
      absolute = absolute.mul(factor)
      percent = percent.mul(factor)
    }

    return { absolute, percent, status: "can receive discount" }
  },
})

export const cartDiscountPresentSelector = selector({
  key: "cartDiscountPresent",
  get: ({ get }) => get(cartDiscountSelector) != undefined,
})

// Total calculations

// Represents the total of all items in cart (including invoice item discounts)
export const cartItemsWithItemDiscountTotalSelector = selector({
  key: "cartItemsWithItemDiscountTotal",
  get: ({ get }) => {
    let total = sumBigArray(
      get(cartItemsSelector).map(
        item => evaluateGrossPositionTotal(item).gross,
      ),
    )

    // In this special case,
    // if there is a cart refund with a roundingDifference present
    // (because the original receipt also had one),
    // in progress, it is required to account for it again.
    const cartRefundInfo = get(cartRefundInfoState)
    total =
      cartRefundInfo && cartRefundInfo.roundingDifference
        ? total.minus(cartRefundInfo.roundingDifference)
        : total

    return total
  },
})

// Represents the total of all items in cart (including invoice item discounts)
// which are allowed to be discountable
export const cartItemsWithItemDiscountDiscountableTotalSelector = selector({
  key: "cartItemsWithItemDiscountDiscountableTotal",
  get: ({ get }) =>
    sumBigArray(
      get(cartItemsDiscountableSelector).map(
        item => evaluateGrossPositionTotal(item).gross,
      ),
    ),
})

// Represents the total of the
// cart / invoice / receipt including both invoice item and cart discounts.
export const cartItemsWithAllDiscountsTotalSelector = selector({
  key: "cartItemsWithAllDiscountsTotal",
  get: ({ get }) => {
    const cartTotalWithItemDiscount = get(
      cartItemsWithItemDiscountTotalSelector,
    )
    const absoluteCartDiscount = get(cartDiscountAbsoluteSelector)

    return !absoluteCartDiscount
      ? cartTotalWithItemDiscount
      : cartTotalWithItemDiscount.minus(absoluteCartDiscount).round(2)
  },
})

// Represents the possible difference, when a cart discount is applied.
export const cartTotalRoundingDifferenceSelector = selector({
  key: "cartTotalRoundingDifference",
  get: ({ get }) => {
    const cartRefundInfo = get(cartRefundInfoState)
    if (cartRefundInfo && cartRefundInfo.roundingDifference)
      return cartRefundInfo.roundingDifference

    // rounding difference =
    // evaluated real total (mathematically correct) -
    // actual total (forced by operator and requirements)
    return sumBigArray(
      get(evaluatedCartItemsSelector).map(item => item.taxInfo.gross),
    ).minus(get(cartItemsWithAllDiscountsTotalSelector))
  },
})

// Contact handling

export const currentInvoiceContactIdState = jsonDbAtom({
  key: "currentInvoiceContactId",
  default: undefined as ContactId | undefined,
  schema: z.optional(z.string()),
})

export const currentInvoiceContactSelector = selector({
  key: "currentInvoiceContact",
  get: ({ get }) => {
    const contactId = get(currentInvoiceContactIdState)

    if (!contactId) return undefined

    return get(contactsState)[contactId]
  },
})

export const contactAssignedSelector = selector({
  key: "contactAssignedToInvoice",
  get: ({ get }) => get(currentInvoiceContactIdState) != undefined,
})

export const currentInvoiceContactBillingAddressSelector = selector<
  BillingAddress | undefined
>({
  key: "currentInvoiceContactBillingAddress",
  get: ({ get }) => {
    const contact = get(currentInvoiceContactSelector)

    if (!contact) return undefined

    return {
      contactId: contact.contactId,
      salutation: contact.salutation,
      firstName: contact.firstName,
      lastName: contact.lastName,
      street: contact.street,
      streetNumber: contact.streetNumber,
      zipCode: contact.zipCode,
      city: contact.city,
      countryCode: contact.countryCode,
    }
  },
})

export const currentInvoiceDepartmentIdState = jsonDbAtom({
  key: "currentInvoiceDepartmentId",
  default: undefined as DepartmentId | undefined,
  schema: z.optional(z.string()),
})

export const currentInvoiceDepartmentSelector = selector({
  key: "currentInvoiceDepartment",
  get: ({ get }) => {
    const contact = get(currentInvoiceContactSelector)
    const departmentId = get(currentInvoiceDepartmentIdState)

    if (!contact || !departmentId) return undefined

    return contact.departments?.[departmentId]
  },
})

export const currentInvoiceDepartmentInfoSelector = selector<
  DepartmentInfo | undefined
>({
  key: "currentInvoiceDepartmentInfo",
  get: ({ get }) => {
    const department = get(currentInvoiceDepartmentSelector)

    if (!department) return undefined

    return {
      departmentId: department.departmentId,
      name: department.name,
    }
  },
})

export const currentInvoiceDepartmentNameSelector = selector({
  key: "currentInvoiceDepartmentName",
  get: ({ get }) => {
    const department = get(currentInvoiceDepartmentSelector)

    if (!department) return undefined

    return department.name
  },
})

export const currentInvoiceShippingAddressIdState = jsonDbAtom({
  key: "currentInvoiceShippingAddressId",
  default: undefined as ShippingAddressId | undefined,
  schema: z.optional(z.string()),
})

export const currentInvoiceShippingAddressSelector = selector({
  key: "currentInvoiceShippingAddress",
  get: ({ get }) => {
    const contact = get(currentInvoiceContactSelector)
    const shippingAddressId = get(currentInvoiceShippingAddressIdState)

    if (!contact || !shippingAddressId) return undefined

    return contact.shippingAddresses?.[shippingAddressId]
  },
})

export const currentInvoiceShippingAddressInfoSelector = selector<
  ShippingAddressInfo | undefined
>({
  key: "currentInvoiceShippingAddressInfo",
  get: ({ get }) => {
    const shippingAddress = get(currentInvoiceShippingAddressSelector)

    if (!shippingAddress) return undefined

    return {
      shippingAddressId: shippingAddress.shippingAddressId,
      salutation: shippingAddress.salutation,
      firstName: shippingAddress.firstName,
      lastName: shippingAddress.lastName,
      street: shippingAddress.street,
      streetNumber: shippingAddress.streetNumber,
      zipCode: shippingAddress.zipCode,
      city: shippingAddress.city,
      countryCode: shippingAddress.countryCode,
    }
  },
})

export const currentContactCartDiscountSelector = selector({
  key: "currentInvoiceContactCartDiscount",
  get: ({ get }) => {
    const contact = get(currentInvoiceContactSelector)

    if (!contact) return undefined

    return contact.cartDiscount
  },
})

// States whether the current cartDiscount
// is equal to the currently selected contact's cartDiscount
export const currentCartDiscountContactDiscountSelector = selector({
  key: "currentCartDiscountContactDiscount",
  get: ({ get }) => {
    const contact = get(currentInvoiceContactSelector)
    if (!contact) return false

    const cartDiscount = get(cartDiscountSelector)
    if (!cartDiscount) return false

    return isEqual(contact.cartDiscount, cartDiscount)
  },
})

export const currentInvoiceContactTextSelector = selector({
  key: "currentInvoiceContactText",
  get: ({ get }) => {
    const contact = get(currentInvoiceContactSelector)

    if (!contact) return undefined

    return contact.printOnReceipt ? contact.receiptText : undefined
  },
})

// Cart status

// High level description of the cart state.
// Whether, we can we proceed to payment or not.
export const cartStatusSelector = selector({
  key: "cartStatus",
  get: ({ get }) => {
    const items = get(cartItemsSelector)
    const nonVoidedItemCount = items.filter(
      invoiceItem => !invoiceItem.amount.eq(0),
    ).length

    // A cart without non-voided items cannot be paid for
    if (nonVoidedItemCount == 0) return "no items"

    // The discount granted in the cart could exceed the allowed maximum
    const cartTotalWithItemDiscount = get(
      cartItemsWithItemDiscountTotalSelector,
    )
    const cartTotalWithAllDiscounts = get(
      cartItemsWithAllDiscountsTotalSelector,
    )
    const cartDiscountMax = get(cartDiscountMaxSelector)
    const effectiveDiscount = cartTotalWithItemDiscount.minus(
      cartTotalWithAllDiscounts,
    )

    if (effectiveDiscount.gt(cartDiscountMax.absolute))
      return "invalid discount"

    return cartTotalWithAllDiscounts.gte(0)
      ? "ready for payment"
      : "ready for payout"
  },
})

// If we are in refund mode, we remember the maximum (inverted)
// amount of each invoiceItem in the original receipt,
// to avoid adding any more items that
// were not present in the receipt that we're refunding.
export const cartRefundInfoState = jsonDbAtom({
  key: "cartRefundInfo",
  default: undefined as
    | undefined
    | {
        originalReceiptId: string
        invoiceItemAmounts: { [invoiceItemId: string]: Big }
        roundingDifference?: Big
      },
  schema: z
    .object({
      originalReceiptId: z.string(),
      invoiceItemAmounts: z.record(z.number()),
      roundingDifference: z.ostring(),
    })
    .nullable()
    .optional(),
  serialize: data =>
    data
      ? {
          originalReceiptId: data.originalReceiptId,
          invoiceItemAmounts: mapValues(data.invoiceItemAmounts, value =>
            value.toNumber(),
          ),
          roundingDifference: data.roundingDifference?.toString(),
        }
      : undefined,
  deserialize: json =>
    json
      ? {
          originalReceiptId: json.originalReceiptId,
          invoiceItemAmounts: mapValues(json.invoiceItemAmounts, value =>
            Big(value),
          ),
          roundingDifference: json.roundingDifference
            ? Big(json.roundingDifference)
            : undefined,
        }
      : undefined,
})

// Flag that indicates whether the shopping cart is in refund mode.
export const refundModeSelector = selector({
  key: "refundMode",
  get: ({ get }) => get(cartRefundInfoState) != undefined,
})
