import React, { ReactNode } from "react"
import { ActivityIndicator } from "react-native"

import { MCIcon } from "../MCIcon"
import { Icon } from "../display/Icon"
import { useColor } from "../hooks/useColor"
import { Box } from "../layout/Box"
import { Column } from "../layout/FlexBox"
import { Color } from "../types"
import { Label } from "../typography/Label"

export type IconButtonProps = {
  icon: MCIcon
  size: "XS" | "S" | "M" | "L" | "XL"

  label?: string

  // If set to true, an ActivityIndicator
  // will be displayed in the top right corner
  // of the button.
  // This always supersedes a potential cornerElement.
  pending?: boolean

  disabled?: boolean
  labelColor?: Color
  backgroundColor?: Color

  // Element to be displayed
  // in the top right corner of the button
  cornerElement?: ReactNode

  onPress?: () => void
}

export function IconButton(props: IconButtonProps) {
  const labelColor = props.labelColor ? props.labelColor : "background"
  const labelColorCode = useColor(labelColor)

  const buttonColor = useColor(
    props.backgroundColor ? props.backgroundColor : "primary",
  )

  const cornerElement = (props.pending || props.cornerElement) && (
    <Box
      style={{
        top: 5,
        right: 5,
        zIndex: 2,
        position: "absolute",
      }}
    >
      {props.pending && <ActivityIndicator color={labelColorCode} />}
      {!props.pending && props.cornerElement}
    </Box>
  )

  const icon = <Icon name={props.icon} size={props.size} color={labelColor} />

  const label = props.label ? (
    <Label text={props.label} bold singleLine color={labelColor} />
  ) : null

  return (
    <>
      {cornerElement}
      <Column
        pad="XXXS"
        alignCenter
        backgroundColor={buttonColor}
        style={[{ zIndex: 1, borderRadius: 6 }]}
        disabled={props.disabled || props.pending}
        onPress={props.onPress}
      >
        {icon}
        {label}
      </Column>
    </>
  )
}
