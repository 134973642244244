import React, { useEffect, useState } from "react"

import { bigToEuros, Euros, eurosToBig } from "~shared/lib/Money"

import { useKassenAppUi } from "../Context"
import { Row } from "../layout/FlexBox"
import { Label } from "../typography/Label"
import { ErrorField } from "./field/ErrorField"
import { MoneyInput } from "./field/MoneyInput"

export function MoneyRangeForm({
  disabled,
  initialRange,
  onChange,
}: {
  initialRange: [Euros | undefined, Euros | undefined]
  onChange(range: [Euros | undefined, Euros | undefined]): void

  disabled?: boolean
}) {
  const {
    general: { from, to },
    moneyRangeForm: { invalidRange },
  } = useKassenAppUi().strings

  const [start, setStart] = useState(eurosToBig(initialRange[0]))
  const [end, setEnd] = useState(eurosToBig(initialRange[1]))

  const isInvalid = start && end && start.gt(end)
  const [endError, setEndError] = useState<string | undefined>(undefined)

  useEffect(() => {
    onChange([bigToEuros(start), bigToEuros(end)])

    if (isInvalid) return

    if (endError) setEndError(undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start, end])

  const setValidationError = () => {
    if (!isInvalid) return
    setEndError(invalidRange)
  }

  return (
    <Row alignCenter gap="XS">
      <Label text={from} medium />
      <ErrorField>
        <MoneyInput
          transparent
          value={start}
          noValueNeeded
          fontSize="medium"
          disabled={disabled}
          style={{ width: 80 }}
          onChange={setStart}
          onSubmit={setValidationError}
        />
      </ErrorField>

      <Label text={to} medium />
      <ErrorField errorMessage={endError}>
        <MoneyInput
          transparent
          value={end}
          noValueNeeded
          fontSize="medium"
          disabled={disabled}
          style={{ width: 80 }}
          errorMessage={endError}
          onChange={setEnd}
          onSubmit={setValidationError}
        />
      </ErrorField>
    </Row>
  )
}
