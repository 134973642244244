import { useRecoilValue } from "recoil"

import { logLevelState, versionNumberPressedCountState } from "./state"

export function useLogLevel(): string {
  return useRecoilValue(logLevelState)
}

export function useVersionNumberPressedCount(): number {
  return useRecoilValue(versionNumberPressedCountState)
}
