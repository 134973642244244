import React, { ReactNode } from "react"

import { useOnOff } from "@axtesys/hooks"

import { HoverablePressableOpacity } from "../container/HoverablePressableOpacity"
import { Box } from "../layout/Box"
import { Label } from "../typography/Label"

const MAX_WIDTH = 175

type ContentTextType = string | ReactNode

export type ToolTipProps = {
  text: ContentTextType
  children: ReactNode
}

export function ToolTip(props: ToolTipProps) {
  const [isHovered, onHoverIn, onHoverOut] = useOnOff(false)

  let content: ContentTextType
  if (typeof props.text == "string") {
    content = <Label text={props.text} medium />
  } else content = props.text

  return (
    <HoverablePressableOpacity
      alwaysActive
      onHoverIn={onHoverIn}
      onHoverOut={onHoverOut}
    >
      {props.children}

      {isHovered && (
        <Box style={{ width: MAX_WIDTH, position: "absolute" }}>
          <Box
            style={{
              left: 22,
              bottom: 0,
              maxWidth: MAX_WIDTH,
              position: "absolute",
            }}
          >
            {content}
          </Box>
        </Box>
      )}
    </HoverablePressableOpacity>
  )
}
