import React, { ReactNode } from "react"
import { StyleProp, ViewStyle } from "react-native"

import { Column } from "../layout/FlexBox"
import { Gap } from "../layout/Gap"
import { Label } from "../typography/Label"

export function ErrorMessageContainer(props: {
  children: ReactNode
  errorMessage?: string
  style?: StyleProp<ViewStyle>
}) {
  return (
    <Column gap="XXXS" style={props.style}>
      {props.children}
      {props.errorMessage != null ? (
        <Label text={props.errorMessage} small color="error" />
      ) : (
        <Gap vertical="XXS" />
      )}
    </Column>
  )
}
