import React, { Children, ReactNode } from "react"
import { StyleProp, ViewStyle } from "react-native"

import { Box } from "../layout/Box"
import { Row } from "../layout/FlexBox"

export type TableRowProps = {
  children: ReactNode
  flex: number[]

  // Grants access to the style property of each column
  styles?: StyleProp<ViewStyle>[]

  // Grants access to the style of the row container
  containerStyle?: StyleProp<ViewStyle>
}

export function TableRow(props: TableRowProps) {
  const styles = props.styles
  const spacing = props.flex
  const children = Children.toArray(props.children)

  return (
    <Row alignCenter style={props.containerStyle}>
      {children.map((child, index) => (
        <Box
          key={child.toString() + spacing[index]}
          style={[{ flex: spacing[index] }, styles && styles[index]]}
        >
          {child}
        </Box>
      ))}
    </Row>
  )
}
