import { useMemo } from "react"
import { useRecoilCallback } from "recoil"

import {
  DateTimePickerConfig,
  dateTimePickerConfigState,
  dateTimePickerVisibility,
} from "./state"

type InputOptions = Omit<DateTimePickerConfig, "type" | "onChange">

export function useDateTimePicker() {
  const pick = useDateTimePickerInternal()

  return useMemo(
    () => ({
      pickTime: (config: InputOptions) => pick({ ...config, type: "time" }),
      pickDate: (config: InputOptions) => pick({ ...config, type: "date" }),
      pickDateOrTime: (config: InputOptions) =>
        pick({ ...config, type: "datetime" }),
    }),
    [pick],
  )
}

function useDateTimePickerInternal() {
  return useRecoilCallback(
    ({ set }) =>
      async (config: Omit<DateTimePickerConfig, "onChange">) =>
        new Promise<Date | undefined>(resolve => {
          set(dateTimePickerConfigState, {
            ...config,
            onChange: resolve,
          })
          set(dateTimePickerVisibility, true)
        }),
    [],
  )
}
