import React from "react"

import { Button } from "../button/Button"
import { BoxProps } from "../layout/Box"
import { Row } from "../layout/FlexBox"

type ButtonBarProps = {
  actionButtons?: ButtonConfig[]
  cancelButton?: ButtonConfig
  contentContainer?: BoxProps
}

type ButtonConfig = {
  label: string
  onPress?: () => void
  disabled?: boolean
  errorMessage?: string
}

export function ButtonBar(props: ButtonBarProps) {
  return (
    <Row
      gap="XS"
      style={{ justifyContent: "flex-end" }}
      {...props.contentContainer}
    >
      {props.cancelButton && (
        <Button
          outlined
          text={props.cancelButton.label}
          onPress={props.cancelButton.onPress}
        />
      )}
      <Row gap="XS">
        {props.actionButtons?.map(buttonConfig => {
          return (
            <Button
              key={`buttonBarButton:${buttonConfig.label}`}
              text={buttonConfig.label}
              {...buttonConfig}
            />
          )
        })}
      </Row>
    </Row>
  )
}
