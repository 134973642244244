import { clamp, floor } from "lodash"
import React, { ReactNode, useState } from "react"
import { LayoutChangeEvent, View } from "react-native"

import { Box, useSpacing } from "../layout/Box"
import { Column, ColumnProps, Row } from "../layout/FlexBox"
import { ResponsiveSpacing } from "../types"

// Lays out children in a grid, filling rows first.
// Children are expected to be the same height/width.

type GridSpecificProps = ColumnProps & {
  children: ReactNode[]
  alignRowsCenter?: boolean
  verticalGap?: ResponsiveSpacing
  horizontalGap?: ResponsiveSpacing
}

export type GridProps = GridSpecificProps &
  ({ numCols: number; flexCols?: number[] } | { colWidth: number })

export function Grid(props: GridProps) {
  if ("numCols" in props) {
    return <GridWithNumCols {...props}>{props.children}</GridWithNumCols>
  } else {
    return (
      <GridWithCalculatedNumCols {...props}>
        {props.children}
      </GridWithCalculatedNumCols>
    )
  }
}

function GridWithCalculatedNumCols(props: GridProps) {
  const [numCols, setNumCols] = useState<number>(0)

  if (!("colWidth" in props) || props.colWidth <= 0) return null

  const onLayoutChange = ({ nativeEvent }: LayoutChangeEvent) =>
    setNumCols(
      clamp(
        floor(nativeEvent.layout.width / props.colWidth),
        1,
        Number.MAX_VALUE,
      ),
    )

  return (
    <View style={{ flexShrink: 1 }} onLayout={onLayoutChange}>
      <GridWithNumCols {...props} numCols={numCols}>
        {props.children}
      </GridWithNumCols>
    </View>
  )
}

function GridWithNumCols(
  props: GridSpecificProps & {
    numCols: number
    flexCols?: number[]
  },
) {
  const {
    gap,
    numCols,
    flexCols,
    verticalGap,
    horizontalGap,
    alignRowsCenter,
    ...columnProps
  } = props

  // Prevent division through zero
  // when 0 was passed as a value for numCols
  if (numCols <= 0) return null

  const children = props.children.filter(child => child != null)

  const numRows = Math.ceil(children.length / numCols)
  const rows: ReactNode[] = []
  for (let y = 0; y < numRows; y++) {
    const childrenPerRow: ReactNode[] = []
    for (let x = 0; x < numCols; x++) {
      const cell = children[y * numCols + x]
      childrenPerRow.push(
        <Box
          expand
          key={x}
          flex={flexCols && flexCols.length > x ? flexCols[x] : 1}
        >
          {cell}
        </Box>,
      )
    }
    rows.push(
      <Row key={y} gap={horizontalGap ?? gap} alignCenter={alignRowsCenter}>
        {childrenPerRow}
      </Row>,
    )
  }
  return (
    <Column {...columnProps} gap={verticalGap ?? gap}>
      {rows}
    </Column>
  )
}

// WebGrid is available on Web Platform only and uses css grid
// to vastly simplify layouting.
export type WebGridProps = {
  colWidth: number
  gap: ResponsiveSpacing
  children: ReactNode[]
}

export function WebGrid(props: WebGridProps) {
  const gridGap = useSpacing(props.gap)

  return (
    <div
      style={{
        gridGap,
        display: "grid",
        gridTemplateColumns: `repeat( auto-fit, minmax(${props.colWidth}px, 1fr) )`,
      }}
    >
      {props.children}
    </div>
  )
}
