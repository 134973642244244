import React from "react"
import { Dimensions } from "react-native"

import { Box, BoxProps } from "./Box"

export type FullScreenProps = BoxProps

export function FullScreenBox(props: FullScreenProps) {
  const { width, height } = Dimensions.get("window")

  return (
    <Box
      {...props}
      style={[
        {
          position: "absolute",
          left: 0,
          top: 0,
          width,
          height,
          overflow: "hidden",
        },
        props.style,
      ]}
    >
      {props.children}
    </Box>
  )
}
