import stringify from "json-stable-stringify"
import { initial, isEqual, last, now } from "lodash"
import React, {
  createContext,
  createElement,
  memo,
  useCallback,
  useContext,
  useMemo,
} from "react"
import { ActivityIndicator, Keyboard, View } from "react-native"
import { atom, useRecoilCallback, useRecoilState, useRecoilValue } from "recoil"

import { useMountEffect, useTransitionEffect } from "@axtesys/hooks"

import { logInfo, logTrace } from "~shared/feature/Logging/hooks"

import {
  CreateNavigationArgs,
  InternalCreateNavigationResult,
  InternalScreenStackProps,
  ScreenDef,
  ScreenRoute,
  ScreenStack,
} from "../types"

type GeneralScreenRoute = ScreenRoute<any>
type GeneralScreenStack = ScreenStack<any>

// Contains the stack of screens displayed by the ScreenStackComponent
// This is a singleton and therefore should
// not be declared in the createNavigation function!
const screenStackState = atom<GeneralScreenStack>({
  key: "screenStack",
  default: [],
})

const routeContext = createContext<GeneralScreenRoute>(null as any)

export function createNavigation<S extends ScreenDef>(
  args: CreateNavigationArgs<S>,
): InternalCreateNavigationResult<S> {
  const MemoizedRoute = memo(
    ({
      route,
      implementations,
      ...props
    }: Omit<InternalScreenStackProps<S>, "defaultRoute"> & {
      route: ScreenRoute<S>
    }) => {
      const currentRouteImplementation = implementations[route.screenName]

      if (!currentRouteImplementation)
        return (
          <ActivityIndicator size="large" color="#B71E3F" style={{ flex: 1 }} />
        )

      const content = createElement(
        currentRouteImplementation,
        route.screenProps,
      )

      return (
        <props.fallback>
          <routeContext.Provider value={route as GeneralScreenRoute}>
            <View
              style={{
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                position: "absolute",
              }}
            >
              {content}
            </View>
          </routeContext.Provider>
        </props.fallback>
      )
    },
  )

  return {
    ScreenStackComponent({
      fallback,
      defaultRoute,
      implementations,
    }: InternalScreenStackProps<S>) {
      const [screenStack, setScreenStack] = useRecoilState(screenStackState)

      const initialiseScreenStack = useCallback(
        () => setScreenStack([defaultRoute as GeneralScreenRoute]),
        [defaultRoute, setScreenStack],
      )

      useMountEffect(initialiseScreenStack)
      useTransitionEffect(
        defaultRoute,
        (currentDefaultRoute, lastDefaultRoute) => {
          if (isEqual(currentDefaultRoute, lastDefaultRoute)) return
          initialiseScreenStack()
        },
      )

      let renderedRoutes = screenStack

      // Make sure that once we are in singleScreenMode (currently only web),
      // there is actually only one route rendered at a time
      // (which should be the last/latest one in the current stack).
      if (args.singleScreenMode && renderedRoutes.length > 1)
        renderedRoutes = [last(renderedRoutes)!]

      return (
        <View style={{ flex: 1 }}>
          {renderedRoutes.map(route => (
            <MemoizedRoute
              route={route}
              fallback={fallback}
              key={stringify(route)}
              implementations={implementations}
            />
          ))}
        </View>
      )
    },

    useNavigate() {
      return useRecoilCallback(
        ({ set }) =>
          (screenName, screenProps, options) => {
            Keyboard.dismiss()

            const screen = screenName.toString()
            logInfo(`Navigating to ${screen}`, screenProps, options ?? "")

            const t0 = now()

            set(screenStackState, prevStack => {
              const mutatedScreenStack = [...prevStack]

              if (options?.replaceCurrent) mutatedScreenStack.pop()
              if (options?.replaceStack) mutatedScreenStack.splice(0)

              // Do not push the screen again if we are already there
              if (
                !isEqual(last(mutatedScreenStack), { screenName, screenProps })
              )
                mutatedScreenStack.push({ screenProps, screenName: screen })

              return mutatedScreenStack
            })

            logTrace(`Navigation action took ${now() - t0}ms`)
          },
        [],
      )
    },

    useNavigateBack() {
      const [screenStack, setScreenStack] = useRecoilState(screenStackState)

      return useMemo(
        () =>
          screenStack.length != 1
            ? () => {
                Keyboard.dismiss()
                setScreenStack(stack => initial(stack))
              }
            : undefined,
        [screenStack.length, setScreenStack],
      )
    },

    useRoute() {
      const screenStack = useRecoilValue(screenStackState)
      return useMemo(() => last(screenStack)!, [screenStack])
    },

    useScreenStack() {
      return useRecoilValue(screenStackState)
    },

    useScreenName() {
      return useContext(routeContext).screenName
    },

    useScreenProps(expectedScreenName) {
      const { screenName, screenProps } = useContext(routeContext)

      return useMemo(() => {
        if (expectedScreenName != screenName)
          throw Error(
            `Requested screenProps from ${expectedScreenName.toString()} while in ${screenName.toString()}`,
          )

        return screenProps
      }, [expectedScreenName, screenName, screenProps])
    },

    useEditScreenStack() {
      return useRecoilCallback(
        ({ set }) =>
          edit =>
            set(
              screenStackState,
              screenStack => edit(screenStack) as GeneralScreenStack,
            ),
        [],
      )
    },
  }
}
