import React from "react"

import { Column } from "../../layout/FlexBox"
import { InputLabel, InputLabelProps } from "../../typography/InputLabel"
import { ErrorField, ErrorFieldProps } from "./ErrorField"

type FormFieldProps = InputLabelProps & ErrorFieldProps

// A top labeled form input / field component
export function FormField(
  props: FormFieldProps & { isNoErrorMessage?: boolean },
) {
  const label = props.label && <InputLabel {...props} />
  const input = props.isNoErrorMessage ? (
    props.children
  ) : (
    <ErrorField {...props}>{props.children}</ErrorField>
  )

  return (
    <Column gap="XXXXS" style={props.style}>
      {label}
      {input}
    </Column>
  )
}
