import { useWindowDimensions } from "react-native"

import { useDeviceInfo } from "@axtesys/hooks"

import { ScreenSize } from "../types"

export function useScreenSize(): ScreenSize {
  const { deviceType } = useDeviceInfo()
  const { width } = useWindowDimensions()

  switch (deviceType) {
    case "phone":
      return "S"
    case "tablet":
      return "M"
    case "desktop":
      if (width > 1400) {
        return "L"
      } else if (width < 1400 && width > 600) {
        // Smaller Desktop screens use M
        return "M"
      } else {
        // Or even S when cropped down
        return "S"
      }
    default:
      throw Error()
  }
}
