import { useCallback, useRef } from "react"

import { useAppDialog } from "@axtesys/kassen-app-ui"
import { interpolateString } from "@axtesys/react-tools"

import { useTranslation } from "../../contexts/TranslationContext"
import { useIsAppFrontend } from "../Config/queries"
import { useSimpleConfirmDialog } from "./providers/SimpleConfirmDialogProvider"

export function useSystemTimeDialog() {
  const currentDisplayRef = useRef(false)
  const { general, showDialogOk } = useSharedDialogConfig()
  const { validationMutationInPastOrFuture, validationMutationInPastDeadlock } =
    useTranslation().generalValidationMessages

  return useCallback(
    async (type: "past" | "future", latestOfflineTimestamp?: string) => {
      if (currentDisplayRef.current) return

      currentDisplayRef.current = true
      await showDialogOk({
        title: general.warning,
        text:
          interpolateString(validationMutationInPastOrFuture, general[type]) +
          (latestOfflineTimestamp
            ? `\n\n${interpolateString(
                validationMutationInPastDeadlock,
                latestOfflineTimestamp,
              )}`
            : ""),
      })
      currentDisplayRef.current = false
    },
    [
      general,
      showDialogOk,
      validationMutationInPastDeadlock,
      validationMutationInPastOrFuture,
    ],
  )
}

export function useErrorNoticeDialog() {
  const { general, showDialogOk } = useSharedDialogConfig()

  return useCallback(
    async (text?: string) =>
      text && showDialogOk({ title: general.error, text }),
    [general, showDialogOk],
  )
}

function useSharedDialogConfig() {
  const appDialog = useAppDialog()
  const { general } = useTranslation()
  const dialog = useSimpleConfirmDialog()

  const { showDialogOk } = useIsAppFrontend() ? appDialog : dialog

  return { general, showDialogOk }
}
