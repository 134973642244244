import { useRecoilValue } from "recoil"

import { ModuleIdentifier, UserRole } from "../../api/graphql/types"
import { contains } from "../../lib/Collections"
import { User } from "../../lib/User"
import { CompanyId } from "../../types"
import {
  authenticatedUserState,
  operatorSelector,
  userLoggedInState,
} from "./state"

export function useAuthenticatedUser() {
  return useRecoilValue(authenticatedUserState)
}

export function useOperator() {
  return useRecoilValue(operatorSelector)
}

export function useCompanyId(): CompanyId | undefined {
  return useAuthenticatedUser()?.companyId
}

export function useCompanyName(): string | undefined {
  return useAuthenticatedUser()?.companyName
}

export function useUserFullName(): string | undefined {
  const authenticatedUser = useAuthenticatedUser()

  if (!authenticatedUser) return undefined

  return `${authenticatedUser.firstName} ${authenticatedUser.lastName}`
}

export function useUserInfo(): User | undefined {
  const authenticatedUser = useAuthenticatedUser()

  if (!authenticatedUser) return undefined

  return {
    firstName: authenticatedUser.firstName,
    lastName: authenticatedUser.lastName,
  }
}

export function useUserRole(): UserRole | undefined {
  return useAuthenticatedUser()?.userRole
}

export function useAllowModuleUsage(module: ModuleIdentifier): boolean {
  const authenticatedUser = useAuthenticatedUser()

  if (!authenticatedUser) return false
  if (authenticatedUser.userRole == "Administrator") return true

  return contains(authenticatedUser.modules ?? [], module)
}

export function useIsContactManagementAllowed(): boolean {
  return useAllowModuleUsage("ContactManagement")
}

// In case this permission flag is set to true,
// navigation, access and manipulations
// to the different management related
// screens and features (currently only contact management)
// will be allowed.
export function useGrantManagementAccess() {
  return useAuthenticatedUser()?.permissions?.grantManagement == true
}

// In case this permission flag is set to true,
// navigation, access and manipulations
// to the BillingReportScreen and InterimBillingReportScreen
// will be allowed.
export function useGrantReportAccess() {
  return useAuthenticatedUser()?.permissions?.grantReportAccess == true
}

// In case this feature flag is set to true,
// navigation to the PaymentScreen (through the use of one
// of the payment buttons - not payout button) will be skipped,
// and the current shopping cart will be finalized
// by creating a new receipt.
// That leads to the fact that split payments and
// change payouts cannot be applied.
export function useIsInstantReceiptMode() {
  return useAuthenticatedUser()?.features?.printReceiptInstantly == true
}

export function useIsLoggedIn(): boolean {
  return useRecoilValue(userLoggedInState)
}

function useIsRoleLoggedIn(role: UserRole): boolean {
  const isUserRole = useUserRole() == role
  const isLoggedIn = useRecoilValue(userLoggedInState)

  return isLoggedIn && isUserRole
}

export function useIsAdminLoggedIn(): boolean {
  return useIsRoleLoggedIn("Administrator")
}

export function useIsManagerLoggedIn(): boolean {
  return useIsRoleLoggedIn("Manager")
}

export function useIsAdminOrManagerLoggedIn(): boolean {
  const isManagerLoggedIn = useIsRoleLoggedIn("Manager")
  const isAdministratorLoggedIn = useIsRoleLoggedIn("Administrator")

  return isAdministratorLoggedIn || isManagerLoggedIn
}

export function useIsOperatorLoggedIn(): boolean {
  return useIsRoleLoggedIn("Operator")
}

export function useIsAssumingCompany(): boolean {
  const isCompanyIdPresent = useCompanyId() != undefined
  const isAdministratorLoggedIn = useIsRoleLoggedIn("Administrator")

  return isAdministratorLoggedIn && isCompanyIdPresent
}
