import {
  isValidBIC as isITValidBIC,
  isValidIBAN as isITValidIBAN,
} from "ibantools"
import { inRange, last, map, sum } from "lodash"

// Adapted and slightly customized based on:
// https://github.com/manishsaraan/email-validator/blob/master/index.js
const emailRegex =
  /^[-!#$%&'*+äöüÄÖÜ/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+äöüÄÖÜ/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/

export function isValidEmail(email?: string): boolean {
  if (!email) return false

  const emailParts = email.split("@")
  if (emailParts.length !== 2) return false

  // Pre at-sign
  const userPart = emailParts[0]
  // Post at-sign
  const domainPart = emailParts[1]
  if (userPart.length > 64 || domainPart.length > 255) return false

  const domainParts = domainPart.split(".")
  if (domainParts.some(part => part.length > 63)) return false

  return emailRegex.test(email)
}

const uidRegex = "(A|a)(T|t)(U|u)\\d{8}"
const taxNumberRegex = "\\d{2}-\\d{3}/\\d{4}"

export function isValidTaxNumber(taxNumber?: string): boolean {
  if (!taxNumber) return false

  const uidNumberMatches = taxNumber.match(uidRegex)
  const taxNumberMatches = taxNumber.match(taxNumberRegex)

  return (
    (uidNumberMatches != null && uidNumberMatches[0] == taxNumber) ||
    (taxNumberMatches != null && taxNumberMatches[0] == taxNumber)
  )
}

export function isIBANValid(iban?: string) {
  if (!iban) return false

  // 1. Extract the countryCode from the potential IBAN
  // (should always be represented by the first two characters)
  const countryCode = iban.substring(0, 2)

  // 2. In a second step we want
  // to extract the account identification
  const accountIdentification = iban.substring(2)

  // 3. Remove all guideline violating characters of the identification
  const cleanedAccountIdentification = accountIdentification.replace(/\D/g, "")

  // 4. Validate th cleaned IBAN
  return isITValidIBAN(`${countryCode}${cleanedAccountIdentification}`)
}

export function isBICValid(bic?: string): boolean {
  if (!bic) return false

  const cleanedBIC = bic.replace(/[^0-9a-zA-Z]+/g, "")

  return isITValidBIC(cleanedBIC)
}

const phoneNumberRegex = /^[0-9+\-()\s]*$/

export function isValidPhoneNumber(phoneNumber?: string) {
  if (!phoneNumber) return false

  if (phoneNumber.substring(1).includes("+")) return false

  return phoneNumber.match(phoneNumberRegex) != null
}

export function isZipCodeValid(zipCode?: string) {
  return zipCode && zipCode?.length >= 4
}

// Implemented based on the following specification
// https://en.wikipedia.org/wiki/International_Article_Number#Calculation_of_checksum_digit

// GTIN stands for Global Trade Item Number and exists in four commonly used types.
// GTIN-8: Most commonly used on end consumer packaging that have a small form factor
//         (frequently encoded as an EAN-8 barcode).
// GTIN-12: Most commonly used inside of North America on end consumer packaging
//         (frequently encoded as a UPC [Universal Product Code; UPC-A] barcode).
// GTIN-13: Most common variant used on end consumer packaging
//          (frequently encoded as an EAN-13 barcode).
// GTIN-14: Most commonly used on master / bulk cartons
//          that include several pieces of the same kind of product
//          (act as the outer packaging; frequently encoded as an ITF-14 barcode).

const gtinRegex = /^(\d{8}|\d{12}|\d{13}|\d{14})$/

function calculateGTINChecksum(
  gtin: string,
  evenWeight: number,
  oddWeight: number,
) {
  const preChecksum = sum(
    map(gtin, (digit, index) =>
      index % 2 == 0 ? +digit * evenWeight : +digit * oddWeight,
    ),
  )

  return preChecksum >= 0 ? (10 - (preChecksum % 10)) % 10 : preChecksum
}

export function isValidGTIN(gtin?: string) {
  if (!gtin || !gtin.match(gtinRegex)) return false

  const actualChecksum = +last(gtin)!

  let calculatedChecksum: number
  const gtinWithoutChecksum = gtin.slice(0, -1)
  switch (gtinWithoutChecksum.length) {
    case 12:
      calculatedChecksum = calculateGTINChecksum(gtinWithoutChecksum, 1, 3)
      break
    case 7:
    case 11:
    case 13:
      calculatedChecksum = calculateGTINChecksum(gtinWithoutChecksum, 3, 1)
      break
    default:
      calculatedChecksum = -1
      break
  }

  if (!inRange(calculatedChecksum, 0, 10)) return false

  return actualChecksum == calculatedChecksum
}

export function isIntegerNumber(input?: number | string) {
  if (!input) return false
  return input.toString().match(/^\d+$/) != null
}
