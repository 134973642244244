import { atom } from "recoil"
import { BehaviorSubject } from "rxjs"
import * as z from "zod"

import { jsonDbAtom } from "@axtesys/react-tools"

import { LogMessage, LogMessageEntity } from "./types"

export const logMessageSubject = new BehaviorSubject<LogMessage[]>([])

export const logMessageQueueState = jsonDbAtom({
  key: "logMessageQueue",
  default: [] as LogMessage[],
  schema: z.array(LogMessageEntity.schema),
})

export const logLevelState = jsonDbAtom({
  key: "logLevelSetting",
  default: "info",
  schema: z.string(),
})

export const versionNumberPressedCountState = atom({
  key: "versionNumberPressedCount",
  default: 0,
})
