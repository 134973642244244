import * as z from "zod"

import { createEntityFor } from "@axtesys/react-tools"

import {
  OperatorFeaturesInput,
  OperatorPermissionInput,
  Salutation,
} from "../api/graphql/types"

export type OperatorId = string

export type OperatorData = Omit<Operator, "id">

export type OperatorInfo = {
  operatorId: OperatorId
  salutation: Salutation
  fullName: string
}

export type Operator = {
  id: OperatorId
  salutation: Salutation
  firstName: string
  lastName: string
  password: string
  repeatedPassword: string
  permissions: OperatorPermissionInput
  features: OperatorFeaturesInput

  operatorCode?: number
}

export type OperatorFormData = {
  salutation: Salutation
  firstName: string
  lastName: string
  password: string
  repeatedPassword: string

  allowDiscount: boolean
  allowManagement: boolean
  allowReportAccess: boolean
  maxDiscountPercent: string

  printReceiptInstantly: boolean

  operatorCode?: number
}

export const OperatorInfoEntity = createEntityFor<OperatorInfo>()
  .withSchema(
    z.object({
      operatorId: z.string(),
      salutation: z.enum([
        "Mr",
        "Ms",
        "NonBinary",
        "Enterprise",
        "NotSpecified",
        "Family",
        "Club",
      ]),
      fullName: z.string(),
    }),
  )
  .serialize(data => data)
  .deserialize(json => json)
