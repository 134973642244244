import { parseISO } from "date-fns"
import { isEqual } from "lodash"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { View } from "react-native"

import { useOnOff } from "@axtesys/hooks"
import {
  DateLike,
  formatDate,
  formatDateTime,
  formatTime,
} from "@axtesys/react-tools"
import {
  DateOrTimeView,
  DatePickerProps,
  DateTimePickerProps,
  DateView,
  MobileDatePicker,
  MobileDateTimePicker,
  MobileTimePicker,
  pickersLayoutClasses,
  TimePickerProps,
  TimeView,
} from "@mui/x-date-pickers"

import { MCIcon } from "../../MCIcon"
import { useSpacing } from "../../layout/Box"
import { Row } from "../../layout/FlexBox"
import { DateTimeModes } from "../../types"
import { LinkLabel } from "../../typography/LinkLabel"
import { IconConfig, TextInput, TextInputProps } from "./TextInput"

type InputOptions = Date | string | undefined

type TimeViewProps<T extends InputOptions> = Pick<
  TimePickerProps<T>,
  "openTo" | "onClose"
>
type DateViewProps<T extends InputOptions> = Pick<
  DatePickerProps<T>,
  "openTo" | "onClose"
>
type DateTimeViewProps<T extends InputOptions> = Pick<
  DateTimePickerProps<T>,
  "openTo" | "onClose"
>

type InheritedProps<T extends InputOptions> = Omit<
  TextInputProps,
  "value" | "iconRight" | "iconLeft" | "onChange"
> &
  Pick<DatePickerProps<T>, "disableFuture">

type OverwrittenProps<T extends InputOptions> = {
  type: DateTimeModes
  onChange: (value?: Date) => void

  value?: T
  visible?: boolean
  clearable?: boolean
  overlayMode?: boolean
  showTodayButton?: boolean

  onSubmit?: () => void
  shouldDisableSelection?: (date: Date) => boolean
}

type DateTimeInputProps<T extends InputOptions> = InheritedProps<T> &
  OverwrittenProps<T> &
  (TimeViewProps<T> | DateViewProps<T> | DateTimeViewProps<T>)

const TIME_WIDTH = 45
const DATE_TIME_WIDTH = 100
const TIME_ICON = "clock-outline" as MCIcon
const CALENDAR_ICON = "calendar-month-outline" as MCIcon

export function DateTimeInput<T extends InputOptions>(
  props: DateTimeInputProps<T>,
) {
  const { type } = props
  const pickerProps = useConstructPickerProps(props)

  if (type == "time") {
    return <TimeInputField {...pickerProps} />
  } else {
    return type == "date" ? (
      <DateInputField {...pickerProps} />
    ) : (
      <DateTimeInputField {...pickerProps} />
    )
  }
}

type DTInputFieldProps = ReturnType<typeof useConstructPickerProps>

function TimeInputField(props: DTInputFieldProps) {
  return <MobileTimePicker {...props} openTo={props.openTo as TimeView} />
}

function DateInputField(props: DTInputFieldProps) {
  return <MobileDatePicker {...props} openTo={props.openTo as DateView} />
}

function DateTimeInputField(props: DTInputFieldProps) {
  return (
    <MobileDateTimePicker {...props} openTo={props.openTo as DateOrTimeView} />
  )
}

type InputFieldProps<T extends InputOptions> = Omit<
  DateTimeInputProps<T>,
  "value"
> & {
  icon: MCIcon
  value: string
  width: number
  onOpen: () => void
}

function InputField<T extends InputOptions>(props: InputFieldProps<T>) {
  const {
    icon,
    value,
    width,
    style,
    disabled,
    clearable,
    containerStyle,
    onOpen,
    onChange,
  } = props

  const iconRight: IconConfig =
    clearable && value
      ? { name: "close", onPress: () => onChange(undefined) }
      : { name: icon }

  return (
    <TextInput
      {...props}
      fontSize="medium"
      disabled={disabled}
      pointerEvents="none"
      iconRight={iconRight}
      style={[{ width }, style]}
      containerStyle={[{ maxWidth: 140 }, containerStyle]}
      onChange={() => {}}
      // Required for Web (does not support onPressIn)
      onPress={!disabled ? onOpen : undefined}
      // Required for App (does not support onPress)
      onPressIn={!disabled ? onOpen : undefined}
    />
  )
}

type FieldProps = {
  icon: MCIcon
  width: number
  format: (date: DateLike) => string
  onOpen: () => void
}

function Field<T extends InputOptions>({
  icon,
  width,
  value,
  format,
  onOpen,
  overlayMode,
  ...fieldProps
}: DateTimeInputProps<T> & FieldProps) {
  return !overlayMode ? (
    <InputField
      {...fieldProps}
      icon={icon}
      width={width}
      value={value ? format(value) : ""}
      onOpen={onOpen}
    />
  ) : (
    <View style={{ display: "none" }} />
  )
}

function useConstructPickerProps<T extends InputOptions>(
  props: DateTimeInputProps<T>,
) {
  const {
    type,
    value,
    openTo,
    visible,
    disabled,
    disableFuture,
    clearable = false,
    showTodayButton = true,
    onChange,
    onSubmit,
    onClose,
  } = props

  const styleConfig = useStyleConfig()
  const [isOpen, showPicker, closePicker] = useOnOff(false)
  const [internalValue, setInternalValue] = useState<Date>(
    sanitizeDateValue(value),
  )

  useEffect(() => setInternalValue(sanitizeDateValue(value)), [value])

  const onClosePicker = useCallback(() => {
    requestAnimationFrame(() => {
      closePicker()
      onClose?.()
    })
  }, [closePicker, onClose])

  const onAccept = useCallback(() => {
    onClosePicker()
    if (isEqual(sanitizeDateValue(value), internalValue)) return
    onChange(internalValue)
    onSubmit?.()
  }, [internalValue, onChange, onClosePicker, onSubmit, value])

  const onReset = useCallback(() => {
    onClosePicker()
    setInternalValue(sanitizeDateValue(value))
  }, [onClosePicker, value])

  const onToday = useCallback(() => {
    setInternalValue(new Date())
  }, [])

  const onClear = useCallback(() => {
    onClosePicker()
    onToday()
    onChange(undefined)
    onSubmit?.()
  }, [onChange, onClosePicker, onSubmit, onToday])

  const actionBar = useCallback(
    () => (
      <div className={pickersLayoutClasses.actionBar}>
        <Row
          alignCenter
          spaceBetween
          padTop="XXS"
          padBottom="XS"
          padHorizontal="S"
        >
          {clearable && <LinkLabel bold text="Löschen" onPress={onClear} />}
          {showTodayButton && <LinkLabel bold text="Heute" onPress={onToday} />}
          <LinkLabel bold text="Abbrechen" onPress={onReset} />
          <LinkLabel bold text="OK" onPress={onAccept} />
        </Row>
      </div>
    ),
    [clearable, onAccept, onClear, onReset, onToday, showTodayButton],
  )

  const typeBasedFieldProps = useMemo(
    () =>
      type == "time"
        ? {
            icon: TIME_ICON,
            width: TIME_WIDTH,
            format: formatTime,
          }
        : {
            icon: CALENDAR_ICON,
            width: DATE_TIME_WIDTH,
            format: type == "date" ? formatDate : formatDateTime,
          },
    [type],
  )

  const field = () => (
    <Field {...props} {...typeBasedFieldProps} onOpen={showPicker} />
  )

  const isTimeType = type == "time"
  const shouldDisableDateWhen = props.shouldDisableSelection
    ? (date?: InputOptions) => {
        if (!date || !props.shouldDisableSelection) return false
        return props.shouldDisableSelection(new Date(date))
      }
    : undefined

  return {
    disabled,
    ampm: false,
    disableFuture,
    ampmInClock: false,
    value: internalValue,
    open: visible ?? isOpen,
    slots: { field, actionBar },
    showDaysOutsideCurrentMonth: !isTimeType,
    openTo: openTo || (isTimeType ? "hours" : "day"),
    orientation: "portrait" as "portrait" | "landscape",
    slotProps: { dialog: { sx: styleConfig, onClose: onClosePicker } },
    onOpen: showPicker,
    onClose: onClosePicker,
    onChange: (date: Date | null) => date && setInternalValue(date),
    shouldDisableDate: !isTimeType ? shouldDisableDateWhen : undefined,
    shouldDisableTime:
      isTimeType || type == "datetime" ? shouldDisableDateWhen : undefined,
  }
}

function useStyleConfig() {
  const columnGap = useSpacing("XXXXS")

  return useMemo(
    () => ({
      // Applies the general layout styling
      // of the OUTER toolbar container
      "& .MuiPickersLayout-toolbar": {
        borderTopWidth: 5,
        borderBottomWidth: 3,
        borderTopStyle: "solid",
        borderTopColor: "#2B5B95",
        borderBottomStyle: "solid",
        backgroundColor: "#2B5B95",
        borderBottomColor: "#B71E3F",
      },

      // Applies the general layout styling
      // of the INNER toolbar container
      "& .MuiPickersToolbar-content": {
        flex: 1,
        columnGap,
        alignItems: "center",
        justifyContent: "center",
      },

      // Styles the selected states
      // of the day, month, year, minutes and hours
      // ripples in a DateTime-/TimePicker context.
      // Additionally, we also style the title/date of the date picker.
      "& button > .Mui-selected,& .MuiDatePickerToolbar-title": {
        color: "white",
      },

      // Inserts spacing between the actions buttons
      "& .MuiDialogActions-root": {
        flexDirection: "row-reverse",
        justifyContent: "space-between",
      },

      // Increases the padding of the clock selection view
      "& .MuiTimeClock-root": { paddingTop: 5.25 },

      // Prevents the dialog from getting too small in width
      "& .MuiDialogContent-root": { minWidth: 330 },

      // Applies another color to the week day display row labels
      "& .MuiTypography-caption": { color: "black" },

      // Removes the date/time selection header in the toolbar
      "& .MuiTypography-overline": { display: "none" },

      // Default font style of date/time label
      // items in toolbar when not being selected
      "& .MuiPickersToolbarText-root": { color: "rgba(255,255,255,0.75)" },
    }),
    [columnGap],
  )
}

function sanitizeDateValue<T extends InputOptions>(value?: T): Date {
  return typeof value == "string" ? parseISO(value) : value ?? new Date()
}
