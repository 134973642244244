import { createElement } from "react"

import { useBackButtonEffect } from "@axtesys/hooks"
import {
  createAppNavigation as createLibAppNavigation,
  CreateNavigationArgs,
  CreateNavigationResult,
  EditScreenStack,
  GoBackAction,
  Navigate,
  ScreenDef,
  ScreenStackProps,
} from "@axtesys/navigation"

import { useExecuteSafeAction } from "./hooks"

export function createAppNavigation<S extends ScreenDef>(
  args: CreateNavigationArgs<S>,
): CreateNavigationResult<S> {
  const { ScreenStackComponent, ...navigation } = createNavigation<S>(
    createLibAppNavigation(args),
  )

  return {
    ...navigation,

    ScreenStackComponent(props: ScreenStackProps<S>) {
      const navigateBack = navigation.useNavigateBack()

      // Responsible to trigger the correct back navigation effect
      // on Android devices using their hardware/OS key.
      useBackButtonEffect(() => {
        if (!navigateBack) return false
        navigateBack()
        return true
      })

      return createElement(ScreenStackComponent, props)
    },
  }
}

function createNavigation<S extends ScreenDef>({
  useNavigate,
  useNavigateBack,
  useEditScreenStack,
  ...navigation
}: CreateNavigationResult<S>): CreateNavigationResult<S> {
  return {
    ...navigation,

    useNavigate(): Navigate<S> {
      const navigate = useNavigate()
      const executeSafeAction = useExecuteSafeAction()

      return (screenName, screenProps, options) =>
        executeSafeAction(() => navigate(screenName, screenProps, options))
    },

    useNavigateBack(): GoBackAction | undefined {
      const navigateBack = useNavigateBack()
      const executeSafeAction = useExecuteSafeAction()

      const navigateBackAction = () => executeSafeAction(navigateBack)

      return navigateBack ? navigateBackAction : undefined
    },

    useEditScreenStack(): EditScreenStack<S> {
      const editScreenStack = useEditScreenStack()
      const executeSafeAction = useExecuteSafeAction()

      return edit => executeSafeAction(() => editScreenStack(edit))
    },
  }
}
