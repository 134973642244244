import React from "react"

import { isEmptyOrBlank } from "@axtesys/react-tools"

import { MCIcon } from "../../MCIcon"
import { INTEGER_REGEX } from "../../constants"
import { ChipView, ChipViewProps } from "../../display/Chip"
import { Column } from "../../layout/FlexBox"
import { IconConfig, TextInput, TextInputProps } from "./TextInput"

type ChipInputProps = Pick<
  TextInputProps,
  | "value"
  | "maxLength"
  | "blurOnSubmit"
  | "errorMessage"
  | "keyboardType"
  | "textInputRef"
  | "onChange"
  | "onSubmitEditing"
> &
  Pick<ChipViewProps, "values" | "chipMinWidth" | "erroredValues"> & {
    onChangeValues: (values?: string[]) => void

    icon?: MCIcon
    isInteger?: boolean
    emptyValuesLabel?: string
  }

export function ChipInput(props: ChipInputProps) {
  const {
    icon,
    value,
    values,
    errorMessage,
    emptyValuesLabel,
    isInteger = false,
    onChange,
    onChangeValues,
  } = props

  const sanitizedValue = isInteger
    ? value?.replaceAll(INTEGER_REGEX, "")
    : value
  const keyboardType = isInteger ? "number-pad" : undefined

  const isValuePresent = !isEmptyOrBlank(sanitizedValue)
  const isErrorMessagePresent = !isEmptyOrBlank(errorMessage)

  const onBlur = () => {
    if (isValuePresent || !isErrorMessagePresent) return
    onChange?.("")
  }
  const onSubmitEditing =
    isValuePresent && !isErrorMessagePresent
      ? () => {
          if (sanitizedValue && !values?.includes(sanitizedValue.trim())) {
            const changedValues = sanitizedValue
              ? [...(values ?? []), sanitizedValue]
              : values
            onChangeValues?.(
              changedValues && changedValues?.length > 0
                ? changedValues
                : undefined,
            )
          }

          props.onSubmitEditing?.()
        }
      : undefined

  const iconRight = {
    name: icon ?? "text-box-plus",
    color: onSubmitEditing ? "primary" : "disabled",
    onPress: onSubmitEditing,
  } as IconConfig

  return (
    <Column gap="XS">
      <TextInput
        keyboardType={keyboardType}
        {...props}
        iconRight={iconRight}
        value={sanitizedValue}
        onBlur={onBlur}
        onSubmitIfValid={undefined}
        onSubmitEditing={onSubmitEditing}
      />
      {emptyValuesLabel && (
        <ChipView
          {...props}
          emptyValuesLabel={emptyValuesLabel}
          onChange={onChangeValues}
        />
      )}
    </Column>
  )
}
