import React from "react"

import { INTEGER_REGEX } from "../../constants"
import { TextInput, TextInputProps } from "./TextInput"

export function IntegerTextInput({ value, ...props }: TextInputProps) {
  return (
    <TextInput
      {...props}
      keyboardType="number-pad"
      value={value?.replaceAll(INTEGER_REGEX, "")}
    />
  )
}
