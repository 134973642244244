import React from "react"

import { ResponsiveSpacing } from "../types"
import { useSpacing } from "./Box"
import { Row, RowProps } from "./FlexBox"

type WrappingRowProps = RowProps & {
  grow?: boolean
  rowGap?: ResponsiveSpacing
  columnGap?: ResponsiveSpacing
}

export function WrappingRow({
  gap,
  grow,
  style,
  children,
  ...props
}: WrappingRowProps) {
  const rowGap = useSpacing(gap ?? props.rowGap ?? "none")
  const columnGap = useSpacing(gap ?? props.columnGap ?? "none")

  return (
    <Row
      style={[
        {
          rowGap,
          columnGap,
          flexWrap: "wrap",
          flexGrow: grow ? 1 : undefined,
        },
        style,
      ]}
      {...props}
    >
      {children}
    </Row>
  )
}
