import React from "react"
import { Platform } from "react-native"

import { useOpenInAppBrowser } from "~src/application/app/feature/InAppBrowser"

import { PressableOpacity } from "../button/PressableOpacity"
import { Label, LabelProps } from "./Label"

interface LinkTextProps {
  url?: string
  onPress?(): void
}

export function LinkLabel(props: LabelProps & LinkTextProps) {
  const openInAppBrowser = useOpenInAppBrowser()

  return (
    <PressableOpacity
      onPress={props.onPress ?? (props.url ? onPressLink : undefined)}
    >
      <Label color="primary" {...props} />
    </PressableOpacity>
  )

  async function onPressLink() {
    if (Platform.OS == "web") {
      window.open(props.url, "_blank")
    } else {
      await openInAppBrowser(props.url!)
    }
  }
}
