import { Platform } from "react-native"
import { atom } from "recoil"
import * as z from "zod"

import { jsonDbAtom } from "@axtesys/react-tools"

export type FrontendType = "app" | "web" | "admin" | "designer"

// Export available frontends for the DevSwitcher.
export const frontends: FrontendType[] = ["app", "web", "admin", "designer"]

// This is the frontend we are rendering in a production build.
// The value is set by a script.
// NOTE: NEVER try to change this! No name, const / let or export change!
const EXPO_APPLICATION = "admin"

export const frontendState = __DEV__
  ? jsonDbAtom({
      key: "frontend",
      default: "app",
      schema: z.string(),
    })
  : atom({
      key: "frontend",
      default: frontends.includes(EXPO_APPLICATION ?? "unknown")
        ? // @ts-ignore constant will always be XYZ
          EXPO_APPLICATION != "app" && Platform.OS == "web"
          ? EXPO_APPLICATION
          : "app"
        : Platform.OS == "web"
        ? "web"
        : "app",
    })
