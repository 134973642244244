import {
  addDays,
  differenceInDays,
  endOfDay,
  endOfYesterday,
  isAfter,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
  startOfYesterday,
} from "date-fns"
import { isEqual } from "lodash"
import React, { useCallback, useEffect, useState } from "react"

import { useSubsequentEffect, useTransitionEffect } from "@axtesys/hooks"
import {
  formatDate,
  formatDateTime,
  formatIsoDate,
  formatIsoDateTime,
  interpolateString,
  validateDate,
} from "@axtesys/react-tools"

import { useKassenAppUi } from "../Context"
import { ToolTipIcon } from "../display/ToolTipIcon"
import { Row } from "../layout/FlexBox"
import { Label } from "../typography/Label"
import { DateTimeInput } from "./field/DateTimeInput"
import { SingleDropdownInput } from "./field/DropdownInput"

type DateRange = [Date, Date]

export type DateRangeType =
  | { mode: DateQuickSelection; range: DateRange }
  | { range: DateRange }

type DateQuickSelection =
  | "Today"
  | "Yesterday"
  | "ThisWeek"
  | "ThisMonth"
  | "ThisYear"
  | "Custom"

export type DateTimeRangeFormProps = {
  value: DateRangeType
  mode: "date" | "datetime"
  onChange(dateRange: DateRangeType): void

  disabled?: boolean
  disableFuture?: boolean
  selectionLimit?: number
  disableFutureUntil?: Date
  hideSelectionLimitBubble?: boolean
  excludeFromQuickSelection?: DateQuickSelection[]
  onSelectionViolationChange?: (state: boolean) => void
}

export function DateTimeRangeForm(props: DateTimeRangeFormProps) {
  const {
    mode,
    value,
    disabled,
    selectionLimit,
    disableFutureUntil,
    hideSelectionLimitBubble,
    onChange,
    onSelectionViolationChange,
  } = props

  const shouldDisableFutureUntil = disableFutureUntil
    ? (date: Date) => isAfter(date, disableFutureUntil)
    : undefined
  const disableFuture = !shouldDisableFutureUntil
    ? props.disableFuture ?? true
    : false

  const { strings } = useKassenAppUi()
  const computeSelectionMode = useComputeSelectionMode(mode)

  // State definitions

  const showQuickSelection = "mode" in value
  const [startDateError, setStartDateError] = useState<string | undefined>(
    undefined,
  )
  const [endDateError, setEndDateError] = useState<string | undefined>(
    undefined,
  )
  const [endDate, setEndDate] = useState(value.range[1])
  const [startDate, setStartDate] = useState(value.range[0])
  const [quickSelection, setQuickSelection] = useState<DateQuickSelection>(
    "mode" in value ? value.mode : "Today",
  )
  const [selectionViolation, setSelectionViolation] = useState(false)

  // Side-effects / useEffect hooks

  // Update
  // In case the range value changes, because of an update
  // outside/external to the managing logic of this component,
  // do update the controlled values inside here too.
  useTransitionEffect(value.range, (currentState, lastState) => {
    if (
      isEqual(lastState, currentState) ||
      isEqual([startDate, endDate], currentState)
    )
      return

    setStartDate(currentState[0])
    setEndDate(currentState[1])
  })

  // Validation
  // Check if the entered timespan is valid.
  // 1. Check for general date format validity
  // 2. Verify whether a possible selectionLimit constraint passes
  // 3. Verify that the start is before the end date
  useEffect(() => {
    const validStartDate = validateDate(mode, startDate)
    const validEndDate = validateDate(mode, endDate)

    if (validStartDate) setStartDateError(undefined)
    else setStartDateError(strings.dateTimeRangeForm.invalidDate)

    if (validEndDate) setEndDateError(undefined)
    else setEndDateError(strings.dateTimeRangeForm.invalidDate)

    if (!validStartDate || !validEndDate) return

    if (
      selectionLimit &&
      differenceInDays(addDays(endDate, 1), startDate) > selectionLimit
    ) {
      setSelectionViolation(true)
      onSelectionViolationChange?.(true)
      setStartDateError(strings.dateTimeRangeForm.invalidDate)
      setEndDateError(strings.dateTimeRangeForm.invalidRange)
      return
    } else {
      setSelectionViolation(false)
      onSelectionViolationChange?.(false)
      setStartDateError(undefined)
      setEndDateError(undefined)
    }

    if (startDate > endDate) {
      setEndDateError(strings.dateTimeRangeForm.invalidRange)
      return
    }

    const range: DateRange =
      mode == "date"
        ? [new Date(formatIsoDate(startDate)), new Date(formatIsoDate(endDate))]
        : [
            new Date(formatIsoDateTime(startDate)),
            new Date(formatIsoDateTime(endDate)),
          ]

    onChange(showQuickSelection ? { range, mode: quickSelection } : { range })
  }, [
    endDate,
    onChange,
    startDate,
    strings.dateTimeRangeForm.invalidDate,
    strings.dateTimeRangeForm.invalidRange,
    mode,
    quickSelection,
    showQuickSelection,
    selectionLimit,
    onSelectionViolationChange,
  ])

  // Responsible for setting the correct timespan,
  // when using the quick selection.
  //
  // Special case:
  // 'Custom' - in this case an user defined timespan is requested.
  // Therefore do not update the start and end date (return),
  // as it was already changed by the corresponding DateTimeInput onChange handler.
  useSubsequentEffect(() => {
    if (!showQuickSelection) return

    let end: Date
    let start: Date
    const now = Date.now()

    switch (quickSelection) {
      case "Today":
        start = startOfDay(now)
        end = endOfDay(now)
        break
      case "Yesterday":
        start = startOfYesterday()
        end = endOfYesterday()
        break
      case "ThisWeek":
        start = startOfWeek(now)
        end = endOfDay(now)
        break
      case "ThisMonth":
        start = startOfMonth(now)
        end = endOfDay(now)
        break
      case "ThisYear":
        start = startOfYear(now)
        end = endOfDay(now)
        break
      default:
        return
    }

    setStartDate(start)
    setEndDate(end)
  }, [quickSelection, showQuickSelection])

  // In case the start and end date is a custom / user defined selection,
  // set the quick selection mode to 'Custom'
  // (only if the quick selection is displayed).
  // Otherwise do not update the selection mode again,
  // as it would lead to more side-effect
  // (through useEffect) executions as actually necessary.
  useEffect(() => {
    if (!showQuickSelection) return
    setQuickSelection(computeSelectionMode(startDate, endDate))
  }, [startDate, endDate, showQuickSelection, computeSelectionMode])

  return (
    <Row alignCenter gap="XS">
      {showQuickSelection && (
        <QuickSelectionInput
          {...props}
          quickSelection={quickSelection}
          onChange={setQuickSelection}
        />
      )}

      <Row alignCenter gap="XXS">
        <Label medium text={strings.general.from} />
        <DateTimeInput
          transparent
          type={mode}
          value={startDate}
          disabled={disabled}
          errorMessage={startDateError}
          disableFuture={disableFuture}
          onChange={date => date && setStartDate(date)}
          shouldDisableSelection={shouldDisableFutureUntil}
        />
      </Row>

      <Row alignCenter gap="XXS">
        <Label medium text={strings.general.to} />
        <DateTimeInput
          transparent
          type={mode}
          value={endDate}
          disabled={disabled}
          errorMessage={endDateError}
          disableFuture={disableFuture}
          onChange={date => date && setEndDate(date)}
          shouldDisableSelection={shouldDisableFutureUntil}
        />
      </Row>

      {hideSelectionLimitBubble != true && selectionLimit && (
        <ToolTipIcon
          color={selectionViolation ? "error" : undefined}
          icon={selectionViolation ? "alert-circle" : undefined}
          text={interpolateString(
            strings.dateTimeRangeForm.selectionLimit,
            selectionLimit,
          )}
        />
      )}
    </Row>
  )
}

function QuickSelectionInput({
  disabled,
  quickSelection,
  selectionLimit,
  excludeFromQuickSelection,
  onChange,
}: Omit<DateTimeRangeFormProps, "onChange"> & {
  quickSelection: DateQuickSelection
  onChange: (quickSelection: DateQuickSelection) => void
}) {
  const strings = useKassenAppUi().strings
  let excludedSelectionValues = excludeFromQuickSelection
    ? [...excludeFromQuickSelection, "Custom"]
    : ["Custom"]

  if (selectionLimit != undefined) {
    if (selectionLimit < 1)
      throw Error("Selection limit must be greater or equal to 1")

    if (selectionLimit < 2)
      excludedSelectionValues = [...excludedSelectionValues, "Yesterday"]

    if (selectionLimit < 7)
      excludedSelectionValues = [...excludedSelectionValues, "ThisWeek"]

    if (selectionLimit < 31)
      excludedSelectionValues = [...excludedSelectionValues, "ThisMonth"]

    if (selectionLimit < 366)
      excludedSelectionValues = [...excludedSelectionValues, "ThisYear"]
  }

  return (
    <SingleDropdownInput
      transparent
      fontSize="medium"
      disabled={disabled}
      style={{ width: 85 }}
      value={quickSelection}
      excludedValues={excludedSelectionValues}
      displayKeyValuePairs={strings.dateTimeRangeForm.durations}
      onChange={onChange}
    />
  )
}

export function useComputeSelectionMode(mode: DateTimeRangeFormProps["mode"]) {
  return useCallback(
    (startDate: Date, endDate: Date): DateQuickSelection => {
      const now = Date.now()

      const formatToIso = (date: Date) =>
        mode == "date" ? formatDate(date) : formatDateTime(date)

      const end = formatToIso(endDate)
      const start = formatToIso(startDate)
      const endOfToday = formatToIso(endOfDay(now))

      if (start == formatToIso(startOfDay(now)) && end == endOfToday) {
        return "Today"
      } else if (
        start == formatToIso(startOfYesterday()) &&
        end == formatToIso(endOfYesterday())
      ) {
        return "Yesterday"
      } else if (start == formatToIso(startOfWeek(now)) && end == endOfToday) {
        return "ThisWeek"
      } else if (start == formatToIso(startOfMonth(now)) && end == endOfToday) {
        return "ThisMonth"
      } else if (start == formatToIso(startOfYear(now)) && end == endOfToday) {
        return "ThisYear"
      } else {
        return "Custom"
      }
    },
    [mode],
  )
}
