import * as z from "zod"

import { createEntityFor } from "@axtesys/react-tools"

type Features = { printReceiptInstantly: boolean }

export const FeaturesEntity = createEntityFor<Features>()
  .withSchema(z.object({ printReceiptInstantly: z.boolean() }))
  .serialize(data => data)
  .deserialize(json => json)
