import { Keyboard, Platform } from "react-native"
import { atom, useRecoilValue } from "recoil"

export function useKeyboardState() {
  return useRecoilValue(keyboardState)
}

const keyboardState = atom({
  key: "keyboardState",
  default: "hidden" as "visible" | "hidden",
  effects: [
    ({ setSelf }) => {
      const onShow = () => setSelf("visible")
      const onHide = () => setSelf("hidden")

      const showSubscription = Keyboard.addListener(
        Platform.OS == "ios" ? "keyboardWillShow" : "keyboardDidShow",
        onShow,
      )
      const hideSubscription = Keyboard.addListener(
        Platform.OS == "ios" ? "keyboardWillHide" : "keyboardDidHide",
        onHide,
      )

      return () => {
        showSubscription.remove()
        hideSubscription.remove()
      }
    },
  ],
})
