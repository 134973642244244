import { Dimensions, ScaledSize } from "react-native"
import { atom, useRecoilValue } from "recoil"

export type ScreenSize = {
  width: number
  height: number
}

export function useScreenSize(): ScreenSize {
  return useRecoilValue(screenSizeState)
}

const screenSizeState = atom({
  key: "screenSize",
  default: Dimensions.get("window"),
  effects: [
    ({ setSelf }) => {
      const handler = (dims: { window: ScaledSize; screen: ScaledSize }) => {
        setSelf(dims.window)
      }
      const screenSizeSubscription = Dimensions.addEventListener(
        "change",
        handler,
      )
      return () => screenSizeSubscription.remove()
    },
  ],
})
