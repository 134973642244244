import { initial, isEqual, last } from "lodash"
import React, { lazy, useEffect, useRef } from "react"

import { useTransitionEffect } from "@axtesys/hooks"
import { isEmptyOrBlank } from "@axtesys/react-tools"

import {
  CreateNavigationArgs,
  CreateNavigationResult,
  ScreenDef,
  ScreenRoute,
  ScreenStackProps,
} from "../types"
import { createNavigation } from "./createNavigation"

export const WebSuspenseBoundary = lazy(
  () => import("../boundaries/WebSuspenseBoundary"),
)

export function createWebNavigation<S extends ScreenDef>(
  args: CreateNavigationArgs<S>,
): CreateNavigationResult<S> {
  const { ScreenStackComponent, ...navigation } = createNavigation<S>({
    ...args,
    webScreenMode: true,
    singleScreenMode: true,
  })

  function readRouteFromBrowser(): ScreenRoute<S> | undefined {
    for (const [screenName, urlWithPlaceholders] of Object.entries(args.urls)) {
      if (!urlWithPlaceholders) continue

      // See if the current route matches
      // one of the ScreenRoutes and extract params
      const screenProps = extractPropsFromUrl(
        urlWithPlaceholders,
        document.location.pathname,
      )

      if (!screenProps) continue

      return { screenName, screenProps } as ScreenRoute<S>
    }

    return undefined
  }

  function BrowserLocationSyncer() {
    const ignoreNextUpdateRef = useRef(false)

    const editScreenStack = navigation.useEditScreenStack()
    useEffect(() => {
      const listener = () => {
        if (window.history.state == null) return
        ignoreNextUpdateRef.current = true
        editScreenStack(() => window.history.state)
      }

      window.addEventListener("popstate", listener)
      return () => window.removeEventListener("popstate", listener)
    }, [editScreenStack])

    const stack = navigation.useScreenStack()
    useTransitionEffect(stack, (nextStack, prevStack) => {
      if (ignoreNextUpdateRef.current) {
        ignoreNextUpdateRef.current = false
        return
      }

      const route = last(nextStack)
      if (!route) return

      const isPop = isEqual(nextStack, initial(prevStack))
      if (isPop) {
        window.history.go(-1)
        return
      }

      const location = replacePlaceholdersWithProps(
        args.urls[route.screenName],
        route.screenProps,
      )

      const isPush = nextStack.length > prevStack.length
      if (isPush) window.history.pushState(stack, document.title, location)
      else window.history.replaceState(stack, document.title, location)
    })
    return null
  }

  return {
    ...navigation,

    ScreenStackComponent(props: ScreenStackProps<S>) {
      let defaultRoute = readRouteFromBrowser() ?? props.defaultRoute
      if (props.implementations[defaultRoute.screenName] == null)
        defaultRoute = props.defaultRoute

      return (
        <>
          <ScreenStackComponent
            defaultRoute={defaultRoute}
            fallback={WebSuspenseBoundary}
            implementations={props.implementations}
          />
          <BrowserLocationSyncer />
        </>
      )
    },
  }
}

function replacePlaceholdersWithProps(
  urlWithPlaceholders: string,
  screenProps: Record<string, string | undefined>,
): string {
  // If there is an "id" property in props,
  // search for ":id" in the url and replace it with the value
  let urlWithProps = urlWithPlaceholders
  for (const [key, value] of Object.entries(screenProps))
    urlWithProps = urlWithProps.replace(`:${key}`, value ?? "")

  // Omit params if not found
  urlWithProps = urlWithProps.replace(/:.+/, "")

  return urlWithProps
}

function extractPropsFromUrl(
  urlWithPlaceholders: string,
  urlWithProps: string,
): Record<string, string> | null {
  const screenProps: Record<string, string> = {}
  const parts = urlWithPlaceholders.split("/")
  const routeWithParamsParts = urlWithProps.split("/")

  if (parts.length != routeWithParamsParts.length) return null

  for (let i = 0; i < parts.length; i++) {
    const isPlaceholder = parts[i].startsWith(":")

    if (isPlaceholder) {
      const paramName = parts[i].substring(1)
      const paramValue = routeWithParamsParts[i]

      if (!paramValue.startsWith(":") && !isEmptyOrBlank(paramValue))
        screenProps[paramName] = paramValue
    } else if (!(parts[i] == routeWithParamsParts[i])) return null
  }

  return screenProps
}
