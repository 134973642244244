import { registerLocale } from "i18n-iso-countries"

import * as Sentry from "@sentry/browser"

import versionInfo from "~src/versionInfo.json"

export function setupWebPlatform() {
  registerLocale(require("i18n-iso-countries/langs/de.json"))

  setupSentry()
  injectGlobalStyles()
}

function setupSentry() {
  if (__DEV__) return
  Sentry.init({
    dsn: "https://1717a770b16d4e889f6cc5147024c8f1@o387439.ingest.sentry.io/5222694",
  })
  Sentry.setTag("version", versionInfo.version)
}

// Removes accessibility boxes around input components
function injectGlobalStyles() {
  const style = document.createElement("style")
  style.textContent = `textarea, select, input, button { outline: none!important }`
  document.head.append(style)
}
