import React from "react"

import { LoadingIndicator } from "~shared/components/LoadingIndicator"

// Creates a custom React Context and returns
// - a Provider that sets up the ContextValue in some root component
// - a Hook that provides access to the ContextValue in a child component
// This can be used to reduce the need for "Prop Drilling" in nested components.
//
// If the context value has a truthy `isLoading` property,
// the Provider will render a Loading spinner
// instead of their children, making it so that
// descendants of this context will not access it while it is loading.
export function createCustomContext<Props, ContextValue>(
  useCreateContext: (props: Props) => ContextValue,
) {
  // Create a React Context without default value (we enforce the use of a Provider)
  const context = React.createContext<ContextValue>(null as any)

  function Provider(props: Props & { children?: React.ReactNode }) {
    const contextValue = useCreateContext(props)
    const isLoading = (contextValue as any).isLoading

    return (
      <context.Provider value={contextValue}>
        {isLoading ? <LoadingIndicator /> : props.children}
      </context.Provider>
    )
  }

  function useContext(): Omit<ContextValue, "isLoading"> {
    return React.useContext(context)
  }

  return [Provider, useContext] as const
}
