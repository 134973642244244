import { isEqual } from "lodash"
import React, { Ref, useEffect, useMemo, useRef, useState } from "react"
import {
  ColorValue,
  NativeSyntheticEvent,
  Platform,
  StyleProp,
  TextInput as RNTextInput,
  TextInputFocusEventData,
  TextInputProps as RNTextInputProps,
  TextStyle,
  ViewStyle,
} from "react-native"

import { FocusChainLink } from "@axtesys/hooks"
import { isEmptyOrBlank, mergeRefs } from "@axtesys/react-tools"

import { MCIcon } from "../../MCIcon"
import { Icon } from "../../display/Icon"
import { Row } from "../../layout/FlexBox"
import { useTheme } from "../../theme"
import { Color, TextSize } from "../../types"

// This value regulates the height of the field container
// (the actual RNTextInput will expand to the size of the (parent) container
const HEIGHT = 40

export const SPACING_CORRECTION = 8

export type IconConfig = {
  name: MCIcon

  color?: Color
  size?: "XS" | "S" | "M" | "L"

  onPress?: () => void
}

export type FocusBlurEvent = NativeSyntheticEvent<TextInputFocusEventData>

// Allow every native property except the ones specified
type InheritedProps = Omit<
  RNTextInputProps,
  "ref" | "style" | "onChange" | "onSubmitEditing"
>

// Overwrite the standard / native
// properties with custom implementations
type OverwrittenProps = {
  onChange?: (value: string) => void

  // Style property which is directly applied
  // to the standard react native `TextInput` component
  style?: StyleProp<ViewStyle & TextStyle>
}

type CustomProps = {
  disabled?: boolean
  fontSize?: TextSize
  iconLeft?: IconConfig
  iconRight?: IconConfig
  textInputRef?: Ref<RNTextInput>
  focus?: FocusChainLink
  noValueNeeded?: boolean
  underlineColor?: ColorValue

  mode?: "flat" | "outlined"

  // Style property which is applied to the container of the InputField
  // (Icons and the TextInput is included in there)
  containerStyle?: StyleProp<ViewStyle>

  // When this flag is set the underline color will be displayed
  // as if there wouldn't be a value present.
  // This behavior is noticeable till the value is changed for the first time.
  trackInitialValue?: boolean

  // For convenience reasons we pass a
  // possible error message to the input component
  // It has no other use than displaying an
  // error state (message is not shown in here)
  errorMessage?: string

  // Changes the backgroundColor to a transparent background
  // NOTE: As multiple components would be required and this
  // would lead to quite a lot of boilerplate code,
  // we re-introduce this property back to the base TextInput
  transparent?: boolean

  onPress?: () => void
  onSubmitEditing?: () => void
  onSubmitIfValid?: () => Promise<boolean>
  onBlur?: (event?: FocusBlurEvent) => void
  onFocus?: (event?: FocusBlurEvent) => void
}

export type TextInputProps = InheritedProps & OverwrittenProps & CustomProps

export function TextInput(props: TextInputProps) {
  const errorIndicator = props.errorMessage != undefined

  // Field props

  const optimizedGeneralProps = {
    ...props,
    value: undefined,
    onChange: undefined,
  }
  const customizedInputProps = useCustomizedTextInputProps({
    ...props,
    errorIndicator,
  })

  const field = (
    <RNTextInput {...optimizedGeneralProps} {...customizedInputProps} />
  )

  // Icon definition

  const iconProps = useMemo(
    () => ({
      errorIndicator,
      disabled: props.disabled,
    }),
    [props.disabled, errorIndicator],
  )
  const leftIcon = props.iconLeft && (
    <InputIcon config={props.iconLeft} {...iconProps} />
  )
  const rightIcon = props.iconRight && (
    <InputIcon config={props.iconRight} {...iconProps} />
  )

  // Container props

  const containerProps = useTextInputContainerProps({
    ...props,
    underlineColor: customizedInputProps.underlineColor,
  })

  return (
    <Row
      gap="XXXS"
      alignCenter
      onPress={props.onPress}
      onLayout={props.onLayout}
      {...containerProps}
    >
      {leftIcon}
      {field}
      {rightIcon}
    </Row>
  )
}

function InputIcon(props: {
  config?: IconConfig
  disabled?: boolean
  errorIndicator?: boolean
}) {
  const { config, disabled, errorIndicator } = props

  if (!config) return null

  return (
    <Icon
      {...config}
      disabled={disabled}
      color={errorIndicator ? "error" : config.color}
    />
  )
}

function useCustomizedTextInputProps(
  props: TextInputProps & { errorIndicator: boolean },
) {
  const { fontSize, color, fontFamily } = useTheme()
  const [isFocused, setIsFocused] = useState(false)
  const { multiline, blurOnSubmit, spacingCorrectionStyle } =
    useIOSSelectionHackProps(props)
  const initialValueRef = useRef<string | undefined>(props.value)

  // Sole purpose:
  // Handle the initial-value-tracking functionality
  useEffect(() => {
    if (
      props.trackInitialValue &&
      isEmptyOrBlank(initialValueRef.current) &&
      !isEmptyOrBlank(props.value)
    ) {
      initialValueRef.current = props.value
    }
  }, [props.trackInitialValue, props.value])

  // Determine the color of field's underline and selection
  let interactionColor
  if (props.underlineColor) interactionColor = props.underlineColor
  else if (props.errorIndicator) interactionColor = color.error
  else if (props.disabled) interactionColor = color.disabled
  else if (isFocused && props.editable != false)
    interactionColor = color.secondary2
  else if (
    props.trackInitialValue &&
    isEqual(props.value, initialValueRef.current)
  )
    interactionColor = color.base4
  else if (props.value || props.noValueNeeded) interactionColor = color.primary
  else interactionColor = color.text.light

  const onFocus = (event?: FocusBlurEvent) => {
    setIsFocused(true)
    if (event) props.onFocus?.(event)
    else props.onFocus?.()
  }
  const onBlur = (event?: FocusBlurEvent) => {
    setIsFocused(false)
    if (event) props.onBlur?.(event)
    else props.onBlur?.()
  }

  const onFocusSubmit = async () => {
    // Submit on pressing enter if the form is valid
    const submitted = await props.onSubmitIfValid?.()
    if (submitted) return

    // Otherwise move the focus to the next field
    props.focus?.onSubmitEditing()
  }

  // Handle whatever behavior first before continuing
  // with submitting and focus change
  const onSubmitEditing = () => {
    props.onSubmitEditing?.()
    onFocusSubmit()
  }

  // Defines the aggregated ref for (auto) focus behavior and
  // navigation between input fields
  const ref = mergeRefs(
    props.textInputRef,
    props.focus?.ref,
    useAutoFocusHackRef({
      autoFocus: props.autoFocus,
      onFocus,
    }),
  )

  const style = useMemo(
    () => [
      spacingCorrectionStyle,
      {
        flex: 1,
        height: HEIGHT,
        // paddingHorizontal is required to be set to zero,
        // as older Android version (7.1) add an unwanted custom padding.
        paddingHorizontal: 0,
        fontFamily: fontFamily.sourceSansPro.regular,
        color: props.errorIndicator ? color.error : color.text.default,
        fontSize: props.fontSize ? fontSize[props.fontSize] : fontSize.default,
      },
      props.style,
    ],
    [
      color.error,
      color.text.default,
      fontFamily.sourceSansPro.regular,
      fontSize,
      props.errorIndicator,
      props.fontSize,
      props.style,
      spacingCorrectionStyle,
    ],
  )

  // It does not make sense to fire a
  // change event in case the value has not changed
  const onChangeText = (text: string) => {
    if (props.value == text) return
    props.onChange?.(text)
  }

  return {
    ref,
    style,
    multiline,
    blurOnSubmit,

    autoFocus: false,
    value: props.value ?? "",
    placeholderTextColor: color.base4,
    selectionColor: (Platform.OS != "android"
      ? props.selectionColor ?? interactionColor
      : undefined) as ColorValue | undefined,
    editable: !props.disabled && props.editable,
    keyboardType: props.keyboardType ?? "default",
    underlineColor: interactionColor as ColorValue,
    returnKeyType: props.returnKeyType ? props.returnKeyType : "next",

    onBlur,
    onFocus,
    onChangeText,
    onSubmitEditing,
  }
}

function useTextInputContainerProps(props: TextInputProps) {
  const { color } = useTheme()

  const mode = props.mode ?? "flat"

  const backgroundColor = useMemo(
    () => (props.transparent ? "transparent" : color.background),
    [color.background, props.transparent],
  )

  const style: StyleProp<ViewStyle> = [
    mode == "outlined" && {
      borderRadius: 5,
      borderColor: color.text.default,
      // Differentiate between our mobile and web solutions
      borderWidth: Platform.OS == "web" ? 2 : 1,
    },
    mode == "flat" && {
      borderBottomWidth: 2,
    },
    {
      backgroundColor,
      // We always want to color the bottom border
      // based on input and focus state
      borderBottomColor: props.underlineColor,
    },
    props.containerStyle,
  ]

  return { style }
}

function useAutoFocusHackRef(props: { autoFocus?: boolean; onFocus(): void }) {
  const focusedOnceRef = useRef(false)
  const elementRef = useRef<RNTextInput | null>(null)

  const focusNow = () => {
    if (!props.autoFocus) return
    if (focusedOnceRef.current) return

    const element = elementRef.current
    if (element == null) return

    element.focus()
    props.onFocus()

    focusedOnceRef.current = true
  }

  return (element: RNTextInput | null) => {
    elementRef.current = element
    // An increased timeout duration is required.
    // Otherwise the selection behavior will break (in release mode)
    // and affect automatic formatting in a negative manner.
    setTimeout(focusNow, 100)
  }
}

function useIOSSelectionHackProps(props: TextInputProps) {
  // Last checked on 15th of February 2023
  // iOS-only: Properties corresponding to that flag are part of the autoFocus + selectTextOnFocus hack
  // autoFocus + selectTextOnFocus Bugs on Android and iOS are still not fixed in latest RNV
  // https://github.com/facebook/react-native/issues/30585
  // https://github.com/facebook/react-native/issues/41988
  const isIOSSelectionHackEnabled =
    Platform.OS == "ios" && (props.selectTextOnFocus || props.autoFocus)

  // Also part of our selection hack.
  // Required, as otherwise blurOnSubmit could break behavior on Android.
  let blurOnSubmit
  if (
    props.blurOnSubmit == true ||
    (isIOSSelectionHackEnabled && !props.multiline)
  ) {
    blurOnSubmit = true
  } else if (props.blurOnSubmit == false) {
    blurOnSubmit = false
  } else blurOnSubmit = undefined

  return {
    blurOnSubmit,
    multiline: props.multiline || isIOSSelectionHackEnabled,
    // In case of selection hack being used,
    // it is required to correct the inserted space of a multiline field
    spacingCorrectionStyle: isIOSSelectionHackEnabled && {
      paddingTop: SPACING_CORRECTION,
    },
  }
}
