import { atom } from "recoil"
import * as z from "zod"

import { jsonDbAtom } from "@axtesys/react-tools"

import { DrawerDevice } from "~shared/api/graphql"
import { selector } from "~shared/lib/recoil/lib"

import { receiptPrintersState } from "../CashRegister/state"

export const drawerDeviceState = jsonDbAtom({
  key: "drawerDevice",
  default: "None" as DrawerDevice,
  schema: z.enum(["None", "Sunmi", "Printer"]),
})

export const drawerPresentSelector = selector({
  key: "drawerPresent",
  get: ({ get }) => get(drawerDeviceState) != "None",
})

export const drawerConnectedPrinterIdState = jsonDbAtom({
  key: "drawerConnectedPrinterId",
  default: undefined as string | null | undefined,
  schema: z.string().nullable().optional(),
})

export const drawerConnectedPrinterState = selector({
  key: "drawerConnectedPrinter",
  get: ({ get }) =>
    get(receiptPrintersState).find(
      printer => printer.printerId == get(drawerConnectedPrinterIdState),
    ),
})

export const openDrawerRequestsState = atom({
  key: "openDrawerRequests",
  default: 0,
})

export const openDrawerSelector = selector({
  key: "openDrawer",
  get: ({ get }) => get(openDrawerRequestsState) > 0,
})
