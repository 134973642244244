import { useCallback, useEffect, useState } from "react"

import { useForm, UseFormValue } from "@axtesys/hooks"
import { interpolateString, isEmptyOrBlank } from "@axtesys/react-tools"

import { MutationVariables, useGraphQL } from "~shared/api/graphql"

import {
  isValidEmail,
  isValidPhoneNumber,
  isValidTaxNumber,
  isZipCodeValid,
} from "../../lib/Validation"
import { CompanyId } from "../../types"
import { useLogin, useLogoutAndFail, useStrings } from "./hooks"

type LoginType = "manager" | "admin"
export type LoginFormData = { email: string; password: string }
export type LoginFormDataValue = UseFormValue<LoginFormData>

export function useRegisterCompanyWithManager(
  navigateToLogin: (managerEmail: string) => void,
) {
  const strings = useStrings()
  const { sendMutation } = useGraphQL()

  return useCallback(
    async (
      variables: MutationVariables<"RegisterCompanyWithManager">,
      form: UseFormValue<{
        companyName: string
        email: string
        taxId: string
        password: string
        repeatedPassword: string
      }>,
    ) => {
      await sendMutation("RegisterCompanyWithManager", {
        variables: { ...variables, taxId: variables.taxId.toUpperCase() },
        onSuccess: () => navigateToLogin(variables.managerEmail),
        snackMessage: () =>
          interpolateString(
            strings.successRegisterMessage,
            variables.companyName,
          ),
        onError: ({ errorCode, errorMessage }) => {
          switch (errorCode) {
            case "CompanyNameExists":
              form.setErrorMessages({ companyName: errorMessage })
              break
            case "InvalidEmailAddress":
            case "ManagerEmailExists":
              form.setErrorMessages({ email: errorMessage })
              break
            case "TaxIdExists":
            case "InvalidTaxId":
              form.setErrorMessages({ taxId: errorMessage })
              break
            case "InvalidPassword":
              form.setErrorMessages({
                password: errorMessage,
                repeatedPassword: errorMessage,
              })
              break
            default:
              break
          }
        },
      })
    },
    [navigateToLogin, sendMutation, strings.successRegisterMessage],
  )
}

export function useRegistrationForm(
  navigateToLogin: (managerEmail: string) => void,
) {
  const strings = useStrings()
  const registerCompanyWithManager =
    useRegisterCompanyWithManager(navigateToLogin)

  return useForm({
    data: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      password: "",
      repeatedPassword: "",
      companyName: "",
      taxId: "",
      street: "",
      streetNumber: "",
      zipCode: "",
      city: "",
      tosAccepted: false as boolean,
    },
    label: {
      firstName: strings.firstName,
      lastName: strings.lastName,
      email: strings.email,
      phoneNumber: strings.phoneNumber,
      password: strings.password,
      repeatedPassword: strings.repeatPassword,
      companyName: strings.company,
      taxId: strings.uidNumber,
      street: strings.street,
      streetNumber: strings.streetNumber,
      zipCode: strings.zipCode,
      city: strings.city,
    },
    placeholder: {
      email: strings.emailHint,
      phoneNumber: strings.phoneNumberHint,
      taxId: strings.uidNumberHint,
    },
    mandatory: {
      firstName: true,
      lastName: true,
      email: true,
      password: true,
      repeatedPassword: true,
      companyName: true,
      taxId: true,
      street: true,
      streetNumber: true,
      zipCode: true,
      city: true,
      phoneNumber: true,
    },
    validate: {
      email: email => isValidEmail(email) || strings.validationEmail,
      repeatedPassword: (repeatedPassword, { password }) =>
        repeatedPassword == password || strings.validationPasswordsNotMatching,
      taxId: taxId => isValidTaxNumber(taxId) || strings.validationTaxNumber,
      zipCode: zipCode => isZipCodeValid(zipCode) || strings.validationZipCode,
      tosAccepted: tosAccepted =>
        tosAccepted == true || strings.validationTosNotAccepted,
      phoneNumber: phoneNumber =>
        isValidPhoneNumber(phoneNumber) || strings.validationPhoneNumber,
    },
    submit: async (
      {
        email: managerEmail,
        firstName: managerFirstName,
        lastName: managerLastName,
        password: managerPassword,
        phoneNumber: managerTelephone,
        ...data
      },
      form,
    ) => {
      await registerCompanyWithManager(
        {
          ...data,
          managerEmail,
          managerFirstName,
          managerLastName,
          managerPassword,
          managerTelephone,
        },
        form,
      )
    },
  })
}

export function useRequestResendActivation(isEmailNotConfirmed: boolean) {
  const strings = useStrings()
  const { sendMutation } = useGraphQL()
  const [activationEmailResent, setActivationEmailResent] = useState(false)

  // In case the use already used the resend `LinkLabel`,
  // after registration and tries to login,
  // but is still not confirmed re-display the resend `LinkLabel`
  useEffect(() => {
    if (isEmailNotConfirmed) setActivationEmailResent(false)
  }, [isEmailNotConfirmed])

  const resendActivation = useCallback(
    async (managerEmail: string, form: UseFormValue<LoginFormData>) => {
      await sendMutation("PublicResendActivationEmail", {
        variables: { managerEmail },
        onSuccess: () => {
          setActivationEmailResent(true)
          form.setErrorMessages({})
        },
        snackMessage: () =>
          interpolateString(strings.successResendActivationMail, managerEmail),
        onError: ({ errorCode, errorMessage }) => {
          switch (errorCode) {
            case "InvalidEmailAddress":
              form.setErrorMessages({ email: errorMessage })
              break
            case "AlreadyConfirmed":
              setActivationEmailResent(true)
              form.setErrorMessages({ email: errorMessage })
              break
            default:
              break
          }
        },
      })
    },
    [sendMutation, strings.successResendActivationMail],
  )

  return { activationEmailResent, resendActivation }
}

export function useLoginAdmin() {
  const login = useLogin()
  const { sendMutation } = useGraphQL()

  return useCallback(
    async (variables: LoginFormData, form: LoginFormDataValue) => {
      await sendMutation("LoginAdminAccount", {
        variables,
        excludeFromLogging: "password",
        onSuccess: ({ data }) => login(data.authentication.loginAdmin),
        onError: ({ errorCode, errorMessage }) => {
          switch (errorCode) {
            case "InvalidEmailAddress":
              form.setErrorMessages({ email: errorMessage })
              break
            case "InvalidCredentials":
              form.setErrorMessages({ submit: errorMessage })
              break
            default:
              break
          }
        },
      })
    },
    [login, sendMutation],
  )
}

export function useAssumeCompany() {
  const login = useLogin()
  const { sendMutation } = useGraphQL()
  const logoutAndFail = useLogoutAndFail()

  return useCallback(
    async (companyId?: CompanyId) => {
      await sendMutation("AssumeCompany", {
        variables: { companyId },
        onSuccess: ({ data }) => login(data.authentication.assumeCompany),
        onError: logoutAndFail,
      })
    },
    [login, logoutAndFail, sendMutation],
  )
}

export function useLoginManager() {
  const login = useLogin()
  const { sendMutation } = useGraphQL()

  return useCallback(
    async (variables: LoginFormData, form: LoginFormDataValue) => {
      await sendMutation("LoginManagerAccount", {
        variables,
        excludeFromLogging: "password",
        onSuccess: ({ data }) => login(data.authentication.loginManager),
        onError: ({ errorCode, errorMessage }) => {
          switch (errorCode) {
            case "AccountLocked":
            case "InvalidEmailAddress":
            case "EmailNotConfirmed":
              form.setErrorMessages({ email: errorMessage })
              break
            case "InvalidCredentials":
              form.setErrorMessages({ submit: errorMessage })
              break
            default:
              break
          }
        },
      })
    },
    [login, sendMutation],
  )
}

function useLoginUser(loginUserType: LoginType) {
  const loginAdmin = useLoginAdmin()
  const loginManager = useLoginManager()

  return async (variables: LoginFormData, form: LoginFormDataValue) => {
    if (loginUserType == "admin") {
      await loginAdmin(variables, form)
    } else {
      await loginManager(variables, form)
    }
  }
}

export function useLoginUserForm(loginUserType: LoginType) {
  const strings = useStrings()
  const login = useLoginUser(loginUserType)

  return useForm({
    data: { email: "", password: "" },
    label: {
      email: strings.email,
      password: strings.password,
    },
    mandatory: { email: true, password: true },
    validate: {
      email: email => isValidEmail(email) || strings.validationCompanyEmail,
      password: password =>
        !isEmptyOrBlank(password) || strings.validationCompanyPassword,
    },
    submit: ({ email, password }, form) => login({ email, password }, form),
  })
}
