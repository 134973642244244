import React, { useMemo, useRef } from "react"
import { isMobile } from "react-device-detect"
import { ActivityIndicator, Platform } from "react-native"

import {
  useDeviceInfo,
  useOnOff,
  useStateIfMounted,
  useSubsequentEffect,
} from "@axtesys/hooks"

import { logError } from "../../../src/shared/feature/Logging/hooks"
import { MCIcon } from "../MCIcon"
import { HoverablePressableOpacity } from "../container/HoverablePressableOpacity"
import { Icon } from "../display/Icon"
import { useScreenSize } from "../hooks/useScreenSize"
import { ErrorField } from "../input/field/ErrorField"
import { Column, Row } from "../layout/FlexBox"
import { useTheme } from "../theme"
import { Color } from "../types"
import { Label } from "../typography/Label"

export type ButtonProps = {
  // Main text / label
  text?: string

  // Sub text displayed below the main text / label
  subText?: string

  // Icon which is displayed
  // on the left side of the label text
  icon?: MCIcon

  // If true, the button uses
  // the full width of its container.
  fluid?: boolean

  // Use 'call-to-action' for important
  // buttons that initiate a new action.
  callToAction?: boolean

  // Button could also have no filling color,
  // only the borders and the text is colored
  outlined?: boolean

  // Button can be set to be disabled, ignoring presses.
  // It will also be disabled if no onPress handler is given.
  disabled?: boolean

  // A Button can show a pending state (loading indicator).
  // It can still be pressed, unless `disabled` is also set!
  pending?: boolean

  // An error message to be displayed below the button
  errorMessage?: string

  // In case this is set, and we are on a larger screen,
  // use the maxWidth for the button
  fluidOverwrite?: boolean

  onHoverIn?(): void
  onHoverOut?(): void
  onPress?(): void | Promise<void>
}

export function Button(props: ButtonProps) {
  const maxWidth = useMaxWidth()
  const isWeb = Platform.OS == "web"
  const { deviceType } = useDeviceInfo()
  const { isPending, onPress } = usePendingState(props)
  const { isHovered, onHoverIn, onHoverOut } = useHoverState()
  const { isPressed, onPressIn, onPressOut } = usePressedState()

  const padVertical = isWeb ? "XS" : "XXS"
  const fluidOverwrite =
    props.fluid && props.fluidOverwrite && deviceType != "phone"
  const isDisabled = props.disabled || props.onPress == null || isPending
  const { color, fillingColor, labelColor } = useColors(props, {
    isPending,
    isDisabled,
    isHovered,
    isPressed,
  })

  const onHandleHoverIn = () => {
    onHoverIn()
    props.onHoverIn?.()
  }

  const onHandleHoverOut = () => {
    onHoverOut()
    props.onHoverOut?.()
  }

  useSubsequentEffect(() => {
    if (!isWeb || !(isPressed || isPending)) return
    onHandleHoverOut()
  }, [isPressed, isPending])

  const pendingIndicator = isPending && <ActivityIndicator color="white" />

  const icon = props.icon && (
    <Icon size="S" name={props.icon} color={labelColor} />
  )

  const text = (
    <Column alignCenter>
      <Label
        bold={!isWeb}
        text={props.text}
        selectable={false}
        textAlign="center"
        color={labelColor}
      />
      {props.subText && (
        <Label
          small
          textAlign="center"
          selectable={false}
          color={labelColor}
          text={props.subText}
        />
      )}
    </Column>
  )

  const button = (
    <HoverablePressableOpacity
      disabled={isDisabled}
      onPress={onPress}
      onPressIn={onPressIn}
      onPressOut={onPressOut}
      onHoverIn={onHandleHoverIn}
      onHoverOut={onHandleHoverOut}
      onLongPress={isMobile ? onHandleHoverIn : undefined}
    >
      <Row
        gap="XXXS"
        alignCenter
        borderRadius="M"
        borderColor={color}
        padVertical={padVertical}
        padHorizontal={["XXS", "XS"]}
        backgroundColor={fillingColor}
        style={[
          {
            height: 50,
            maxWidth: "100%",
            borderWidth: 2,
            justifyContent: "center",
          },
          isHovered && { opacity: 0.8 },
          isDisabled && { opacity: 0.4 },
        ]}
      >
        {pendingIndicator}
        {icon}
        {text}
      </Row>
    </HoverablePressableOpacity>
  )

  const buttonWithErrorHandling = props.errorMessage ? (
    <ErrorField errorMessage={props.errorMessage}>{button}</ErrorField>
  ) : (
    button
  )

  return (
    <Column
      style={[
        props.fluid && { width: "100%" },
        (!props.fluid || fluidOverwrite) && { maxWidth },
      ]}
    >
      {buttonWithErrorHandling}
    </Column>
  )
}

function usePendingState(props: ButtonProps) {
  const { onPress } = props

  // This is to really avoid running two onPress commands in parallel
  const pendingRef = useRef(false)

  const [isPending, setIsPending] = useStateIfMounted(false)

  return {
    // `pending` from props overrides the internal state
    isPending: props.pending ?? isPending,

    // disable `onPress` while the previous onPress-Handler is still running
    onPress:
      onPress && !isPending
        ? async () => {
            if (!pendingRef.current) {
              try {
                setIsPending(true)
                pendingRef.current = true
                await Promise.resolve(onPress())
              } catch (error) {
                logError("Uncaught error in Button onPress handler", error)
                throw error
              } finally {
                pendingRef.current = false
                setIsPending(false)
              }
            }
          }
        : undefined,
  }
}

function useHoverState() {
  const [isHovered, onHoverIn, onHoverOut] = useOnOff(false)
  return { isHovered, onHoverIn, onHoverOut }
}

function usePressedState() {
  const [isPressed, onPressIn, onPressOut] = useOnOff(false)
  return { isPressed, onPressIn, onPressOut }
}

function useMaxWidth() {
  const screenSize = useScreenSize()
  // Custom maxWidth the button and its error message, depending on the screen size of the used device
  return useMemo(
    () =>
      ({
        S: 250,
        M: 300,
        L: 350,
      })[screenSize],
    [screenSize],
  )
}

function useColors(
  props: ButtonProps,
  args: {
    isDisabled: boolean
    isPressed: boolean
    isPending: boolean
    isHovered: boolean
  },
) {
  const theme = useTheme()

  const color = useMemo(() => {
    const variants = props.callToAction
      ? theme.color.button.callToAction
      : theme.color.button.default

    let color: string
    if (args.isDisabled) color = variants.disabled
    else if (args.isPressed || args.isPending) color = variants.pressed
    else if (args.isHovered) color = variants.hovered
    else color = variants.default
    return color
  }, [
    args.isDisabled,
    args.isHovered,
    args.isPending,
    args.isPressed,
    props.callToAction,
    theme,
  ])

  const fillingColor = !props.outlined ? color : undefined

  const labelColor = useMemo<Color>(() => {
    if (props.outlined) return props.callToAction ? "accent" : "primary"
    return "background"
  }, [props.callToAction, props.outlined])

  return { color, fillingColor, labelColor }
}
