import React, { ReactNode } from "react"

import { useKassenAppUi } from "../../Context"
import { AlternativePressableOpacity } from "../../button/AlternativePressableOpacity"
import { Icon } from "../../display/Icon"
import { ToolTipIcon } from "../../display/ToolTipIcon"
import { useToolTipFontConfig } from "../../hooks/useToolTipFontConfig"
import { Box } from "../../layout/Box"
import { Column, Row } from "../../layout/FlexBox"
import { Label } from "../../typography/Label"
import { IconConfig } from "../field/TextInput"

type ToolTipIconConfig = IconConfig & { tooltip?: string }

export type InputRowProps = {
  text?: string
  disabled?: boolean
  children?: ReactNode
  icon?: ToolTipIconConfig
  onPress?: () => void
}

export function InputRow({
  icon,
  text,
  disabled,
  children,
  onPress,
}: InputRowProps) {
  const content = (
    <Row
      gap="XXXS"
      alignCenter
      spaceBetween
      padLeft="XS"
      padVertical="XXXS"
      disabled={disabled}
    >
      {text && <Label bold text={text} />}
      {children}
      {icon && (
        <Box pad="XXXXS">
          {icon.tooltip ? (
            <InputToolTipIcon {...icon} />
          ) : (
            <Icon name={icon.name} color={icon.color} />
          )}
        </Box>
      )}
    </Row>
  )

  return onPress ? (
    <AlternativePressableOpacity disabled={disabled} onPress={onPress}>
      {content}
    </AlternativePressableOpacity>
  ) : (
    content
  )
}

function InputToolTipIcon({ name, color, tooltip }: ToolTipIconConfig) {
  const {
    general: { note },
  } = useKassenAppUi().strings
  const { titleProps, messageProps } = useToolTipFontConfig()

  return (
    <ToolTipIcon icon={name} color={color ?? "default"}>
      <Column gap={["S", "S", "XS"]}>
        <Label {...titleProps} text={note} />
        <Label {...messageProps} text={tooltip} />
      </Column>
    </ToolTipIcon>
  )
}
