import React, { ReactNode } from "react"

import { Box, BoxProps } from "../layout/Box"

export type CardProps = {
  children: ReactNode
}

export function Card(props: CardProps & BoxProps) {
  return (
    <Box
      elevation={10}
      borderRadius="L"
      backgroundColor="white"
      pad={["XXXS", "XS", "S"]}
      {...props}
    >
      {props.children}
    </Box>
  )
}
