import { DependencyList, useEffect } from "react"

// Executes the action after the given number of milliseconds.
// The timeout restarts (whether or not the action was executed) whenever the DependencyList changes
export function useTimeoutEffect(
  milliseconds: number,
  action: () => void,
  dependencyList: DependencyList,
) {
  useEffect(() => {
    const timeout = setTimeout(action, milliseconds)
    return () => clearTimeout(timeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencyList)
}
