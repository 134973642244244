import { atom } from "recoil"
import * as z from "zod"

import { jsonDbAtom, secondsToDate } from "@axtesys/react-tools"

import { Salutation } from "../../api/graphql/types"
import { selector } from "../../lib/recoil/lib"
import { PermissionsEntity } from "../../types"
import { FeaturesEntity } from "../../types/Features"
import { MIN_REMAINING_JWT_TTL } from "./constants"

const authenticationSchema = z
  .object({
    accessToken: z.string(), // JWT
    accessExpiresAt: z.string(), // isoDate
    refreshToken: z.string(), // JWT
    refreshExpiresAt: z.string(), // isoDate

    companyId: z.string().optional(),
    companyName: z.string().optional(),
    userId: z.string(),
    userRole: z.enum(["Operator", "Manager", "Administrator"]),
    firstName: z.string(),
    lastName: z.string(),
    salutation: z.enum([
      "Mr",
      "Ms",
      "NonBinary",
      "Enterprise",
      "NotSpecified",
      "Family",
      "Club",
    ]),

    features: z.optional(FeaturesEntity.schema),
    permissions: z.optional(PermissionsEntity.schema),
    modules: z.array(z.string()).optional(),
  })
  .nullable()
  .optional()

export const authenticatedUserState = jsonDbAtom({
  key: "authenticatedUser",
  default: undefined as z.infer<typeof authenticationSchema>,
  schema: authenticationSchema,
})

export const userLoggedInState = atom({
  key: "userLoggedIn",
  default: selector({
    key: `userLoggedIn.default`,
    get: ({ get }) => {
      const authenticatedUser = get(authenticatedUserState)

      if (!authenticatedUser) return false

      // In case the remaining time to live of the
      // refresh token is lower than MIN_REMAINING_JWT_TTL,
      // consider it no longer valid.
      return (
        secondsToDate(authenticatedUser.refreshExpiresAt) >=
        MIN_REMAINING_JWT_TTL
      )
    },
  }),
})

export const operatorSelector = selector({
  key: "operator",
  get: ({ get }) => {
    const operatorInfo = get(authenticatedUserState)

    return {
      operatorId: operatorInfo?.userId ?? "",
      salutation: operatorInfo?.salutation ?? ("NotSpecified" as Salutation),
      fullName: `${operatorInfo?.firstName} ${operatorInfo?.lastName}`,
    }
  },
})
