import React, { useCallback, useState } from "react"
import { View } from "react-native"

import { useOnOff } from "@axtesys/hooks"

import { PressableOpacity } from "../../button/PressableOpacity"
import { KeyLabels, Menu } from "../../container/Menu"
import { useReCalculationEffect } from "../../hooks/useReCalculationEffect"
import { calculatePosition } from "../../lib"
import { ScreenCoordinates } from "../../types"
import { TextInput, TextInputProps } from "./TextInput"
import { useDisplayValue } from "./shared/hooks"

type DropdownInputProps = Omit<
  TextInputProps,
  "iconLeft" | "iconRight" | "mode" | "onChange"
> & {
  displayKeyValuePairs: KeyLabels
  excludedValues?: (keyof KeyLabels)[]
}

export type SingleDropdownInputProps = {
  value?: keyof KeyLabels
  onChange(value: keyof KeyLabels): void
} & DropdownInputProps

export type MultiDropdownInputProps = {
  category: string
  values: (keyof KeyLabels)[]
  onChange(values: (keyof KeyLabels)[]): void

  defaultKey?: keyof KeyLabels
} & DropdownInputProps

type DropdownInputInternalProps = {
  displayValue: string
  mode: "single" | "multi"
  onSelect(key: keyof KeyLabels): void

  values?: (keyof KeyLabels)[]
} & DropdownInputProps

export function SingleDropdownInput(props: SingleDropdownInputProps) {
  const { value, onChange, displayKeyValuePairs } = props

  return (
    <InternalDropdownInput
      mode="single"
      displayValue={displayKeyValuePairs[value ?? ""] ?? ""}
      onSelect={onChange}
      {...props}
    />
  )
}

export function MultiDropdownInput(props: MultiDropdownInputProps) {
  const { values, defaultKey = "default", onChange } = props

  const onSelectMulti = useCallback(
    (key: keyof KeyLabels) => {
      if (!values.find(value => value == key)) {
        if (
          key == defaultKey ||
          (values.length == 1 && values[0] == defaultKey)
        ) {
          onChange([key])
        } else {
          onChange([...values, key])
        }
      } else if (values.length > 1) {
        onChange(values.filter(value => value != key))
      } else {
        onChange([defaultKey])
      }
    },
    [defaultKey, onChange, values],
  )

  return (
    <InternalDropdownInput
      mode="multi"
      displayValue={useDisplayValue(props)}
      onSelect={onSelectMulti}
      {...props}
    />
  )
}

function InternalDropdownInput(props: DropdownInputInternalProps) {
  const {
    mode,
    values,
    displayValue,
    excludedValues,
    displayKeyValuePairs,
    onSelect,
    ...textInputProps
  } = props

  // Required to adjust positioning
  // of dropdown when window dimensions change
  const viewRef = React.createRef<View>()
  const [position, setPosition] = useState<ScreenCoordinates>({ x: 0, y: 0 })
  const calculateMenuPosition = () =>
    calculatePosition({ viewRef, setPosition, viewHeightAddition: true })

  // Dimensions received from the `TextInput` component in order to:
  // Set the `minWidth` of the menu. This leads to a better fit /
  // display for a non-collapsed `Menu`.
  const [minWidth, setMinWidth] = useState<number | undefined>()
  const [menuVisible, showMenu, hideMenu] = useOnOff(false)

  useReCalculationEffect({
    dependency: menuVisible,
    executeIfNotVisible: hideMenu,
    calculate: calculateMenuPosition,
  })

  const interceptor = (
    <PressableOpacity onPress={!props.disabled ? showMenu : undefined}>
      <View pointerEvents="none" ref={viewRef}>
        <TextInput
          {...textInputProps}
          editable={false}
          value={displayValue}
          iconRight={{ name: menuVisible ? "chevron-up" : "chevron-down" }}
          onChange={() => {}}
        />
      </View>
    </PressableOpacity>
  )

  const menu = (
    <Menu
      position={position}
      minWidth={minWidth}
      visible={menuVisible}
      selectedValues={values}
      excludedValues={excludedValues}
      displayKeyValuePairs={displayKeyValuePairs}
      onDismiss={hideMenu}
      onSelect={key => {
        onSelect(key)
        calculateMenuPosition()
        if (mode == "single") hideMenu()
      }}
    />
  )

  return (
    <View
      pointerEvents={props.disabled ? "none" : undefined}
      onLayout={({ nativeEvent: { layout } }) => setMinWidth(layout.width)}
    >
      {interceptor}
      {!props.disabled && menu}
    </View>
  )
}
