import { useEffect, useState } from "react"
import { Observable, BehaviorSubject } from "rxjs"

export function useObservable<T>(behaviorSubject: BehaviorSubject<T>): T
export function useObservable<T>(observable: Observable<T>): T | undefined
export function useObservable<T>(observable: Observable<T>) {
  const [state, setState] = useState(
    observable instanceof BehaviorSubject ? observable.getValue() : undefined,
  )

  useEffect(() => {
    const subscription = observable.subscribe(setState)
    return () => subscription.unsubscribe()
  }, [observable])

  return state
}
